import React from "react";
import { FormattedDate, FormattedTime, FormattedMessage } from "react-intl";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { UncontrolledAlert } from "reactstrap";

import Loader from "../utils/withLoading";
import { defineTeamRequestStatusUi } from "../../assets/general_functions/genericHelpers";
import TeamRequestsDetailComponent from "./teamRequestDetail/TeamRequestDetailComponent";
import { sortArray } from "../../assets/general_functions/objectsAndArrays";

class TeamRequestsTableComponent extends React.Component {
    state = {
        detailsPanelIsOpen: false,
        activeRequest: ""
    };

    toggleDetailsPanel = (newActiveRequest = "") => {
        this.setState(prevState => ({
            detailsPanelIsOpen: !prevState.detailsPanelIsOpen,
            activeRequest: newActiveRequest
        }));

        this.props.actions.closeNotificationAlert();
    };

    // A function to render a single table row
    renderTableRow = (request, isOddRow) => {
        const requestOwnerText = request.owner != undefined ? request.owner.emailAddress : "";
        const rowStyle = isOddRow ? "odd-row" : "";
        const teamRequestStatusUi = defineTeamRequestStatusUi(request.status);

        let actionTextClass = "request-table-details-action";
        let actionText = (
            <FormattedMessage id="teamRequestTable.openDetailPanelText" defaultMessage="Meer informatie..." />
        );

        // If the use should approve or reject the request, change the actionText and class.
        if (request.status === 0 && this.props.userCanApprove) {
            actionTextClass = actionTextClass.concat("--action-required");
            actionText = (
                <FormattedMessage
                    id="teamRequestTable.approvalRequiredOpenDetailPanelText"
                    defaultMessage="Goedkeuring vereist"
                />
            );
        }

        return (
            <tr className={rowStyle} key={request.id + request.title}>
                <td>
                    <span>{request.title}</span>
                </td>
                <td>
                    <FormattedDate value={request.created} year="numeric" month="long" day="numeric" />
                    {" - "}
                    <FormattedTime value={request.created} />
                </td>
                <td>
                    <span>{requestOwnerText}</span>
                </td>
                <td>
                    <span>{request.teamType.name}</span>
                </td>
                <td>
                    <span style={{ color: teamRequestStatusUi.fontColor }}>{teamRequestStatusUi.statusText}</span>
                </td>
                <td className="request-table-action-column" onClick={() => this.toggleDetailsPanel(request)}>
                    <div className={actionTextClass}>{actionText}</div>
                </td>
            </tr>
        );
    };

    renderNotificationBar = () => {
        const { removeRequestStatus, approveRequestStatus, rejectRequestStatus } = this.props.teamRequests;
        const shouldRender = removeRequestStatus != "" || approveRequestStatus != "" || rejectRequestStatus != "";
        let notificationBar = "";

        if (shouldRender) {
            let notificationText = "";
            let color = "";

            if (removeRequestStatus) {
                color = removeRequestStatus === "success" ? "success" : "danger";
                notificationText =
                    removeRequestStatus === "success" ? (
                        <FormattedMessage
                            id="teamRequestTableDetail.action.deleteSuccess"
                            defaultMessage="Aanvraag verwijderd"
                        />
                    ) : (
                        <FormattedMessage
                            id="teamRequestTableDetail.action.deleteFailure"
                            defaultMessage="Er ging iets mis bij het verwijderen van de aanvraag"
                        />
                    );
            } else if (approveRequestStatus) {
                color = approveRequestStatus === "success" ? "success" : "danger";
                notificationText =
                    approveRequestStatus === "success" ? (
                        <FormattedMessage
                            id="teamRequestTableDetail.action.approveSuccess"
                            defaultMessage="Aanvraag goedgekeurd"
                        />
                    ) : (
                        <FormattedMessage
                            id="teamRequestTableDetail.action.approveFailure"
                            defaultMessage="Er ging iets mis bij het goedkeuren van de aanvraag"
                        />
                    );
            } else if (rejectRequestStatus) {
                color = rejectRequestStatus === "success" ? "success" : "danger";
                notificationText =
                    rejectRequestStatus === "success" ? (
                        <FormattedMessage
                            id="teamRequestTableDetail.action.rejectSuccess"
                            defaultMessage="Aanvraag afgewezen"
                        />
                    ) : (
                        <FormattedMessage
                            id="teamRequestTableDetail.action.rejectFailure"
                            defaultMessage="Er ging iets mis bij het afwijzen van de aanvraag"
                        />
                    );
            }

            notificationBar = (
                <UncontrolledAlert
                    className="custom-team-request-alert"
                    color={color}
                    toggle={() => this.props.actions.closeNotificationAlert()}
                >
                    {notificationText}
                </UncontrolledAlert>
            );
        }

        return notificationBar;
    };

    render() {
        const {
            provisioningRequests,
            provisioningRequestsLoading,
            provisioningRequestsError
        } = this.props.teamRequests;
        const { detailsPanelIsOpen, activeRequest } = this.state;
        let provisioningRequestRows = null; // This is null rather than "" because of a whitespace error that occurs in the table otherwise
        let notificationBar = this.renderNotificationBar();
        let content = "";

        if (provisioningRequests && provisioningRequests.length > 0) {
            const sortedTeamRequests = [...provisioningRequests].sort(sortArray("created"));
            let isOddRow = false;
            provisioningRequestRows = sortedTeamRequests.map(request => {
                if (request.status !== 6) {
                    isOddRow = !isOddRow;
                    return this.renderTableRow(request, isOddRow);
                }
            });
        }

        if (provisioningRequestsLoading) {
            content = <Loader isLoading={true} />;
        } else if (provisioningRequests.length === 0 || provisioningRequestsError !== "") {
            content = (
                <FormattedMessage id="teamRequest.noRequestsFound" defaultMessage="Geen team aanvragen gevonden" />
            );
        } else {
            content = (
                <React.Fragment>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>
                                    <FormattedMessage id="teamRequestTable.headerTitle" default="Titel" />
                                </th>
                                <th>
                                    <FormattedMessage id="teamRequestTable.headerCreatedOn" default="Aangamaakt op" />
                                </th>
                                <th>
                                    <FormattedMessage id="teamRequestTable.headerOwner" default="Eigenaar" />
                                </th>
                                <th>
                                    <FormattedMessage id="teamRequestTable.headerTeamType" default="Type" />
                                </th>
                                <th>
                                    <FormattedMessage id="teamRequestTable.headerStatus" default="Status" />
                                </th>
                                <th>
                                    <FormattedMessage id="teamRequestTable.headerActions" default="Actions" />
                                </th>
                            </tr>
                            {provisioningRequestRows}
                        </tbody>
                    </table>
                    <Panel
                        className="account-panel team-request-panel" // TODO make 'account-panel' into generic styling since it is used in multiple locations
                        isOpen={detailsPanelIsOpen}
                        type={PanelType.smallFixedFar}
                        isBlocking="false"
                        closeButtonAriaLabel="Close"
                        isLightDismiss={true}
                        onLightDismissClick={() => this.toggleDetailsPanel()}
                    >
                        <section className="account-panel__panel-header">
                            <div className="account-panel__panel-header--flex">
                                <h1>{activeRequest.title}</h1>

                                <div className="account-panel__panel-close" onClick={() => this.toggleDetailsPanel()}>
                                    <Icon iconName="ChromeClose" />
                                </div>
                            </div>
                        </section>
                        <section>
                            <div style={{ background: "white" }}>
                                {notificationBar}
                                <div className="team-request-panel-content-wrapper">
                                    <TeamRequestsDetailComponent
                                        teamRequest={activeRequest}
                                        userCanApprove={this.props.userCanApprove}
                                        actions={this.props.actions}
                                    />
                                </div>
                            </div>
                        </section>
                    </Panel>
                </React.Fragment>
            );
        }

        return <div className="requests-table-wrapper">{content}</div>;
    }
}

export default TeamRequestsTableComponent;
