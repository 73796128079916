import React from "react";
import { connect } from "react-redux";

import { fetchTeamRequests, removeTeamRequest, closeNotificationAlert } from "../../../store/teamRequests/actions";
import TeamRequestsTable from "../../../common/teamRequestsTable/TeamRequestsTable";
import { FormattedMessage } from "react-intl";

class MyTeamRequestsContainer extends React.Component {
    componentDidMount() {
        this.props.fetchTeamRequests("MyTeamContainer");
    }

    render() {
        const teamRequestTableActions = {
            onDelete: this.props.removeTeamRequest,
            closeNotificationAlert: this.props.closeNotificationAlert
        };

        return (
            <div className="my-team-requests-wrapper">
                <div className="my-team-requests-header">
                    <FormattedMessage id="teamRequest.my.headerText" default="Mijn team aanvragen" />
                </div>
                <TeamRequestsTable
                    teamRequests={this.props.teamRequests}
                    userCanApprove={false}
                    actions={teamRequestTableActions}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        teamRequests: state.teamRequestsSlice
    };
};

export default connect(
    mapStateToProps,
    { fetchTeamRequests, removeTeamRequest, closeNotificationAlert }
)(MyTeamRequestsContainer);
