import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

import { getGroupPlans } from "../../../../../HttpRepositories/graphRepository";
import * as teamActions from "../../../../../store/teams/actions";
import WithLoading from "../../../../utils/withLoading";

import { logUserOpenedChannel } from "../../../../../logging/applicationInsights/logFunctions";

class ChannelsComponent extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        channels: []
    };

    componentDidMount() {
        this.props.fetchTeamChannels(this.props.teamId);
    }

    logOpenChannel = channelId => {
        logUserOpenedChannel(
            this.props.currentUser.userPrincipalName,
            this.props.currentUser.tenant.name,
            channelId
        );
    };

    parseUi(channel) {
        return (
            <li key={channel.id} className="channel-list-item">
                <div className="channel-wrapper">
                    <div className="member-text-wrapper">
                        <a
                            href={channel.webUrl}
                            target="_blank"
                            onClick={channelName =>
                                this.logOpenChannel(channel.displayName)
                            }
                        >
                            {channel.displayName}
                        </a>
                        <span className="member-text-job-title">
                            {channel.description}
                        </span>
                    </div>
                </div>
            </li>
        );
    }

    render() {
        // if the content does not get changed, we could conclude that there are no channels for this team.
        let content = (
            <FormattedMessage
                id="teamModalBody.channelsNoneAvailable"
                defaultMessage="Er zijn geen kanalen beschikbaar."
            />
        );

        if (this.props.channelsLoading) {
            content = <WithLoading isLoading={this.props.channelsLoading} />;
        } else {
            if (this.props.currentTeam && this.props.currentTeam.channels) {
                if (Object.keys(this.props.currentTeam).length > 1) {
                    if (
                        this.props.currentTeam.channels != "" &&
                        this.props.currentTeam.channels != "undefined"
                    ) {
                        if (this.props.currentTeam.channels.length > 0) {
                            let channelArray = [];
                            channelArray = this.props.currentTeam.channels.map(
                                channel => {
                                    let channelItem = this.parseUi(channel);
                                    return channelItem;
                                }
                            );
                            content = <ul>{channelArray}</ul>;
                        }
                    }
                }
            } else {
                content = <p>error</p>;
            }
        }
        return <div>{content}</div>;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUserSlice.currentUser,
        currentTeam: state.teamsSlice.currentTeam,
        channelsLoading: state.teamsSlice.channelsLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTeamChannels: teamId =>
            dispatch(teamActions.fetchTeamChannels(teamId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChannelsComponent);
