import * as actions from "./actions";
import { objectToArray, sortArray } from "../../assets/general_functions/objectsAndArrays";

const initialState = {
    loading: false,
    teamsError: "",
    firstBatchOfTeams: [],
    firstBatchLoading: false,
    currentTeam: {},
    currentTeamLoading: false
};

const allTeamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_ALLTEAMS_STARTED: {
            return {
                ...state,
                loading: true
            };
        }

        case actions.FETCH_ALLTEAMS_SUCCESS: {
            const teams = objectToArray(action.payload);

            return {
                ...state,
                loading: false,
                allTeams: teams
            };
        }
        case actions.FETCH_ALLTEAMS_FAILURE:
        case actions.FETCH_ALLTEAMS_FIRSTBATCH_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                teamsError: error
            };
        }

        case actions.FETCH_ALLTEAMS_ALL_SUCCESS: {
        }

        case actions.FETCH_ALLTEAMS_FIRSTBATCH_STARTED: {
            return {
                ...state,
                firstBatchLoading: true
            };
        }

        case actions.FETCH_ALLTEAMS_FIRSTBATCH_SUCCESS: {
            const teams = objectToArray(action.payload);

            return {
                ...state,
                firstBatchLoading: false,
                firstBatchOfTeams: teams
            };
        }
        case actions.FETCH_ALLTEAMS_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                teamsError: error
            };
        }

        case actions.FETCH_ALLTEAM_DETAILS_STARTED: {
            return {
                ...state,
                currentTeamLoading: true
            };
        }

        case actions.FETCH_ALLTEAM_DETAILS_SUCCESS: {
            const currentTeam = action.payload.result;

            return {
                ...state,
                currentTeamLoading: false,
                currentTeam: currentTeam
            };
        }

        case actions.REMOVE_CURRENT_ALLTEAM: {
            const currentTeam = {};

            return {
                ...state,
                currentTeamLoading: false,
                currentTeam: currentTeam
            };
        }

        case actions.FETCH_ALLTEAM_DETAILS_FAILURE: {
            const currentTeam = action.payload.error;

            return {
                ...state,
                currentTeamLoading: false,
                teamsError: false
            };
        }

        case actions.UPDATE_ALLTEAMS_FIRST_BATCH: {
            return {
                ...state,
                firstBatchLoading: false,
                firstBatchOfTeams: action.payload.teams
            };
        }

        default:
            return state;
    }
};

export default allTeamsReducer;
