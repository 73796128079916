import * as actions from "./actions";
import { objectToArray } from "../../assets/general_functions/objectsAndArrays";

const initialState = {
    termSets: [],
    termSetsError: "",
    termSetsLoading: false,
    termGroups: [],
    termGroupsLoading: false,
    termGroupsError: "",
    termsError: "",
    termLoading: false,
    notification: {
        isOpen: false,
        isSuccess: true,
        // notification codes:        0: Create term success, 1: create term fail, 2: update term success, 3: update term fail,
        // 4: create termSet success, 5: create termSet fail, 6: update termSet success, 7: update termSet fail
        code: null
    },
    navState: {
        activeTab: 0
    }
};

const termSetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_TERMSETS_STARTED: {
            return {
                ...state,
                termSetsLoading: true
            };
        }

        case actions.FETCH_TERMSETS_SUCCESS: {
            const termSets = objectToArray(action.payload);

            return {
                ...state,
                termSetsLoading: false,
                termSetsError: "",
                termSets
            };
        }

        case actions.FETCH_TERMSETS_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                termSetsLoading: false,
                termSetsError: error
            };
        }

        case actions.FETCH_TERMGROUPS_STARTED: {
            return {
                ...state,
                termGroupsLoading: true
            };
        }

        case actions.FETCH_TERMGROUPS_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                termGroupsLoading: false,
                termGroupsError: error
            };
        }

        case actions.FETCH_TERMGROUPS_SUCCESS: {
            const termGroups = objectToArray(action.payload);

            return {
                ...state,
                termGroupsLoading: false,
                termGroupsError: "",
                termGroups
            };
        }

        case actions.CREATE_TERMSET_STARTED: {
            return {
                ...state,
                termGroupsLoading: true
            };
        }

        case actions.CREATE_TERMSET_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                termSetsLoading: false,
                termSetsError: error,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: false,
                    code: 5
                }
            };
        }

        case actions.CREATE_TERMSETS_SUCCESS: {
            const { termGroupId } = action.payload;
            const oldState = { ...state };

            // add termset to the correct termGroup
            const updatedTermGroups = oldState.termGroups.map(termGroup => {
                if (termGroup.id === termGroupId) {
                    termGroup.termSets.push(action.payload);
                }
                return termGroup;
            });

            return {
                ...state,
                termSetsLoading: false,
                termGroups: updatedTermGroups,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: true,
                    code: 4
                }
            };
        }

        case actions.UPDATE_TERMSET_STARTED: {
            return {
                ...state,
                termGroupsLoading: true,
                notification: initialState.notification
            };
        }

        case actions.UPDATE_TERMSET_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                termSetsLoading: false,
                termSetsError: error,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: false,
                    code: 7
                }
            };
        }

        case actions.UPDATE_TERMSET_SUCCESS: {
            const { termGroupId } = action.payload;
            const oldState = { ...state };

            // update termset with new values in the correct termGroup.
            const updatedTermGroups = oldState.termGroups.map(termGroup => {
                if (termGroup.id === termGroupId) {
                    // if the termSet is found break out of the loop.
                    for (let i = 0; i < termGroup.termSets.length; i++) {
                        if (termGroup.termSets[i].id === action.payload.id) {
                            action.payload.terms = termGroup.termSets[i].terms;
                            termGroup.termSets[i] = action.payload;
                            break;
                        }
                    }

                    return termGroup;
                }
                return termGroup;
            });

            return {
                ...state,
                termSetsLoading: false,
                termGroups: updatedTermGroups,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: true,
                    code: 6
                }
            };
        }

        case actions.CREATE_TERM_STARTED: {
            return {
                ...state,
                termLoading: true
            };
        }

        case actions.CREATE_TERM_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                termLoading: false,
                termsError: error,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: false,
                    message: 1
                }
            };
        }

        case actions.CREATE_TERM_SUCCESS: {
            const oldState = { ...state };

            // loop over the termgroup, try to find the termset by termsetId
            const updatedTermGroups = oldState.termGroups.map(termGroup => {
                for (let termSet of termGroup.termSets) {
                    if (termSet.id === action.payload.termSetId) {
                        termSet.terms = termSet.terms.concat(action.payload);
                        break;
                    }
                }
                return termGroup;
            });

            return {
                ...state,
                termLoading: false,
                termGroups: updatedTermGroups,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: true,
                    code: 0
                }
            };
        }

        case actions.UPDATE_TERM_STARTED: {
            return {
                ...state,
                termLoading: true,
                notification: initialState.notification
            };
        }

        case actions.UPDATE_TERM_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                termLoading: false,
                termsError: error,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: false,
                    code: 3
                }
            };
        }

        case actions.UPDATE_TERM_SUCCESS: {
            const oldState = { ...state };
            // loop over the termgroup, try to find the termset by termsetId
            const updatedTermGroups = oldState.termGroups.map(termGroup => {
                for (let termSet of termGroup.termSets) {
                    if (termSet.id === action.payload.termSetId) {
                        // replace the term with the action.payload
                        for (let i in termSet.terms) {
                            if (termSet.terms[i].id === action.payload.id) {
                                termSet.terms[i] = action.payload;

                                break;
                            }
                        }

                        break;
                    }
                }
                return termGroup;
            });

            return {
                ...state,
                termLoading: false,
                termGroups: updatedTermGroups,
                notification: {
                    isOpen: true,
                    isSuccess: true,
                    code: 2
                }
            };
        }

        case actions.CLEAR_METADATA_NOTIFICATION: {
            return {
                ...state,
                notification: initialState.notification
            };
        }
        case actions.UPDATE_METADATA_NAV_STATE: {
            return {
                ...state,
                navState: action.payload
            };
        }

        default: {
            return state;
        }
    }
};

export default termSetsReducer;
