import React from "react";
import TeamModalHeader from "./teamModalHeader/teamModalHeader";
import TeamModalBody from "./teamModalBody/teamModalBody";
import { connect } from "react-redux";
import * as teamActions from "../../../store/teams/actions";

class TeamModal extends React.Component {
    async componentDidMount() {
        await this.props.fetchTeamDetails(this.props.teamId);
    }
    render() {
        let error = "";
        let allMembers = [];
        if (this.props.allTeamsIAmMemberOf != "") {
            allMembers = this.props.allTeamsIAmMemberOf;
        }

        return (
            <React.Fragment>
                {error}
                <TeamModalHeader
                    teamId={this.props.teamId}
                    title={this.props.title}
                    description={this.props.description}
                    favorite={this.props.favorite}
                    closeModal={this.props.closeModal}
                    error={this.props.error}
                    teamUrl={this.props.currentTeam.teamUrl}
                />

                <TeamModalBody
                    teamId={this.props.teamId}
                    files={this.props.currentTeam.files}
                    members={this.props.members}
                    openedFrom={this.props.openedFrom}
                    allTeamsIAmMemberOf={allMembers}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.teamsSlice.teamsError,
        loading: state.teamsSlice.currentUserLoading,
        currentTeam: state.teamsSlice.currentTeam
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTeamDetails: teamId => dispatch(teamActions.fetchTeamDetails(teamId, "", ""))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamModal);
