import React from "react";
import { connect } from "react-redux";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { DefaultButton } from "office-ui-fabric-react";

import { fetchTeamRequests, removeTeamRequest } from "../../../../store/teamRequests/actions";
import Loader from "../../../utils/withLoading";
import { defineTeamRequestStatusUi } from "../../../../assets/general_functions/genericHelpers";
import { sortArray } from "../../../../assets/general_functions/objectsAndArrays";

class RequestedTeamOverviewComponent extends React.Component {
    state = {
        activeTeamRequest: null
    };

    componentDidMount() {
        this.props.fetchTeamRequests("RequestedTeamOverviewComponent");
    }

    toggleActiveTeamRequest = teamRequestId => {
        if (this.state.activeTeamRequest === teamRequestId) {
            this.setState({ activeTeamRequest: null });
        } else {
            this.setState({ activeTeamRequest: teamRequestId });
        }
    };

    removeTeamRequestHandler = item => {
        this.props.removeTeamRequest(item);
    };

    createListItemsUi = () => {
        if (
            this.props.teamRequests &&
            Object.keys(this.props.teamRequests).length > 0 &&
            typeof this.props.teamRequests.provisioningRequestsNotificationHub != undefined
        ) {
            const firstFiveProvisioningRequests = [];

            // Don't map over the array if it is empty.
            if (
                this.props.teamRequests.provisioningRequestsNotificationHub === undefined ||
                this.props.teamRequests.provisioningRequestsNotificationHub.length === 0
            ) {
                return (
                    <FormattedMessage
                        id="notificationHub.noRecentTeamRequests"
                        defaultMessage="Geen aanvragen gevonden"
                    />
                );
            }

            // Sort the provisioning requests by date created
            const sortedProvisioningRequests = [...this.props.teamRequests.provisioningRequestsNotificationHub]
                .filter(request => request.status !== 6)
                .sort(sortArray("created"));

            // Put the first five items of the sorted array into the array that will be mapped over.
            for (let i = 0; i < 5; i++) {
                if (sortedProvisioningRequests[i] != undefined) {
                    firstFiveProvisioningRequests.push(sortedProvisioningRequests[i]);
                }
            }

            // Don't map over the array if it is empty.
            if (firstFiveProvisioningRequests.length === 0) {
                return (
                    <FormattedMessage
                        id="notificationHub.noRecentTeamRequests"
                        defaultMessage="Geen aanvragen gevonden"
                    />
                );
            }

            return firstFiveProvisioningRequests.map(request => {
                let { status, title, id: teamRequestId, owner, statusReason } = request;
                const teamRequestStatusUi = defineTeamRequestStatusUi(status);

                let metaClassCss = `requested-teams-list-wrapper__meta-wrapper requested-teams-list-wrapper__meta-wrapper--${teamRequestStatusUi.fontColor}`;
                let iconType = <Icon iconName="ChevronRight" />;
                let detailsUi;
                let removeButton = "";

                // If the status of the request is equal to 'Requested', 'Rejected', or 'Failed' -> include a delete button
                if (status === 0 || status === 2 || status === 5) {
                    const buttonTExt = (
                        <FormattedMessage id="notificationHub.deleteButtonText" defaultMessage="Verwijderen" />
                    );
                    removeButton = (
                        <div className="requested-teams-list-wrapper__row requested-teams-list-wrapper__row--action-row">
                            <DefaultButton
                                onClick={() => this.removeTeamRequestHandler(request.id)}
                                text={buttonTExt}
                            />
                        </div>
                    );
                }

                if (this.state.activeTeamRequest === teamRequestId) {
                    iconType = <Icon iconName="ChevronDown" />;

                    detailsUi = (
                        <div className="requested-teams-list-wrapper__item-details">
                            <div className="requested-teams-list-wrapper__row">
                                <div className="requested-teams-list-wrapper__row-left">
                                    <FormattedMessage
                                        id="notificationHub.requestedTeamDetailsOwnerText"
                                        defaultMessage="Eigenaar"
                                    />
                                </div>
                                <div className="requested-teams-list-wrapper__row-right">
                                    {owner ? owner.emailAddress || "-" : " - "}
                                </div>
                            </div>

                            <div className="requested-teams-list-wrapper__row">
                                <div className="requested-teams-list-wrapper__row-left">
                                    <FormattedMessage
                                        id="notificationHub.requestedTeamDetailsStatusText"
                                        defaultMessage="Status"
                                    />
                                </div>
                                <div className="requested-teams-list-wrapper__row-right">{statusReason || "-"}</div>
                            </div>

                            {removeButton}
                        </div>
                    );
                }

                return (
                    <li key={request.id}>
                        <div
                            className="requested-teams-list-wrapper__text-wrapper"
                            onClick={() => this.toggleActiveTeamRequest(teamRequestId)}
                        >
                            <div style={{ display: "flex" }}>
                                <div className="expand-icon-styles">{iconType}</div>
                                <div style={{ marginTop: "-0.2rem" }}>{title}</div>
                            </div>
                        </div>
                        <div className={metaClassCss}>{teamRequestStatusUi.statusText}</div>
                        {detailsUi}
                    </li>
                );
            });
        }
    };

    render() {
        const listItems = this.createListItemsUi();

        let content = (
            <React.Fragment>
                <ul className="requested-teams-list-wrapper">{listItems}</ul>
                <div className="more-requests" onClick={() => this.props.togglePanel("notificationHub")}>
                    <NavLink to="/myTeamRequests">
                        <FormattedMessage
                            id="notificationHub.myTeamRequestsLinkText"
                            defaultMessage="Al mijn aanvragen"
                        />
                    </NavLink>
                </div>
            </React.Fragment>
        );

        if (this.props.teamRequests.provisioningRequestsLoadingNotificationHub) {
            content = <Loader isLoading={true} smallMargin />;
        }

        return <div className="notification-overview-wrapper">{content}</div>;
    }
}

const mapStateToProps = state => {
    return {
        teamRequests: state.teamRequestsSlice
    };
};

export default connect(mapStateToProps, { fetchTeamRequests, removeTeamRequest })(RequestedTeamOverviewComponent);
