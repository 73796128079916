import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

import { createTermSet, updateTermSet } from "../../../../../store/metaData/actions";
import { FormattedMessage, injectIntl } from "react-intl";

class TermSetFormComponent extends React.Component {
    state = {
        activeItemCurrentData: {}
    };

    disableFieldWhenNoActiveTerms = isActiveValue => {
        const { mode, activeItem } = this.props;
        let isDisabled = "disabled";

        // If a new termSet is being added, automatically disable return 'disabled' (since a new termSet does not contain active terms yet).
        if (mode === "add" || !isActiveValue || this.props.cannotEdit) {
            return isDisabled;
        }

        // Loop through the terms of the selected termSet, if it does not contain an active term, return 'disabled'.
        if (typeof activeItem.terms !== "undefined") {
            let hasActiveTerm = false;
            for (let term of activeItem.terms) {
                if (term.isActive) {
                    hasActiveTerm = true;
                    break;
                }
            }

            if (hasActiveTerm) {
                isDisabled = "";
            }
        }

        return isDisabled;
    };

    render() {
        const { intl } = this.props;
        let termGroupId = this.props.activeItem.id;
        let name = "";
        let isReassignable = false;
        let isActive = true;
        let isRequired = false;
        let sumbitButton = "";
        let submitButtonText = <FormattedMessage id="general.formCreateText" defaultMessage="Aanmaken" />;
        let isDisabled = "disabled";

        if (this.props.mode === "update") {
            termGroupId = this.props.activeItem.termGroupId;
            name = this.props.activeItem.name;
            isReassignable = this.props.activeItem.isReassignable;
            isActive = this.props.activeItem.isActive;
            isRequired = this.props.activeItem.isRequired ? true : false;
            submitButtonText = <FormattedMessage id="general.formEditText" defaultMessage="Aanpassen" />;
        }
        if (!this.props.cannotEdit) {
            isDisabled = "";
            sumbitButton = (
                <button type="submit" className="btn btn-primary teams-submit-button">
                    {submitButtonText}
                </button>
            );
        }

        return (
            <div className="details-form-wrapper">
                <Formik
                    enableReinitialize
                    initialValues={{
                        termGroupId: termGroupId,
                        Name: name,
                        isReassignable: isReassignable,
                        isActive: isActive,
                        isRequired: isRequired
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            if (this.props.mode === "update") {
                                const objectToSend = values;
                                objectToSend.id = this.props.activeItem.id;
                                this.props.updateTermSet(objectToSend);
                            } else {
                                this.props.createTermSet(values);
                            }

                            setSubmitting(false);
                        }, 500);
                    }}
                    validationSchema={Yup.object().shape({
                        Name: Yup.string()
                            .min(
                                2,
                                intl.formatMessage({
                                    id: "metadata.form.validation.termSetNameMinTwoCharacters",
                                    defaultMessage: "U moet minstens 2 tekens gebruiken."
                                })
                            )
                            .required(
                                intl.formatMessage({
                                    id: "metadata.form.validation.termSetNameRequired",
                                    defaultMessage: "U moet een naam voor de termSet opgeven"
                                })
                            )
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;

                        let isDisabledBecauseNoActiveTerms = this.disableFieldWhenNoActiveTerms(values.isActive);
                        return (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    className="form-input-group"
                                    type="hidden"
                                    name="termGroupId"
                                    value={termGroupId}
                                ></Field>
                                <label htmlFor="Name" style={{ display: "block" }}>
                                    <FormattedMessage id="metadata.form.termSetNameInputLabel" defaultMessage="Naam" />
                                </label>
                                {errors.Name && touched.Name && <div className="input-feedback">{errors.Name}</div>}
                                <div className="form-input-wrapper">
                                    <div className="form-input-description">
                                        <FormattedMessage
                                            id="metadata.form.termSetNameInputDescription"
                                            defaultMessage="Voer een naam voor deze termSet in"
                                        />
                                    </div>
                                    <div className="form-input-field">
                                        <input
                                            id="Name"
                                            placeholder={intl.formatMessage({
                                                id: "metadata.form.termSetNameInputDescription",
                                                defaultMessage: "Voer een naam voor deze termSet in"
                                            })}
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Name}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="checkbox-spacer">
                                    <label htmlFor="isRequired" style={{ display: "block" }}>
                                        <FormattedMessage
                                            id="metadata.form.termSetIsRequiredInputLabel"
                                            defaultMessage="Is verplicht"
                                        />
                                    </label>

                                    <div className="form-input-wrapper">
                                        <div className="form-input-description">
                                            <FormattedMessage
                                                id="metadata.form.termSetIsRequiredInputDescription"
                                                defaultMessage="Deze termset is verplicht"
                                            />
                                        </div>

                                        <div className="form-input-field">
                                            <Field
                                                type="checkbox"
                                                name="isRequired"
                                                checked={values.isRequired}
                                                onChange={() => {
                                                    setFieldValue("isRequired", !values.isRequired);
                                                }}
                                                disabled={isDisabledBecauseNoActiveTerms}
                                                title={
                                                    isDisabledBecauseNoActiveTerms
                                                        ? intl.formatMessage({
                                                              id: "metadata.form.termSetIsRequiredDisabledInfoText",
                                                              defaultMessage:
                                                                  "Een term set kan niet verplicht zijn als deze inactief is, of als deze geen onderliggende actieve terms bevat."
                                                          })
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="checkbox-spacer">
                                    <label htmlFor="isActive" style={{ display: "block" }}>
                                        <FormattedMessage
                                            id="metadata.form.termSetIsActiveLabel"
                                            defaultMessage="Is actief"
                                        />
                                    </label>

                                    <div className="form-input-wrapper">
                                        <div className="form-input-description">
                                            <FormattedMessage
                                                id="metadata.form.termSetIsActiveDescription"
                                                defaultMessage="Deze termset mag aan teams gekoppeld worden"
                                            />
                                        </div>

                                        <div className="form-input-field">
                                            <Field
                                                type="checkbox"
                                                name="isActive"
                                                checked={values.isActive}
                                                onChange={() => {
                                                    // Uncheck the value of isRequired if the new value of isActive will be false.
                                                    // This because a termset which is required but is not active is not a desired situation.
                                                    if (values.isActive) {
                                                        setFieldValue("isRequired", false);
                                                    }
                                                    setFieldValue("isActive", !values.isActive);
                                                }}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="checkbox-spacer">
                                    <label htmlFor="isReassignable" style={{ display: "block" }}>
                                        <FormattedMessage
                                            id="metadata.form.termSetIsReassignableLabel"
                                            defaultMessage="Is aanpasbaar"
                                        />
                                    </label>
                                    <div className="form-input-wrapper">
                                        <div className="form-input-description">
                                            <FormattedMessage
                                                id="metadata.form.termSetIsReassignableDescription"
                                                defaultMessage="Deze termset mag gewijzigd worden"
                                            />
                                        </div>

                                        <div className="form-input-field">
                                            <Field
                                                type="checkbox"
                                                name="isReassignable"
                                                checked={values.isReassignable}
                                                onChange={() => {
                                                    setFieldValue("isReassignable", !values.isReassignable);
                                                }}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {sumbitButton}
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export default connect(
    null,
    { createTermSet, updateTermSet }
)(injectIntl(TermSetFormComponent));
