import React from "react";
import { Icon, Toggle } from "office-ui-fabric-react";
import { Form, Field, Formik } from "formik";
import { injectIntl, FormattedMessage } from "react-intl";
import * as Yup from "yup";

import * as filterActions from "../../../../store/filters/actions";
import * as teamRequestActions from "../../../../store/teamRequests/actions";
import * as settingsActions from "../../../../store/settings/actions";

import { connect } from "react-redux";
import { objectToArray } from "../../../../assets/general_functions/objectsAndArrays";
import { logUserRequestedNewTeam } from "../../../../logging/applicationInsights/logFunctions";

import { fetchTeamTypes } from "../../../../store/teamTypes/actions";

class ProvisioningRequestPanel extends React.Component {
	state = {
		metaDataFilters: [],
		teamNameHasBeenCopied: false,
		teamMailName: "",
		teamType: "",
		allowToAddGuests: false,
		teamNameTouched: false,
		validationSchema: {},
		cleanActiveFilterSets: [],
	};

	constructTeamMailName = (teamNameValue, teamTypeValue, internalTeamNameValue = "") => {
		this.setState({ teamNameTouched: true });

		// clean the teamName
		let teamName = teamNameValue; // .replace(/[^a-z0-9-_\s]/gi, "")
		let teamTypeId = teamTypeValue;
		let internalTeamName = internalTeamNameValue.replace(/[^a-z0-9-_]/gi, "");
		let domain = "";

		// if internal name is set, we override the teamName
		if (internalTeamNameValue != "" && internalTeamName !== teamName && this.state.teamNameHasBeenCopied) {
			teamName = internalTeamName;
		}

		// if both are set start processing,
		if (teamTypeId != "" && teamName != "") {
			const teamTypeObject = this.props.teamTypes.filter((teamType) => {
				if (teamType.id == teamTypeId) {
					return teamType;
				}
			});

			// check if the teamType has a custom vanity domain, if not return the default domain
			if (teamTypeObject[0].domain) {
				domain = teamTypeObject[0].domain;
			} else {
				domain = this.props.currentUser.tenant.domain;
			}

			// when the domain is set, make a call to the microsoft graph to see whether the email is available.
			this.props.checkProvisioningRequest(internalTeamNameValue, teamTypeObject[0].approvalRequired);
			this.setState({
				teamNameHasBeenCopied: true,
				teamMailName: `${teamName}@${domain}`,
				teamType: teamTypeValue,
			});

			return `${teamName}@${domain}`;
		}

		// else return an empty string.
		else {
			return "";
		}
	};

	createMetadataDropdowns = (formikProps) => {
		const { intl } = this.props;
		const { touched, errors } = formikProps;
		const teamCreatedMessage = intl.formatMessage({
			id: "general.makeAChoice",
			defaultMessage: "Maak een keuze...",
		});

		let activeFilterSets = this.state.cleanActiveFilterSets;

		// Filter the set of activeFilter to only include those which have at least one active term*, or those which are required.
		activeFilterSets = activeFilterSets.filter((activeFilter) => {
			return activeFilter.terms.length > 0 || activeFilter.isRequired;
		});

		const metaDataFilters = activeFilterSets.map((activeFilter) => {
			let hasDropdownSelectDefaultValue = false;
			for (let term of activeFilter.terms) {
				if (term.termId === "000") {
					hasDropdownSelectDefaultValue = true;
					break;
				}
			}

			if (!hasDropdownSelectDefaultValue) {
				activeFilter.terms.unshift({
					termId: "000",
					termName: teamCreatedMessage,
				});
			}

			const options = activeFilter.terms.map((term) => {
				const termName = `TERM-${term.termId}`;
				return (
					<option key={term.termId} value={termName}>
						{term.termName}
					</option>
				);
			});

			let labelToUse = activeFilter.termSet;

			if (activeFilter.isRequired) {
				labelToUse = (
					<React.Fragment>
						<div className="request-field-label">
							<div className="request-field-label__text">{activeFilter.termSet}</div>
							<span className="request-field-label__required-star">*</span>
						</div>
						{touched[activeFilter.termSet] && errors[activeFilter.termSet] && (
							<p className="error-message">{errors[activeFilter.termSet]}</p>
						)}
					</React.Fragment>
				);
			}

			return (
				<div key={activeFilter.termSet}>
					<label>
						<span>{labelToUse}</span>
						<Field
							className="form-input-group"
							component="select"
							name={activeFilter.termSet}
							key={activeFilter.termSet}
							disabled={options.length === 1 ? true : false}
						>
							{options}
						</Field>
					</label>
				</div>
			);
		});

		return metaDataFilters;
	};

	componentDidMount() {
		this.props.fetchTeamTypes();
		this.props.fetchGroupSettings();
		this.props.onFetchFilters();

		const activeFilterSets = this.props.filterTermSets.map((termSet) => {
			// the teamType metaData has it's own endpoint. Therefore we do not want to
			// show it in the metaData fields. We cannot remove it on the API though, because
			// it is used for filtering.
			if (termSet.isActive) {
				let terms = termSet.terms
					.filter((term) => {
						if (term.isActive) {
							return term;
						}
					})
					.map((term) => {
						return {
							termId: term.id,
							termName: term.name,
						};
					});

				return {
					termSet: termSet.name,
					terms: terms,
					isRequired: termSet.isRequired,
				};
			}
		});

		const cleanActiveFilterSets = activeFilterSets.filter(function(el) {
			return el != null;
		});

		this.setState({ cleanActiveFilterSets });
		this.createValidationSchema(cleanActiveFilterSets);
	}

	createValidationSchema = (termSets) => {
		const { intl } = this.props;
		const mustProviseTwoCharactersMessage = intl.formatMessage({
			id: "provisioning.mustUseTwoCharactersError",
			defaultMessage: "U moet minimaal 2 tekens gebruiken.",
		});

		const mustProvideTeamNameMessage = intl.formatMessage({
			id: "provisioning.mustProvideATeamnameError",
			defaultMessage: "U moet een teamnaam opgeven.",
		});

		const mustProvideTeamTypeMessage = intl.formatMessage({
			id: "provisioning.MustSelectATeamTypeError",
			defaultMessage: "U moet een teamtype selecteren.",
		});

		const fieldRequiredMessage = intl.formatMessage({
			id: "general.requiredField",
			defaultMessage: "Dit veld is verplicht",
		});

		// Create the initial Yup object shape
		let yupObjectShape = {
			teamName: Yup.string()
				.min(2, mustProviseTwoCharactersMessage)
				.required(mustProvideTeamNameMessage),
			teamType: Yup.string().required(mustProvideTeamTypeMessage),
			teamDescription: Yup.string().required(fieldRequiredMessage),
		};

		// Append the yup object shape with the required termsets.
		for (let termSet of termSets) {
			if (termSet.isRequired) {
				yupObjectShape[termSet.termSet] = Yup.string()
					.required(fieldRequiredMessage)
					.test(
						termSet.termSet,
						fieldRequiredMessage,
						(value) => value !== "TERM-000" && value !== undefined
					);
			}
		}

		// Create the Yup object using the created object shape.
		let validationSchema = Yup.object().shape(yupObjectShape);

		this.setState({ validationSchema });
	};

	render() {
		const { intl } = this.props;
		const teamCreatedMessage = intl.formatMessage({
			id: "general.makeAChoice",
			defaultMessage: "Maak een keuze...",
		});
		const teamNamePlaceholder = intl.formatMessage({
			id: "provisioning.teamNamePlaceHolder",
			defaultMessage: "Vul een teamnaam in...",
		});

		const publicMessage = intl.formatMessage({
			id: "general.public",
			defaultMessage: "Publiek",
		});

		const privateMessage = intl.formatMessage({
			id: "general.private",
			defaultMessage: "Prive",
		});

		let teamTypeOptions = [
			<option key="0" value="">
				{teamCreatedMessage}
			</option>,
		];
		let teamExists = false;

		if (this.props.doesTeamExis == true) {
			teamExists = true;
		}

		this.props.teamTypes.map((teamType) => {
			if (teamType.isActive) {
				teamTypeOptions.push(
					<option key={teamType.id} value={teamType.id}>
						{teamType.name}
					</option>
				);
			}
		});

		const groupCheckClass = this.props.doesTeamExist
			? `microsoft-group-check group-error-message`
			: `microsoft-group-check group-success-message`;

		return (
			<React.Fragment>
				<section className="account-panel__panel-header">
					<div className="account-panel__panel-header--flex">
						<h1>
							<FormattedMessage id="provisioning.PanelTitle" defaultMessage="Nieuw team Aanvragen" />
						</h1>

						<div className="account-panel__panel-close" onClick={this.props.closeProvisioningRequestPanel}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				<Formik
					initialValues={{
						teamName: "",
						teamMailName: "",
						internalTeamName: "",
						teamDescription: "",
						allowToAddGuests: this.props.settingsSlice.allowGuestsDefaultValue,
						userId: "",
						teamType: "",
						requestType: 3,
						privacyLevel: 1,
					}}
					onSubmit={(values, { setSubmitting }) => {
						const title = values.teamName;
						const teamMailName = values.teamMailName;
						const userId = values.userId;
						const teamDescription = values.teamDescription;
						const allowToAddGuests = values.allowToAddGuests;
						const teamType = values.teamType;
						const internalTeamName = values.internalTeamName;
						const requestType = values.requestType;
						const privacyLevel = parseInt(values.privacyLevel, 10);

						values = objectToArray(values);
						let terms = [];

						values.filter((value) => {
							if (typeof value === "string" && value.substr(0, 4) === "TERM") {
								let termValue = value.substr(5, value.length);
								if (termValue !== "000") {
									terms.push({ id: parseInt(termValue, 10) });
								}
							}
						});

						const objToSend = {
							title: title,
							alias: internalTeamName,
							description: teamDescription,
							allowToAddGuests: allowToAddGuests,
							ownerId: userId,
							requestType: requestType,
							privacyLevel: privacyLevel,
							teamType: { id: parseInt(teamType, 10) },
							status: 1,
							statusReason: "test",
							terms: terms,
						};

						this.props.createTeamRequest(objToSend);
					}}
					validationSchema={this.state.validationSchema}
				>
					{(props) => {
						const {
							values,
							touched,
							errors,
							errorsArray,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						} = props;

						const metaDataDropDowns = this.createMetadataDropdowns(props);
						let metaDataDropDownHeader = "";

						// Render the 'additional data' header if there any dropdowns the user can fill in.
						if (metaDataDropDowns != undefined && metaDataDropDowns.length > 0) {
							metaDataDropDownHeader = (
								<h3 className="spacer-top">
									<FormattedMessage
										id="provisioning.metaDataLabel"
										defaultMessage="Overige informatie"
									/>
								</h3>
							);
						}

						return (
							<section className="request">
								<h3>
									<FormattedMessage
										id="provisioning.PanelEssentialInformation"
										defaultMessage="Basis informatie"
									/>
								</h3>
								<Form>
									<div>
										<label>
											<div className="request-field-label">
												<div className="request-field-label__text">
													<FormattedMessage
														id="provisioning.teamNameLabel"
														defaultMessage="Team name"
													/>
												</div>
												<span className="request-field-label__required-star">*</span>
											</div>
											{this.state.teamNameTouched && errors.teamName && (
												<p className="error-message">{errors.teamName}</p>
											)}

											<Field
												className="form-input-group"
												type="text"
												name="teamName"
												placeholder={teamNamePlaceholder}
												onBlur={(e) => {
													setFieldValue(values.teamName, e.target.value);
													if (
														!this.state.teamNameHasBeenCopied ||
														values.internalTeamName == ""
													) {
														setFieldValue(
															"internalTeamName",
															e.target.value.replace(/[^a-z0-9-_]/gi, "")
														);
														setFieldValue(
															"teamMailName",
															this.constructTeamMailName(
																values.teamName,
																values.teamType,
																values.internalTeamName
															),
															false
														);
													}
												}}
											/>
										</label>
									</div>
									<div>
										<label>
											<div className="request-field-label">
												<div className="request-field-label__text">
													<FormattedMessage
														id="provisioning.internalTeamNameLabel"
														defaultMessage="Interne team naam"
													/>
												</div>
												<span className="request-field-label__required-star">*</span>
											</div>

											<Field
												className="form-input-group"
												type="text"
												name="internalTeamName"
												onBlur={(e) => {
													setFieldValue(
														"internalTeamName",
														e.target.value.replace(/[^a-z0-9-_]/gi, "")
													);
													setFieldValue(
														"teamMailName",
														this.constructTeamMailName(
															values.teamName,
															values.teamType,
															values.internalTeamName
														),
														false
													);
												}}
											/>
											<span className={groupCheckClass}>{this.state.teamMailName}</span>
										</label>
									</div>

									<div>
										<label>
											<div className="request-field-label">
												<div className="request-field-label__text">
													<FormattedMessage
														id="provisioning.teamTypeLabel"
														defaultMessage="Teamtype"
													/>
												</div>
												<span className="request-field-label__required-star">*</span>
											</div>

											{touched.teamType && errors.teamType && (
												<p className="error-message">{errors.teamType}</p>
											)}
											<Field
												className="form-input-group"
												component="select"
												name="teamType"
												onClick={(e) => {
													setFieldValue("teamType", e.target.value);
													setFieldValue(
														"teamMailName",
														this.constructTeamMailName(
															values.internalTeamName,
															values.teamType,
															values.internalTeamName
														),
														false
													);
												}}
											>
												{teamTypeOptions}
											</Field>
										</label>
									</div>

									<div>
										<Field className="form-input-group" type="hidden" name="requestType" />
									</div>
									<div>
										<label>
											<div className="request-field-label">
												<div className="request-field-label__text">
													<FormattedMessage
														id="provisioning.privacyLevelLabel"
														defaultMessage="Beveiligingsgraad"
													/>
												</div>
												<span className="request-field-label__required-star">*</span>
											</div>

											<Field className="form-input-group" component="select" name="privacyLevel">
												<option value="1">{privateMessage}</option>
												<option value="0">{publicMessage}</option>
											</Field>
										</label>
									</div>

									<div>
										<Field className="form-input-group" type="hidden" name="teamMailName" />

										<label>
											<div className="request-field-label">
												<div className="request-field-label__text">
													<FormattedMessage
														id="provisioning.descriptionLabel"
														defaultMessage="Beschrijving"
													/>
												</div>
												<span className="request-field-label__required-star">*</span>
											</div>

											{touched.teamDescription && errors.teamDescription && (
												<p className="error-message">{errors.teamDescription}</p>
											)}
											<Field
												component="textarea"
												className="form-input-group textarea"
												name="teamDescription"
											/>
										</label>
									</div>

									<div>
										<label>
											<div className="request-field-label">
												<div className="request-field-label__text">
													<FormattedMessage
														id="provisioning.allowToAddGuestsLabel"
														defaultMessage="Gasten toelaten"
													/>
												</div>
											</div>

											{touched.allowToAddGuests && errors.allowToAddGuests && (
												<p className="error-message">{errors.allowToAddGuests}</p>
											)}
											<Toggle
												className="form-input-group toggle"
												disabled={!this.props.settingsSlice.allowGuestsEnabled}
												checked={values.allowToAddGuests}
												onChange={(e, checked) => setFieldValue("allowToAddGuests", checked)}
											/>
										</label>
									</div>

									{metaDataDropDownHeader}
									{metaDataDropDowns}

									<button
										className="btn btn-primary teams-submit-button"
										type="submit"
										onClick={() => {
											setFieldValue("userId", this.props.currentUser.objectId);
											this.props.closeProvisioningRequestPanel();
											logUserRequestedNewTeam(
												this.props.currentUser.userPrincipalName,
												this.props.currentUser.tenant.name
											);
										}}
										// Default the button to be disabled, after the teamName has been touched, it is based on the validations.
										disabled={
											!this.state.teamNameTouched ||
											(this.props.doesTeamExist ||
												(Object.keys(errors).length !== 0 && errors.constructor === Object))
										}
									>
										<FormattedMessage id="provisioning.submit" defaultMessage="Start aanvraag" />
									</button>
								</Form>
							</section>
						);
					}}
				</Formik>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		filterTermSets: state.filtersSlice.filterTermSets,
		currentUser: state.currentUserSlice.currentUser,
		teamTypes: state.teamTypesSlice.teamTypes,
		doesTeamExist: state.teamRequestsSlice.provisioningTeamAlreadyExists,
		settingsSlice: state.settingsSlice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchFilters: () => dispatch(filterActions.fetchFilters()),
		fetchTeamTypes: () => dispatch(fetchTeamTypes()),
		checkProvisioningRequest: (mailName, approvalRequired) =>
			dispatch(teamRequestActions.checkIfTeamMailNameExists(mailName, approvalRequired)),
		createTeamRequest: (objToSend) => dispatch(teamRequestActions.createTeamRequest(objToSend)),
		fetchGroupSettings: () => dispatch(settingsActions.fetchGroupSettings()),
	};
};

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProvisioningRequestPanel)
);
