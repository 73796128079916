import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import TeamCardLogo from "../../teamCardLogo/teamCardLogo";
import { connect } from "react-redux";
import * as userActions from "../../../../store/users/actions";
import * as teamActions from "../../../../store/teams/actions";
import { logUserStartedApplicationFromTeam } from "../../../../logging/applicationInsights/logFunctions";
import { FormattedMessage, injectIntl } from "react-intl";

class TeamModalHeader extends React.Component {
    logApplicationStarted = application => {
        logUserStartedApplicationFromTeam(
            this.props.currentUser.userPrincipalName,
            this.props.currentUser.tenant.name,
            application
        );
    };

    render() {
        const { intl } = this.props;
        let sharepointLink = "";
        let oneNoteLink;
        let filesLink;
        let outlookLink = "";
        let calendarLink = "";

        const outlookTitle = intl.formatMessage({
            id: "teamModalHeader.outlookIconTitle",
            defaultMessage: "E-mail"
        });
        const calendarTitle = intl.formatMessage({
            id: "teamModalHeader.calendarIconTitle",
            defaultMessage: "Agenda"
        });
        const oneNoteTitle = intl.formatMessage({
            id: "teamModalHeader.OneNoteTitle",
            defaultMessage: "Notitieblok"
        });
        const filestitle = intl.formatMessage({
            id: "teamModalHeader.filesTitle",
            defaultMessage: "Bestanden"
        });
        const sharepointTitle = intl.formatMessage({
            id: "teamModalHeader.sharePointTitle",
            defaultMessage: "Site"
        });
        const teamsTitle = intl.formatMessage({
            id: "teamModalHeader.teamsTitle",
            defaultMessage: "Teams"
        });

        let teamsLink = <Icon iconName="TeamsLogo" title={teamsTitle} className="teamsDisabled" />;

        if (this.props.teamUrl != "") {
            teamsLink = (
                <a href={this.props.teamUrl} target="_blank">
                    <Icon
                        iconName="TeamsLogo"
                        title="Teams"
                        className="teams"
                        onClick={() => this.logApplicationStarted("Teams")}
                    />
                </a>
            );
        }

        if (this.props.currentUser.tenant) {
            sharepointLink = `https://${this.props.currentUser.tenant.name}.sharepoint.com/_layouts/15/groupstatus.aspx?id=${this.props.teamId}`;

            oneNoteLink = `https://${this.props.currentUser.tenant.name}.sharepoint.com/_layouts/Groupstatus.aspx?id=${this.props.teamId}&target=notebook`;

            filesLink = `https://${this.props.currentUser.tenant.name}.sharepoint.com/_layouts/15/groupstatus.aspx?id=${this.props.teamId}&target=documents`;
        }

        if (this.props.currentTeam != "" && typeof this.props.currentTeam != "undefined") {
            if (this.props.currentTeam.mail != "" && typeof this.props.currentTeam.mail != "undefined") {
                calendarLink = `https://outlook.office.com/owa/#path=/group/${this.props.currentTeam.mail}/calendar`;

                outlookLink = `https://outlook.office.com/owa/?path=/group/${this.props.currentTeam.mail}/mail`;
            }
        }

        return (
            <section className="team-header-wrapper">
                <div className="team-header">
                    <Icon
                        iconName="ChromeClose"
                        onClick={this.props.closeModal}
                        className="team-header__close-button"
                    />
                </div>
                <div className="content-wrapper">
                    <div className="content-wrapper__logo">
                        <TeamCardLogo injectClass="logo" teamId={this.props.teamId} />
                    </div>
                    <div className="content-wrapper__title">
                        <h1>{this.props.title}</h1>
                        <div className="content-wrapper__application-bar">
                            <a href={outlookLink} target="_blank">
                                <Icon
                                    iconName="OutlookLogo"
                                    title={outlookTitle}
                                    className="outlook"
                                    onClick={() => this.logApplicationStarted("Outlook")}
                                />
                            </a>
                            <a href={calendarLink} target="_blank">
                                <Icon
                                    iconName="Calendar"
                                    title={calendarTitle}
                                    className="calendar"
                                    onClick={() => this.logApplicationStarted("Calendar")}
                                />
                            </a>
                            <a href={oneNoteLink} target="_blank">
                                <Icon
                                    iconName="OneNoteLogo"
                                    title={oneNoteTitle}
                                    className="onenote"
                                    onClick={() => this.logApplicationStarted("One note")}
                                />
                            </a>
                            <a href={filesLink} target="_blank">
                                <Icon
                                    iconName="OpenFile"
                                    title={filestitle}
                                    className="files"
                                    onClick={() => this.logApplicationStarted("One drive")}
                                />
                            </a>
                            <a
                                href={sharepointLink}
                                target="_blank"
                                onClick={() => this.logApplicationStarted("Sharepoint")}
                            >
                                <Icon iconName="SharepointLogo" title={sharepointTitle} className="sharepoint" />
                            </a>
                            {teamsLink}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.currentUserSlice.currentUserError,
        loading: state.currentUserSlice.currentUserLoading,
        currentUser: state.currentUserSlice.currentUser,
        currentTeam: state.teamsSlice.currentTeam
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCurrentUser: () => dispatch(userActions.fetchCurrentUser())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TeamModalHeader));
