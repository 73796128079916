import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { GetGroupMembers } from "../../../HttpRepositories/oneApiRepository";
import { getImage } from "../../../HttpRepositories/graphRepository";
import UserImage from "../../../assets/images/anonymous-user-gravatar.png";
import membersComponent from "../teamModal/teamModalBody/membersComponent/membersComponent";

class TeamCardPersonaMembers extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }

  state = {
    members: []
  };

  async componentDidMount() {
    this._isMounted = true;
    let members = this.props.members;

    const membersToSend = [];
    var i = 0;

    for (const member of members) {
      if (i <= 2) {
        if (member.id != "") {
          const memberPhotoUrl = `https://graph.microsoft.com/beta/users/${member.id}/photo/$value`;

          let memberPhoto = await getImage(memberPhotoUrl);
          if (memberPhoto !== "error") {
            member.photo = memberPhoto;
          } else {
            member.photo = UserImage;
          }

          membersToSend.push(member);
        }
        i++;
      }
    }

    if (this._isMounted) {
      this.setState({ members: membersToSend });
    }
  }

  render() {
    let members = [];
    let memberMoreCount = null;
    let memberCountToShow = null;

    if (this.props.members.length > 1) {
      memberMoreCount = this.props.members.length - 2;

      if (memberMoreCount > 0) {
        memberCountToShow = (
          <div className="more-members"> +{memberMoreCount}</div>
        );
      }
    }

    if (this.state.members.length > 0) {
      this.state.members.map(member => {
        const memberImg = (
          <img
            key={member.id}
            src={member.photo}
            alt={member.displayName}
            className="team-card-persona-wrapper"
            style={{ marginRight: "10px" }}
            title={member.displayName}
          />
        );

        members.push(memberImg);
      });
    }

    return (
      <React.Fragment>
        {members} {memberCountToShow}
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default TeamCardPersonaMembers;
