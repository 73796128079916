import React from "react";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Settingsbutton from "./settingsbutton/settingsButton";
import MobileMenu from "./mobileMenu/mobileMenu";
import LogoutButton from "./logoutButton/logoutButton";
import OneLogo from "../../assets/images/One.png";
import { logUserNavigatedToOffice } from "../../logging/applicationInsights/logFunctions";
import NotificationHubButton from "./notificationHubButton/notificationHubButton";
import { closeNotificationAlert } from "../../store/teamRequests/actions";
import { injectIntl } from "react-intl";

class navbar extends React.Component {
  state = {
    collapse: false,
    redirectToTrial: false,
    isOpen: false,
    openPanelType: ""
  };

  onTogglePanel = panelType => {
    this.setState(prevState => {
      if (prevState.isOpen) {
        panelType = "";
      }

      return {
        isOpen: !prevState.isOpen,
        openPanelType: panelType
      };
    });

    this.props.closeNotificationAlert();
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  closeMobileMenu = () => {
    this.setState({ collapse: false });
  };

  render() {
    const { intl } = this.props;
    const currentUserName =
      this.props.currentUser != "" ? this.props.currentUser.name : "";

    const currentTenantName =
      this.props.currentUser != "" ? this.props.currentUser.tenant.name : "";

    const logUserToOffice = event => {
      event.stopPropagation();
      logUserNavigatedToOffice(currentUserName, currentTenantName);
      event.persist();
    };

    let logoClass = "navbar__companyLogo";

    let menuRightSideContent = (
      <div>
        <NotificationHubButton
          togglePanel={this.onTogglePanel}
          isOpen={this.state.isOpen}
          panelType={this.state.openPanelType}
        />
        <Settingsbutton
          iconName="Settings"
          togglePanel={this.onTogglePanel}
          isOpen={this.state.isOpen}
          panelType={this.state.openPanelType}
        />
        <LogoutButton
          togglePanel={this.onTogglePanel}
          isOpen={this.state.isOpen}
          panelType={this.state.openPanelType}
        />
      </div>
    );

    // overrides the right links of the navbar in case we sell the signup/sales page
    if (this.props.type === "anonymous") {
      menuRightSideContent = (
        <ul className="menu-right-side-content">
          <li>
            <NavLink activeClassName="active-class-right-menu" to="/teams">
              Proefversie
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active-class-right-menu" to="/info">
              Info
            </NavLink>
          </li>
        </ul>
      );

      logoClass = "navbar__companyLogo-anonymous";
    }

    // disable hamburger manu if the user has no subscription
    let hamburgerMenu = "";
    if (this.props.type !== "anonymous") {
      hamburgerMenu = (
        <div className="navbar__mobile-menu-button" onClick={this.toggle}>
          <div className="navbar__mobile-menu-button__icon-wrapper">
            <FontAwesomeIcon
              icon={faBars}
              className="navbar__mobile-menu-button__icon-wrapper-icon"
            />
          </div>
        </div>
      );
    }

    let logoStyles = "navbar__app-name";
    if (this.props.type === "anonymous") {
      logoStyles = "navbar__app-name navbar__indent-logo";
    }

    return (
      <React.Fragment>
        <nav className="navbar__container">
          <div onClick={event => logUserToOffice(event)}>
            {/* Microsoft portal link and program title */}
            <DefaultButton
              className="navbar__office-logo"
              href={`https://www.office.com?auth=2&home=1`}
              target="_blank"
              title={intl.formatMessage({
                id: "navBar.office365Link",
                defaultMessage: "Ga naar office365"
              })}
            >
              <div className="navbar__office-logo__icon-wrapper">
                <Icon
                  iconName="Waffle"
                  className="navbar__office-logo__icon-wrapper-icon"
                />
              </div>
            </DefaultButton>

            {/* Mobile menu hamburger icon */}
            {hamburgerMenu}

            {/* App Name */}
            <div className={logoStyles}>
              <div className="navbar__app-name__wrapper">
                Teams <sub className="beta-logo">BETA</sub>
              </div>
            </div>
          </div>

          {/* App Logo*/}
          <div className={logoClass}>
            <img src={OneLogo} alt="company logo" />
          </div>

          {/* Settings / logout button*/}
          {menuRightSideContent}
        </nav>

        {/* Renders mobile menu */}
        <Collapse isOpen={this.state.collapse}>
          <MobileMenu closeMenu={() => this.closeMobileMenu()} />
        </Collapse>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUserSlice.currentUser,
    router: state.router
  };
};

export default withRouter(
  connect(mapStateToProps, { closeNotificationAlert })(injectIntl(navbar))
);
