import React from "react";
import Loader from "react-loader-spinner";

class WithLoading extends React.Component {
    render() {
        if (this.props.smallMargin) {
            var styles = {
                display: "flex",
                marginTop: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
            };
        } else {
            var styles = {
                display: "flex",
                marginTop: "100px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
            };
        }

        let loading = this.props.isLoading ? (
            <div className="loaderWrapper" style={styles}>
                <Loader type="ThreeDots" color="#00BFFF" height="50" width="50" />{" "}
            </div>
        ) : null;

        return <React.Fragment>{loading}</React.Fragment>;
    }
}

export default WithLoading;
