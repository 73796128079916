export default {
	// --== General ==--
	"general.makeAChoice": "Select an option",
	"general.public": "Public",
	"general.private": "Private",
	"general.yes": "Yes",
	"general.no": "No",
	"general.formEditText": "Edit",
	"general.save": "Save",
	"general.formCreateText": "Create",
	"general.requiredField": "This is a required field",

	// --== Nav ==--
	"nav.myTeams": "My teams",
	"nav.discoverTeams": "All teams",
	"nav.teams": "Teams",
	"nav.manage": "Manage",
	"nav.metadataLinkText": "Terms",
	"navBar.office365Link": "Go to Microsoft Office365",

	// --== Summary ==--
	"summary.memberOf": "Member of",
	"summary.ownerOf": "Owner of",

	// --== Search ==--
	"search.placeholder": "Search...",
	"search.awaitMessage": "Please wait a moment...",

	// --== CommandBar ==--
	"commandbar.removeFilter": "Remove filters",
	"commandbar.filter": "Filter",
	"commandbar.addTeam": "New team request",
	"commandbar.teamCreated": "A new team will be created, this might take a few minutes...",
	"commandbar.teamCreatedNeedsApproval": "A team was requested and is pending for approval...",
	"commandbar.teamCreatedFailed": "Something has gone wrong while creating the team.",

	// -== Filters ==-
	"filters.status.active": "Active",
	"filters.status.expired": "Expired",
	"filters.status.archived": "Archived",
	"filters.status.deleted": "Deleted",

	// --== TeamCard ==--
	"teamcard.memberCount": "Members",
	"teamcard.marksAsFavorite": "Favorite",
	"teamcard.marksAsNonFavorite": "Unfavorite",
	"teamcard.statusLabelPublic": "Public team",
	"teamcard.statusLabelPrivate": "Private team",

	// --== Teams ==--
	"teams.noSearchResults": "No teams found",
	"teams.activeFilters": "Active filters",
	"teams.privateLabel": "Private",
	"teams.publicLabel": "Public",
	"teams.viewTeam": "View",
	"teams.joinTeam": "Join",

	// --== Provisioning ==--
	"provisioning.PanelTitle": "New team Request",
	"provisioning.PanelEssentialInformation": "Basic info",
	"provisioning.teamNameLabel": "Team name",
	"provisioning.teamNamePlaceHolder": "Enter a team name...",
	"provisioning.internalTeamNameLabel": "Internal team name",
	"provisioning.teamTypeLabel": "Teamtype",
	"provisioning.requestTypeLabel": "Request type",
	"provisioning.privacyLevelLabel": "Privacy level",
	"provisioning.descriptionLabel": "Description",
	"provisioning.allowToAddGuestsLabel": "Allow to add guests",
	"provisioning.metaDataLabel": "Other info",
	"provisioning.submit": "Request team",
	"provisioning.mustSelectATypeError": "You must select a type.",
	"provisioning.mustUseTwoCharactersError": "You must use at least 2 characters",
	"provisioning.mustProvideATeamnameError": "You must enter a teamName.",
	"provisioning.MustSelectATeamTypeError": "You must select a teamType.",

	// --== TeamModal ==--

	"teamModalHeader.outlookIconTitle": "E-mail",
	"teamModalHeader.calendarIconTitle": "Agenda",
	"teamModalHeader.OneNoteTitle": "Notes",
	"teamModalHeader.filesTitle": "Files",
	"teamModalHeader.sharePointTitle": "Site",
	"teamModalHeader.teamsTitle": "Teams",

	"teamModalBody.channelsTabTitle": "Channels",
	"teamModalBody.channelsNoneAvailable": "There are no channels available",

	"teamModalBody.filesTabName": "Files",
	"teamModalBody.filesTabTitle": "All files",
	"teamModalBody.filesTabNoFiles": "There are no files",

	"teamModalBody.plansTabName": "Plans",
	"teamModalBody.plansTabTitle": "All plans",
	"teamModalBody.planNoneAvailable": "Currently there are no plans.",

	"teamModalBody.membersTabName": "members",
	"teamModalBody.membersTabTitle": "All members",
	"teamModalBody.membersExternalUserTitle": "External user",
	"teamModalBody.membersOwnersHeader": "Owners",
	"teamModalBody.membersMembersHeader": "Members",

	"teamModalBody.infoTabName": "Info",
	"teamModalBody.infoTabTitle": "About this team",
	"teamModalBody.infoExpandMetaDataLink": "Edit information",
	"teamModalBody.archivedStatusLabel": "Status",
	"teamModalBody.archivedStatusActive": "Active",
	"teamModalBody.archivedStatusInactive": "Archived",
	"teamModalBody.infoTabCategoryLabel": "Category info",
	"teamModalBody.infoTabBacisInfoLabel": "Basic info",
	"teamModalBody.infoTabActionsLabel": "Actions",
	"teamModalBody.infoTabTeamArchiveLabel": "Archive team",
	"teamModalBody.infoTabNoTermsActive": "There are no terms for this team.",
	"teamModalBody.infoTabTeamTypeLabel": "Teamtype",

	"teamModalBody.archivePanelHeaderLabel": "Archive Team",
	"teamModalBody.archivePanelAreYouSureMessage": "Are you sure you want to archive this team?",
	"teamModalBody.archivePanelAchiveYesLabel": "Archive",
	"teamModalBody.archivePanelAchiveNoLabel": "Cancel",
	"teamModalBody.archieveSuccessAlertLabel": "The team has been archived succesfully.",
	"teamModalBody.archieveFailedAlertLabel": "We were unable to archive the team.",

	"teamModalBody.groupSettings.label": "Group settings",
	"teamModalBody.groupSettings.allowToAddGuets.label": "Allow to add guests",
	"teamModalBody.groupSettings.allowToAddGuets.chooseValue": "Choose a value",

	// --== MetaData ==--
	"metadata.teamTypesManage": "Manage teamtypes",
	"metadata.categoriesManage": "Manage categories",
	"metadata.panelTitle": "Manage filter data",
	"metadata.teamInfo": "Team information",
	"metadata.placeholder": "Select a ",
	"metadata.disabledTitle": "One or more fields are required.",
	"metadata.submitLabel": "Update information",
	"metadata.dataUpdated": "The team information has been updated.",
	"metadata.dataHasntUpdated": "The team information was unable to update.",
	"metadata.noMetaDataAvailable": "There are no team classification available in your organization.",
	"metadata.newTermSetText": "Create new term set",
	"metadata.newTermText": "Create new term",
	"metadata.detailsHeaderText": "Manage categories",
	"metadata.detailsContentText": "None selected, select a category from the left panel.",
	"metadata.createTermSuccessAlert": "Term created",
	"metadata.createTermFailedAlert": "Something went wrong while creating the term",
	"metadata.updateTermSuccessAlert": "Term has been updated",
	"metadata.updateTermFailedAlert": "Something went wrong while updating the term",
	"metadata.createTermSetSuccessAlert": "TermSet created",
	"metadata.createTermSetFailedAlert": "Something went wrong while creating the termSet",
	"metadata.updateTermSetSuccessAlert": "TermSet has been updated",
	"metadata.updateTermSetFailedAlert": "Something went wrong while updating the termSet",
	"metadata.newTeamTypeText": "Create new teamtype",
	"metadata.addTeamTypeSuccessAlert": "Teamtype has been updated",
	"metadata.addTeamTypeFailedAlert": "Something went wrong while updating the teamtype",
	"metadata.updateTeamTypeFailedAlert": "Something went wrong while updating the teamtype",
	"metadata.updateTeamTypeSuccessAlert": "Teamtype has been updated",

	"metadata.form.teamTypeLabel": "Teamtype",

	"metadata.form.termNameInputLabel": "Name",
	"metadata.form.termNameInputDescription": "Enter a name for this term",
	"metadata.form.termIsActiveInputLabel": "Is active",
	"metadata.form.termIsActiveInputDescription": "This term may be linked to teams",
	"metadata.form.validation.termNameMinTwoCharacters": "You have to enter at least two characters for the name.",
	"metadata.form.validation.termNameRequired": "You have to enter a name for this term",

	"metadata.form.termSetNameInputLabel": "Name",
	"metadata.form.termSetNameInputDescription": "Enter a name for this term set",
	"metadata.form.termSetIsRequiredInputLabel": "Is required",
	"metadata.form.termSetIsRequiredInputDescription": "This term set is required",
	"metadata.form.termSetIsActiveLabel": "Is active",
	"metadata.form.termSetIsActiveDescription": "This term set can be assigned to teams",
	"metadata.form.termSetIsReassignableLabel": "Is reassignable",
	"metadata.form.termSetIsReassignableDescription": "This term set can be edited",
	"metadata.form.termSetIsRequiredDisabledInfoText":
		"A term set can't be required if is inactive, or if it does not contain active terms.",
	"metadata.form.termIsRequiredDisabledInfoText":
		"This term can not be made inactive because the term set containing it is required and this term is the last active term in that set.",
	"metadata.form.validation.termSetNameMinTwoCharacters": "You have to enter at least two characters for the name.",
	"metadata.form.validation.termSetNameRequired": "You have to enter a name for this term set",

	"metadata.form.termGroupNameInputLabel": "Name",
	"metadata.form.termGroupNameInputDescription": "Enter a name for this term group",
	"metadata.form.termGroupIsTenantWideInputLabel": "Is tenant wide",
	"metadata.form.termGroupIsTenantWideInputDescription": "This term group can be used across the organization",
	"metadata.form.validation.termGroupNameMinTwoCharacters": "You have to enter at least two characters for the name.",
	"metadata.form.validation.termGroupNameRequired": "You have to enter a name for this term group ",

	"metadata.form.teamType.nameLabel": "Teamtype",
	"metadata.form.teamType.nameDescription": "The name for this teamtype",
	"metadata.form.teamType.namePlaceholder": "Enter a name for this teamtype",
	"metadata.form.teamType.domainLabel": "Domain",
	"metadata.form.teamType.domainDescription": "Use this name for the mail nickname",
	"metadata.form.teamType.domainPlaceholder": "Enter a domain for the mail nickname",
	"metadata.form.teamType.approvalRequiredLabel": "Approval required",
	"metadata.form.teamType.approvalRequiredDescription": "Decides whether a team needs approval prior creating it",

	// --== Account ==--
	"account.settings": "My settings",
	"account.myProfile": "My profile",
	"account.logout": "Logout",
	"account.appSettings": "Application settings",
	"account.appSettingsPanelTitle": "Application settings",
	"account.settingsHeader": "General settings",
	"account.showProvisioningDropdownText": "show provisioning form for new teams to users",
	"account.languageDropdownText": "Language",
	"account.saveSttingsText": "Save settings",
	"account.settingsText": "Settings",
	"account.applicationSettingsHeader": "Application settings",
	"account.tenantSettingsHeader": "Tenant settings",
	"account.userSettingsHeader": "User settings",
	"account.accountSettingsUpdateFailed": "Something went wrong.",
	"account.accountSettingsUpdateSucces": "Settings saved succesfully.",
	"account.languageRequiredText": "Please select a language",

	// --== NotificationHub ==--
	"notificationHub.notificationHeaderText": "Notifications",
	"notificationHub.requestedHeaderText": "Recent team requests",
	"notificationHub.noRecentTeamRequests": "No requests found",
	"notificationHub.panelHeader": "Messages",
	"notificationHub.myTeamRequestsLinkText": "All my requests",
	"notificationHub.requestedTeamDetailsOwnerText": "Owner",
	"notificationHub.requestedTeamDetailsStatusText": "Status",
	"notificationHub.deleteButtonText": "Delete",
	"notificationHub.deleteSuccess": "Request deleted",
	"notificationHub.deleteFailure": "Something went wrong with deleting the request",

	// --== TeamRequest ==--
	"teamRequest.statusTextRequested": "Requested",
	"teamRequest.statusTextApproved": "Approved",
	"teamRequest.statusTextRejected": "Rejected",
	"teamRequest.statusTextInProgress": "In Progress",
	"teamRequest.statusTextReady": "Ready",
	"teamRequest.statusTextFailed": "Failed",
	"teamRequest.statusTextDeleted": "Deleted",
	"teamRequest.noRequestsFound": "No team requests found",

	"teamRequest.my.headerText": "My team requests",
	"teamRequest.all.headerText": "All team requests",

	// --== TeamRequestsTableComponent ==--
	"teamRequestTable.openDetailPanelText": "More information...",
	"teamRequestTable.approvalRequiredOpenDetailPanelText": "Approval required",
	"teamRequestTable.headerTitle": "Title",
	"teamRequestTable.headerCreatedOn": "Created on",
	"teamRequestTable.headerOwner": "Owner",
	"teamRequestTable.headerTeamType": "Type",
	"teamRequestTable.headerStatus": "Status",
	"teamRequestTable.headerActions": "Actions",

	"teamRequestTableDetail.titleLabel": "Title",
	"teamRequestTableDetail.statusLabel": "Status",
	"teamRequestTableDetail.statusReason": "Status reason",
	"teamRequestTableDetail.descriptionLabel": "Description",
	"teamRequestTableDetail.allowToAddGuestsLabel": "Allow to add guests",
	"teamRequestTableDetail.teamTypeLabel": "Teamtype",
	"teamRequestTableDetail.privacyLevelLabel": "Privacy level",
	"teamRequestTableDetail.ownerLabel": "Owner",
	"teamRequestTableDetail.createdLabel": "Created on",
	"teamRequestTableDetail.modifiedLabel": "Last modified on",
	"teamRequestTableDetail.activeTermsLabel": "Active terms",

	"teamRequestTableDetail.action.approve": "Approve",
	"teamRequestTableDetail.action.reject": "Reject",
	"teamRequestTableDetail.action.delete": "Delete",
	"teamRequestTableDetail.action.cancel": "Cancel",

	"teamRequestTableDetail.action.deleteFailure": "Something went wrong with deleting the request",
	"teamRequestTableDetail.action.deleteSuccess": "Request deleted",
	"teamRequestTableDetail.action.approveFailure": "Something went wrong with approving the request",
	"teamRequestTableDetail.action.approveSuccess": "Request approved",
	"teamRequestTableDetail.action.rejectFailure": "Something went wrong with rejecting the request",
	"teamRequestTableDetail.action.rejectSuccess": "Request rejected",

	// --== Settings ==--
	"settings.panelTitle": "Settings",
	"settings.appSettingsTitle": "Application settings",
	"settings.appSettingsDescription": "Change the application settings.",
	"settings.metaDataSettingTitle": "Manage Categories",
	"settings.metaDataSettingDescription": "Manage the categories for the application.",
	"settings.allTeamRequestsSettingTitle": "All team requests",
	"settings.allTeamRequestsSettingDescription": "Here you can manage and look into all team requests.",
};
