import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import { defineTeamRequestStatusUi } from "../../../assets/general_functions/genericHelpers";

class TeamRequestsDetailComponent extends React.Component {
	renderActionButtons = () => {
		const { teamRequest, userCanApprove, actions } = this.props;
		let actionsButtonsToRender = "";
		let approveActionButton = "";
		let rejectActionButton = "";
		let deleteActionButton = "";

		// If the status of the request is 'Requested' and the user should be able to approve or reject...
		if (teamRequest.status === 0 && userCanApprove) {
			// ...include an 'approve' button
			approveActionButton = (
				<div
					className="team-request-wrapper__action team-request-wrapper__action--green"
					onClick={() => actions.onApprove(teamRequest.id)}
				>
					<Icon iconName="Accept" />
					<FormattedMessage id="teamRequestTableDetail.action.approve" defaultMessage="Goedkeuren" />
				</div>
			);

			// ...include a 'reject' button
			rejectActionButton = (
				<div
					className="team-request-wrapper__action team-request-wrapper__action--red"
					onClick={() => actions.onReject(teamRequest.id)}
				>
					<Icon iconName="ChromeClose" />
					<FormattedMessage id="teamRequestTableDetail.action.reject" defaultMessage="Afwijzen" />
				</div>
			);
		}

		// If the status of the request is equal to 'Requested', 'Rejected', or 'Failed' -> include a delete button
		if (teamRequest.status === 0 || teamRequest.status === 2 || teamRequest.status === 5) {
			if (!userCanApprove) {
				let deleteActionButtonClass = "team-request-wrapper__action team-request-wrapper__action--red";
				let deleteIcon = <Icon iconName="Delete" />;
				let deleteText = (
					<FormattedMessage id="teamRequestTableDetail.action.delete" defaultMessage="Verwijderen" />
				);

				// if the user is in accessing from MyTeamRequests and the status is 'Requested', show the text 'cancel' instead of 'delete'
				if (!userCanApprove && teamRequest.status === 0) {
					deleteActionButtonClass = "team-request-wrapper__action team-request-wrapper__action--red";
					deleteIcon = <Icon iconName="ChromeClose" />;
					deleteText = (
						<FormattedMessage id="teamRequestTableDetail.action.cancel" defaultMessage="Annuleren" />
					);
				}

				// Use the full width if this is the only action to be rendered.
				if (approveActionButton == "" && rejectActionButton == "") {
					deleteActionButtonClass += " team-request-wrapper__action--full-width";
				}

				// include the delete button
				deleteActionButton = (
					<div className={deleteActionButtonClass} onClick={() => actions.onDelete(teamRequest.id)}>
						{deleteIcon}
						{deleteText}
					</div>
				);
			}
		}

		// If one of the actionButtons has a value, set the actionsButtonToRender. If not, the buttons to render will remain empty.
		if (approveActionButton != "" || rejectActionButton != "" || deleteActionButton != "") {
			actionsButtonsToRender = (
				<div className="team-request-wrapper wrapper-spacer-team-requests">
					{approveActionButton}
					{rejectActionButton}
					{deleteActionButton}
				</div>
			);
		}

		return actionsButtonsToRender;
	};

	renderActiveTerms = () => {
		let activeItemsToRender = "";
		if (!this.props.teamRequest.terms || !this.props.teamRequest.terms.length) {
			return activeItemsToRender;
		}

		const renderedTerms = this.props.teamRequest.terms.map((term) => {
			return (
				<div className="team-request-detail-item" key={term.id}>
					<div className="team-request-detail-item__header">{term.termSetName}</div>
					<div className="team-request-detail-item__content">{term.name}</div>
				</div>
			);
		});

		if (renderedTerms.length > 0) {
			activeItemsToRender = (
				<div className="team-request-detail-row">
					<div className="team-request-detail-row__category">
						<h2>
							<FormattedMessage id="provisioning.metaDataLabel" defaultMessage="Overige informatie" />
						</h2>
					</div>
					{renderedTerms}
				</div>
			);
		}

		return activeItemsToRender;
	};

	render() {
		const { teamRequest, userCanApprove } = this.props;
		let statusReason = "";

		// Render nothing if the new teamRequest is empty.
		if (!teamRequest) {
			return "";
		}

		const teamRequestStatusUi = defineTeamRequestStatusUi(teamRequest.status);
		let privacyLevelText = "";

		if (teamRequest.privacyLevel === 0) {
			privacyLevelText = <FormattedMessage id="general.public" defaultMessage="Publiek" />;
		} else {
			privacyLevelText = <FormattedMessage id="general.private" defaultMessage="Privé" />;
		}

		if (userCanApprove && teamRequest.statusReason) {
			statusReason = (
				<div className="team-request-detail-item">
					<div className="team-request-detail-item__header">
						<FormattedMessage id="teamRequestTableDetail.statusReason" defaultMessage="Reden van status" />
					</div>
					<div className="team-request-detail-item__content">{teamRequest.statusReason}</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				{this.renderActionButtons()}

				<div className="team-request-detail-row">
					<div className="team-request-detail-row__category">
						<h2>
							<FormattedMessage
								id="provisioning.PanelEssentialInformation"
								defaultMessage="Basisinformatie"
							/>
						</h2>
					</div>

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage id="teamRequestTableDetail.titleLabel" defaultMessage="Titel" />
						</div>
						<div className="team-request-detail-item__content">{teamRequest.title}</div>
					</div>

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage id="teamRequestTableDetail.statusLabel" defaultMessage="Status" />
						</div>
						<div
							className="team-request-detail-item__content"
							style={{ color: teamRequestStatusUi.fontColor }}
						>
							{teamRequestStatusUi.statusText}
						</div>
					</div>

					{statusReason}

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage
								id="teamRequestTableDetail.descriptionLabel"
								defaultMessage="Beschrijving"
							/>
						</div>
						<div className="team-request-detail-item__content">{teamRequest.description}</div>
					</div>

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage
								id="teamRequestTableDetail.allowToAddGuestsLabel"
								defaultMessage="Gasten toelaten"
							/>
						</div>
						<div className="team-request-detail-item__content">
							{teamRequest.allowToAddGuests ? (
								<FormattedMessage id="general.yes" defaultMessage="Ja" />
							) : (
								<FormattedMessage id="general.no" defaultMessage="Nee" />
							)}
						</div>
					</div>

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage id="teamRequestTableDetail.teamTypeLabel" defaultMessage="Teamtype" />
						</div>
						<div className="team-request-detail-item__content">{teamRequest.teamType.name}</div>
					</div>

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage
								id="teamRequestTableDetail.privacyLevelLabel"
								defaultMessage="Privacy niveau"
							/>
						</div>
						<div className="team-request-detail-item__content">{privacyLevelText}</div>
					</div>

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage id="teamRequestTableDetail.ownerLabel" defaultMessage="Eigenaar" />
						</div>
						<div className="team-request-detail-item__content">{teamRequest.owner.emailAddress || "-"}</div>
					</div>

					<div className="team-request-detail-item">
						<div className="team-request-detail-item__header">
							<FormattedMessage
								id="teamRequestTableDetail.createdLabel"
								defaultMessage="Aangevraagd op"
							/>
						</div>
						<div className="team-request-detail-item__content">
							<FormattedDate value={teamRequest.created} year="numeric" month="long" day="numeric" />
						</div>
					</div>
				</div>

				{this.renderActiveTerms()}
			</React.Fragment>
		);
	}
}

export default TeamRequestsDetailComponent;
