import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

import * as filterActions from "../../../../store/filters/actions";
import * as teamsActions from "../../../../store/teams/actions";
import * as teamTypesActions from "../../../../store/teamTypes/actions";
import { sortArray } from "../../../../assets/general_functions/objectsAndArrays";

import Checkbox from "../../../../common/utils/checkbox/checkbox";

class FilterArea extends React.Component {
	state = {
		filterValues: [],
		searchType: "",
		checkedFilterItems: new Map(),
		checkedStatusFilters: [],
		checkedTypeFilters: [],
	};

	async componentDidMount() {
		if (this.props.filterTermSets == undefined) {
			this.props.onFetchFilters();
		}

		if (this.props.teamTypes == undefined || this.props.teamTypes.length === 0) {
			this.props.fetchTeamTypes();
		}

		if (this.props.myTeams.length === 0 || typeof this.props.myTeams.length === "undefined") {
			// load all private and all public teams
			if (typeof this.props.myTeams == "undefined" || this.props.myTeams.length === 0) {
				await this.props.fetchAllTeams("private");
			}
			if (typeof this.props.allTeams == "undefined" || this.props.allTeams.length === 0) {
				await this.props.fetchAllTeams("public");
			}
		}
	}

	handleSubmit = (event) => {
		let checkedStatusFilters = this.state.checkedStatusFilters;
		if (event) {
			event.preventDefault();

			// closes the menu when the filter button is clicked.
			this.props.toggleFilterMenu();
		} else {
			checkedStatusFilters = [];
		}

		let teamSetToPass = [];

		// check whether filteredteams is set, if not pass either the
		// all teams or all my teams as data to filter on.

		if (this.state.searchType === "private") {
			// check whether there are more filters selected than what has been stored in
			// redux store. If so, we use the allmyteams endpoint.
			if (this.props.activeFilters.length != this.state.filterValues.length) {
				teamSetToPass = this.props.myTeams;
			} else {
				// if there is already a filteredTeams array in the redux state we use this instead.
				if (this.props.filteredTeams.length > 0 && typeof this.props.filteredTeams !== "undefined") {
					teamSetToPass = this.props.filteredTeams;
				}
				// if the filteredTeams array is empty use the myteams array.
				else {
					teamSetToPass = this.props.myTeams;
				}
			}
		} else if (this.state.searchType === "public") {
			// check whether there are more filters selected than what has been stored in
			// redux store. If so, we use the allmyteams endpoint.
			if (this.props.activeFilters.length != this.state.filterValues.length) {
				teamSetToPass = this.props.allTeams;
			} else {
				// if there is already a filteredTeams array in the redux state we use this instead.
				if (this.props.filteredTeams.length > 0 && typeof this.props.filteredTeams !== "undefined") {
					teamSetToPass = this.props.filteredTeams;
					// if the filteredTeams array is empty use the allTeams array.
				} else {
					teamSetToPass = this.props.allTeams;
				}
			}
		}

		this.props.saveFilters(
			this.state.filterValues,
			teamSetToPass,
			this.state.searchType,
			checkedStatusFilters,
			this.state.checkedTypeFilters
		);
	};

	resetCheckedFilter = () => {
		this.setState({
			filterValues: [],
			checkedStatusFilters: [],
			checkedTypeFilters: [],
		});
		const emptyMap = new Map();
		this.setState({ checkedFilterItems: new Map() });
	};

	handleFormChange = (termId, termSetId, termName, termSetName, e) => {
		const isChecked = e.target.checked;
		this.setState((prevState) => prevState.checkedFilterItems.set(termId, isChecked));

		// we first create the new object.
		const filterToAdd = {
			termId,
			termSetId,
			termName,
			termSetName,
			checked: e.target.checked,
		};

		// if the state is empty, add the new object to the state
		if (this.state.filterValues.length === 0) {
			this.setState((prevState) => ({
				filterValues: [...prevState.filterValues, filterToAdd],
			}));
			// if the state is not empty, check whether the filter needs to be added.
			// if it's not in the state already add it.
		} else if (this.state.filterValues.length > 0 && e.target.checked === true) {
			const exists = this.state.filterValues.filter((filter) => {
				return filter.termId === termId ? termId : "";
			});

			if (typeof exists !== "undefined" && exists.length === 0) {
				this.setState((prevState) => ({
					filterValues: [...prevState.filterValues, filterToAdd],
				}));
			}
			// if the state is not empty and we need to remove the filter.
			// filter over the values and return all but the value that was unset.
		} else if (this.state.filterValues.length > 0 && e.target.checked === false) {
			const newState = this.state.filterValues.filter((filter) => {
				if (filter.termId !== termId) {
					return filter;
				}
			});

			this.setState({
				filterValues: newState,
			});
		}
	};

	parseStatusFilterUI = () => {
		const { intl, hideStatusFilters } = this.props;
		// Some of these statusses should not yet be implemented
		const teamStatusses = [
			{
				statusCode: 0,
				intlId: "filters.status.active",
				defaultMessage: "Actief",
			},
			// { statusCode: 1, intlId: "filters.status.expired", defaultMessage: "Verlopen" },
			{
				statusCode: 2,
				intlId: "filters.status.archived",
				defaultMessage: "Gearchiveerd",
			},
			// { statusCode: 3, intlId: "filters.status.deleted", defaultMessage: "Verwijderd" }
		];
		let statusses = "";

		statusses = teamStatusses.map((status, index) => {
			const checkboxText = intl.formatMessage({
				id: status.intlId,
				defaultMessage: status.defaultMessage,
			});
			let isChecked = false;
			let title = "";

			if (hideStatusFilters) {
				isChecked = false;
				title = "Status filters kunnen nog niet worden toegepast op alle teams";
			} else {
				for (let item of this.state.checkedStatusFilters) {
					if (item.statusCode === status.statusCode) {
						isChecked = true;
					}
				}
			}

			return (
				<label className="checkbox-item-wrapper" key={status.statusCode}>
					<input
						type="checkbox"
						checked={isChecked}
						name={checkboxText}
						key={status.statusCode}
						value={checkboxText}
						disabled={hideStatusFilters}
						title={title}
						onChange={(event) => this.handleStatusFilterChange(status, isChecked, event)}
					/>
					{checkboxText}
					<br />
				</label>
			);
		});

		// TODO: add translations
		return (
			<div className="termset-wrapper">
				<div className="termset-wrapper__header">Status</div>
				<div className="termset-wrapper__terms-wrapper">{statusses}</div>
				<div />
			</div>
		);
	};

	handleStatusFilterChange = (statusFilter, isChecked, event) => {
		let checkedStatusFilters = [...this.state.checkedStatusFilters];
		if (isChecked) {
			checkedStatusFilters = checkedStatusFilters.filter((status) => status.statusCode !== statusFilter.statusCode);
		} else {
			checkedStatusFilters.push(statusFilter);
		}

		this.setState({ checkedStatusFilters });
	};

	parseTypeFilterUI = () => {
		const { intl, teamTypes } = this.props;
		let types = "";

		types = teamTypes.map((type, index) => {
			let isChecked = false;

			for (let item of this.state.checkedTypeFilters) {
				if (item.id === type.id) {
					isChecked = true;
				}
			}

			return (
				<label className="checkbox-item-wrapper" key={type.id}>
					<input
						type="checkbox"
						checked={isChecked}
						name={type.name}
						key={type.id}
						value={type.id}
						onChange={(event) => this.handleTypeFilterChange(type, isChecked, event)}
					/>
					{type.name}
					<br />
				</label>
			);
		});

		// TODO: add translations
		return (
			<div className="termset-wrapper">
				<div className="termset-wrapper__header">Types</div>
				<div className="termset-wrapper__terms-wrapper">{types}</div>
				<div />
			</div>
		);
	};

	handleTypeFilterChange = (typeFilter, isChecked, event) => {
		let checkedTypeFilters = [...this.state.checkedTypeFilters];
		if (isChecked) {
			checkedTypeFilters = checkedTypeFilters.filter((type) => type.id !== typeFilter.id);
		} else {
			checkedTypeFilters.push(typeFilter);
		}

		this.setState({ checkedTypeFilters });
	};

	render() {
		let error = "";
		let filters = [];
		let activeTermSets = [];

		// When the user navigates to /allTeams, clear the status filter and execute a submit with these 'new set filters'.
		if (this.props.hideStatusFilters && this.state.checkedStatusFilters.length > 0 && this.props.activeStatusFilters.length > 0) {
			this.setState({ checkedStatusFilters: [] });
			this.handleSubmit(null);
		}
		if (this.props.error) {
			error = <p>There is an error!</p>;
		}

		if (this.props.needsToDelete) {
			this.resetCheckedFilter();
			this.props.unsetNeedsToDelete();
		}

		const termSets = this.props.termSets === undefined ? [] : this.props.termSets;
		// remove inactive termSets and termSets without terms.
		activeTermSets = termSets.filter((termSet) => {
			if (termSet.isActive && termSet.terms != undefined && termSet.terms.length > 0) return termSet;
		});

		if (activeTermSets) {
			filters = activeTermSets.map((termSet) => {
				let terms = "";
				terms = termSet.terms.sort(sortArray("name")).reverse();

				terms = termSet.terms.map((term, i) => {
					if (term.isActive && term.isSelectable) {
						let checkedState = this.state.checkedFilterItems.get(term.id);

						if (typeof checkedState == "undefined") {
							checkedState = false;
						}

						let setAutoFocus = false;
						if (i === 0) {
							setAutoFocus = true;
						}

						return (
							<Checkbox
								key={term.id}
								autoFocus={setAutoFocus}
								tabIndex={i}
								checkboxTermId={term.id}
								checkboxTermName={term.name}
								checkboxValue={term.name}
								checkboxTermSetId={termSet.id}
								checkboxTermSetName={termSet.name}
								handleOnChange={this.handleFormChange}
								checked={checkedState}
								activeFilters={this.props.activeFilters}
							/>
						);
					}
				});

				const termSetArea = (
					<div className="termset-wrapper" key={termSet.name}>
						<div className="termset-wrapper__header">{termSet.name}</div>
						<div className="termset-wrapper__terms-wrapper">{terms}</div>
						<div />
					</div>
				);

				return termSetArea;
			});
		}

		return (
			<section className="filter-area">
				{error}
				<form onSubmit={this.handleSubmit}>
					{/* ---  The status filters are hidden for the time being because the functionality is not yet supported in the API  --- */}
					{/* {this.parseStatusFilterUI()}*/}
					{this.parseTypeFilterUI()}
					{filters}
					<button className="btn btn-primary teams-submit-button make-a-block" type="submit">
						Filter
					</button>
				</form>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		termSets: state.filtersSlice.filterTermSets,
		teamTypes: state.teamTypesSlice.teamTypes,
		error: state.filtersSlice.filtersError,
		loading: state.filtersSlice.filterLoading,
		isCurrentlyFiltering: state.filtersSlice.isCurrentlyFiltering,
		teams: state.teamsSlice.teams,
		searchTerm: state.teamsSlice.searchterm,
		filteredTeams: state.teamsSlice.filteredTeams,
		myTeams: state.teamsSlice.allMyTeams,
		allTeams: state.teamsSlice.allTeams,
		pathName: state.router.location.pathname,
		activeFilters: state.filtersSlice.activeFilters,
		activeStatusFilters: state.filtersSlice.activeStatusFilters,
		activeTypeFilters: state.filtersSlice.activeTypeFilters,
		needsToDelete: state.filtersSlice.needsToDelete,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchFilters: () => dispatch(filterActions.fetchFilters()),
		fetchTeamTypes: () => dispatch(teamTypesActions.fetchTeamTypes()),
		saveFilters: (filters, teamsToFilter, searchType, statusFilters, typeFilters) =>
			dispatch(filterActions.saveFilters(filters, teamsToFilter, searchType, statusFilters, typeFilters)),
		fetchAllTeams: (type) => dispatch(teamsActions.fetchAllTeams(type)),
		unsetNeedsToDelete: () => dispatch(filterActions.unsetNeedsToDelete()),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterArea)));
