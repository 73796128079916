import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
    fetchTeamRequests,
    removeTeamRequest,
    closeNotificationAlert,
    approveTeamRequest,
    rejectTeamRequest
} from "../../../store/teamRequests/actions";
import TeamRequestsTable from "../../../common/teamRequestsTable/TeamRequestsTable";
import BreadCrumb from "../../../containers/applicationSettingsContainer/breadCrumb/breadCrumb";

class AllTeamRequestsContainer extends React.Component {
    componentDidMount() {
        this.props.fetchTeamRequests("AllTeamContainer");
    }

    render() {
        const pathToPage = [
            {
                text: <FormattedMessage id="settings.panelTitle" defaultMessage="Instellingen" />,
                key: "settings"
            },
            {
                text: (
                    <FormattedMessage id="settings.allTeamRequestsSettingTitle" defaultMessage="Alle team aanvragen" />
                ),
                key: "settings/allTeamRequesets"
            }
        ];

        const teamRequestTableActions = {
            onApprove: this.props.approveTeamRequest,
            onReject: this.props.rejectTeamRequest,
            onDelete: this.props.removeTeamRequest,
            closeNotificationAlert: this.props.closeNotificationAlert
        };

        return (
            <React.Fragment>
                <BreadCrumb path={pathToPage} />
                <div className="all-team-requests-wrapper">
                    <div className="all-team-requests-header">
                        <FormattedMessage id="teamRequest.all.headerText" default="Alle team aanvragen" />
                    </div>
                    <TeamRequestsTable
                        teamRequests={this.props.teamRequests}
                        userCanApprove={true}
                        actions={teamRequestTableActions}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        teamRequests: state.teamRequestsSlice
    };
};

export default connect(
    mapStateToProps,
    { fetchTeamRequests, removeTeamRequest, closeNotificationAlert, approveTeamRequest, rejectTeamRequest }
)(AllTeamRequestsContainer);
