export const parseApplicableFilters = (applicableTermSets, activeFilters) => {
    activeFilters.map(filter => {
        // apply applicable termSet to the array if it does not exist yet.
        let alreadyExists = false;
        const termSetToAdd = {
            termSetName: filter.termSetName,
            termSetId: filter.termSetId
        };

        alreadyExists = applicableTermSets.filter(termset => {
            if (termset.termSetId === termSetToAdd.termSetId) return true;
        });

        // if the termSet doesn't exit yet add it to the array. Note that already exists coeerces into an array.
        if (alreadyExists.length === 0 || typeof alreadyExists === "undefined") {
            applicableTermSets.push(termSetToAdd);
        }
    });
    return applicableTermSets;
};

export const parseApplicableTerms = (applicableTermsSets, activeFilters) => {
    applicableTermsSets.filter(filterGroup => {
        const filtersToAdd = [];
        activeFilters.map(filterTerm => {
            if (filterTerm.termSetId === filterGroup.termSetId) {
                filtersToAdd.push(filterTerm);
            }
        });
        filterGroup.terms = filtersToAdd;
    });
};

export const parseTeamTerms = (team, terms) => {
    const allTermSets = [];

    terms.filter(term => {
        const termSetToAdd = {
            termSetName: term.termSetName,
            termSetId: term.termSetId
        };

        // if the termTermSet is not in the array yet, add it.
        if (allTermSets.indexOf(term.termSetId) === -1) {
            allTermSets.push(termSetToAdd);
        }
    });

    const termSets = [];
    allTermSets.filter(termSet => {
        const termsToAdd = [];

        // filter over the terms.
        terms.filter(term => {
            if (term.termSetId === termSet.termSetId) {
                termsToAdd.push(term);
            }
        });

        termSet.terms = termsToAdd;
        termSets.push(termSet);
    });

    const uniqueTermSets = [];

    // because on each entry we add the termTermSet to the array, get rid of the duplicates.
    filterUniques(termSets, uniqueTermSets);
    team.orderedTerms = uniqueTermSets;

    return team;
};

export const filterUniques = (termSets, uniqueTermSets) => {
    termSets.filter(teamTermSet => {
        if (uniqueTermSets.length === 0 || typeof uniqueTermSets === "undefined") {
            uniqueTermSets.push(teamTermSet);
        } else {
            let exists = false;
            uniqueTermSets.filter(uniqueTerm => {
                if (uniqueTerm.termSetId === teamTermSet.termSetId) {
                    exists = true;
                }
            });

            if (exists === false) {
                uniqueTermSets.push(teamTermSet);
            }
        }
    });
};

export const compareFilters = (team, applicableTermSets) => {
    if (team.orderedTerms.length >= applicableTermSets.length) {
        const filterGroupMatches = [];

        // loop through the applicableFilters, if the filter termSet matches at least 1 object in
        // the team filter sets, add a return of true
        for (var applicableTermSet of applicableTermSets) {
            // if the termSets matches, check whether at least one term matches
            for (var teamTermSet of team.orderedTerms) {
                if (teamTermSet.termSetId === applicableTermSet.termSetId) {
                    for (var n = 0; n < applicableTermSet.terms.length; n++) {
                        let found = false;

                        // if one of the terms within a termset matches, exit the team term loop.
                        for (let i = 0; i < teamTermSet.terms.length; i++) {
                            if (teamTermSet.terms[i].id === applicableTermSet.terms[n].termId) {
                                found = true;
                                filterGroupMatches.push(true);
                                break;
                            }
                        }

                        // if a term matches, cancel the applicable filters for the termset, because it matches
                        // at least one condition.
                        if (found === true) {
                            break;
                        }
                    }
                }
            }
        }

        // if all values are true then add the team to the array of teams.
        if (filterGroupMatches.length === applicableTermSets.length) {
            return team;
        }
    }
};

export const filterOnStatus = (teams, statusFilters) => {
    let filteredTeams = [...teams];

    filteredTeams = filteredTeams.filter(team => {
        for (let statusFilter of statusFilters) {
            if (team.teamStatus === statusFilter.statusCode) {
                return true;
            }
        }
        return false;
    });

    return filteredTeams;
};

export const filterOnType = (teams, typeFilters) => {
    let filteredTeams = [...teams];

    filteredTeams = filteredTeams.filter(team => {
        for (let typeFilter of typeFilters) {
            if (team.teamType != undefined && team.teamType.id === typeFilter.id) {
                return true;
            }
        }
        return false;
    });

    return filteredTeams;
};
