import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getImage, getPersonaImage } from "../../../HttpRepositories/graphRepository";

class TeamCardPersona extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
	}

	state = {
		userImage: ""
	};

	async componentDidMount() {
		this._isMounted = true;

		let userPhoto = await getPersonaImage(
			`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/people/${this.props.member.id}/photo`
		);

		if (userPhoto !== "error" && this._isMounted) {
			this.setState({ userImage: userPhoto });
		}
	}

	render() {
		let userPhoto = "";
		const userIcon = (
			<div className="team-card-persona-wrapper__photo">
				<FontAwesomeIcon icon={faUser} className="team-card-persona-wrapper__photo-icon" />
			</div>
		);

		if (this.state.userImage === "") {
			userPhoto = userIcon;
		} else {
			if (this.props.squareStyling) {
				userPhoto = (
					<img
						src={this.state.userImage}
						alt={this.props.member.displayName}
						className="team-card-persona-wrapper__square"
						title={this.props.member.displayName}
					/>
				);
			} else {
				let classes = "team-card-persona-wrapper";
				// scales the persona placeholder when the api returns a png
				if (this.state.userImage.type === "image/png") {
					classes += " img-placeholder";
				}

				if (this.props.big === true) {
					classes = "team-card-persona-wrapper member-big";

					if (this.state.userImage.type === "image/png") {
						classes += " img-placeholder-big";
					}
				}
				userPhoto = (
					<img
						src={this.state.userImage.image}
						alt={this.props.member.displayName}
						className={classes}
						title={this.props.member.displayName}
					/>
				);
			}
		}

		return <React.Fragment>{userPhoto}</React.Fragment>;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
}

export default TeamCardPersona;
