import { adalOneApiFetch } from "../../adalConfig";

export const ADD_TEAM_TYPE = "ADD_TEAM_TYPE";
export const ADD_TEAM_TYPE_SUCCESS = "ADD_TEAM_TYPE_SUCCESS";
export const ADD_TEAM_TYPE_FAILED = "ADD_TEAM_TYPE_FAILED";

export const UPDATE_TEAM_TYPE = "UPDATE_TEAM_TYPE";
export const UPDATE_TEAM_TYPE_SUCCESS = "UPDATE_TEAM_TYPE_SUCCESS";
export const UPDATE_TEAM_TYPE_FAILED = "UPDATE_TEAM_TYPE_FAILED";

export const FETCH_TEAM_TYPES = "FETCH_TEAM_TYPES";
export const FETCH_TEAM_TYPES_FAILURE = "FETCH_TEAM_TYPES_FAILURE";
export const FETCH_TEAM_TYPES_SUCCESS = "FETCH_TEAM_TYPES_SUCCESS";

export const UPDATE_NOTIFICATION_MESSAGE = "UPDATE_NOTIFICATION_MESSAGE";
export const TEAM_TYPE_CLEAR_NOTIFICATION = "TEAM_TYPE_CLEAR_NOTIFICATION";

export const addTeamType = teamType => {
    return dispatch => {
        dispatch(addTeamTypeRequest(teamType));
    };
};

const addTeamTypeRequest = teamType => {
    return dispatch => {
        dispatch(addTeamTypeStarted());

        adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teamtypes`, {
            method: "PUT",
            body: JSON.stringify(teamType),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                // If the reponse code is not positive, throw an error.
                if (response.status != 200 && response.status != 204 && response.status != 201) {
                    dispatch(addTeamTypeFailure("fout"));
                    return;
                }

                response.json().then(result => {
                    dispatch(addTeamTypeSuccess(result));
                });
            })
            .catch(error => {
                dispatch(addTeamTypeFailure(error));
            });
    };
};

const addTeamTypeStarted = () => {
    return {
        type: ADD_TEAM_TYPE
    };
};

const addTeamTypeSuccess = teamType => {
    return {
        type: ADD_TEAM_TYPE_SUCCESS,
        payload: {
            teamType
        }
    };
};
const addTeamTypeFailure = error => {
    return {
        type: ADD_TEAM_TYPE_FAILED,
        payload: {
            error
        }
    };
};

export const fetchTeamTypes = () => {
    return dispatch => {
        dispatch(getTeamTypes());
    };
};

export const getTeamTypes = () => {
    return dispatch => {
        dispatch(getTeamTypesStarted());

        adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teamtypes`, {})
            .then(response => {
                if (response.status != 200 && response.status != 204) {
                    dispatch(fetchTeamTypesFailure("fout"));
                    return;
                }

                response.text().then(result => {
                    result = JSON.parse(result);
                    dispatch(fetchTeamTypesSucces(result));
                });
            })
            .catch(error => {
                dispatch(fetchTeamTypesFailure(error));
                return;
            });
    };
};
const getTeamTypesStarted = () => ({
    type: FETCH_TEAM_TYPES
});

const fetchTeamTypesFailure = error => ({
    type: FETCH_TEAM_TYPES_FAILURE,
    payload: {
        error
    }
});
const fetchTeamTypesSucces = teamsTypes => ({
    type: FETCH_TEAM_TYPES_SUCCESS,
    payload: {
        teamsTypes
    }
});

export const updateNotificationMessage = messageType => ({
    type: UPDATE_NOTIFICATION_MESSAGE,
    payload: {
        messageType
    }
});

export const updateTeamType = teamType => {
    return dispatch => {
        dispatch(updateTeamTypeRequest(teamType));
    };
};

const updateTeamTypeRequest = teamType => {
    return dispatch => {
        dispatch(updateTeamTypeStarted(teamType));

        adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teamtypes`, {
            method: "PATCH",
            body: JSON.stringify(teamType),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                // If the reponse code is not positive, throw an error.
                if (response.status != 200 && response.status != 204 && response.status != 201) {
                    dispatch(updateTeamTypeFailure("fout"));
                    return;
                }

                response.json().then(result => {
                    dispatch(updateTeamTypeSuccess(result));
                });
            })
            .catch(error => {
                dispatch(updateTeamTypeFailure(error));
            });
    };
};

const updateTeamTypeStarted = () => {
    return {
        type: UPDATE_TEAM_TYPE
    };
};

const updateTeamTypeSuccess = teamType => {
    return {
        type: UPDATE_TEAM_TYPE_SUCCESS,
        payload: {
            teamType
        }
    };
};
const updateTeamTypeFailure = error => {
    return {
        type: UPDATE_TEAM_TYPE_FAILED,
        payload: {
            error
        }
    };
};

export const clearTeamTypesNotifications = () => ({
    type: TEAM_TYPE_CLEAR_NOTIFICATION
});
