import React from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const mobileMenu = props => {
	return (
		<div className='mobile-menu'>
			<ul>
				<li>
					<Link to='/teams' onClick={props.closeMenu}>
						<div className='mobile-menu__menu-item-wrapper'>
							<div className='mobile-menu__icon'>
								<Icon iconName='Group' />
							</div>
							<div className='mobile-menu__text'>
								<FormattedMessage
									id='nav.myTeams'
									defaultMessage='Mijn Teams'
								/>
							</div>
						</div>
					</Link>

					<Link to='/allTeams' onClick={props.closeMenu}>
						<div className='mobile-menu__menu-item-wrapper'>
							<div className='mobile-menu__icon'>
								<Icon iconName='Search' />
							</div>
							<div className='mobile-menu__text'>
								<FormattedMessage
									id='nav.discoverTeams'
									defaultMessage='Alle teams'
								/>
							</div>
						</div>
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default mobileMenu;
