export const authorizeUserIsAdminFunction = (currentUser) => {
	let hasAccess = false;
	const { roles } = currentUser;

	// check if the user has the admin role.
	for (let i = 0; i < roles.length; i++) {
		if (roles[i].code === "admin") {
			hasAccess = true;
			break;
		}
	}
	return hasAccess;
};

export const authorizeUserIsAdminOrTeamsManagerFunction = (currentUser) => {
	let hasAccess = false;
	const { roles } = currentUser;

	// check if the user has the admin role.
	for (let i = 0; i < roles.length; i++) {
		if (roles[i].code === "admin" || roles[i].code === "teammanager") {
			hasAccess = true;
			break;
		}
	}
	return hasAccess;
};
