import * as actions from './actions';

const initialState = {
	currentUserError: '',
	currentUserLoading: false,
	currentUser: [],
	userLoggedInLogSend: false
};

const currentUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.FETCH_CURRENT_USER_STARTED: {
			return {
				...state,
				loading: (state.currentUserLoading = true)
			};
		}

		case actions.FETCH_CURRENT_USER_SUCCESS: {
			return {
				...state,
				currentUserLoading: false,
				currentUser: action.payload
			};
		}

		case actions.FETCH_CURRENT_USER_FAILURE: {
			return {
				...state,
				currentUserLoading: false,
				currentUserError: action.payload.error.message
			};
		}

		case actions.SEND_USER_LOGIN_LOG: {
			return {
				...state,
				userLoggedInLogSend: true
			};
		}

		default: {
			return state;
		}
	}
};

export default currentUserReducer;
