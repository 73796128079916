import { acquireToken, acquireGraphToken } from "../../adalConfig";
export const FETCH_TOKEN = "FETCH_TOKEN";
export const FETCH_TOKEN_STARTED = "FETCH_TOKEN_STARTED";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS ";
export const FETCH_TOKEN_FAILURE = "FETCH_TOKEN_FAILURE";

export const fetchRequest = () => {
	return (dispatch, getState) => {
		dispatch(fetchTokenStarted());

		acquireToken();
		acquireGraphToken();

		dispatch(fetchTokenSuccess(true));
	};
};

export const fetchTokenSuccess = token => {
	return {
		type: FETCH_TOKEN_SUCCESS,
		payload: {
			token: true
		}
	};
};

export const fetchTokenStarted = () => ({
	type: FETCH_TOKEN_STARTED
});

export const fetchTokens = () => {
	return dispatch => {
		dispatch(fetchRequest());
	};
};
