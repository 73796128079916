import * as actions from "./actions";
import { objectToArray } from "../../assets/general_functions/objectsAndArrays";

const initialState = {
    filterTermSets: [],
    filterClassification: "",
    filtersError: "",
    filterLoading: false,
    activeFilters: [],
    activeStatusFilters: [],
    activeTypeFilters: [],
    needsToDelete: false
};

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_FILTERS_STARTED: {
            return {
                ...state,
                filterLoading: true
            };
        }

        case actions.FETCH_FILTERS_SUCCESS: {
            const filters = objectToArray(action.payload);
            let filterObjectParsed = {};

            filters.map(filterSet => {
                filterObjectParsed = {
                    filterSets: filters
                };
            });

            return {
                ...state,
                filterLoading: false,
                filtersError: "",
                filterClassification: filterObjectParsed.filterName,
                filterTermSets: filterObjectParsed.filterSets
            };
        }

        case actions.SAVE_FILTERS: {
            const { filters, statusFilters, typeFilters } = action.payload;

            return {
                ...state,
                activeFilters: filters,
                activeStatusFilters: statusFilters,
                activeTypeFilters: typeFilters
            };
        }

        case actions.FETCH_FILTERS_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                filterLoading: false,
                filtersError: error
            };
        }

        case actions.REMOVE_FILTER: {
            const filterIdToRemove = action.payload.filterToRemove;

            const newActiveFilters = state.activeFilters.filter(filter => filter.termId !== filterIdToRemove);

            return {
                ...state,
                activeFilters: newActiveFilters
            };
        }

        case actions.REMOVE_ALL_FILTERS: {
            return {
                ...state,
                activeFilters: [],
                activeStatusFilters: [],
                activeTypeFilters: [],
                needsToDelete: true
            };
        }

        case actions.UNSET_NEEDS_TO_DELETE: {
            return {
                ...state,
                needsToDelete: false
            };
        }

        default: {
            return state;
        }
    }
};

export default filtersReducer;
