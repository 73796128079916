import React from "react";
import { connect } from "react-redux";

import { FormattedMessage, injectIntl } from "react-intl";
import { filterUniques } from "../../../../../assets/general_functions/filterFunctions";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { UncontrolledAlert } from "reactstrap";

import PanelExpandMetaData from "./panelExpandMetaData/panelExpandMetaData";
import PanelGroupSettings from "./panelGroupSettings/panelGroupSettings";
import PanelArchive from "./panelArchive/panelArchive";

import * as teamActions from "../../../../../store/teams/actions";
import { logUserOpenedEnrichMetaData } from "../../../../../logging/applicationInsights/logFunctions";

class InfoComponent extends React.Component {
	state = {
		modalMetaDataOpen: false,
		archiveModalOpen: false,
		groupSettingsPanelOpen: false,
		isAdmin: false,
	};

	componentDidMount() {
		if (this.props.currentTeam != "") {
			this.props.fetchTeamUsers(this.props.currentTeam.id);
		}

		if (this.props.teamNotifications.isOpen) {
			this.closeAlert();
		}

		if (this.props.currentUser && this.props.currentUser.roles && this.props.openedFrom === "allTeams") {
			let isAdmin = false;
			this.props.currentUser.roles.filter((role) => {
				if (role.isAdmin === true) {
					isAdmin = true;
				}
			});

			if (isAdmin === true) {
				this.setState({ isAdmin: true });
			}
		}
	}

	convertTermsToList = (termSets) => {
		const termsAsList = termSets.map((termSet) => {
			const termSetName = termSet.termSetName;
			const terms = termSet.allTermValues.map((term) => {
				return <li key={term}>{term}</li>;
			});

			return (
				<div key={termSetName}>
					<div className="info-rule__header">
						<b>{termSetName}</b>
					</div>

					<ul className="info-rule__list">{terms}</ul>
				</div>
			);
		});

		return termsAsList;
	};

	openPanelAndSendAiCall = (teamId) => {
		logUserOpenedEnrichMetaData(
			this.props.currentUser.userPrincipalName,
			this.props.currentUser.tenant.name,
			teamId
		);

		this.openMetaDataPanel();
	};

	openArchivePanel = (teamId) => {
		this.toggleArchivePanel();
	};

	toggleArchivePanel = () => {
		this.setState({ archiveModalOpen: !this.state.archiveModalOpen });
	};
	toggleGroupSettingsPanel = () => {
		this.setState({ groupSettingsPanelOpen: !this.state.groupSettingsPanelOpen });
	};

	openMetaDataPanel = () => {
		this.setState({ modalMetaDataOpen: true });
	};
	closeMetaDataPanel = () => {
		this.setState({ modalMetaDataOpen: false });
	};

	closeAlert = () => {
		this.props.clearTeamNotification();
	};

	createActionLinks = (canEditMetaData, currentUserIsAdmin) => {
		// decide which action will be allowed to show to admin users
		if (
			(canEditMetaData && currentUserIsAdmin) ||
			(this.props.openedFrom === "allTeams" &&
				(canEditMetaData || currentUserIsAdmin) &&
				this.props.currentTeam.teamStatus != 2)
		) {
			return (
				<React.Fragment>
					<div className="info-rule__header">
						<FormattedMessage id="teamModalBody.infoTabActionsLabel" defaultMessage="Acties" />
					</div>
					<ul className="info-rule__list">
						<li>
							<div
								onClick={(teamId) => this.openPanelAndSendAiCall(this.props.currentTeam.id)}
								className="fake-link"
							>
								<FormattedMessage
									id="teamModalBody.infoExpandMetaDataLink"
									defaultMessage="Informatie aanpassen"
								/>
							</div>
						</li>
						{currentUserIsAdmin &&
							this.props.currentTeam.teamStatus != 2 &&
							this.props.currentTeam.isTeam ? (
							<li>
								<div
									onClick={(teamId) => this.openArchivePanel(this.props.currentTeam.id)}
									className="fake-link"
								>
									<FormattedMessage
										id="teamModalBody.archivePanelHeaderLabel"
										defaultMessage="Team archiveren"
									/>
								</div>
							</li>
						) : (
							""
						)}
						<li>
							<div onClick={() => this.toggleGroupSettingsPanel()} className="fake-link">
								Group settings
							</div>
						</li>
					</ul>
				</React.Fragment>
			);
		} else return "";
	};

	render() {
		let alertMessage = "";
		let mail = "";
		let status;
		let termSets = [];
		let teamTypeUi = "";
		let canEditMetaData = false;
		const currentUserId = this.props.currentUser.objectId;
		let isPrivate = false;

		// check whether the currentUser is an admin
		let currentUserIsAdmin = false;
		if (
			this.props.currentTeam != undefined &&
			this.props.currentTeam.users != undefined &&
			this.props.currentTeam.users.length > 0
		) {
			this.props.currentTeam.users.map((user) => {
				// we user the user.objectId as the id from office 365. because user.id points to the one db userId

				if (user.memberType === 1 && this.props.currentUser.objectId === user.id) {
					currentUserIsAdmin = true;
				}
			});
		}

		// if the redux store says that the component needs to be updates, update it and set the need update to false.
		if (this.props.needToUpdateArchive) {
			this.props.fetchTeamDetails(this.props.currentTeam.id);
			this.props.clearArchiveNeedsToUpdate();
		}

		if (this.props.teamNotifications.isOpen && this.props.teamNotifications.isSuccess) {
			alertMessage = (
				<UncontrolledAlert
					style={{
						marginTop: "-3rem",
						marginBottom: "2rem",
						marginLeft: "0",
						marginRight: "0",
						padding: "0",
					}}
					color="success"
					toggle={this.closeAlert}
				>
					<span className="custom-alert-text">
						<FormattedMessage
							id="teamModalBody.archieveSuccessAlertLabel"
							defaultMessage="Het team is gearchiveerd."
						/>
					</span>
				</UncontrolledAlert>
			);
		}

		if (this.props.teamNotifications.isOpen && !this.props.teamNotifications.isSuccess) {
			alertMessage = (
				<UncontrolledAlert
					style={{ marginTop: "-3rem", marginBottom: "2rem" }}
					color="danger"
					toggle={this.closeAlert}
				>
					<span className="custom-alert-text">
						<FormattedMessage
							id="teamModalBody.archieveFailedAlertLabel"
							defaultMessage="Het team kon niet gearchiveerd worden."
						/>
					</span>
				</UncontrolledAlert>
			);
		}

		// show the currentStatus of the team.
		if (this.props.currentItem != "") {
			if (this.props.currentTeam.teamStatus === 2) {
				status = (
					<React.Fragment>
						<div className="info-rule__header">
							<FormattedMessage id="teamModalBody.archivedStatusLabel" defaultMessage="Status" />
						</div>
						<ul className="info-rule__list">
							<li>
								<FormattedMessage
									id="teamModalBody.archivedStatusInactive"
									defaultMessage="gearchiveerd"
								/>
							</li>
						</ul>
					</React.Fragment>
				);
			} else if (this.props.currentTeam.teamStatus === 0) {
				status = (
					<React.Fragment>
						<div className="info-rule__header">
							<FormattedMessage id="teamModalBody.archivedStatusLabel" defaultMessage="Status" />
						</div>
						<ul className="info-rule__list">
							<li>
								<FormattedMessage id="teamModalBody.archivedStatusActive" defaultMessage="Actief" />
							</li>
						</ul>
					</React.Fragment>
				);
			}
		}

		if (this.props.currentTeam.isPrivate === false) {
			isPrivate = (
				<React.Fragment>
					<div className="info-rule__header">
						<FormattedMessage id="provisioning.privacyLevelLabel" defaultMessage="Privacyniveau" />
					</div>
					<ul className="info-rule__list">
						<li>
							<FormattedMessage id="general.public" defaultMessage="Publiek" />
						</li>
					</ul>
				</React.Fragment>
			);
		} else {
			isPrivate = (
				<React.Fragment>
					<div className="info-rule__header">
						<FormattedMessage id="provisioning.privacyLevelLabel" defaultMessage="Privacyniveau" />
					</div>
					<ul className="info-rule__list">
						<li>
							<FormattedMessage id="general.private" defaultMessage="Prive" />
						</li>
					</ul>
				</React.Fragment>
			);
		}

		if (this.props.currentTeam && Object.keys(this.props.currentTeam).length > 0) {
			const currentTeamMembers = this.props.currentTeam.users;

			if (currentTeamMembers != undefined && currentTeamMembers != "" && currentTeamMembers.length > 0) {
				const isUserGroupAdmin = currentTeamMembers.filter((teamMember) => {
					if (teamMember.id === currentUserId && teamMember.memberType === 1) {
						return teamMember;
					}
				});

				if ((isUserGroupAdmin.length > 0 || this.state.isAdmin) && this.props.currentTeam.teamStatus !== 2) {
					canEditMetaData = true;
				}
			}
		}

		let { metaDataUpdated, metaDataUpdating, needsToResetUpdateMessage, reloadData } = this.props.currentTeam;

		if ((needsToResetUpdateMessage === true && metaDataUpdated, reloadData)) {
			this.props.fetchTeamDetails(this.props.currentTeam.id, true, metaDataUpdated);
		}

		if (metaDataUpdated === true && metaDataUpdating === false) {
			alertMessage = (
				<UncontrolledAlert
					style={{ marginTop: "-3rem", marginBottom: "3rem" }}
					color="success"
					className="custom-alert"
					toggle={this.closeAlert}
				>
					<FormattedMessage id="metadata.dataUpdated" defaultMessage="De teaminformatie is bijgewerkt." />
				</UncontrolledAlert>
			);
			window.setTimeout(() => {
				this.props.removeMetaDataUpdated();
			}, 5000);
		}

		if (
			this.props.currentTeam.mailEnabled === true &&
			typeof this.props.currentTeam.mail != "undefined" &&
			this.props.currentTeam.mail != ""
		) {
			mail = (
				<React.Fragment>
					<div className="info-rule__header">E-mail</div>
					<ul className="info-rule__list">
						<li>{this.props.currentTeam.mail}</li>
					</ul>
				</React.Fragment>
			);
		}

		// iterate over all the terms and extract a list of termSets.
		if (typeof this.props.currentTeam.terms != "undefined" && this.props.currentTeam.terms.length > 0) {
			this.props.currentTeam.terms.map((termSet) => {
				termSets.push(termSet);
			});

			const uniqueTermSets = [];
			// because on each entry we add the termTermSet to the array, get rid of the duplicates.
			filterUniques(termSets, uniqueTermSets);

			const allValues = [];
			//filter through all unique termSets.
			uniqueTermSets.map((filterSet) => {
				// create an array to push all terms from one filterSet.
				const allTermsPerFilter = [];
				termSets.filter((term) => {
					if (term.termSetName === filterSet.termSetName) allTermsPerFilter.push(term.name);
				});

				// add the allTermsPerFilter to the filterSetObject
				filterSet.allTermValues = allTermsPerFilter;
				allValues.push(filterSet);
			});

			termSets = allValues;
		}

		let termsAsList = this.convertTermsToList(termSets);

		const actionLinks = this.createActionLinks(canEditMetaData, currentUserIsAdmin);

		// if meta data is empty show a message
		if (termsAsList == "" && termsAsList.length === 0) {
			termsAsList = (
				<FormattedMessage
					id="teamModalBody.infoTabNoTermsActive"
					defaultMessage="Er zijn geen categorieën voor dit team."
				/>
			);
		}

		// Define the teamType UI
		if (this.props.currentTeam.teamType != undefined) {
			teamTypeUi = (
				<div>
					<div className="info-rule__header">
						<b>
							<FormattedMessage id="teamModalBody.infoTabTeamTypeLabel" defaultMessage="Teamtype" />
						</b>
					</div>

					<ul className="info-rule__list">{this.props.currentTeam.teamType.name}</ul>
				</div>
			);
		}

		return (
			<section className="info-wrapper">
				<div className="info-content-wrapper">
					{alertMessage}
					<div className="info-content-left">
						<h2>
							<FormattedMessage id="teamModalBody.infoTabCategoryLabel" defaultMessage="Categorie info" />
						</h2>
						{termsAsList}
					</div>
					<div className="info-content-right">
						<h2>
							<FormattedMessage id="teamModalBody.infoTabBacisInfoLabel" defaultMessage="Basis info" />
						</h2>
						{teamTypeUi}
						{mail}
						{isPrivate}
						{status}
						{actionLinks}
					</div>
				</div>

				<Panel
					className="account-panel"
					isOpen={this.state.modalMetaDataOpen}
					type={PanelType.smallFixedFar}
					isLightDismiss={true}
					onLightDismissClick={this.closeMetaDataPanel}
				>
					<PanelExpandMetaData onDismiss={this.closeMetaDataPanel} />
				</Panel>

				<Panel
					className="account-panel"
					isOpen={this.state.archiveModalOpen}
					type={PanelType.smallFixedFar}
					isLightDismiss={true}
					onLightDismissClick={this.toggleArchivePanel}
				>
					<PanelArchive onDismiss={this.toggleArchivePanel} teamId={this.props.currentTeam.id} />
				</Panel>
				<Panel
					className="account-panel"
					isOpen={this.state.groupSettingsPanelOpen}
					type={PanelType.smallFixedFar}
					isLightDismiss={true}
					onLightDismissClick={this.toggleGroupSettingsPanel}
				>
					{this.state.groupSettingsPanelOpen && (
						<PanelGroupSettings
							onDismiss={this.toggleGroupSettingsPanel}
							teamId={this.props.currentTeam.id}
						/>
					)}
				</Panel>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentTeam: state.teamsSlice.currentTeam,
		teamNotifications: state.teamsSlice.notification,
		detailsLoading: state.teamsSlice.detailsLoading,
		currentUser: state.currentUserSlice.currentUser,
		needToUpdateArchive: state.teamsSlice.archiveNeedsToUpdate,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTeamDetails: (teamId, keepMessage, metaDataUpdated) =>
			dispatch(teamActions.fetchTeamDetails(teamId, keepMessage, metaDataUpdated)),
		removeMetaDataUpdated: () => dispatch(teamActions.removeMetadataUpdateBar()),
		fetchTeamUsers: (teamId) => dispatch(teamActions.fetchTeamUsers(teamId)),
		clearTeamNotification: () => dispatch(teamActions.clearTeamNotification()),
		clearArchiveNeedsToUpdate: () => dispatch(teamActions.clearArchiveNeedsToUpdate()),
		fetchTeamUsers: (teamId) => dispatch(teamActions.fetchTeamUsers(teamId)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(injectIntl(InfoComponent));
