import React from "react";
import { Formik, Field } from "formik";
import { injectIntl, FormattedMessage } from "react-intl";
import * as Yup from "yup";

class TermGroupFormComponent extends React.Component {
    render() {
        const { id: termGroupId, isTenantWide, name } = this.props.activeItem.activeItem;
        const { intl } = this.props;
        const isDisabled = true;

        return (
            <div className="details-form-wrapper">
                <Formik
                    enableReinitialize
                    initialValues={{
                        termGroupId,
                        name,
                        isTenantWide
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 500);
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .min(
                                2,
                                intl.formatMessage({
                                    id: "metadata.form.validation.termGroupNameMinTwoCharacters",
                                    defaultMessage: "U moet minstens 2 tekens gebruiken."
                                })
                            )
                            .required(
                                intl.formatMessage({
                                    id: "metadata.form.validation.termGroupNameRequired",
                                    defaultMessage: "U moet een naam voor de termGroup opgeven"
                                })
                            )
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    className="form-input-group"
                                    type="hidden"
                                    name="termGroupId"
                                    value={termGroupId}
                                ></Field>

                                <label htmlFor="name" style={{ display: "block" }}>
                                    <FormattedMessage
                                        id="metadata.form.termGroupNameInputLabel"
                                        defaultMessage="Naam"
                                    />
                                </label>
                                {errors.name && touched.name && <div className="input-feedback">{errors.name}</div>}

                                <div className="form-input-wrapper">
                                    <div className="form-input-description">
                                        <FormattedMessage
                                            id="metadata.form.termGroupNameInputDescription"
                                            defaultMessage="Voer een naam voor deze termGroup in"
                                        />
                                    </div>
                                    <div className="form-input-field">
                                        <input
                                            id="name"
                                            placeholder={intl.formatMessage({
                                                id: "metadata.form.termGroupNameInputDescription",
                                                defaultMessage: "Voer een naam voor deze termGroup in"
                                            })}
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="checkbox-spacer">
                                    <label htmlFor="isActive" style={{ display: "block" }}>
                                        <FormattedMessage
                                            id="metadata.form.termGroupIsTenantWideInputLabel"
                                            defaultMessage="Is organisatie breed"
                                        />
                                    </label>

                                    <div className="form-input-wrapper">
                                        <div className="form-input-description">
                                            <FormattedMessage
                                                id="metadata.form.termGroupIsTenantWideInputDescription"
                                                defaultMessage="Deze termgroup kan door de hele organisatie gebruikt worden"
                                            />
                                        </div>

                                        <div className="form-input-field">
                                            <Field
                                                type="checkbox"
                                                name="isTenantWide"
                                                checked={values.isTenantWide}
                                                onChange={() => {
                                                    setFieldValue("isTenantWide", !values.isTenantWide);
                                                }}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export default injectIntl(TermGroupFormComponent);
