import { adalOneApiFetch, adalGraphFetch } from "../../adalConfig";
import { objectToArray } from "../../assets/general_functions/objectsAndArrays";

export const FETCH_ALL_TEAMS = "FETCH_ALL_TEAMS";
export const FETCH_ALLTEAMS_STARTED = "FETCH_ALLTEAMS_STARTED";
export const FETCH_ALLTEAMS_SUCCESS = "FETCH_ALLTEAMS_SUCCESS";
export const FETCH_ALLTEAMS_FAILURE = "FETCH_ALLTEAMS_FAILURE";

export const FETCH_ALLTEAMS_FIRSTBATCH_STARTED = "FETCH_ALLTEAMS_FIRSTBATCH_STARTED";
export const FETCH_ALLTEAMS_FIRSTBATCH_SUCCESS = "FETCH_ALLTEAMS_FIRSTBATCH_SUCCESS";
export const FETCH_ALLTEAMS_FIRSTBATCH_FAILURE = "FETCH_ALLTEAMS_FIRSTBATCH_FAILURE";


export const FETCH_ALLTEAM_DETAILS_STARTED = "FETCH_ALLTEAM_DETAILS_STARTED";
export const FETCH_ALLTEAM_DETAILS_SUCCESS = "FETCH_ALLTEAM_DETAILS_SUCCESS";
export const FETCH_ALLTEAM_DETAILS_FAILURE = "FETCH_ALLTEAM_DETAILS_FAILURE";

export const UPDATE_ALLTEAMS_FIRST_BATCH = "UPDATE_ALLTEAMS_FIRST_BATCH";
export const REMOVE_CURRENT_ALLTEAM = "REMOVE_CURRENT_ALLTEAM";



export const FETCH_ALLTEAMS_ALL_SUCCESS = "FETCH_ALLTEAMS_ALL_SUCCESS";
export const fetchAllTeamsRequest = () => {
	return dispatch => {
		dispatch(getAllTeamStarted());

		let teamsArray = [];
		getAllTeams("", teamsArray, dispatch);
	};
};

export const fetchAllTeamsFirstBatchRequest = () => {
	return dispatch => {
		dispatch(getFirstBatchAllTeamStarted());

		let teamsArray = [];
		getFirstBatchAllTeams(dispatch);
	};
};


export const fetchAllTeamsAllSuccess = (teams) => ({
	type: FETCH_ALLTEAMS_ALL_SUCCESS,
	payload: {
		...teams
	}
})

export const removeCurrentAllTeam = () => ({
	type: REMOVE_CURRENT_ALLTEAM
})


const getFirstBatchAllTeams = (dispatch) => {

	dispatch(getFirstBatchAllTeamStarted());

	const url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams?pageSize=100`;

	adalOneApiFetch(fetch, url, {})
		.then(response => {
			if (response.status != 200 && response.status != 204) {
				dispatch(fetchFirstBatchAllTeamsFailure("fout"));
				return;
			}

			response.json().then(result => {
				dispatch(fetchFirstBatchAllTeamsSucces(objectToArray(result.items)));
			});
		})
		.catch(error => {
			dispatch(fetchFirstBatchAllTeamsFailure(error));
		});
};



const getFirstBatchAllTeamStarted = () => ({
	type: FETCH_ALLTEAMS_FIRSTBATCH_STARTED
});

const fetchFirstBatchAllTeamsSucces = teams => ({
	type: FETCH_ALLTEAMS_FIRSTBATCH_SUCCESS,
	payload: {
		...teams
	}
});

const fetchFirstBatchAllTeamsFailure = error => ({
	type: FETCH_ALLTEAMS_FIRSTBATCH_FAILURE,
	payload: {
		error
	}
});




const getAllTeams = (url, teamsArray, dispatch) => {
	if (url == "") {
		url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams?pageSize=100`;
	}

	dispatch(getAllTeamStarted());

	adalOneApiFetch(fetch, url, {})
		.then(response => {
			if (response.status != 200 && response.status != 204) {
				dispatch(fetchAllTeamsFailure("fout"));
				return;
			}

			response.json().then(result => {

				let nextLink = "";
				if (typeof result.links.nextPage != "undefined" && result.links.nextPage != '') {
					nextLink = `${process.env.REACT_APP_ONE_API_URL}/api${
						result.links.nextPage
						}`;
				}

				if (nextLink != "") {
					const teams = objectToArray(result.items);
					teamsArray.push(...teams);
					getAllTeams(nextLink, teamsArray, dispatch);
				} else {
					dispatch(fetchAllTeamsSucces(teamsArray));
				}
			});
		})
		.catch(error => {
			dispatch(fetchAllTeamsFailure(error));
		});
};

const getAllTeamStarted = () => ({
	type: FETCH_ALLTEAMS_STARTED
});

const fetchAllTeamsSucces = teams => ({
	type: FETCH_ALLTEAMS_SUCCESS,
	payload: {
		...teams
	}
});

const fetchAllTeamsFailure = error => ({
	type: FETCH_ALLTEAMS_FAILURE,
	payload: {
		error
	}
});




// Get the teams link, used to get the teams channel
export const fetchTeamDetailsRequest = (teamId) => {
	return dispatch => {
		dispatch(fetchTeamDetailsStarted(teamId));

		adalOneApiFetch(
			fetch,
			`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${teamId}`,
			{}
		)
			.then(response => {
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamDetailsFailure("fout"));
					return;
				}
				let teamUrl = "";

				response.json().then(result => {
					if (response.value) {
						teamUrl = response.value[0].uri;
					}


					dispatch(
						fetchTeamDetailsSuccess(
							result
						)
					);
				});
			})
			.catch(error => {
				dispatch(fetchTeamDetailsFailure(error));
			});
	};
};


export const fetchTeamDetails = (
	id
) => {
	return dispatch => {
		dispatch(fetchTeamDetailsRequest(id));
	};
};

const fetchTeamDetailsStarted = teamId => ({
	type: FETCH_ALLTEAM_DETAILS_STARTED,
	payload: {
		teamId
	}
});

const fetchTeamDetailsSuccess = (
	result
) => ({
	type: FETCH_ALLTEAM_DETAILS_SUCCESS,
	payload: {
		result
	}
});

const fetchTeamDetailsFailure = error => ({
	type: FETCH_ALLTEAM_DETAILS_FAILURE,
	payload: {
		error
	}
});


export const updateFirstBatchSection = teams => ({
	type: UPDATE_ALLTEAMS_FIRST_BATCH,
	payload: {
		teams
	}
});
