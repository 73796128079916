import React from "react";
import PropTypes from "prop-types";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Fade from "react-reveal/Fade";
import { FormattedMessage } from "react-intl";

class TreeViewComponent extends React.Component {
    handleFlyoutMenu = (node, typeOfNode, termSetItem, e) => {
        e.stopPropagation();

        // if the current id in the state is equal to what the user clicked on, reset the state to 0.
        // this created the effect of closing the flyout menu

        if (this.props.activeFlyOut.id === node.id && this.props.activeFlyOut.type === typeOfNode) {
            this.props.handleCloseFlyOut("", "");
        } else {
            this.props.handleSetFlyOut(node, typeOfNode, termSetItem, e);
        }
    };

    parseTermGroupItem(termGroup, termSetItems) {
        let flyout = "";
        let isCurrentItem = this.props.activeFlyOut.type === "termGroup" && this.props.activeFlyOut.id === termGroup.id;

        const actionWrapperStyle = isCurrentItem ? "term-box__actions-wrapper active" : "term-box__actions-wrapper";
        if (isCurrentItem) {
            flyout = (
                <Fade appear={true}>
                    <section
                        className="term-box__action-flyout"
                        onClick={e => this.props.handleActiveNode(termGroup, "termGroup", "add", e)}
                    >
                        <ul>
                            <li>
                                <FormattedMessage
                                    id="metadata.newTermSetText"
                                    defaultMessage="Nieuwe termset aanmaken"
                                />
                            </li>
                        </ul>
                    </section>
                </Fade>
            );
        }

        let defaultCursor = { cursor: "default" };

        return (
            <div key={termGroup.id}>
                <div className="term-box">
                    <div
                        className="term-box__text-wrapper"
                        onClick={e => this.props.handleActiveNode(termGroup, "termGroup", "update", e)}
                    >
                        {termGroup.name}
                    </div>
                    <div
                        className={actionWrapperStyle}
                        style={defaultCursor}
                        onClick={e => this.handleFlyoutMenu({ id: termGroup.id }, "termGroup", termGroup, e)}
                    >
                        <Icon iconName="ChevronRightSmall" />
                    </div>
                    {flyout}
                </div>

                <div className="termsets-indent">{termSetItems}</div>
            </div>
        );
    }

    parseTermSetItem(termSetItem, termItems) {
        const { id: activeItemId } = this.props.activeItem;
        const isActiveItem = activeItemId === termSetItem.id && this.props.activeItem.hasOwnProperty("termGroupId");
        let styles = isActiveItem ? "term-box__text-wrapper active--term-boxes" : "term-box__text-wrapper";
        let content = "";

        let flyout = "";
        let actionWrapperStyle = "term-box__actions-wrapper";

        if (this.props.activeFlyOut != "") {
            let isCurrentItem =
                this.props.activeFlyOut.type === "termSet" && this.props.activeFlyOut.id === termSetItem.id;
            actionWrapperStyle = isCurrentItem ? "term-box__actions-wrapper active" : "term-box__actions-wrapper";

            if (isCurrentItem) {
                flyout = (
                    <Fade appear={true}>
                        <section
                            className="term-box__action-flyout"
                            onClick={e => {
                                this.props.handleActiveNode(termSetItem, "termSet", "add", e);
                            }}
                        >
                            <ul>
                                <li>
                                    <FormattedMessage id="metadata.newTermText" defaultMessage="Nieuwe term aanmaken" />
                                </li>
                            </ul>
                        </section>
                    </Fade>
                );
            }
        }

        if (termSetItem.name.toLowerCase() === "type" || termSetItem.name.isClassification === true) {
            styles = isActiveItem
                ? "term-box__text-wrapper term-box--full-length active--term-boxes"
                : "term-box__text-wrapper term-box--full-length";
            content = (
                <div key={termSetItem.id}>
                    <div className="term-box">
                        <div
                            className={styles}
                            onClick={e => this.props.handleActiveNode(termSetItem, "termSet", "update", e)}
                        >
                            {termSetItem.name}
                        </div>
                    </div>

                    <ul>{termItems}</ul>
                </div>
            );
        } else {
            content = (
                <div key={termSetItem.id}>
                    <div className="term-box">
                        <div
                            className={styles}
                            onClick={e => this.props.handleActiveNode(termSetItem, "termSet", "update", e)}
                        >
                            {termSetItem.name}
                        </div>
                        <div
                            className={actionWrapperStyle}
                            onClick={e => this.handleFlyoutMenu({ id: termSetItem.id }, "termSet", termSetItem, e)}
                        >
                            <Icon iconName="ChevronRightSmall" />
                        </div>

                        {flyout}
                    </div>

                    <ul>{termItems}</ul>
                </div>
            );
        }

        return content;
    }
    parseTermItem(termItem) {
        const { id: activeItemId } = this.props.activeItem;
        const isActiveItem = activeItemId === termItem.id && this.props.activeItem.hasOwnProperty("termSetId");
        const styles = isActiveItem
            ? "term-box__text-wrapper term-box--full-length active--term-boxes"
            : "term-box__text-wrapper term-box--full-length";

        return (
            <li className="term-box " key={termItem.id}>
                <div className={styles} onClick={e => this.props.handleActiveNode(termItem, "term", "update", e)}>
                    {termItem.name}
                </div>
            </li>
        );
    }

    parseTaxonomyToTreeUi() {
        // parse all terms of the specified termSet and return an array of list elements
        const listItems = this.props.termGroupsWithChildren.map(termGroup => {
            // parse the termSet as an ul element and put all the Terms inside of it.

            const termSets = termGroup.termSets.map(termSet => {
                const termItems = termSet.terms.map(term => {
                    return this.parseTermItem(term);
                });

                return this.parseTermSetItem(termSet, termItems);
            });

            // return termSets;

            return this.parseTermGroupItem(termGroup, termSets);
            // return this.parseTermSetItem(termSet, termItems);
        });

        // create the tenant node
        let tenantName;
        if (
            this.props.currentUser != "" &&
            Object.keys(this.props.currentUser).length !== 0 &&
            this.props.currentUser.constructor === Object
        ) {
            tenantName = this.props.currentUser.tenant.name;
        }

        return (
            <div>
                <div className="term-box--tenant"> {tenantName} </div>
                <div className="term-groups-and-children">{listItems}</div>
            </div>
        );
    }

    render() {
        const treeViewUi = this.parseTaxonomyToTreeUi();

        return <div className="treeview-component-wrapper">{treeViewUi}</div>;
    }
}

TreeViewComponent.propTypes = {
    termGroupsWithChildren: PropTypes.array.isRequired
};

export default TreeViewComponent;
