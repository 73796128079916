import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clearNotification } from "../../../store/metaData/actions";
import TreeViewTeamTypes from "./treeViewTeamTypes/treeViewTeamTypes";
import TreeDetailsWindow from "./treeDetailsWindow/TreeDetailsWindow";
import { arrayIsNullOrEmpty } from "../../../assets/general_functions/typeCheckings";
import { getTeamTypes } from "../../../store/teamTypes/actions";

class TermTypesTreeComponent extends React.Component {
  state = {
    activeItem: {},
    activeItemType: "",
    mode: "",
    activeFlyOut: {
      id: "",
      type: ""
    },
    cannotEdit: false
  };

  async componentDidMount() {
    await this.props.getTeamTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      // check if the activeItem is a term by looking for the termSetId key.
      const isTerm = this.state.activeItem.hasOwnProperty("termSetId");

      // Iterate over the redux store data to find the corresponding termSet or term
      for (let termGroup of prevProps.termGroupsWithChildren) {
        for (let termSet of termGroup.termSets) {
          // Save either the term, or the termSet, as the new activeItem to the state. For this we use the isTerm boolean.
          if (isTerm) {
            for (let term of termSet.terms) {
              if (
                term.id === this.state.activeItem.id &&
                prevState.activeItem.name !== term.name
              ) {
                this.setState({ activeItem: term });
                break;
              }
            }
          } else {
            if (
              termSet.id === this.state.activeItem.id &&
              prevState.activeItem.name !== termSet.name
            ) {
              this.setState({ activeItem: termSet });
              break;
            }
          }
        }
      }
    }
  }

  closeFlyOut = () => {
    this.setState({ ...this.state, activeFlyOut: { id: "", type: "" } });
  };

  setFlyOut = (node, typeOfNode, termSetItem, e) => {
    e.stopPropagation();
    this.setState({
      ...this.state,
      activeFlyOut: { id: node.id, type: typeOfNode },
      activeItem: "",
      activeItemType: typeOfNode,
      mode: "update"
    });
  };

  handleActiveNode = async (node, typeOfNode, typeOfClick, e) => {
    e.stopPropagation();

    await this.setState({
      activeItem: node,
      activeItemType: typeOfNode,
      mode: typeOfClick,
      cannotEdit: true
    });
    this.props.clearNotification();
    this.closeFlyOut();
  };

  setActiveTab = (selectedTabId, tabName) => {
    switch (selectedTabId) {
      case 0:
        this.setState({
          activeTab: selectedTabId,
          bodyComponent: (
            <section className="tree-component-wrapper">
              <TreeViewTeamTypes
                termGroupsWithChildren={this.props.termGroupsWithChildren}
                teamTypes={this.props.teamTypes}
                handleActiveNode={this.handleActiveNode}
                handleCloseFlyOut={this.closeFlyOut}
                handleSetFlyOut={this.setFlyOut}
                activeFlyOut={this.state.activeFlyOut}
                currentUser={this.props.currentUser}
                activeItem={this.state.activeItem}
              />
              <TreeDetailsWindow
                activeItem={this.state}
                actionFromTeamTypes={true}
              />
            </section>
          )
        });
        break;

      case 1:
        this.setState({
          activeTab: selectedTabId,
          bodyComponent: <section>Test types</section>
        });
        break;
      default:
        this.setState({
          activeTab: 0,
          bodyComponent: <section className="tree-component-wrapper"></section>
        });
    }
  };

  parseDataForTeamTypes = () => {
    let termGroupWithTeamTypeObj = {};
    for (let termGroup of this.props.termGroupsWithChildren) {
      if (!arrayIsNullOrEmpty(termGroup.termSets)) {
        for (let termSet of termGroup.termSets) {
          if (termSet.isTeamType) {
            const newTermTypes = { ...termGroup };
            termGroupWithTeamTypeObj = newTermTypes.termSets = [{ ...termSet }];

            break;
          }
        }
      }
    }
    return termGroupWithTeamTypeObj;
  };

  render() {
    const data = this.parseDataForTeamTypes();
    return (
      <React.Fragment>
        <section className="tree-component-wrapper">
          <TreeViewTeamTypes
            termGroupsWithChildren={data}
            teamTypes={this.props.teamTypes}
            handleActiveNode={this.handleActiveNode}
            handleCloseFlyOut={this.closeFlyOut}
            handleSetFlyOut={this.setFlyOut}
            activeFlyOut={this.state.activeFlyOut}
            currentUser={this.props.currentUser}
            activeItem={this.state.activeItem}
          />

          <TreeDetailsWindow
            activeItem={this.state}
            actionFromTeamTypes={true}
          />
        </section>
      </React.Fragment>
    );
  }
}

TermTypesTreeComponent.propTypes = {
  termGroupsWithChildren: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    notification: state.metaDataSlice.notification,
    teamTypes: state.teamTypesSlice.teamTypes
  };
};

export default connect(mapStateToProps, { clearNotification, getTeamTypes })(
  TermTypesTreeComponent
);
