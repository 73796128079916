import React, { Component } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { initializeIcons } from "@uifabric/icons";
import { acquireToken, acquireGraphToken } from "../../adalConfig";

import { IntlProvider } from "react-intl";
import ReactAI from "react-appinsights";
import { connect } from "react-redux";

import * as userActions from "../../store/users/actions";
import * as tokenActions from "../../store/tokens/actions";
import {
    md5,
    objectToArray
} from "./../../assets/general_functions/objectsAndArrays";
import WithLoading from "../../common/utils/withLoading";
import Navbar from "../../common/navbar/navbar";
import Searchbar from "../../common/utils/searchBar/searchBar";
import LeftNavigation from "../../common/leftNavigation/leftNavigation";
import PageContainer from "../pageContainer/pageContainer";
import messages_en from "../../assets/translations/translations_en";
import messages_nl from "../../assets/translations/translations_nl";
import StylesheetLoader from "../../common/utils/stylesheetLoader/stylesheetLoader";
import configureStore, { history } from "../../store/configureStore";
import { userHasLoggedIn } from "../../logging/applicationInsights/logFunctions";
import { fetchSettings } from "../../store/settings/actions";

initializeIcons();
library.add(faBars, faUser);

class App extends Component {
    state = {
        languageChoice: "nl",
        tokensAreLoaded: false,
        token: ""
    };

    async componentDidMount() {
        await this.props.onFetchCurrentUser();
        await this.props.onFetchTokens();
        await this.props.getSettings();
    }

    appTokenRefresher = () => {
        acquireToken();
        acquireGraphToken();
    };

    decideLanguageToUse = propSettings => {
        let languageToReturn = "nl";

        if (propSettings != "") {
            const settings = propSettings;

            // first check tenant language
            if (
                settings.tenantSettings != "" &&
                settings.tenantSettings.length > 0
            ) {
                for (let se of settings.tenantSettings) {
                    if (se.key === "Language") {
                        languageToReturn = se.value;
                        break;
                    }
                }
            }

            // check if there is an application language
            if (
                settings.applicationSettings != "" &&
                settings.applicationSettings.length > 0
            ) {
                for (let se of settings.applicationSettings) {
                    if (se.key === "Language") {
                        languageToReturn = se.value;
                        break;
                    }
                }
            }

            // check if there is a user language
            if (
                settings.userSettings != "" &&
                settings.userSettings.length > 0
            ) {
                for (let se of settings.userSettings) {
                    if (se.key === "Language") {
                        languageToReturn = se.value;
                        break;
                    }
                }
            }
        }

        return languageToReturn;
    };

    render() {
        let languageToUse = this.decideLanguageToUse(this.props.settings);

        ReactAI.init({
            instrumentationKey:
                process.env.REACT_APP_APP_INISGHTS_INSTRUMENTATION_KEY,
            disableAjaxTracking: true,
            disableExceptionTracking: true
        });

        // Sets an interval that refreshes to get the token every 15 minutes
        // We use this because our custom API's do not automatically issue a
        // refresh token.
        setInterval(this.appTokenRefresher, 900000);

        let loader = "";

        if (this.props.currentUserLoading) {
            loader = <WithLoading isLoading={true} />;
        }

        // we use redux to track whether the user has had a login log before.
        // this way we make sure that the user login event will only be send once.
        if (
            Object.entries(this.props.currentUser).length !== 0 &&
            this.props.currentUser.constructor === Object
        ) {
            this.props.sendUserLoginLog();
            if (!this.props.userLoggedInLogSend) {
                userHasLoggedIn(
                    this.props.currentUser.emailAddress,
                    this.props.currentUser.tenant.name
                );
            }
        }

        const messages = {
            nl: messages_nl,
            en: messages_en
        };

        return (
            <React.Fragment>
                {loader == "" ? (
                    <React.Fragment>
                        <StylesheetLoader user={this.props.currentUser} />
                        <IntlProvider
                            locale={languageToUse}
                            messages={messages[languageToUse]}
                        >
                            <div className="siteContainer">
                                <Navbar
                                    currentUserProfile={this.props.currentUser}
                                />

                                <div className="mobile-searchbar">
                                    <Searchbar />
                                </div>

                                <div className="page-container">
                                    <aside>
                                        <LeftNavigation />
                                    </aside>

                                    <section className="main">
                                        <PageContainer
                                            currentUser={this.props.currentUser}
                                        />
                                    </section>
                                </div>
                            </div>
                        </IntlProvider>
                    </React.Fragment>
                ) : (
                    <div>{loader}</div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUserError: state.currentUserSlice.currentUserError,
        currentUserLoading: state.currentUserSlice.currentUserLoading,
        currentUser: state.currentUserSlice.currentUser,
        userLoggedInLogSend: state.currentUserSlice.userLoggedInLogSend,
        settings: state.settingsSlice.settings
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCurrentUser: () => dispatch(userActions.fetchCurrentUser()),
        onFetchTokens: () => dispatch(tokenActions.fetchRequest()),
        sendUserLoginLog: () => dispatch(userActions.sendUserLoginLog()),
        getSettings: () => dispatch(fetchSettings())
    };
};

export default ReactAI.withTracking(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
