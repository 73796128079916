import { adalOneApiFetch } from "../../adalConfig";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";

export const FETCH_GROUP_SETTINGS = "FETCH_GROUP_SETTINGS";
export const FETCH_GROUP_SETTINGS_SUCCESS = "FETCH_GROUP_SETTINGS_SUCCESS";
export const FETCH_GROUP_SETTINGS_FAILURE = "FETCH_GROUP_SETTINGS_FAILURE";
export const FETCH_GROUP_SETTINGS_BY_ID = "FETCH_GROUP_SETTINGS_BY_ID";
export const FETCH_GROUP_SETTINGS_BY_ID_SUCCESS = "FETCH_GROUP_SETTINGS_BY_ID_SUCCESS";
export const FETCH_GROUP_SETTINGS_BY_ID_FAILURE = "FETCH_GROUP_SETTINGS_BY_ID_FAILURE";

export const CREATE_GROUP_SETTINGS_BY_ID = "CREATE_GROUP_SETTINGS_BY_ID";
export const CREATE_GROUP_SETTINGS_BY_ID_SUCCESS = "CREATE_GROUP_SETTINGS_BY_ID_SUCCESS";
export const CREATE_GROUP_SETTINGS_BY_ID_FAILURE = "CREATE_GROUP_SETTINGS_BY_ID_FAILURE";

export const UPDATE_GROUP_SETTINGS_BY_ID = "UPDATE_GROUP_SETTINGS_BY_ID";
export const UPDATE_GROUP_SETTINGS_BY_ID_SUCCESS = "UPDATE_GROUP_SETTINGS_BY_ID_SUCCESS";
export const UPDATE_GROUP_SETTINGS_BY_ID_FAILURE = "UPDATE_GROUP_SETTINGS_BY_ID_FAILURE";

export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";
export const UPDATE_USER_SETTINGS_FAILURE = "UPDATE_USER_SETTINGS_FAILURE";

export const UPDATE_APPLICATION_SETTINGS = "UPDATE_APPLICATION_SETTINGS";
export const UPDATE_APPLICATION_SETTINGS_SUCCESS = "UPDATE_APPLICATION_SETTINGS_SUCCESS";
export const UPDATE_APPLICATION_SETTINGS_FAILURE = "UPDATE_APPLICATION_SETTINGS_FAILURE";

export const CLOSE_APPICATION_SETTINGS_ALERT = "CLOSE_APPICATION_SETTINGS_ALERT";

export const CLOSE_GROUP_SETTINGS_PANEL = "CLOSE_GROUP_SETTINGS_PANEL";

export const CLOSE_USER_SETTINGS_ALERT = "CLOSE_USER_SETTINGS_ALERT";

export const closeGroupSettingsPanel = () => {
	return {
		type: CLOSE_GROUP_SETTINGS_PANEL,
	};
};
export const updateGroupSettingsById = (groupId, settings) => {
	return (dispatch) => {
		dispatch(updateGroupSettingsByIdRequest(groupId, settings));
	};
};

export const updateGroupSettingsByIdRequest = (groupId, settings) => {
	return (dispatch) => {
		dispatch(updateGroupSettingsByIdStarted());
		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/group/${groupId}/settings`, {
			method: "PATCH",
			body: JSON.stringify(settings),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(updateGroupSettingsByIdFailure("fout"));
					return;
				}

				dispatch(updateGroupSettingsByIdSuccess());
			})
			.catch((error) => {
				dispatch(updateGroupSettingsByIdFailure(error));
			});
	};
};

const updateGroupSettingsByIdSuccess = () => {
	return {
		type: UPDATE_GROUP_SETTINGS_BY_ID_SUCCESS,
	};
};

const updateGroupSettingsByIdStarted = () => ({
	type: UPDATE_GROUP_SETTINGS_BY_ID,
});
const updateGroupSettingsByIdFailure = (error) => {
	return {
		type: UPDATE_GROUP_SETTINGS_BY_ID_FAILURE,
		payload: {
			error,
		},
	};
};
export const createGroupSettingsById = (groupId, settings) => {
	return (dispatch) => {
		dispatch(createGroupSettingsByIdRequest(groupId, settings));
	};
};

export const createGroupSettingsByIdRequest = (groupId, settings) => {
	return (dispatch) => {
		dispatch(createGroupSettingsByIdStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/group/${groupId}/settings`, {
			method: "POST",
			body: JSON.stringify(settings),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(createGroupSettingsByIdFailure("fout"));
					return;
				}

				response.json().then((result) => {
					dispatch(createGroupSettingsByIdSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(createGroupSettingsByIdFailure(error));
			});
	};
};

const createGroupSettingsByIdSuccess = (settings) => {
	return {
		type: CREATE_GROUP_SETTINGS_BY_ID_SUCCESS,
		payload: {
			settings,
		},
	};
};

const createGroupSettingsByIdStarted = () => ({
	type: CREATE_GROUP_SETTINGS_BY_ID,
});
const createGroupSettingsByIdFailure = (error) => {
	return {
		type: CREATE_GROUP_SETTINGS_BY_ID_FAILURE,
		payload: {
			error,
		},
	};
};

export const fetchGroupSettingsById = (groupId) => {
	return (dispatch) => {
		dispatch(fetchGroupSettingsByIdRequest(groupId));
	};
};

export const fetchGroupSettingsByIdRequest = (groupId) => {
	return (dispatch) => {
		dispatch(fetchGroupSettingsByIdStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/group/${groupId}/settings`, {})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchGroupSettingsByIdFailure("fout"));
					return;
				}

				response.json().then((result) => {
					dispatch(fetchGroupSettingsByIdSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(fetchGroupSettingsByIdFailure(error));
			});
	};
};

const fetchGroupSettingsByIdSuccess = (settings) => {
	return {
		type: FETCH_GROUP_SETTINGS_BY_ID_SUCCESS,
		payload: {
			settings,
		},
	};
};

const fetchGroupSettingsByIdStarted = () => ({
	type: FETCH_GROUP_SETTINGS_BY_ID,
});
const fetchGroupSettingsByIdFailure = (error) => {
	return {
		type: FETCH_GROUP_SETTINGS_BY_ID_FAILURE,
		payload: {
			error,
		},
	};
};

export const fetchGroupSettings = () => {
	return (dispatch) => {
		dispatch(fetchGroupSettingsRequest());
	};
};

export const fetchGroupSettingsRequest = () => {
	return (dispatch) => {
		dispatch(fetchGroupSettingsStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/groupSettings`, {})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchGroupSettingsFailure("fout"));
					return;
				}

				response.json().then((result) => {
					dispatch(fetchGroupSettingsSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(fetchGroupSettingsFailure(error));
			});
	};
};

export const fetchGroupSettingsSuccess = (settings) => {
	return {
		type: FETCH_GROUP_SETTINGS_SUCCESS,
		payload: {
			settings,
		},
	};
};

export const fetchGroupSettingsStarted = () => ({
	type: FETCH_GROUP_SETTINGS,
});
export const fetchGroupSettingsFailure = (error) => {
	return {
		type: FETCH_GROUP_SETTINGS_FAILURE,
		payload: {
			error,
		},
	};
};

export const fetchSettingsRequest = () => {
	return (dispatch) => {
		dispatch(fetchSettingsStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/settings`, {})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchSettingsFailure("fout"));
					return;
				}

				response.json().then((result) => {
					dispatch(fetchSettingsSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(fetchSettingsFailure(error));
			});
	};
};

export const fetchSettingsSuccess = (settings) => {
	return {
		type: FETCH_SETTINGS_SUCCESS,
		payload: {
			settings,
		},
	};
};

export const fetchSettingsStarted = () => ({
	type: FETCH_SETTINGS,
});

export const closeApplicationSettingsAlert = () => ({
	type: CLOSE_APPICATION_SETTINGS_ALERT,
});

export const closeUserSettingsAlert = () => ({
	type: CLOSE_USER_SETTINGS_ALERT,
});

export const fetchSettingsFailure = (error) => {
	return {
		type: FETCH_SETTINGS_FAILURE,
		payload: {
			error,
		},
	};
};

export const fetchSettings = () => {
	return (dispatch) => {
		dispatch(fetchSettingsRequest());
	};
};

export const updateUserSettings = (settingObj) => {
	return (dispatch) => {
		dispatch(updateUserSettingsRequest(settingObj));
	};
};

export const updateUserSettingsRequest = (settingObj) => {
	return (dispatch) => {
		dispatch(updateUserSettingsStarted());
		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/userSettings`, {
			method: "POST",
			body: JSON.stringify(settingObj),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(updateUserSettingsFailure({ message: "fout" }));
					return;
				}
				let teamUrl = "";

				response.json().then((result) => {
					dispatch(updateUserSettingsSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(updateUserSettingsFailure(error));
			});
	};
};

export const updateUserSettingsStarted = () => ({
	type: UPDATE_USER_SETTINGS,
});

export const updateUserSettingsFailure = (error) => {
	return {
		type: UPDATE_USER_SETTINGS_FAILURE,
		payload: {
			error,
		},
	};
};

export const updateUserSettingsSuccess = (settings) => {
	return {
		type: UPDATE_USER_SETTINGS_SUCCESS,
		payload: {
			settings,
		},
	};
};

export const updateApplicationSettings = (settingObj) => {
	return (dispatch) => {
		dispatch(updateApplicationSettingsRequest(settingObj));
	};
};

export const updateApplicationSettingsRequest = (settingObj) => {
	return (dispatch) => {
		dispatch(updateApplicationSettingsStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applicationSettings`, {
			method: "POST",
			body: JSON.stringify(settingObj),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(updateApplicationSettingsFailure({ message: "fout" }));
					return;
				}
				let teamUrl = "";

				response.json().then((result) => {
					dispatch(updateApplicationSettingsSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(updateApplicationSettingsFailure(error));
			});
	};
};

export const updateApplicationSettingsStarted = () => ({
	type: UPDATE_APPLICATION_SETTINGS,
});

export const updateApplicationSettingsFailure = (error) => {
	return {
		type: UPDATE_APPLICATION_SETTINGS_FAILURE,
		payload: {
			error,
		},
	};
};

export const updateApplicationSettingsSuccess = (settings) => {
	return {
		type: UPDATE_APPLICATION_SETTINGS_SUCCESS,
		payload: {
			settings,
		},
	};
};
