
import React, { Component } from 'react'

export default class expiredPage extends Component {
    render() {
        return (
            <section className="app-center">
                <h1>{this.props.location.state}</h1>
            </section>
        )
    }
}
