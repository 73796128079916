import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  createMetaDataOptions,
  createMetaDataFilledInOptions
} from "./generateMetaDataSets";
import Select from "react-select";

import * as teamActions from "../../../../../../store/teams/actions";
import { fetchTeamTypes } from "../../../../../../store/teamTypes/actions";
import { logUserEnrichedMetaData } from "../../../../../../logging/applicationInsights/logFunctions";

class PanelExpandMetaData extends React.Component {
  state = {
    formFields: [],
    selectedOptions: [],
    requiredFields: [],
    userCansubmit: false,
    selectedTeamType: 0
  };

  checkIfAllRequiredFieldsAreMatched = () => {
    // create an array of all the termSet names that are filled in.

    const filledInTermSets = [];
    this.state.selectedOptions.map(filledInItem => {
      filledInTermSets.push(filledInItem.split("-")[0]);
    });

    const checked = [];

    // loop over the required fields
    this.state.requiredFields.filter(requiredTermSetId => {
      // see whether the required fields termsetId at least occurs once in the filledin array
      for (let filledInTerm of filledInTermSets) {
        // if true add the value to the checked array and continue to the next required field termsetId.
        if (filledInTerm === requiredTermSetId) {
          checked.push(true);
          break;
        }
      }
    });

    // check whether the required fields array length equals the checked array length.
    if (checked.length === this.state.requiredFields.length) {
      this.setState({ userCansubmit: true });
    } else {
      this.setState({ userCansubmit: false });
    }
  };

  handleTeamTypeChange = selectedOption => {
    this.setState({ selectedTeamType: selectedOption });
  };

  handleChange = (selectedOption, action) => {
    // holds the selectedOptions for all formFields
    if (action.action === "select-option") {
      const allSelectedOptions = this.state.selectedOptions;

      // loop over all the currently selected items (note react-select only sends the currently changed array)
      selectedOption.map(option => {
        // check if our state contains this entry. If not add it.
        if (this.state.selectedOptions.indexOf(option.value) === -1)
          allSelectedOptions.push(option.value);
      });

      this.setState({ selectedOptions: allSelectedOptions });
    } else if (action.action === "remove-value") {
      // loop over all selected items in the state and filter out the one that has to be removed.
      const allSelectedOptions = this.state.selectedOptions.filter(item => {
        if (item !== action.removedValue.value) return item;
      });

      this.setState({ selectedOptions: allSelectedOptions });
    } else if (action.action === "clear") {
      // find our what the termSetId is of the cleared field.
      const termSetToClear = this.props.filters.filter(filter => {
        if (filter.name === action.name) return filter;
      });

      const termSetId = termSetToClear[0].id;

      // loop over all items in the state. filter out all items that are of the current termSet.
      const allSelectedOptions = this.state.selectedOptions.filter(item => {
        const itemTermSetId = item.split("-")[0];

        if (itemTermSetId != termSetId) return item;
      });

      this.setState({ selectedOptions: allSelectedOptions });
    }

    // we need a pauze because state is not updated immediately.
    setTimeout(() => {
      this.checkIfAllRequiredFieldsAreMatched();
    }, 100);
  };

  handleSubmit = e => {
    e.preventDefault();
    const objectsToSend = [];
    const newTerms = [];
    const termsToDelete = [];
    const termsToKeep = [];

    // the terms that the team already had before enriching the metadata
    const termsAlreadyFilledIn = this.props.currentTeam.terms.map(term => {
      return term.id;
    });

    // decide which terms to delete
    for (let selectedOption of this.state.selectedOptions) {
      const termSetId = parseInt(selectedOption.split("-")[0]);
      const termId = parseInt(selectedOption.split("-")[1]);

      // if the option is still in the filledin values we push it into the termsToKeep array.
      if (termsAlreadyFilledIn.indexOf(termId) >= 0) {
        termsToKeep.push(termId);
      }
    }

    // filter the already filled in option with the options to keep array. If a value is not present push it to the terms to delete array.
    for (let filledInTerm of termsAlreadyFilledIn) {
      if (termsToKeep.indexOf(filledInTerm) === -1) {
        let objectToDelete = {};

        // loop the current team terms to get the correct termSetId
        for (let teamTerm of this.props.currentTeam.terms) {
          const termId = parseInt(teamTerm.id);
          const termSetId = parseInt(teamTerm.termSetId);

          // create the object to send to the array termsToDelete, when the item is found exit the loop.
          if (filledInTerm === termId) {
            objectToDelete = {
              termId: termId,
              termSetId: termSetId
            };
            break;
          }
        }

        termsToDelete.push(objectToDelete);
      }
    }

    // decide which terms are new.
    this.state.selectedOptions.map(selectedOption => {
      const termSetId = selectedOption.split("-")[0];
      const termId = selectedOption.split("-")[1];

      // create the object to send in the array new terms
      const objectToSend = {
        termId: parseInt(termId),
        termSetId: parseInt(termSetId)
      };

      // if the terms are not in the array of already filled in terms we add them to the array.
      if (termsAlreadyFilledIn.indexOf(parseInt(termId)) === -1) {
        newTerms.push(objectToSend);
      }
    });

    const requestData = {
      teamId: this.props.currentTeam.id,
      newTerms,
      deletedTerms: termsToDelete,
      teamType: { id: this.state.selectedTeamType.value }
    };

    // send the request via redux to the API.
    this.props.sendMetaDataUpdate(requestData);

    this.props.onDismiss();

    // select all the selected items termSetId and termIds

    logUserEnrichedMetaData(
      this.props.currentUser.userPrincipalName,
      this.props.currentUser.tenant.name,
      this.props.currentTeam.id
    );
  };

  async componentDidMount() {
    const { intl } = this.props;
    await this.props.fetchTeamTypes();
    const formFieldsData = createMetaDataOptions(this.props.filters);

    if (
      this.props.currentTeam.teamType != null &&
      this.props.currentTeam.teamType != undefined
    ) {
      this.props.teamTypes.filter(teamType => {
        if (teamType.id === this.props.currentTeam.teamType.id) {
          this.setState({
            selectedTeamType: {
              value: teamType.id,
              label: teamType.name
            }
          });
        }
      });
    }

    const formFieldsFilledInData = createMetaDataFilledInOptions(
      this.props.currentTeam.terms
    );

    const formFields = formFieldsData.map(formFieldData => {
      let fieldIseditable = formFieldData.isEditable;

      if (formFieldData.isRequired) {
        if (formFieldData.items.length > 0) {
          this.state.requiredFields.push(
            formFieldData.items[0].value.split("-")[0]
          );
        }
      }

      if (formFieldData.items != "" && formFieldData.items.length > 0) {
        const termSetId = formFieldData.items[0].value.split("-")[0];

        // loop over the array and return the items that match the current termSetId
        const arrayOfFilledInValues = formFieldsFilledInData.filter(
          filledInData => {
            if (filledInData.value.split("-")[0] === termSetId)
              return filledInData;
          }
        );

        const allSelectedOptions = this.state.selectedOptions;

        // loop over all the currently selected items (note react-select only sends the currently changed array)
        arrayOfFilledInValues.map(option => {
          // check if our state contains this entry. If not add it.
          if (this.state.selectedOptions.indexOf(option.value) === -1) {
            allSelectedOptions.push(option.value);
          }
        });

        // creates and stores the placeholder data in a multilanguage fashion.
        const placeholder = intl.formatMessage({
          id: "metadata.placeholder",
          defaultMessage: "Selecteer een"
        });

        let isMetaDataComplete = this.props.currentTeam.isMetadataComplete;
        let isRequiredFlag = "";

        if (
          formFieldData.isRequired &&
          isMetaDataComplete === false &&
          arrayOfFilledInValues.length === 0
        ) {
          isRequiredFlag = <span className="data-is-missing"> ! </span>;
        }

        return (
          <label key={formFieldData.name}>
            <span>
              {isRequiredFlag} {formFieldData.name}
            </span>
            <Select
              defaultValue={arrayOfFilledInValues}
              key={formFieldData.name}
              isMulti
              name={formFieldData.name}
              options={formFieldData.items}
              className="basic-multi-select form-input-group"
              classNamePrefix="select"
              onChange={this.handleChange}
              isDisabled={!fieldIseditable}
              placeholder={`${placeholder} ${formFieldData.name}...`}
            />
          </label>
        );
      }
    });

    this.checkIfAllRequiredFieldsAreMatched();
    this.setState({ formFields });
  }

  render() {
    const { intl } = this.props;
    const formFields = this.state.formFields;

    let submitButton = "";
    let formButtonOrNoDataMessage = "";
    let teamTypeOptions = "";
    let teamTypeSelect;
    let selectedTeamTypeOption;

    if (
      this.props.teamTypes != undefined &&
      this.props.teamTypes != "" &&
      this.props.teamTypes.length > 0
    ) {
      teamTypeOptions = this.props.teamTypes.map(teamtype => {
        return { value: teamtype.id, label: teamtype.name };
      });

      if (
        this.props.currentTeam.teamType != null &&
        this.props.currentTeam.teamType != undefined
      ) {
        this.props.teamTypes.filter(teamType => {
          if (teamType.id === this.props.currentTeam.teamType.id) {
            selectedTeamTypeOption = {
              value: teamType.id,
              label: teamType.name
            };
          }
        });
      }
    }

    teamTypeSelect = (
      <Select
        defaultValue={selectedTeamTypeOption}
        key="teamType"
        single
        name="teamType"
        options={teamTypeOptions}
        className="basic-multi-select form-input-group"
        classNamePrefix="select"
        onChange={this.handleTeamTypeChange}
        placeholder="Selecteer een teamtype..."
      />
    );

    const disabledTitle = intl.formatMessage({
      id: "metadata.disabledTitle",
      defaultMessage: "Een of meer velden zijn verplicht."
    });

    const submitLabel = intl.formatMessage({
      id: "metadata.submitLabel",
      defaultMessage: "Informatie bijwerken"
    });

    if (this.state.userCansubmit && this.state.selectedTeamType != 0) {
      submitButton = (
        <button
          onClick={e => this.handleSubmit(e)}
          className="btn btn-primary teams-submit-button  submit-button__margin"
        >
          {submitLabel}
        </button>
      );
    } else {
      submitButton = (
        <button
          className="btn btn-primary teams-submit-button submit-button__margin"
          title={disabledTitle}
          disabled="disabled"
        >
          {submitLabel}
        </button>
      );
    }
    if (this.state.formFields.length === 0) {
      formButtonOrNoDataMessage = (
        <FormattedMessage
          id="metadata.noMetaDataAvailable"
          defaultMessage="Er zijn geen team classificaties beschikbaar binnen uw omgeving."
        />
      );
    } else {
      formButtonOrNoDataMessage = submitButton;
    }

    return (
      <React.Fragment>
        <section className="account-panel__panel-header">
          <div className="account-panel__panel-header--flex">
            <h1>
              <FormattedMessage
                id="metadata.panelTitle"
                defaultMessage="Metadata verrijken"
              />
            </h1>

            <div
              className="account-panel__panel-close"
              onClick={() => this.props.onDismiss()}
            >
              <Icon iconName="ChromeClose" />
            </div>
          </div>
        </section>
        <section className="metadata-content">
          <h3>
            <FormattedMessage
              id="metadata.teamInfo"
              defaultMessage="team informatie"
            />
          </h3>

          <form>
            <label>
              <span>
                {this.props.currentTeam.teamType == undefined ||
                this.props.currentTeam.teamType == null ? (
                  <span className="data-is-missing"> ! </span>
                ) : (
                  <span> </span>
                )}
                Teamtype
              </span>
            </label>
            {teamTypeSelect}
            {formFields}
            {formButtonOrNoDataMessage}
          </form>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentTeam: state.teamsSlice.currentTeam,
    filters: state.filtersSlice.filterTermSets,
    currentUser: state.currentUserSlice.currentUser,
    teamTypes: state.teamTypesSlice.teamTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendMetaDataUpdate: requestData =>
      dispatch(teamActions.updateMetaData(requestData)),
    fetchTeamTypes: () => dispatch(fetchTeamTypes)
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PanelExpandMetaData)
);
