import { adalOneApiFetch } from "../../adalConfig";
import { objectToArray } from "../../assets/general_functions/objectsAndArrays";

export const FETCH_TEAMS = "FETCH_TEAMS";
export const GET_TEAMS_STARTED = "GET_TEAMS_STARTED";
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
export const FETCH_TEAMS_FAILURE = "FETCH_TEAMS_FAILURE";
export const SET_TEAMS_SEARCH_TERM = "SET_TEAMS_SEARCH_TERM";
export const FETCH_MORE_TEAMS_STARTED = "FETCH_MORE_TEAMS_STARTED";
export const FETCH_MORE_TEAMS_FAILED = "FETCH_MORE_TEAMS_FAILED";
export const FETCH_MORE_TEAMS_SUCCESS = "FETCH_MORE_TEAMS_SUCCESS";

export const FETCH_TEAM_DETAILS = "FETCH_TEAM_DETAILS ";
export const FETCH_TEAM_DETAILS_STARTED = "FETCH_TEAM_DETAILS_STARTED";
export const FETCH_TEAM_DETAILS_FAILURE = "FETCH_TEAM_DETAILS_FAILURE";
export const FETCH_TEAM_DETAILS_SUCCESS = "FETCH_TEAM_DETAILS_SUCCESS";

export const FETCH_ALL_TEAMS = "FETCH_ALL_TEAMS";
export const FETCH_ALL_TEAMS_STARTED = "FETCH_ALL_TEAMS_STARTED";
export const FETCH_ALL_TEAMS_SUCCESS = "FETCH_ALL_TEAMS_SUCCESS";
export const FETCH_ALL_TEAMS_FAILED = "FETCH_ALL_TEAMS_FAILED";

export const REMOVE_CURRENT_TEAM = "REMOVE_CURRENT_TEAM";

export const FETCH_TEAM_FILES_STARTED = "FETCH_TEAM_FILES_STARTED";
export const FETCH_TEAM_FILES_SUCCESS = "FETCH_TEAM_FILES_SUCCESS";
export const FETCH_TEAM_FILES_FAILED = "FETCH_TEAM_FILES_FAILED";

export const FETCH_TEAM_USERS_STARTED = "FETCH_TEAM_USERS_STARTED";
export const FETCH_TEAM_USERS_SUCCESS = "FETCH_TEAM_USERS_SUCCESS";
export const FETCH_TEAM_USERS_FAILED = "FETCH_TEAM_USERS_FAILED";

export const FETCH_TEAM_PLANS_STARTED = "FETCH_TEAM_PLANS_STARTED";
export const FETCH_TEAM_PLANS_SUCCESS = "FETCH_TEAM_PLANS_SUCCESS";
export const FETCH_TEAM_PLANS_FAILED = "FETCH_TEAM_PLANS_FAILED";

export const FETCH_TEAM_CHANNELS_STARTED = "FETCH_TEAM_CHANNELS_STARTED";
export const FETCH_TEAM_CHANNELS_SUCCESS = "FETCH_TEAM_CHANNELS_SUCCESS";
export const FETCH_TEAM_CHANNELS_FAILED = "FETCH_TEAM_CHANNELS_FAILED";

export const TOGGLE_FAVORITE_STARTED = "TOGGLE_FAVORITE_STARTED";
export const TOGGLE_FAVORITE_SUCCESS = "TOGGLE_FAVORITE_SUCCESS";
export const TOGGLE_FAVORITE_FAILED = "TOGGLE_FAVORITE_FAILED";

export const UPDATE_METADATA = "UPDATE_METADATA";
export const UPDATE_METADATA_SUCCESS = "UPDATE_METADATA_SUCCESS";
export const UPDATE_METADATA_STARTED = "UPDATE_METADATA_STARTED";
export const UPDATE_METADATA_FAILED = "UPDATE_METADATA_FAILED";
export const UPDATE_METADATA_INFOBAR = "UPDATE_METADATA_INFOBAR";

export const SET_ARCHIVE = "SET_ARCHIVE";
export const SET_ARCHIVE_SUCCESS = "SET_ARCHIVE_SUCCESS";
export const SET_ARCHIVE_FAILED = "SET_ARCHIVE_FAILED";

export const UPDATE_MYTEAMS_FIRST_BATCH = "UPDATE_MYTEAMS_FIRST_BATCH";
export const CLEAR_TEAM_NOTIFICATION = "CLEAR_TEAM_NOTIFICATION";
export const CLEAR_TEAM_ARCHIVE_UPDATE = "CLEAR_TEAM_ARCHIVE_UPDATE";

export const removeCurrentTeam = () => ({
	type: REMOVE_CURRENT_TEAM,
});

export const fetchAllTeamsRequest = (type) => {
	return (dispatch) => {
		dispatch(getAllTeamStarted());

		let teamsArray = [];
		getAllTeams("", teamsArray, dispatch, type);
	};
};

// url: the url to fetch the data from.
// teamsArray: the array that will get filled recursively with all the teams.
// dispatch: the dispatch object from fetchAllTeamsRequest.
// type: Decides whether to get all group or my groups.
const getAllTeams = (url, teamsArray, dispatch, type) => {
	if (url === "" && type === "private") {
		url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/me/teams?pageSize=999`;
	} else if (url === "" && type === "public") {
		url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams?pageSize=999`;
	}

	dispatch(getAllTeamStarted());

	adalOneApiFetch(fetch, url, {})
		.then((response) => {
			if (response.status != 200 && response.status != 204) {
				dispatch(fetchAllTeamsFailure("fout"));
				return;
			}

			response.json().then((result) => {
				let nextLink = "";
				if (typeof result.links.nextPage != "undefined") {
					nextLink = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/${result.links.nextPage}`;
				}

				if (nextLink) {
					const teams = objectToArray(result.items);
					teamsArray.push(...teams);
					getAllTeams(nextLink, teamsArray, dispatch, type);
				} else {
					const latestResult = objectToArray(result.items);
					teamsArray.push(...latestResult);

					dispatch(fetchAllTeamsSucces(teamsArray, type));
				}
			});
		})
		.catch((error) => {
			dispatch(fetchAllTeamsFailure(error));
		});
};

export const fetchAllTeams = (type) => {
	return (dispatch) => {
		dispatch(fetchAllTeamsRequest(type));
	};
};

const getAllTeamStarted = () => ({
	type: FETCH_ALL_TEAMS_STARTED,
});

const fetchAllTeamsSucces = (teams, teamsType) => ({
	type: FETCH_ALL_TEAMS_SUCCESS,
	fetchType: teamsType,
	payload: {
		...teams,
	},
});

const fetchAllTeamsFailure = (error) => ({
	type: FETCH_TEAMS_FAILURE,
	payload: {
		error,
	},
});

// Get Teams for my teams page

export const fetchRequest = (teamType) => {
	return (dispatch) => {
		dispatch(getTeamstarted());

		let url = "";
		if (teamType === "myTeams") {
			url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/me/teams`;
		} else if (teamType === "allTeams") {
			url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams`;
		}

		adalOneApiFetch(fetch, url, {})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamsFailure("fout"));
					return;
				}

				response.json().then((result) => {
					const nextPageLink = `${process.env.REACT_APP_ONE_API_URL}/api${result.links.nextPage}`;

					dispatch(fetchTeamsSucces(result.items, nextPageLink));
				});
			})
			.catch((error) => {
				dispatch(fetchTeamsFailure(error));
			});
	};
};

export const fetchTeams = (teamType) => {
	return (dispatch) => {
		dispatch(fetchRequest(teamType));
	};
};

const getTeamstarted = () => ({
	type: GET_TEAMS_STARTED,
});

const fetchTeamsSucces = (teams, nextLink) => ({
	type: FETCH_TEAMS_SUCCESS,
	payload: {
		teams,
		nextLink,
	},
});

const fetchTeamsFailure = (error) => ({
	type: FETCH_TEAMS_FAILURE,
	payload: {
		error,
	},
});

// Get more teams, used for pagination

export const fetchMoreTeamsRequest = (link) => {
	if (link != "" && link != "undefined") {
		return (dispatch) => {
			dispatch(getMoreTeamstarted());

			adalOneApiFetch(fetch, link, {})
				.then((response) => {
					if (response.status != 200 && response.status != 204) {
						dispatch(fetchMoreTeamsFailure("fout"));
						return;
					}

					response.json().then((result) => {
						dispatch(fetchMoreTeamsSucces(result.items, result.links.nextPage));
					});
				})
				.catch((error) => {
					dispatch(fetchMoreTeamsFailure(error));
					return;
				});
		};
	}
};

export const fetchMoreTeams = (link) => {
	return (dispatch) => {
		dispatch(fetchMoreTeamsRequest(link));
	};
};

export const getMoreTeamstarted = () => ({
	type: FETCH_MORE_TEAMS_STARTED,
	payload: {
		moreTeamsLoading: true,
	},
});

const fetchMoreTeamsSucces = (teams, nextPage) => ({
	type: FETCH_MORE_TEAMS_SUCCESS,
	nextPage: nextPage,
	payload: {
		...teams,
		moreTeamsLoading: false,
	},
});

const fetchMoreTeamsFailure = (error) => ({
	type: FETCH_MORE_TEAMS_FAILED,
	payload: {
		error,
		moreTeamsLoading: false,
	},
});

// Get the teams link, used to get the teams channel
export const fetchTeamDetailsRequest = (teamId, keepMessage, metaDataUpdated) => {
	return (dispatch) => {
		dispatch(fetchTeamDetailsStarted(teamId));

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${teamId}`, {})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamDetailsFailure("fout"));
					return;
				}
				let teamUrl = "";

				response.json().then((result) => {
					if (response.value) {
						teamUrl = response.value[0].uri;
					}

					dispatch(fetchTeamDetailsSuccess(result, teamId, keepMessage, metaDataUpdated));
				});
			})
			.catch((error) => {
				dispatch(fetchTeamDetailsFailure(error));
			});
	};
};

export const fetchTeamDetails = (id, keepMessage = false, metaDataUpdated = false) => {
	return (dispatch) => {
		dispatch(fetchTeamDetailsRequest(id, keepMessage, metaDataUpdated));
	};
};

const fetchTeamDetailsStarted = (teamId) => ({
	type: FETCH_TEAM_DETAILS_STARTED,
	payload: {
		teamId,
	},
});

const fetchTeamDetailsSuccess = (result, teamId, keepMessage, metaDataUpdated) => ({
	type: FETCH_TEAM_DETAILS_SUCCESS,
	payload: {
		result,
		teamId,
		keepMessage,
		metaDataUpdated,
	},
});

const fetchTeamDetailsFailure = (error) => ({
	type: FETCH_TEAM_DETAILS_FAILURE,
	payload: {
		error,
	},
});

// Set the search Term

export const setTeamsSearchTerm = (term, searchType, filteredTeamSet) => ({
	type: SET_TEAMS_SEARCH_TERM,
	searchType,
	filteredTeamSet,
	payload: {
		term,
	},
});

// Get the teams link, used to get the teams channel
export const fetchTeamFilesRequest = (teamId) => {
	return (dispatch) => {
		dispatch(fetchTeamFilesStarted(teamId));

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${teamId}/files`, {})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamFilesFailure("fout"));
					return;
				}
				let teamUrl = "";

				response.json().then((result) => {
					if (response.value) {
						teamUrl = response.value[0].url;
					}
					dispatch(fetchTeamFilesSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(fetchTeamFilesFailure(error));
			});
	};
};

export const fetchTeamFiles = (id) => {
	return (dispatch) => {
		dispatch(fetchTeamFilesRequest(id));
	};
};

const fetchTeamFilesStarted = (teamId) => ({
	type: FETCH_TEAM_FILES_STARTED,
});

const fetchTeamFilesSuccess = (result) => ({
	type: FETCH_TEAM_FILES_SUCCESS,
	payload: {
		result,
	},
});

const fetchTeamFilesFailure = (error) => ({
	type: FETCH_TEAM_FILES_FAILED,
	payload: {
		error,
	},
});

export const fetchTeamUsersRequest = (teamId) => {
	return (dispatch) => {
		dispatch(fetchTeamUsersStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${teamId}/users`, {})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamUsersFailure("fout"));
					return;
				}
				let teamUrl = "";

				response.json().then((result) => {
					if (response.value) {
						teamUrl = response.value[0].uri;
					}
					dispatch(fetchTeamUsersSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(fetchTeamUsersFailure(error));
			});
	};
};

export const fetchTeamUsers = (id) => {
	return (dispatch) => {
		dispatch(fetchTeamUsersRequest(id));
	};
};

const fetchTeamUsersStarted = (teamId) => ({
	type: FETCH_TEAM_USERS_STARTED,
});

const fetchTeamUsersSuccess = (result) => ({
	type: FETCH_TEAM_USERS_SUCCESS,
	payload: {
		result,
	},
});

const fetchTeamUsersFailure = (error) => ({
	type: FETCH_TEAM_USERS_FAILED,
	payload: {
		error,
	},
});

export const fetchTeamPlansRequest = (teamId) => {
	return (dispatch) => {
		dispatch(fetchTeamPlansStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${teamId}/plans`, {})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamPlansFailure("fout"));
					return;
				}
				let teamUrl = "";

				response.json().then((result) => {
					if (response.value) {
						teamUrl = response.value[0].uri;
					}
					dispatch(fetchTeamPlansSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(fetchTeamPlansFailure(error));
			});
	};
};

export const fetchTeamPlans = (id) => {
	return (dispatch) => {
		dispatch(fetchTeamPlansRequest(id));
	};
};

const fetchTeamPlansStarted = (teamId) => ({
	type: FETCH_TEAM_PLANS_STARTED,
});

const fetchTeamPlansSuccess = (result) => ({
	type: FETCH_TEAM_PLANS_SUCCESS,
	payload: {
		result,
	},
});

const fetchTeamPlansFailure = (error) => ({
	type: FETCH_TEAM_PLANS_FAILED,
	payload: {
		error,
	},
});

export const toggleFavoriteRequest = (teamId) => {
	return (dispatch) => {
		dispatch(toggleFavoriteStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/toggle_favorite/${teamId}`, {
			method: "POST",
		})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(toggleFavoriteFailure("fout"));
					return;
				}
				let teamUrl = "";

				response.json().then((result) => {
					const objToSend = {
						isFavorite: result.isFavorite,
						teamId: result.id,
					};

					dispatch(toggleFavoriteSuccess(objToSend));
				});
			})
			.catch((error) => {
				dispatch(toggleFavoriteFailure(error));
			});
	};
};

export const toggleFavorite = (id) => {
	return (dispatch) => {
		dispatch(toggleFavoriteRequest(id));
	};
};

const toggleFavoriteStarted = (teamId) => ({
	type: TOGGLE_FAVORITE_STARTED,
});

const toggleFavoriteSuccess = (result) => ({
	type: TOGGLE_FAVORITE_SUCCESS,
	payload: {
		result,
	},
});

const toggleFavoriteFailure = (error) => ({
	type: TOGGLE_FAVORITE_FAILED,
	payload: {
		error,
	},
});

export const updateMetadataRequest = (requestData) => {
	return (dispatch) => {
		dispatch(updateMetaDataStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/update_metadata`, {
			method: "PATCH",
			body: JSON.stringify(requestData),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(updateMetaDataFailed("fout"));
					return;
				}

				response.json().then((result) => {
					result = JSON.parse(result);
					dispatch(updateMetaDataSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(updateMetaDataFailed(error));
			});
	};
};

export const updateMetaData = (requestData) => {
	return (dispatch) => {
		dispatch(updateMetadataRequest(requestData));
	};
};

const updateMetaDataStarted = () => ({
	type: UPDATE_METADATA_STARTED,
});

const updateMetaDataFailed = (error) => ({
	type: UPDATE_METADATA_FAILED,
	payload: {
		error,
	},
});

const updateMetaDataSuccess = (result) => ({
	type: UPDATE_METADATA_SUCCESS,
	payload: {
		result,
	},
});

export const removeMetadataUpdateBar = () => ({
	type: UPDATE_METADATA_INFOBAR,
});

export const fetchTeamChannelsRequest = (teamId) => {
	return (dispatch) => {
		dispatch(fetchTeamChannelsStarted());

		adalOneApiFetch(
			fetch,

			`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${teamId}/channels`,
			{}
		)
			// `https://graph.microsoft.com/v1.0/teams/${teamId}/channels`,
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamChannelsFailure("fout"));
					return;
				}

				response.text().then((result) => {
					result = JSON.parse(result);
					dispatch(fetchTeamChannelsSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(fetchTeamChannelsFailure("fout"));
				return;
			});
	};
};

export const fetchTeamChannels = (id) => {
	return (dispatch) => {
		dispatch(fetchTeamChannelsRequest(id));
	};
};

const fetchTeamChannelsStarted = (teamId) => ({
	type: FETCH_TEAM_CHANNELS_STARTED,
});

const fetchTeamChannelsSuccess = (result) => ({
	type: FETCH_TEAM_CHANNELS_SUCCESS,
	payload: {
		result,
	},
});

const fetchTeamChannelsFailure = (error) => ({
	type: FETCH_TEAM_CHANNELS_FAILED,
	payload: {
		error,
	},
});

export const updateMyTeamsFromScroll = (teams) => ({
	type: UPDATE_MYTEAMS_FIRST_BATCH,
	payload: {
		teams,
	},
});

export const setArchiveRequest = (teamId) => {
	return (dispatch) => {
		dispatch(setArchiveStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/archive/${teamId}`, {
			method: "POST",
		})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204 && response != 201) {
					dispatch(setArchiveFailure("fout"));
					return;
				}

				response.json().then((result) => {
					dispatch(setArchiveSuccess(result.data));
				});
			})
			.catch((error) => {
				dispatch(setArchiveFailure(error));
			});
	};
};

export const setArchive = (teamId) => {
	return (dispatch) => {
		dispatch(setArchiveRequest(teamId));
	};
};

const setArchiveStarted = () => ({
	type: SET_ARCHIVE,
});

const setArchiveSuccess = (data) => ({
	type: SET_ARCHIVE_SUCCESS,
	payload: {
		data,
	},
});

const setArchiveFailure = (error) => ({
	type: SET_ARCHIVE_FAILED,
	payload: {
		error,
	},
});

export const clearTeamNotification = () => ({
	type: CLEAR_TEAM_NOTIFICATION,
});

export const clearArchiveNeedsToUpdate = () => ({
	type: CLEAR_TEAM_ARCHIVE_UPDATE,
});
