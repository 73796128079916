export default {
	// --== General ==--
	"general.makeAChoice": "Maak een keuze",
	"general.public": "Publiek",
	"general.private": "Prive",
	"general.yes": "Ja",
	"general.no": "Nee",
	"general.formEditText": "Aanpassen",
	"general.save": "Opslaan",
	"general.formCreateText": "Aanmaken",
	"general.requiredField": "Dit veld is verplicht",

	// --== Nav ==--
	"nav.myTeams": "Mijn teams",
	"nav.discoverTeams": "Alle teams",
	"nav.teams": "Teams",
	"nav.manage": "Beheer",
	"nav.metadataLinkText": "Categorisering",
	"navBar.office365Link": "Ga naar Microsoft Office365",

	// --== Summary ==--
	"summary.memberOf": "Lid van",
	"summary.ownerOf": "Eigenaar van",

	// --== Search ==--
	"search.placeholder": "Zoeken...",
	"search.awaitMessage": "even geduld a.u.b.",

	// --== CommandBar ==--
	"commandbar.removeFilter": "Filters verwijderen",
	"commandbar.filter": "Filter",
	"commandbar.addTeam": "Nieuw team aanvragen",
	"commandbar.teamCreated": "Het team wordt aangemaakt, dit kan een ogenblik duren...",
	"commandbar.teamCreatedNeedsApproval": "Het team is aangevraagd en wacht op goedkeuring...",
	"commandbar.teamCreatedFailed": "Er is iets mis gegaan met het aanmaken van het team.",

	// -== Filters ==-
	"filters.status.active": "Actief",
	"filters.status.expired": "Verlopen",
	"filters.status.archived": "Gearchiveerd",
	"filters.status.deleted": "Verwijderd",

	// --== TeamCard ==--
	"teamcard.memberCount": "Leden",
	"teamcard.marksAsFavorite": "Stel in als favoriet.",
	"teamcard.marksAsNonFavorite": "Stel in als niet favoriet.",
	"teamcard.statusLabelPublic": "Publiek",
	"teamcard.statusLabelPrivate": "Prive",

	// --== Teams ==--
	"teams.noSearchResults": "Geen teams gevonden",
	"teams.activeFilters": "Actieve filters",
	"teams.privateLabel": "Privé",
	"teams.publicLabel": "Publiek",
	"teams.viewTeam": "Bekijken",
	"teams.joinTeam": "Lid worden",

	// --== Provisioning ==--
	"provisioning.PanelTitle": "Nieuw team aanvragen",
	"provisioning.PanelEssentialInformation": "Basisinformatie",
	"provisioning.teamNameLabel": "Naam",
	"provisioning.teamNamePlaceHolder": "Vul een teamnaam in...",
	"provisioning.internalTeamNameLabel": "Interne naam",
	"provisioning.teamTypeLabel": "Teamtype",
	"provisioning.requestTypeLabel": "Type aanvraag",
	"provisioning.privacyLevelLabel": "Privacyniveau",
	"provisioning.descriptionLabel": "Beschrijving",
	"provisioning.allowToAddGuestsLabel": "Gasten toelaten",
	"provisioning.metaDataLabel": "Overige info",
	"provisioning.submit": "Start aanvraag",
	"provisioning.mustSelectATypeError": "U moet een teamtype selecteren.",
	"provisioning.mustUseTwoCharactersError": "U moet minimaal 2 tekens gebruiken.",
	"provisioning.mustProvideATeamnameError": "U moet een teamnaam opgeven.",
	"provisioning.MustSelectATeamTypeError": "U moet een teamtype selecteren.",

	// --== TeamModal ==--

	"teamModalHeader.outlookIconTitle": "E-mail",
	"teamModalHeader.calendarIconTitle": "Agenda",
	"teamModalHeader.OneNoteTitle": "Notitieblok",
	"teamModalHeader.filesTitle": "Bestanden",
	"teamModalHeader.sharePointTitle": "Site",
	"teamModalHeader.teamsTitle": "Teams",

	"teamModalBody.filesTabName": "Bestanden",
	"teamModalBody.filesTabTitle": "Alle bestanden",
	"teamModalBody.filesTabNoFiles": "Er zijn geen bestanden.",

	"teamModalBody.plansTabName": "Plannen",
	"teamModalBody.plansTabTitle": "Alle plannen",
	"teamModalBody.planNoneAvailable": "Er zijn geen plannen.",

	"teamModalBody.membersTabName": "Leden",
	"teamModalBody.membersTabTitle": "Alle leden",
	"teamModalBody.membersExternalUserTitle": "Externe gebruiker",
	"teamModalBody.membersOwnersHeader": "Eigenaren",
	"teamModalBody.membersMembersHeader": "Leden",

	"teamModalBody.infoTabName": "Info",
	"teamModalBody.infoTabTitle": "Over dit team",
	"teamModalBody.infoExpandMetaDataLink": "Infomatie aanpassen",
	"teamModalBody.archivedStatusLabel": "Status",
	"teamModalBody.archivedStatusActive": "Actief",
	"teamModalBody.archivedStatusInactive": "Gearchiveerd",
	"teamModalBody.infoTabCategoryLabel": "Categorie informatie",
	"teamModalBody.infoTabBacisInfoLabel": "Basisinformatie",
	"teamModalBody.infoTabActionsLabel": "Acties",
	"teamModalBody.infoTabTeamArchiveLabel": "Team archiveren",
	"teamModalBody.infoTabNoTermsActive": "Er zijn geen categorieën voor dit team.",
	"teamModalBody.infoTabTeamTypeLabel": "Teamtype",

	"teamModalBody.archivePanelHeaderLabel": "Team archiveren",
	"teamModalBody.archivePanelAreYouSureMessage": "Weet u zeker dat u dit team wilt archiveren?",
	"teamModalBody.archivePanelAchiveYesLabel": "Archiveren",
	"teamModalBody.archivePanelAchiveNoLabel": "Annuleren",
	"teamModalBody.archieveSuccessAlertLabel": "Het team is gearchiveerd.",
	"teamModalBody.archieveFailedAlertLabel": "Het team kon niet gearchiveerd worden",

	"teamModalBody.channelsTabTitle": "Kanalen",
	"teamModalBody.channelsNoneAvailable": "Er zijn geen kanalen beschikbaar.",

	"teamModalBody.groupSettings.label": "Groepsinstellingen",
	"teamModalBody.groupSettings.allowToAddGuets.label": "Externen toelaten",
	"teamModalBody.groupSettings.allowToAddGuets.chooseValue": "Kies een waarde",

	// --== Metadata ==--
	"metadata.teamTypesManage": "Teamtypes beheren",
	"metadata.categoriesManage": "Categorieën beheren",
	"metadata.panelTitle": "Categorieën beheren",
	"metadata.teamInfo": "Team informatie",
	"metadata.placeholder": "Selecteer een ",
	"metadata.disabledTitle": "een of meer velden zijn verplicht.",
	"metadata.submitLabel": "Informatie bijwerken",
	"metadata.dataUpdated": "De teaminformatie is bijgewerkt.",
	"metadata.dataHasntUpdated": "de team informatie kon niet worden bijgewerkt.",
	"metadata.noMetaDataAvailable": "Er zijn geen team classificaties beschikbaar binnen uw omgeving.",
	"metadata.newTermSetText": "Nieuwe termset aanmaken",
	"metadata.newTermText": "Nieuwe term aanmaken",
	"metadata.detailsHeaderText": "Categorieën beheren",
	"metadata.detailsContentText": "Niets geselecteerd, selecteer een categorie aan de linkerkant.",
	"metadata.createTermSuccessAlert": "Term aangemaakt",
	"metadata.createTermFailedAlert": "Er ging iets mis bij het aanmaken van de term",
	"metadata.updateTermSuccessAlert": "Term succesvol aangepast",
	"metadata.updateTermFailedAlert": "Er ging iets mis bij het aanpassen van de term",
	"metadata.createTermSetSuccessAlert": "TermSet aangemaakt",
	"metadata.createTermSetFailedAlert": "Er ging iets mis bij het aanmaken van de termSet",
	"metadata.updateTermSetSuccessAlert": "TermSet succesvol aangepast",
	"metadata.updateTermSetFailedAlert": "Er ging iets mis bij het aanpassen van de termSet",
	"metadata.newTeamTypeText": "Nieuwe teamtype aanmaken",
	"metadata.addTeamTypeSuccessAlert": "Teamtype is succesvol aangemaakt",
	"metadata.addTeamTypeFailedAlert": "Er ging iets mis bij het aanmaken van de teamtype",
	"metadata.updateTeamTypeFailedAlert": "Er ging iets mis bij het aanpassen van de teamtype",
	"metadata.updateTeamTypeSuccessAlert": "Teamtype is succesvol aangepast",

	"metadata.form.teamTypeLabel": "Teamtype",

	"metadata.form.termNameInputLabel": "Naam",
	"metadata.form.termNameInputDescription": "Voer een naam voor deze term in",
	"metadata.form.termIsActiveInputLabel": "Is actief",
	"metadata.form.termIsActiveInputDescription": "Deze term mag aan teams gekoppeld worden",
	"metadata.form.validation.termNameMinTwoCharacters": "U moet minstens 2 tekens gebruiken.",
	"metadata.form.validation.termNameRequired": "U moet een naam voor de term opgeven",

	"metadata.form.termSetNameInputLabel": "Naam",
	"metadata.form.termSetNameInputDescription": "Voer een naam voor deze termSet in",
	"metadata.form.termSetIsRequiredInputLabel": "Is verplicht",
	"metadata.form.termSetIsRequiredInputDescription": "Deze termset is verplicht",
	"metadata.form.termSetIsActiveLabel": "Is actief",
	"metadata.form.termSetIsActiveDescription": "Deze termset mag aan teams gekoppeld worden",
	"metadata.form.termSetIsReassignableLabel": "Is aanpasbaar",
	"metadata.form.termSetIsReassignableDescription": "Deze termset mag gewijzigd worden",
	"metadata.form.termSetIsRequiredDisabledInfoText":
		"Een term set kan niet verplicht zijn als deze inactief is, of als deze geen onderliggende actieve terms bevat.",
	"metadata.form.termIsRequiredDisabledInfoText":
		"Deze term kan niet inactief worden omdat de bovenliggende term set verplicht is en dit de laatste actieve term daarvan is.",
	"metadata.form.validation.termSetNameMinTwoCharacters": "U moet minstens 2 tekens gebruiken",
	"metadata.form.validation.termSetNameRequired": "U moet een naam voor de termset opgeven",

	"metadata.form.termGroupNameInputLabel": "Naam",
	"metadata.form.termGroupNameInputDescription": "Voer een naam voor deze termGroup in",
	"metadata.form.termGroupIsTenantWideInputLabel": "Is organisatie breed",
	"metadata.form.termGroupIsTenantWideInputDescription":
		"Deze termgroup kan door de hele organisatie gebruikt worden",
	"metadata.form.validation.termGroupNameMinTwoCharacters": "U moet minstens 2 tekens gebruiken.",
	"metadata.form.validation.termGroupNameRequired": "U moet een naam voor de termGroup opgeven",

	"metadata.form.teamType.nameLabel": "Teamtype",
	"metadata.form.teamType.nameDescription": "De naam voor het teamtype",
	"metadata.form.teamType.namePlaceholder": "Voer een naam in voor het teamtype",
	"metadata.form.teamType.domainLabel": "Domein",
	"metadata.form.teamType.domainDescription": "Domein voor de mail alias",
	"metadata.form.teamType.domainPlaceholder": "Voer een domein in voor de mail alias",
	"metadata.form.teamType.approvalRequiredLabel": "Goedkeuring vereist",
	"metadata.form.teamType.approvalRequiredDescription": "Bepaald of een team aanvraag goegkeuring vereist",

	// --== Account ==--
	"account.settings": "Mijn instellingen",
	"account.myProfile": "Mijn profiel",
	"account.logout": "Uitloggen",
	"account.appSettings": "Applicatie-instellingen",
	"account.appSettingsPanelTitle": "Applicatie-instellingen",
	"account.settingsHeader": "Algemene instellingen",
	"account.showProvisioningDropdownText": "Toon aanvraagformulier voor nieuwe teams aan gebruikers",
	"account.languageDropdownText": "Taal",
	"account.saveSttingsText": "Instellingen opslaan",
	"account.settingsText": "Instellingen",
	"account.applicationSettingsHeader": "Applicatie-instellingen",
	"account.tenantSettingsHeader": "Tenant instellingen",
	"account.tenantSettingsHeader": "Tenant settings",
	"account.userSettingsHeader": "Gebruikers instellingen",
	"account.accountSettingsUpdateFailed": "Er is iets fout gegaan.",
	"account.accountSettingsUpdateSucces": "De instellingen zijn opgeslagen.",
	"account.languageRequiredText": "U moet een taal selecteren",

	// --== NotificationHub ==--
	"notificationHub.notificationHeaderText": "Notificaties",
	"notificationHub.requestedHeaderText": "Recente team aanvragen",
	"notificationHub.noRecentTeamRequests": "Geen aanvragen gevonden",
	"notificationHub.panelHeader": "Berichten",
	"notificationHub.myTeamRequestsLinkText": "Al mijn aanvragen",
	"notificationHub.requestedTeamDetailsOwnerText": "Eigenaar",
	"notificationHub.requestedTeamDetailsStatusText": "Status",
	"notificationHub.deleteButtonText": "Verwijderen",
	"notificationHub.deleteSuccess": "Aanvraag verwijderd",
	"notificationHub.deleteFailure": "Er ging iets mis met het verwijderen van de aanvraag",

	// --== TeamRequest ==--
	"teamRequest.statusTextRequested": "Aangevraagd",
	"teamRequest.statusTextApproved": "Goedgekeurd",
	"teamRequest.statusTextRejected": "Afgewezen",
	"teamRequest.statusTextInProgress": "In behandeling",
	"teamRequest.statusTextReady": "Aangemaakt",
	"teamRequest.statusTextFailed": "Mislukt",
	"teamRequest.statusTextDeleted": "Verwijderd",
	"teamRequest.noRequestsFound": "Geen team aanvragen gevonden",

	"teamRequest.my.headerText": "Mijn team aanvragen",
	"teamRequest.all.headerText": "Alle team aanvragen",

	// --== TeamRequestsTableComponent ==--
	"teamRequestTable.openDetailPanelText": "Meer informatie...",
	"teamRequestTable.approvalRequiredOpenDetailPanelText": "Goedkeuring vereist",
	"teamRequestTable.headerTitle": "Titel",
	"teamRequestTable.headerCreatedOn": "Aangemaakt op",
	"teamRequestTable.headerOwner": "Eigenaar",
	"teamRequestTable.headerTeamType": "Type",
	"teamRequestTable.headerStatus": "Status",
	"teamRequestTable.headerActions": "Acties",

	"teamRequestTableDetail.titleLabel": "Titel",
	"teamRequestTableDetail.statusLabel": "Status",
	"teamRequestTableDetail.statusReason": "Reden van status",
	"teamRequestTableDetail.descriptionLabel": "Beschrijving",
	"teamRequestTableDetail.allowToAddGuestsLabel": "Gasten toelaten",
	"teamRequestTableDetail.teamTypeLabel": "Teamtype",
	"teamRequestTableDetail.privacyLevelLabel": "Privacyniveau",
	"teamRequestTableDetail.ownerLabel": "Eigenaar",
	"teamRequestTableDetail.createdLabel": "Aangemaakt op",
	"teamRequestTableDetail.modifiedLabel": "Laatst gewijzigd op",
	"teamRequestTableDetail.activeTermsLabel": "Actieve categorieën",

	"teamRequestTableDetail.action.approve": "Goedkeuren",
	"teamRequestTableDetail.action.reject": "Afwijzen",
	"teamRequestTableDetail.action.delete": "Verwijderen",
	"teamRequestTableDetail.action.cancel": "Annuleren",

	"teamRequestTableDetail.action.deleteFailure": "Er ging iets mis bij het verwijderen van de aanvraag",
	"teamRequestTableDetail.action.deleteSuccess": "Aanvraag verwijderd",
	"teamRequestTableDetail.action.approveFailure": "Er ging iets mis bij het goedkeuren van de aanvraag",
	"teamRequestTableDetail.action.approveSuccess": "Aanvraag goedgekeurd",
	"teamRequestTableDetail.action.rejectFailure": "Er ging iets mis bij het afwijzen van de aanvraag",
	"teamRequestTableDetail.action.rejectSuccess": "Aanvraag afgewezen",

	// --== Settings ==--
	"settings.panelTitle": "Instellingen",
	"settings.appSettingsTitle": "Applicatie-Instellingen",
	"settings.appSettingsDescription": "Hier kunt u de applicatie-instellingen wijzigen.",
	"settings.metaDataSettingTitle": "Categorieën beheren",
	"settings.metaDataSettingDescription": "Beheer de categorieën voor de applicatie.",
	"settings.allTeamRequestsSettingTitle": "Alle team aanvragen",
	"settings.allTeamRequestsSettingDescription": "Hier kunt u alle team aanvragen bekijken en beheren.",
};
