import * as actions from "./actions";

const initialState = {
	tokenError: "",
	tokenLoading: false,
	tokensAreLoaded: false
};

const tokenReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.FETCH_TOKEN_STARTED: {
			return {
				...state,
				tokenLoading: (state.tokenLoading = true)
			};
		}

		case actions.FETCH_TOKEN_SUCCESS: {
			return {
				...state,
				tokenLoading: (state.tokenLoading = false),
				tokensAreLoaded: true
			};
		}

		default: {
			return state;
		}
	}
};

export default tokenReducer;
