import React from 'react';
import TeamCardLogo from '../../../common/teamCard/teamCardLogo/teamCardLogo';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchTeamDetails } from '../../../store/allTeams/actions';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import TeamModal from '../../../common/teamCard/teamModal/teamModal';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import Slide from 'react-reveal/Slide';
import {
	fetchTeamDetails as fetchDetailsOnly,
	removeCurrentTeam
} from '../../../store/teams/actions';
import { FormattedMessage } from 'react-intl';

class CardComponent extends React.Component {
	state = {
		modelIsOpen: false,
		showMoreActions: false
	};

	getTeamNavLink = async (event, team) => {};

	_openModal = id => {
		this.setState({ modelIsOpen: true });
	};

	toggleMoreActions = async (e, team) => {
		this.setState({ showMoreActions: !this.state.showMoreActions });
		await this.props.fetchTeamDetails(this.props.team.id);
	};

	navigateToTeamOrGroup = () => {
		setTimeout(() => {
			let externalUrl = '';
			if (this.props.currentTeam != 'undefined') {
				// if the teamurl is filled we move to MS teams, otherwise we go to outlook.
				if (this.props.currentTeam.teamUrl != '') {
					externalUrl = this.props.currentTeam.teamUrl;
				} else {
					externalUrl = `https://outlook.office.com/owa/?path=/group/${this.props.team.mail}/mail`;
				}

				window.open(externalUrl, '_blank');
			}

			this.setState({ showMoreActions: !this.state.showMoreActions });
		}, 1000);
	};

	joinTeamLink = async () => {
		setTimeout(() => {
			let externalUrl = '';
			if (this.props.currentTeam != 'undefined') {
				// if the group is a team, then navigate to teams.
				if (this.props.currentTeam.teamUrl != '') {
					externalUrl = this.props.currentTeam.teamUrl;
					window.open(externalUrl, '_blank');
				}
				// if the group is a group, navigate to office to join
				else {
					externalUrl = `https://outlook.office365.com/owa/${this.props.currentTeam.mail}/groupsubscription.ashx?action=join`;
					window.open(externalUrl, '_blank');
				}
			}

			this.setState({ showMoreActions: !this.state.showMoreActions });
		}, 1000);
	};

	initActionButtons = (team, memberOfTeams) => {
		let JoinLink = '';

		let joinTeamLink = (
			<li onClick={() => this.joinTeamLink()}>
				<FormattedMessage id='teams.joinTeam' defaultMessage='Lid worden' />
			</li>
		);
		let viewLink = (
			<li onClick={() => this.navigateToTeamOrGroup()}>
				<FormattedMessage id='teams.viewTeam' defaultMessage='Bekijken' />
			</li>
		);
		let showViewLink = true;

		// check if the user is a member of the current team
		if (memberOfTeams.length > 0) {
			for (var i = 0; i < memberOfTeams.length; i++) {
				if (memberOfTeams[i].id === team.id) {
					joinTeamLink = '';
					break;
				}
			}

			if (joinTeamLink != '') {
				viewLink = '';
			}
		}

		const actionButtons = (
			<Slide right duration={500}>
				<div className='card-more-actions' id='slide'>
					<div className='card-more-actions__cancel'>
						<Icon
							iconName='ChromeClose'
							onClick={() => this.toggleMoreActions()}
						/>
					</div>
					<div>
						<ul>
							{joinTeamLink}
							{viewLink}
						</ul>
					</div>
				</div>
			</Slide>
		);

		return actionButtons;
	};

	_closeModal = () => {
		// this.props.removeCurrentTeamData();
		this.props.removeCurrentTeam();
		this.setState({ modelIsOpen: false });
	};

	render() {
		const { team, fetchTeamDetails, currentTeam, memberOfTeams } = this.props;

		let externalUrl = '';
		let showDisabled = false;
		let showJoinTeam = true;
		let moreActionsContent = '';

		if (team.isPrivate) {
			showDisabled = true;
		}

		// check whether we need to show the more action links or show the more buttons;
		if (!this.state.showMoreActions) {
			moreActionsContent = (
				<Icon
					iconName='ChevronLeft'
					className='card-more-icon'
					onClick={this.toggleMoreActions}
				/>
			);
		} else {
			moreActionsContent = this.initActionButtons(team, memberOfTeams);
		}

		return (
			<React.Fragment>
				<div className='allteam-logo' onClick={() => this._openModal()}>
					<div className='allteam-logo__center'>
						<TeamCardLogo teamId={team.id} injectClass='allteam-logo__image' />
					</div>
				</div>
				<div className='allteam-content' onClick={() => this._openModal()}>
					<div>
						<h1>
							{team.title}{' '}
							<span>
								(
								{team.isPrivate === false ? (
									<FormattedMessage
										id='teams.publicLabel'
										defaultMessage='Publiek'
									/>
								) : (
									<FormattedMessage
										id='teams.privateLabel'
										defaultMessage='Privé'
									/>
								)}
								)
							</span>{' '}
						</h1>
					</div>
					<p>{team.description}</p>
				</div>
				<div className='allteam-actions'>{moreActionsContent}</div>

				<Modal
					isOpen={this.state.modelIsOpen}
					onDismiss={this._closeModal}
					isBlocking={false}
					containerClassName='team-modal-wrapper'
					scrollableContentClassName='team-model-body'
				>
					<TeamModal
						teamId={team.id}
						title={team.title}
						description={team.description}
						favorite={team.isFavorite}
						members={team.members}
						closeModal={this._closeModal}
						openedFrom='allTeams'
						allTeamsIAmMemberOf={memberOfTeams}
					/>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	currentTeam: state.allTeamsSlice.currentTeam,
	memberOfTeams: state.teamsSlice.allMyTeams
});

export default connect(
	mapStateToProps,
	{ fetchTeamDetails, fetchDetailsOnly, removeCurrentTeam }
)(CardComponent);
