import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import CommandBar from "./commandBar/commandBar";
import SummarySection from "./summarySection/SummarySection";
import TeamsContainer from "../teamsContainer/teamsContainer";
import AllTeamsContainer from "../allTeamsContainer/AllTeamsContainer";
import ApplicationSettingsContainer from "../applicationSettingsContainer/ApplicationSettingscontainer";
import AppSettingsPage from "../applicationSettingsContainer/settingsTile/appSettingsPage/AppSettingsPage";
import ManageMetadataSettingsPage from "../applicationSettingsContainer/settingsTile/manageMetaDataPage/ManageMetadataSettingsPage";
import MyTeamRequestsContainer from "../teamRequests/myTeamRequestsContainer/MyTeamRequestsContainer";
import AllTeamRequestsContainer from "../teamRequests/allTeamRequestsContainer/AllTeamRequestsContainer";
import PrivateRoute from "../../common/privateRoute/PrivateRoute";
import {
	authorizeUserIsAdminFunction,
	authorizeUserIsAdminOrTeamsManagerFunction,
} from "../../assets/general_functions/authorizeFunctions";

class PageContainer extends React.Component {
	render() {
		// The status filters should be disabled in /allTeams because we can not yet support this functionality
		const hideStatusFilters = this.props.location.pathname === "/allTeams";

		return (
			<section className="main-page-content-wrapper">
				<div className="commandbar-wrapper">
					<CommandBar hideStatusFilters={hideStatusFilters} />
				</div>
				<div className="summary-wrapper">
					<SummarySection />
				</div>

				<div className="page-content">
					<Switch location={this.props.location}>
						<Route exact path="/allTeams" component={AllTeamsContainer} />
						<Route exact path="/teams" component={TeamsContainer} />
						<Route exact path="/myTeamRequests" component={MyTeamRequestsContainer} />
						<PrivateRoute
							exact
							path="/settings"
							component={ApplicationSettingsContainer}
							authorizeFunction={authorizeUserIsAdminOrTeamsManagerFunction}
							redirectTo="/teams"
							currentUser={this.props.currentUser}
						/>

						<PrivateRoute
							exact
							path="/settings/appSettings"
							component={AppSettingsPage}
							authorizeFunction={authorizeUserIsAdminFunction}
							redirectTo="/teams"
							currentUser={this.props.currentUser}
						/>
						<PrivateRoute
							exact
							path="/settings/managemetadata"
							component={ManageMetadataSettingsPage}
							authorizeFunction={authorizeUserIsAdminFunction}
							redirectTo="/teams"
							currentUser={this.props.currentUser}
						/>
						<PrivateRoute
							exact
							path="/settings/allTeamRequests"
							component={AllTeamRequestsContainer}
							authorizeFunction={authorizeUserIsAdminOrTeamsManagerFunction}
							redirectTo="/teams"
							currentUser={this.props.currentUser}
						/>

						<Route exact path="/" component={TeamsContainer} />
					</Switch>
				</div>
			</section>
		);
	}
}

export default withRouter(PageContainer);
