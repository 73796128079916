import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedDate, FormattedMessage } from "react-intl";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import * as teamActions from "../../../../../store/teams/actions";
import { logUserOpenedFile } from "../../../../../logging/applicationInsights/logFunctions";
import WithLoading from "../../../../utils/withLoading";

class FilesComponent extends React.Component {
    async componentDidMount() {
        if (typeof this.props.currentTeam.files === "undefined" || this.props.currentTeam.files.length === 0) {
            await this.props.fetchTeamFiles(this.props.teamId);
        }
    }

    createIconFromExtention = fileExt => {
        let iconName = "";

        if (fileExt === "jpg" || fileExt === "png" || fileExt === "jpeg" || fileExt === "gif") iconName = "FileImage";
        else if (fileExt === "doc" || fileExt === "docx") iconName = "WordDocument";
        else if (fileExt === "ppt" || fileExt === "pptx") iconName = "PowerPointDocument";
        else if (fileExt === "xls" || fileExt === "xlsx") iconName = "ExcelDocument";
        else if (fileExt === "one") iconName = "OneNoteLogo";
        else iconName = "Document";

        const iconClass = `file-icon ${iconName}`;
        return <Icon className={iconClass} iconName={iconName} />;
    };

    logOpenFile = fileName => {
        logUserOpenedFile(this.props.currentUser.userPrincipalName, this.props.currentUser.tenant.name, fileName);
    };

    render() {
        const filesAreLoading = this.props.teams.filesLoading;
        let files = [];

        this.props.currentTeam.hasOwnProperty("files");
        if (
            typeof this.props.currentTeam != "undefined" &&
            typeof this.props.currentTeam != "string" &&
            this.props.currentTeam != "" &&
            Object.keys(this.props.currentTeam).length > 0 &&
            this.props.currentTeam.hasOwnProperty("files") &&
            filesAreLoading === false
        ) {
            files = this.props.currentTeam.files.map(file => {
                const date = <FormattedDate value={file.modified} year="numeric" month="long" day="numeric" />;

                // we get the file extention and parse it into an Icon.
                if (file != undefined && file != "" && file.name != undefined) {
                    const fileExtPlace = file.name.lastIndexOf(".");
                    const fileExt = file.name.substr(fileExtPlace + 1, file.name.length);
                    const fileIcon = this.createIconFromExtention(fileExt);

                    return (
                        <li key={file.name}>
                            <div className="file-wrapper">
                                <div className="file-wrapper__icon-wrapper">{fileIcon}</div>
                                <div className="file-wrapper__filename">
                                    <a
                                        href={file.webUrl}
                                        target="_blank"
                                        onClick={fileName => this.logOpenFile(file.name)}
                                    >
                                        {file.name}
                                    </a>
                                </div>
                                <div className="file-wrapper__date">{date}</div>
                            </div>
                        </li>
                    );
                }
            });
        }

        // because files might take a long time we will use another spinner here instead of the
        // general team details spinner.

        if (
            (typeof this.props.currentTeam.files === "undefined" || this.props.currentTeam.files.length === 0) &&
            filesAreLoading === false
        ) {
            files = <FormattedMessage id="teamModalBody.filesTabNoFiles" defaultMessage="Er zijn geen bestanden..." />;
        } else if (filesAreLoading === true && this.props.detailsLoading === false) {
            files = <WithLoading isLoading={filesAreLoading} />;
        }

        return (
            <div>
                <ul>{files}</ul>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.teamsSlice.teamsError,
        loading: state.teamsSlice.loading,
        currentTeam: state.teamsSlice.currentTeam,
        teams: state.teamsSlice,
        detailsLoading: state.teamsSlice.detailsLoading,
        currentUser: state.currentUserSlice.currentUser
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTeamFiles: teamId => dispatch(teamActions.fetchTeamFiles(teamId))
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FilesComponent)
);
