import React, { Component } from "react";
import { connect } from "react-redux";

import { FormattedMessage } from "react-intl";

import Loader from "../../common/utils/withLoading";
import { objectToArray } from "../../assets/general_functions/objectsAndArrays";
import { arrayIsNullOrEmpty } from "../../assets/general_functions/typeCheckings";

import { fetchTeams, updateMyTeamsFromScroll } from "../../store/teams/actions";
import { removeFilter } from "../../store/filters/actions";
import TeamCard from "../../common/teamCard/teamCard";

import {
    parseApplicableFilters,
    parseApplicableTerms,
    parseTeamTerms,
    compareFilters,
    filterOnStatus,
    filterOnType
} from "../../assets/general_functions/filterFunctions";

class TeamsContainer extends Component {
    state = {
        scrolling: false,
        pageYPosition: 0
    };

    async componentDidMount() {
        await this.props.fetchTeams("myTeams");
    }

    handleScroll = () => {
        if (window.pageYOffset > this.state.pageYPosition + 50 || window.pageYOffset < this.state.pageYPosition - 50) {
            this.setState({ pageYPosition: window.pageYOffset });
        }

        const { scrolling } = this.state;
        if (scrolling) return;
        if (!arrayIsNullOrEmpty(this.props.teams)) {
            const lastTeam = document.querySelector(".team-card-wrapper:last-of-type");

            // get the height of the current team, and get the height of the current position on screen.
            if (lastTeam) {
                const lastTeamOffset = lastTeam.offsetTop + lastTeam.clientHeight;
                const pageOffset = window.pageYOffset + window.innerHeight;
                const bottomOffset = 30;

                if (pageOffset > lastTeamOffset - bottomOffset) {
                    // if all teams has more items then the teams that are currently in the list.
                    if (this.props.teams < this.props.allMyTeams) {
                        const teamsToAdd = this.props.allMyTeams.slice(
                            this.props.teams.length,
                            this.props.teams.length + 50
                        );

                        let allTeams = this.props.teams;
                        allTeams = allTeams.concat(teamsToAdd);

                        this.props.updateMyTeamsFromScroll(allTeams);
                        this.loadMore();

                        setTimeout(() => {
                            this.setState({ scrolling: false });
                        }, 1500);
                    } else window.removeEventListener("scroll", this.handleScroll);
                }
            }
        }
    };

    loadMore = () => {
        this.setState({
            scrolling: true
        });
    };

    applyFilters(teams) {
        let applicableTermSets = [];
        let filteredTeams = [];

        // create a list with applicableFilters
        applicableTermSets = parseApplicableFilters(applicableTermSets, this.props.activeFilters);

        // now that we have the applicableFilterList we sort all terms inside the termsets.
        parseApplicableTerms(applicableTermSets, this.props.activeFilters);

        // loop over the teams and create an object with the filters in it as an array.
        teams.filter(team => {
            team = parseTeamTerms(team, team.terms);
        });

        // see if the length of the team filters is at least as long as the applicableFilters
        teams.filter(team => {
            const filteredTeam = compareFilters(team, applicableTermSets);

            if (filteredTeam != "" && typeof filteredTeam != "undefined") {
                filteredTeams.push(filteredTeam);
            }
        });

        return filteredTeams;
    }

    onCancelFilterClick = termId => {
        this.props.onRemoveFilter(termId);
    };

    render() {
        // offset the scroll to 125px from the top.
        if (this.state.pageYPosition === 0) {
            this.setState({ pageYPosition: 125 });
        }

        let error = "";

        this.scrollListener = window.addEventListener("scroll", this.handleScroll);
        let teams = this.props.teams;
        let noSearchResults = "";
        let filterSummary = "";
        let activeStatusFilters = [];
        let activeTypeFilters = [];

        // the application is loading by default, until the first 50 teams are available.
        let teamsAreLoading = <Loader isLoading={true} />;

        if (!arrayIsNullOrEmpty(this.props.teams)) {
            teamsAreLoading = <Loader isLoading={false} />;
        }

        let cancelableFilters = this.props.activeFilters.map(filter => {
            const titleText = `${filter.termSetName} - ${filter.termName} `;
            return (
                <span key={filter.termId}>
                    <div className="filter-cancel-block__text" title={titleText}>
                        {filter.termName}
                    </div>
                </span>
            );
        });

        // Render the active status filters if there are any.
        if (typeof this.props.activeStatusFilters !== "undefined" && this.props.activeStatusFilters.length > 0) {
            activeStatusFilters = this.props.activeStatusFilters.map(filter => {
                return (
                    <span key={filter.statusCode}>
                        <div className="filter-cancel-block__text">
                            <FormattedMessage id={filter.intlId} defaultMessage={filter.defaultMessage} />
                        </div>
                    </span>
                );
            });
        }

        // Render the active type filters if there are any
        if (this.props.activeTypeFilters !== "undefined" && this.props.activeTypeFilters.length > 0) {
            activeTypeFilters = this.props.activeTypeFilters.map(filter => {
                return (
                    <span key={filter.id}>
                        <div className="filter-cancel-block__text">{filter.name}</div>
                    </span>
                );
            });
        }

        if (cancelableFilters.length > 0 || activeStatusFilters.length > 0 || activeTypeFilters.length > 0) {
            filterSummary = (
                <div className="filter-summary">
                    <FormattedMessage id="teams.activeFilters" defaultMessage="Actieve filters." />:{" "}
                    {activeStatusFilters} {activeTypeFilters} {cancelableFilters}
                </div>
            );
        } else {
            filterSummary = <div className="no-filters-spacer" />;
        }

        // if allmyteams in redux is empty, fetch all teams to search over.
        // note that the searchbar doesn't have to do this as focusing on the searchbar will
        // fetch all the teams.

        if (this.props.searchTerm != "" || this.props.activeFilters.length > 0) {
            teams = this.props.allMyTeams;
            teams = objectToArray(teams);
        }

        // when a search term is defined, apply it on the teams.
        if (this.props.searchTerm != "" && typeof this.props.searchTerm != "undefined") {
            teams = teams.filter(team => {
                if (team.title && team.description) {
                    if (
                        team.title.toLowerCase().indexOf(this.props.searchTerm) != -1 ||
                        team.description.toLowerCase().indexOf(this.props.searchTerm) != -1
                    ) {
                        return team;
                    }
                } else if (team.title) {
                    if (team.title.toLowerCase().indexOf(this.props.searchTerm) != -1) {
                        return team;
                    }
                }
            });
        }

        // If a status filter has been selected, filter on those.
        if (typeof this.props.activeStatusFilters !== "undefined" && this.props.activeStatusFilters.length > 0) {
            teams = filterOnStatus(teams, this.props.activeStatusFilters);
        }

        // If a type filter has been selected, filter on those.
        if (typeof this.props.activeTypeFilters !== "undefined" && this.props.activeTypeFilters.length > 0) {
            teams = filterOnType(teams, this.props.activeTypeFilters);
        }

        // when a filter has been set, apply them on the teams.
        if (this.props.activeFilters.length > 0 && typeof this.props.activeFilters !== "undefined") {
            const filteredTeams = this.applyFilters(teams);
            teams = filteredTeams;
        }

        if (teams) {
            // order the teams first by favorite and then by name
            let sortBy = [
                {
                    prop: "isFavorite",
                    direction: -1
                },
                {
                    prop: "title",
                    direction: 1
                }
            ];

            teams = objectToArray(teams);

            teams = teams.map(team => {
                let isFavoriteTeam = team.isFavorite;

                if (this.props.toggleData.teamId === team.id) {
                    isFavoriteTeam = this.props.toggleData.isFavorite;
                }

                // allows to show multiple mark as favorite teams without reloading
                for (let newFavTeam of this.props.favoriteTeamsArray) {
                    if (team.id === newFavTeam) {
                        isFavoriteTeam = true;
                    }
                }

                // and check if all favorites have been disabled
                for (let oldNavTeam of this.props.nonFavoriteTeamsArray) {
                    if (team.id === oldNavTeam) {
                        isFavoriteTeam = false;
                    }
                }

                if (team != false) {
                    return (
                        <TeamCard
                            // delay={delay}
                            id={team.id}
                            title={team.title}
                            description={team.description}
                            isFavorite={isFavoriteTeam}
                            isPrivate={team.isPrivate}
                            memberCount={team.memberCount}
                            key={team.id}
                            pageYPosition={this.state.pageYPosition}
                            isArchived={team.isArchived}
                            teamStatus={team.teamStatus}
                        />
                    );
                }
            });

            this.scrollListener = window.addEventListener("scroll", this.handleScroll);
        }

        if ((typeof teams === "undefined" || teams.length === 0) && this.props.loadingTeams === false) {
            noSearchResults = (
                <div className="team-cards-wrapper" style={{ margin: "20px" }}>
                    <h1>
                        <FormattedMessage id="teams.noSearchResults" defaultMessage="Er zijn geen zoek resultaten." />
                    </h1>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="App">
                    <div className="team-cards-wrapper">
                        {filterSummary}
                        {error}
                        {teams}
                        {noSearchResults}
                        {teamsAreLoading}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.teamsSlice.teamsError,
        loading: state.teamsSlice.loading,
        teams: state.teamsSlice.teams,
        uiNextLink: state.teamsSlice.uiNextLink,
        allMyTeams: state.teamsSlice.allMyTeams,
        searchTerm: state.teamsSlice.searchTerm,
        filteredTeams: state.teamsSlice.filteredTeams,
        activeFilters: state.filtersSlice.activeFilters,
        activeStatusFilters: state.filtersSlice.activeStatusFilters,
        activeTypeFilters: state.filtersSlice.activeTypeFilters,
        toggleData: state.teamsSlice.toggleData,
        moreTeamsLoading: state.teamsSlice.moreTeamsLoading,
        loadingTeams: state.teamsSlice.loadingTeams,
        favoriteTeamsArray: state.teamsSlice.newFavorites,
        nonFavoriteTeamsArray: state.teamsSlice.newNonFavorites
    };
};

export default connect(
    mapStateToProps,
    { fetchTeams, removeFilter, updateMyTeamsFromScroll }
)(TeamsContainer);
