import { AuthenticationContext, adalFetch } from 'react-adal';

export const adalConfig = {
	instance: 'https://login.microsoftonline.com/',
	clientId: process.env.REACT_APP_CLIENT_ID,
	extraQueryParameter: 'nux=1',
	endpoints: {
		graphApi: 'https://graph.microsoft.com',
		oneApi: process.env.REACT_APP_ONE_API_RESOURCE_ID
	},
	postLogoutRedirectUri: window.location.origin,
	redirectUri: window.location.origin,
	cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalGraphFetch = (fetch, url, options) =>
	adalFetch(authContext, adalConfig.endpoints.graphApi, fetch, url, options);

export const adalOneApiFetch = (fetch, url, options) =>
	adalFetch(authContext, adalConfig.endpoints.oneApi, fetch, url, options);

export const getToken = () => {
	return authContext.getCachedToken(authContext.config.endpoints.oneApi);
};

export const getGraphToken = () => {
	return authContext.getCachedToken('https://graph.microsoft.com');
};

export const acquireGraphToken = async () => {
	await authContext.acquireToken(
		adalConfig.endpoints.graphApi,
		(message, token, msg) => {
			return token;
		}
	);

	return null;
};

export const acquireToken = async () => {
	let tokenToSend = '';
	await authContext.acquireToken(
		adalConfig.endpoints.oneApi,
		(message, token, msg) => {
			tokenToSend = token;
			return token;
		}
	);

	return tokenToSend;
};
