import React from "react";
import { connect } from "react-redux";
import App from "../app/App";
import SubscriptionActions from "../../common/pages/subscriptionActions";
import { Route, Switch } from "react-router-dom";
import { adalOneApiFetch } from "../../adalConfig";
import { SubscriptionStatus } from "@one/core";

import * as userActions from "../../store/users/actions";
import * as tokenActions from "../../store/tokens/actions";

import messages_en from "../../assets/translations/translations_en";
import messages_nl from "../../assets/translations/translations_nl";

import { IntlProvider } from "react-intl";

class CheckTenantHasAccess extends React.Component {
	state = {
		subscriptionType: SubscriptionStatus.None,
		subscriptionErrorMessage: "",
		hasChecked: false,
	};

	async componentDidMount() {
		await this.props.onFetchCurrentUser();
		await this.props.onFetchTokens();

		const response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/checkSubscription`);

		const calculateStatus = (jsonResponse) => {
			if (response.status === 200) {
				if (jsonResponse.currentStatus === SubscriptionStatus.Active) {
					return { subscriptionStatus: SubscriptionStatus.Active };
				}
			}

			if (jsonResponse.subscriptionType === null) {
				return { subscriptionStatus: SubscriptionStatus.None, subscriptionError: "You don't have a valid subscription" };
			} else if (new Date(jsonResponse.endDate) < new Date() || new Date(jsonResponse.startDate) > new Date()) {
				return { subscriptionStatus: SubscriptionStatus.Inactive, subscriptionError: "Your subscription has expired." };
			} else {
				return { subscriptionStatus: SubscriptionStatus.Error, subscriptionError: "Could not check your subscription." };
			}
		};

		const subscriptionResult = calculateStatus(await response.json());

		this.setState({
			subscriptionType: subscriptionResult.subscriptionStatus,
			subscriptionErrorMessage: subscriptionResult.subscriptionError,
			hasChecked: true,
		});
	}

	render() {
		const messages = {
			nl: messages_nl,
			en: messages_en,
		};
		let content = "";
		// if the tenantsubscription check has been performed once, the [stateWasUpdates] was set.
		// now the app knows to which route to redirect.
		if (this.state.hasChecked === true) {
			content = (
				<Switch>
					{this.state.subscriptionType === SubscriptionStatus.Active ? (
						<Route component={App} />
					) : (
						<Route
							render={(props) => (
								<IntlProvider locale="nl" messages={messages["nl"]}>
									<SubscriptionActions
										{...props}
										currentUser={this.props.currentUser}
										subscriptionType={this.state.subscriptionType}
										subscriptionErrorMessage={this.state.subscriptionErrorMessage}
									/>
								</IntlProvider>
							)}
						/>
					)}
					;
				</Switch>
			);
		}

		return <React.Fragment>{content}</React.Fragment>;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUserSlice.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCurrentUser: () => dispatch(userActions.fetchCurrentUser()),
		onFetchTokens: () => dispatch(tokenActions.fetchRequest()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckTenantHasAccess);
