import * as actions from './actions';
import * as filterActions from '../filters/actions';
import {
	objectToArray,
} from '../../assets/general_functions/objectsAndArrays';

const initialState = {
	teamsError: null,
	loading: false,
	detailsLoading: false,
	usersLoading: false,
	plansLoading: false,
	channelsLoading: false,
	metaDataUpdating: false,
	metaDataUpdated: false,
	needsToResetUpdateMessage: false,
	toggleData: '',
	teams: [],
	searchTerm: '',
	currentTeam: '',
	filteredTeams: [],
	allMyTeams: [],
	allTeams: [],
	teamTypes: [],
	uiNextLink: '',
	filesLoading: false,
	moreTeamsLoading: false,
	loadingTeams: false,
	newFavorites: [],
	newNonFavorites: [],
	teamArchivedLoading: false,
	notification: {
        isOpen: false,
        isSuccess: false,
		code: null // notification codes: 0: Archive team success, 1: archive team failed
	},
	archiveNeedsToUpdate: false
};

const teamsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_TEAMS_STARTED: {
			return {
				...state,
				loading: (state.loading = true),
				loadingTeams: true
			};
		}

		case actions.FETCH_TEAMS_SUCCESS: {
			const teams = objectToArray(action.payload.teams);
			const nextLink = action.payload.nextLink;

			return {
				...state,
				loading: false,
				teams: teams,
				uiNextLink: nextLink,
				loadingTeams: false
			};
		}

		case actions.FETCH_TEAMS_FAILURE: {
			const error = action.payload.error;

			return {
				...state,
				loading: false,
				error: (state.error = error),
				loadingTeams: false
			};
		}

		case filterActions.SAVE_FILTERS: {
			const filters = action.payload.filters;
			const teamsToFilter = objectToArray(action.payload.teamsToFilter);
			const searchType = action.payload.searchType;

			const applicableFilters = filters.map(filter => {
				return filter.termId;
			});

			const filteredTeams = [];

			teamsToFilter.filter(team => {
				// check if the team already exists in the array.
				filteredTeams.filter(existingTeam => {
					if (existingTeam.id === team.id) {
						return null;
					}
				});

				if (team.terms.length > 0 && typeof team.terms !== 'undefined') {
					team.terms.filter(teamTerm => {
						if (applicableFilters.indexOf(teamTerm.id) !== -1) {
							filteredTeams.push(team);
						}
					});
				}
			});

			return {
				...state,
				filteredTeams: filteredTeams
			};
		}

		case filterActions.REMOVE_FILTER: {
			return {
				...state,
				teams: []
			};
		}

		case actions.SET_TEAMS_SEARCH_TERM: {
			const searchTerm = action.payload.term.toLowerCase();

			return {
				...state,
				searchTerm: action.payload.term.toLowerCase()
			};
		}

		case actions.FETCH_MORE_TEAMS_STARTED: {
			return {
				...state,
				moreTeamsLoading: true
			};
		}

		case actions.FETCH_MORE_TEAMS_SUCCESS: {
			let nextLink = '';

			// merge the two arrays together
			const teams = objectToArray(action.payload);
			const oldTeams = state.teams;

			const allTeams = oldTeams.concat(teams);

			if (action.nextPage != '') {
				nextLink = `${process.env.REACT_APP_ONE_API_URL}/api${action.nextPage}`;
			} else {
				nextLink = action.nextPage;
			}

			return {
				...state,
				teams: (state.teams = allTeams),
				uiNextLink: nextLink,
				moreTeamsLoading: false
			};
		}

		case actions.FETCH_TEAM_DETAILS: {
			return {
				...state,
				detailsLoading: true
			};
		}

		case actions.FETCH_TEAM_DETAILS_STARTED: {
			return {
				...state,
				detailsLoading: true
			};
		}

		case actions.REMOVE_CURRENT_TEAM: {
			return {
				...state,
				currentTeam: ''
			};
		}

		case actions.FETCH_MORE_TEAMS_FAILED: {
			const error = action.payload.error;

			return {
				...state,
				loading: false,
				error: (state.error = error),
				moreTeamsLoading: false
			};
		}

		case actions.FETCH_TEAM_DETAILS_FAILURE: {
			return {
				...state,
				teamsError: action.payload.error,
				detailsLoading: false
			};
		}

		case actions.FETCH_TEAM_DETAILS_SUCCESS: {
			const keepMessage = action.payload.keepMessage;
			const metaDataUpdated = action.payload.metaDataUpdated;

			let oldFiles = [];
			let oldPlans = [];
			let oldUsers = [];
			let oldChannels = [];

			if (state.currentTeam.hasOwnProperty('files')) {
				oldFiles = state.currentTeam.files;
			}

			if (state.currentTeam.hasOwnProperty('users')) {
				oldFiles = state.currentTeam.users;
			}
			if (state.currentTeam.hasOwnProperty('plans')) {
				oldFiles = state.currentTeam.plans;
			}

			if (state.currentTeam.hasOwnProperty('plans')) {
				oldChannels = state.currentTeam.channels;
			}

			action.payload.result.files = oldFiles;
			action.payload.result.users = oldUsers;
			action.payload.result.plans = oldPlans;
			action.payload.result.channels = oldChannels;

			let newCurrentTeam = { ...action.payload.result };
			newCurrentTeam.metaDataUpdating = false;

			if (keepMessage) {
				newCurrentTeam.needsToResetUpdateMessage = true;
			}

			if (metaDataUpdated) {
				newCurrentTeam.metaDataUpdated = true;
			}

			return {
				...state,
				detailsLoading: false,
				currentTeam: newCurrentTeam
			};
		}
		case actions.FETCH_ALL_TEAMS_STARTED: {
			return {
				...state,
				loading: true
			};
		}

		case actions.FETCH_ALL_TEAMS_SUCCESS: {
			const teams = objectToArray(action.payload);
			const fetchType = action.fetchType;

			if (fetchType === 'private') {
				return {
					...state,
					loading: false,
					allMyTeams: (state.allMyTeams = teams)
				};
			} else if (fetchType === 'public') {
				return {
					...state,
					loading: false,
					allTeams: (state.allTeams = teams)
				};
			}
			return state;
		}

	

		case actions.FETCH_TEAM_FILES_STARTED: {
			return {
				...state,
				filesLoading: true
			};
		}

		case actions.FETCH_TEAM_FILES_FAILED: {
			const error = action.payload.error;

			return {
				...state,
				teamsError: error,
				detailsLoading: false,
				filesLoading: false
			};
		}

		case actions.FETCH_TEAM_FILES_SUCCESS: {
			const newCurrentTeam = { ...state.currentTeam };
			newCurrentTeam.files = action.payload.result;
			
			return {
				...state,
				currentTeam: newCurrentTeam,
				detailsLoading: false,
				filesLoading: false
			};
		}

		case actions.FETCH_TEAM_DETAILS_STARTED: {
			return {
				...state,
				detailsLoading: true
			};
		}

		case actions.FETCH_TEAM_USERS_FAILED: {
			const error = action.payload.error;

			return {
				...state,
				teamsError: error,
				usersLoading: false
			};
		}

		case actions.FETCH_TEAM_USERS_SUCCESS: {
			const newCurrentTeam = { ...state.currentTeam };
			newCurrentTeam.users = action.payload.result;

			return {
				...state,
				currentTeam: newCurrentTeam,
				usersLoading: false
			};
		}

		case actions.FETCH_TEAM_USERS_STARTED: {
			return {
				...state,
				usersLoading: true
			};
		}

		case actions.FETCH_TEAM_PLANS_FAILED: {
			const error = action.payload.error;

			return {
				...state,
				teamsError: error,
				plansLoading: false
			};
		}

		case actions.FETCH_TEAM_PLANS_SUCCESS: {
			const newCurrentTeam = { ...state.currentTeam };
			newCurrentTeam.plans = action.payload.result;

			return {
				...state,
				currentTeam: newCurrentTeam,
				plansLoading: false
			};
		}

		case actions.FETCH_TEAM_PLANS_STARTED: {
			return {
				...state,
				plansLoading: true
			};
		}

		case actions.FETCH_TEAM_CHANNELS_STARTED: {
			return {
				...state,
				channelsLoading: true
			};
		}

		case actions.FETCH_TEAM_CHANNELS_SUCCESS: {
			const newCurrentTeam = { ...state.currentTeam };
			newCurrentTeam.channels = action.payload.result;

			return {
				...state,
				currentTeam: newCurrentTeam,
				channelsLoading: false
			};
		}

		case actions.FETCH_TEAM_CHANNELS_FAILED: {
			const error = action.payload.error;

			return {
				...state,
				teamsError: error,
				channelsLoading: false
			};
		}

		case actions.TOGGLE_FAVORITE_SUCCESS: {
			const toggleStatus = action.payload.result;

			let newFavorite = toggleStatus.teamId;
			let newFavorites = [...state.newFavorites];
			let NonFavorites = [...state.newNonFavorites];

			const teamName = state.teams.filter(team => {
				if (team.id === toggleStatus.teamId) {
					return team;
				}
			});

			toggleStatus.title = teamName[0].title;

			// add an item from the array.
			if (toggleStatus.isFavorite === true) {
				newFavorites.push(newFavorite);

				const newUpdatedNonFavorites = [];
				for (let teamId of NonFavorites) {
					if (teamId !== newFavorite) {
						newUpdatedNonFavorites.push(teamId);
					}
				}

				NonFavorites = newUpdatedNonFavorites;
			}
			// remove an item to the array.
			else {
				// if the item exists in the newToAddArray
				const updatedNewFavorites = [];
				for (let teamId of newFavorites) {
					if (teamId !== newFavorite) {
						updatedNewFavorites.push(teamId);
					}
				}

				// if the item does not exist in the array, we want
				// to make sure to add it to the newNonFavourite array.
				const UpdatedNonFavorites = [];
				if (toggleStatus.isFavorite === false) {
					NonFavorites.push(toggleStatus.teamId);
				}

				newFavorites = updatedNewFavorites;
			}

			return {
				...state,
				toggleData: toggleStatus,
				newFavorites: newFavorites,
				newNonFavorites: NonFavorites
			};
		}

		case actions.TOGGLE_FAVORITE_STARTED: {
			return {
				...state
			};
		}

		case actions.TOGGLE_FAVORITE_FAILED: {
			const error = action.payload.error;

			return {
				...state,
				teamsError: error
			};
		}

		case actions.UPDATE_METADATA_STARTED: {
			return {
				...state,
				metaDataUpdating: true
			};
		}
		case actions.UPDATE_METADATA_INFOBAR: {
			let newCurrentTeam = { ...state.currentTeam };
			newCurrentTeam.reloadData = false;
			newCurrentTeam.metaDataUpdated = false;
			newCurrentTeam.metaDataUpdating = false;

			return {
				...state,
				currentTeam: newCurrentTeam
			};
		}

		case actions.UPDATE_METADATA_SUCCESS: {
			const wasSuccesful = action.payload.result;
			let newCurrentTeam = { ...state.currentTeam };

			if (wasSuccesful) {
				newCurrentTeam.needsToResetUpdateMessage = false;
				newCurrentTeam.metaDataUpdated = true;
				newCurrentTeam.metaDataUpdating = false;
				newCurrentTeam.reloadData = true;
			} else {
				newCurrentTeam.needsToResetUpdateMessage = true;
				newCurrentTeam.metaDataUpdated = false;
				newCurrentTeam.metaDataUpdating = false;
				newCurrentTeam.reloadData = false;
			}

			return {
				...state,
				currentTeam: newCurrentTeam
			};
		}

		


		
		case actions.UPDATE_MYTEAMS_FIRST_BATCH: {
			return {
				...state,
				moreTeamsLoading: false,
				teams: action.payload.teams
			};
		}
		
		case actions.SET_ARCHIVE: {
			return {
				...state,
				teamArchivedLoading: true
			};
		}


		case actions.SET_ARCHIVE_SUCCESS: {
			return {
				...state,
				teamArchivedLoading: false,
				archiveNeedsToUpdate: true,
				notification: {
					...state.notification,
					isOpen: true,
					isSuccess: true,
					code: 0
				}
			};
		}

		case actions.SET_ARCHIVE_FAILED: {
			return {
				...state,
				teamArchivedLoading: false,
				notification: {
					...state.notification,
					isOpen: true,
					isSuccess: false,
					code: 1
				}
			};
		}

		case actions.CLEAR_TEAM_NOTIFICATION: {
			return {
				...state,
				notification: initialState.notification
			};
		}

		case actions.CLEAR_TEAM_ARCHIVE_UPDATE: {
			return {
				...state,
				archiveNeedsToUpdate: false
			};
		}

		default: {
			return state;
		}
	}
};

export default teamsReducer;
