import { adalOneApiFetch, adalGraphFetch } from "../../adalConfig";

export const CHECK_IF_TEAM_NAME_FAILURE = "CHECK_IF_TEAM_NAME_FAILURE";
export const CHECK_IF_TEAM_NAME_SUCCESS = "CHECK_IF_TEAM_NAME_SUCCESS";

export const FETCH_TEAM_REQUESTS = "FETCH_TEAM_REQUESTS";
export const FETCH_TEAM_REQUESTS_SUCCESS = "FETCH_TEAM_REQUESTS_SUCCESS";
export const FETCH_TEAM_REQUESTS_FAILURE = "FETCH_TEAM_REQUESTS_FAILURE";

export const CREATE_TEAM_REQUEST_SUCCESS = "CREATE_TEAM_REQUEST_SUCCESS";
export const CREATE_TEAM_REQUEST_FAILURE = "CREATE_TEAM_REQUEST_FAILURE";
export const CLEAR_CREATE_TEAM_REQUEST_STATUS = "CLEAR_CREATE_TEAM_REQUEST_STATUS";

export const REMOVE_TEAM_REQUEST = "REMOVE_TEAM_REQUEST";
export const REMOVE_TEAM_REQUEST_SUCCESS = "REMOVE_TEAM_REQUEST_SUCCESS";
export const REMOVE_TEAM_REQUEST_FAILURE = "REMOVE_TEAM_REQUEST_FAILURE";
export const RESET_TEAM_REQUEST_STATUS = "RESET_TEAM_REQUEST_STATUS";

export const APPROVE_TEAM_REQUEST_SUCCESS = "APPROVE_TEAM_REQUEST_SUCCESS";
export const APPROVE_TEAM_REQUEST_FAILURE = "APPROVE_TEAM_REQUEST_FAILURE";

export const REJECT_TEAM_REQUEST_SUCCESS = "REJECT_TEAM_REQUEST_SUCCESS";
export const REJECT_TEAM_REQUEST_FAILURE = "REJECT_TEAM_REQUEST_FAILURE";

export const closeNotificationAlert = () => {
	return {
		type: RESET_TEAM_REQUEST_STATUS,
	};
};

// --== Fetch Team Request ==--
export const fetchTeamRequests = (source) => {
	return (dispatch) => {
		dispatch(fetchTeamRequestsCall(source));
	};
};

const fetchTeamRequestsCall = (source) => {
	return (dispatch) => {
		dispatch(fetchTeamRequestsStarted(source));
		let myOrAllRequests = "/me";
		// If the source of the request is AllTeamContainer, get all the provisioning requests.
		if (source === "AllTeamContainer") {
			myOrAllRequests = "";
		}

		adalOneApiFetch(
			fetch,
			`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/${myOrAllRequests}/provisioningrequests/`,
			{}
		)
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchTeamRequestsFailure("fout"));
					return;
				}

				response.json().then((result) => {
					dispatch(fetchTeamRequestsSuccess(result, source));
				});
			})
			.catch((error) => {
				dispatch(fetchTeamRequestsFailure(error));
			});
	};
};

const fetchTeamRequestsSuccess = (request, source) => {
	return {
		type: FETCH_TEAM_REQUESTS_SUCCESS,
		payload: {
			request,
			source,
		},
	};
};
const fetchTeamRequestsFailure = (error) => {
	return {
		type: FETCH_TEAM_REQUESTS_FAILURE,
		payload: {
			error,
		},
	};
};

const fetchTeamRequestsStarted = (source) => ({
	type: FETCH_TEAM_REQUESTS,
	payload: {
		source,
	},
});

// --== Create Team Request ==--
export const createTeamRequest = (teamRequestObj) => {
	return (dispatch) => {
		dispatch(createTeamRequestCall(teamRequestObj));
	};
};

export const clearCreateTeamRequestStatus = () => {
	return {
		type: CLEAR_CREATE_TEAM_REQUEST_STATUS,
	};
};

const createTeamRequestCall = (teamRequestObj) => {
	return (dispatch) => {
		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/provisioningrequests/`, {
			method: "POST",
			body: JSON.stringify(teamRequestObj),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204 && response.status != 201) {
					dispatch(createTeamRequestFailed("fout"));
					return;
				}

				response.json().then((result) => {
					dispatch(createTeamRequestSuccess(result));
				});
			})
			.catch((error) => {
				dispatch(createTeamRequestFailed(error));
			});
	};
};

const createTeamRequestSuccess = (teamRequestObj) => {
	return {
		type: CREATE_TEAM_REQUEST_SUCCESS,
		payload: {
			teamRequestObj,
		},
	};
};

const createTeamRequestFailed = (error) => {
	return {
		type: CREATE_TEAM_REQUEST_FAILURE,
		payload: {
			statusCode: 3,
		},
	};
};

export const checkIfTeamMailNameExists = (mail, approvalRequired) => {
	return (dispatch) => {
		dispatch(checkIfTeamExists(mail, approvalRequired));
	};
};

export const checkIfTeamExists = (mailNickname, approvalRequired) => {
	return (dispatch) => {
		adalGraphFetch(fetch, `https://graph.microsoft.com/v1.0/groups?$filter=mailNickname eq '${mailNickname}'`, {})
			.then((response) => {
				if (response.status != 200 && response.status != 204) {
					dispatch(checkIfTeamExistsFailure("fout"));
					return;
				}

				response.text().then((result) => {
					result = JSON.parse(result);
					dispatch(checkIfTeamExistsSuccess(result, approvalRequired));
				});
			})
			.catch((error) => {
				dispatch(checkIfTeamExistsFailure("fout"));
				return;
			});
	};
};

const checkIfTeamExistsSuccess = (team, approvalRequired) => ({
	type: CHECK_IF_TEAM_NAME_SUCCESS,
	payload: {
		team,
		approvalRequired,
	},
});

const checkIfTeamExistsFailure = (error) => ({
	type: CHECK_IF_TEAM_NAME_FAILURE,
	payload: {
		error,
	},
});

// --== Approve Team Request ==--
export const approveTeamRequest = (teamRequestId) => {
	return (dispatch) => {
		dispatch(approveTeamRequestCall(teamRequestId));
	};
};

const approveTeamRequestCall = (teamRequestId) => {
	return (dispatch) => {
		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/provisioningrequests/`, {
			method: "PATCH",
			body: JSON.stringify({
				id: teamRequestId,
				status: 1,
				statusReason: "Approved",
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(approveTeamRequestFailure(teamRequestId));
					return;
				}

				response.json().then((result) => {
					dispatch(approveTeamRequestSuccess(teamRequestId));
				});
			})
			.catch((error) => {
				dispatch(approveTeamRequestFailure(teamRequestId));
			});
	};
};

const approveTeamRequestSuccess = (itemId) => {
	return {
		type: APPROVE_TEAM_REQUEST_SUCCESS,
		payload: {
			itemId,
		},
	};
};
const approveTeamRequestFailure = (error) => {
	return {
		type: APPROVE_TEAM_REQUEST_FAILURE,
		payload: {
			error,
		},
	};
};

// --== Remove Team Request ==--
export const removeTeamRequest = (teamRequestId) => {
	return (dispatch) => {
		dispatch(removeTeamRequestCall(teamRequestId));
	};
};

const removeTeamRequestCall = (teamRequestId) => {
	return (dispatch) => {
		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/provisioningrequests/`, {
			method: "PATCH",
			body: JSON.stringify({
				id: teamRequestId,
				status: 6,
				statusReason: "Deleted",
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(removeTeamRequestFailure(teamRequestId));
					return;
				}

				response.json().then((result) => {
					dispatch(removeTeamRequestSuccess(teamRequestId));
				});
			})
			.catch((error) => {
				dispatch(removeTeamRequestFailure(error));
			});
	};
};

const removeTeamRequestSuccess = (itemId) => {
	return {
		type: REMOVE_TEAM_REQUEST_SUCCESS,
		payload: {
			itemId,
		},
	};
};
const removeTeamRequestFailure = (error) => {
	return {
		type: REMOVE_TEAM_REQUEST_FAILURE,
		payload: {
			error,
		},
	};
};

// --== Reject Team Request ==--
export const rejectTeamRequest = (teamRequestId) => {
	return (dispatch) => {
		dispatch(rejectTeamRequestCall(teamRequestId));
	};
};

const rejectTeamRequestCall = (teamRequestId) => {
	return (dispatch) => {
		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/provisioningrequests/`, {
			method: "PATCH",
			body: JSON.stringify({
				id: teamRequestId,
				status: 2,
				statusReason: "Rejected",
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(rejectTeamRequestFailure(teamRequestId));
					return;
				}

				response.json().then((result) => {
					dispatch(rejectTeamRequestSuccess(teamRequestId));
				});
			})
			.catch((error) => {
				dispatch(rejectTeamRequestFailure(error));
			});
	};
};

const rejectTeamRequestSuccess = (itemId) => {
	return {
		type: REJECT_TEAM_REQUEST_SUCCESS,
		payload: {
			itemId,
		},
	};
};
const rejectTeamRequestFailure = (error) => {
	return {
		type: REJECT_TEAM_REQUEST_FAILURE,
		payload: {
			error,
		},
	};
};
