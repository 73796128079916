import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { getImage } from "../../../HttpRepositories/graphRepository";

class TeamCardImage extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
	}

	state = {
		groupImage: ""
	};

	getLogo = () => {};

	async componentDidMount() {
		this._isMounted = true;

		let logo = await getImage(`https://graph.microsoft.com/beta/groups/${this.props.teamId}/photo/$value`);

		if (this._isMounted && logo !== "error") {
			this.setState({ groupImage: logo });
		}
	}

	render() {
		let injectedClassName = this.props.injectClass != "" ? this.props.injectClass : "";
		let headerPhoto = "";
		const groupIcon = (
			<div className="team-card-wrapper__header-photo-alt">
				<Icon iconName="Group" />
			</div>
		);
		if (this.state.groupImage === "") {
			headerPhoto = groupIcon;
		} else {
			headerPhoto = <img src={this.state.groupImage} alt=" " />;
		}

		return (
			<React.Fragment>
				<div className={injectedClassName}>{headerPhoto}</div>
			</React.Fragment>
		);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
}

export default TeamCardImage;
