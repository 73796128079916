import {
	FETCH_SETTINGS,
	FETCH_SETTINGS_SUCCESS,
	FETCH_SETTINGS_FAILURE,
	UPDATE_USER_SETTINGS,
	UPDATE_USER_SETTINGS_FAILURE,
	UPDATE_USER_SETTINGS_SUCCESS,
	UPDATE_APPLICATION_SETTINGS,
	UPDATE_APPLICATION_SETTINGS_FAILURE,
	UPDATE_APPLICATION_SETTINGS_SUCCESS,
	CLOSE_APPICATION_SETTINGS_ALERT,
	CLOSE_USER_SETTINGS_ALERT,
	FETCH_GROUP_SETTINGS,
	FETCH_GROUP_SETTINGS_SUCCESS,
	FETCH_GROUP_SETTINGS_FAILURE,
	FETCH_GROUP_SETTINGS_BY_ID,
	FETCH_GROUP_SETTINGS_BY_ID_SUCCESS,
	FETCH_GROUP_SETTINGS_BY_ID_FAILURE,
	CREATE_GROUP_SETTINGS_BY_ID,
	CREATE_GROUP_SETTINGS_BY_ID_SUCCESS,
	CREATE_GROUP_SETTINGS_BY_ID_FAILURE,
	UPDATE_GROUP_SETTINGS_BY_ID,
	UPDATE_GROUP_SETTINGS_BY_ID_SUCCESS,
	UPDATE_GROUP_SETTINGS_BY_ID_FAILURE,
	CLOSE_GROUP_SETTINGS_PANEL,
} from "./actions";

const initialState = {
	settingsError: "",
	settingsLoading: false,
	settings: [],

	groupSettingsError: "",
	groupSettingsLoading: false,
	groupSettings: [],
	allowGuestsEnabled: false,
	allowGuestsDefaultValue: false,
	currentGroupSettings: null,
	currentGroupSettingsLoading: false,
	currentGroupSettingsSaving: false,
	shouldCloseGroupSettingsPanel: false,

	updateUserSettingsLoading: false,
	updateUserSettingsError: "",
	updateApplicationSettingsLoading: false,
	updateApplicationSettingsError: "",
	hasApplicationSettingsUpdated: false,
	hasUserSettingsUpdated: false,
};

const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_GROUP_SETTINGS_BY_ID:
		case CREATE_GROUP_SETTINGS_BY_ID: {
			return {
				...state,
				currentGroupSettingsSaving: true,
				groupSettingsError: "",
			};
		}

		case UPDATE_GROUP_SETTINGS_BY_ID_SUCCESS:
		case CREATE_GROUP_SETTINGS_BY_ID_SUCCESS: {
			return {
				...state,
				currentGroupSettingsSaving: false,
				shouldCloseGroupSettingsPanel: true,
			};
		}

		case UPDATE_GROUP_SETTINGS_BY_ID_FAILURE:
		case CREATE_GROUP_SETTINGS_BY_ID_FAILURE: {
			return {
				...state,
				currentGroupSettingsSaving: false,
				groupSettingsError: "error",
			};
		}
		case CLOSE_GROUP_SETTINGS_PANEL: {
			return {
				...state,
				shouldCloseGroupSettingsPanel: false,
			};
		}
		case FETCH_GROUP_SETTINGS_BY_ID: {
			return {
				...state,
				currentGroupSettingsLoading: true,
			};
		}

		case FETCH_GROUP_SETTINGS_BY_ID_SUCCESS: {
			return {
				...state,
				currentGroupSettingsLoading: false,
				currentGroupSettings: action.payload.settings,
			};
		}

		case FETCH_GROUP_SETTINGS_BY_ID_FAILURE: {
			return {
				...state,
				currentGroupSettingsLoading: false,
			};
		}
		case FETCH_GROUP_SETTINGS: {
			return {
				...state,
				groupSettingsLoading: true,
			};
		}

		case FETCH_GROUP_SETTINGS_SUCCESS: {
			let newAllowGuestsEnabled = false;
			if (action.payload.settings) {
				action.payload.settings.forEach((groupSetting) => {
					if (groupSetting.values) {
						for (let i = 0; i < groupSetting.values.length; i++) {
							const value = groupSetting.values[i];
							if (value.name === "AllowToAddGuests") {
								newAllowGuestsEnabled = value.value === undefined ? false : value.value.toLowerCase() === "true";
								break;
							}
						}
					}
				});
			}
			return {
				...state,
				groupSettingsLoading: false,
				allowGuestsEnabled: newAllowGuestsEnabled,
				groupSettings: action.payload.settings,
			};
		}

		case FETCH_GROUP_SETTINGS_FAILURE: {
			return {
				...state,
				groupSettingsLoading: false,
				groupSettingsError: action.payload.error.message,
			};
		}

		case FETCH_SETTINGS: {
			return {
				...state,
				settingsLoading: true,
			};
		}

		case FETCH_SETTINGS_SUCCESS: {
			let newAllowGuestsDefaultValue = false;
			if (action.payload.settings.applicationSettings !== undefined) {
				action.payload.settings.applicationSettings.map((appSetting) => {
					if (appSetting.key === "AllowToAddGuestsDefaultValue") {
						newAllowGuestsDefaultValue = appSetting.value === "true";
					}
				});
			}
			return {
				...state,
				settingsLoading: false,
				allowGuestsDefaultValue: newAllowGuestsDefaultValue,
				settings: action.payload.settings,
			};
		}

		case FETCH_SETTINGS_FAILURE: {
			return {
				...state,
				settingsLoading: false,
				settingsError: action.payload.error.message,
			};
		}

		case UPDATE_USER_SETTINGS: {
			return {
				...state,
				updateUserSettingsLoading: true,
			};
		}

		case UPDATE_USER_SETTINGS_FAILURE: {
			return {
				...state,
				updateUserSettingsLoading: false,
				updateUserSettingsError: action.payload.error.message,
				hasUserSettingsUpdated: true,
			};
		}

		case CLOSE_USER_SETTINGS_ALERT: {
			return {
				...state,
				updateUserSettingsError: "",
				hasUserSettingsUpdated: false,
			};
		}

		case UPDATE_USER_SETTINGS_SUCCESS: {
			const newUserSettings = action.payload.settings;

			let currentState = Object.assign({}, state);
			let newSettingsArray = "";
			if (currentState.settings.userSettings != "" && currentState.settings.userSettings.length > 0) {
				newSettingsArray = currentState.settings.userSettings.map((setting) => {
					if (setting.key === "Language") {
						// update the current setting with a particular key to the newSetting value
						for (let newSetting of newUserSettings.settings) {
							if (newSetting.key === "Language") {
								const setSetting = Object.assign({}, { key: newSetting.key, value: newSetting.value });
								return setSetting;
								break;
							}
						}
						return setting;
					}
					return setting;
				});
			}

			return {
				...state,
				updateUserSettingsLoading: false,
				settings: {
					...state.settings,
					userSettings: newSettingsArray,
				},
				hasUserSettingsUpdated: true,
			};
		}

		case UPDATE_APPLICATION_SETTINGS: {
			return {
				...state,
				updateApplicationSettingsLoading: true,
			};
		}
		case CLOSE_APPICATION_SETTINGS_ALERT: {
			return {
				...state,
				updateApplicationSettingsError: "",
				hasApplicationSettingsUpdated: false,
			};
		}

		case UPDATE_APPLICATION_SETTINGS_FAILURE: {
			return {
				...state,
				updateApplicationSettingsLoading: false,
				hasApplicationSettingsUpdated: true,
				updateApplicationSettingsError: action.payload.error.message,
			};
		}

		case UPDATE_APPLICATION_SETTINGS_SUCCESS: {
			const newApplicationSettings = action.payload.settings;

			let currentState = Object.assign({}, state);

			const newSettingsArray = currentState.settings.applicationSettings.map((setting) => {
				if (setting.key === "Language") {
					// update the current setting with a particular key to the newSetting value

					for (let newSetting of newApplicationSettings.settings) {
						if (newSetting.key === "Language") {
							const setSetting = Object.assign({}, { key: newSetting.key, value: newSetting.value });
							return setSetting;
						}
					}
					return setting;
				} else if (setting.key === "ShowProvisioningForm") {
					for (let newSetting of newApplicationSettings.settings) {
						if (newSetting.key === "ShowProvisioningForm") {
							const setSetting = Object.assign({}, { key: newSetting.key, value: newSetting.value });
							return setSetting;
						}
					}
				}
				return setting;
			});

			return {
				...state,
				updateApplicationSettingsLoading: false,
				hasApplicationSettingsUpdated: true,
				settings: {
					...state.settings,
					applicationSettings: newSettingsArray,
				},
			};
		}

		default: {
			return state;
		}
	}
};

export default settingsReducer;
