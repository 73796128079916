import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clearNotification } from "../../../store/metaData/actions";
import TreeView from "./treeView/TreeViewComponent";
import TreeDetailsWindow from "./treeDetailsWindow/TreeDetailsWindow";

class TreeComponent extends React.Component {
    state = {
        activeItem: {},
        activeItemType: "",
        mode: "",
        activeFlyOut: {
            id: "",
            type: ""
        },
        cannotEdit: false
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            // check if the activeItem is a term by looking for the termSetId key.
            const isTerm = this.state.activeItem.hasOwnProperty("termSetId");

            // Iterate over the redux store data to find the corresponding termSet or term
            for (let termGroup of prevProps.termGroupsWithChildren) {
                for (let termSet of termGroup.termSets) {
                    // Save either the term, or the termSet, as the new activeItem to the state. For this we use the isTerm boolean.
                    if (isTerm) {
                        for (let term of termSet.terms) {
                            if (term.id === this.state.activeItem.id && prevState.activeItem.name !== term.name) {
                                this.setState({ activeItem: term });
                                break;
                            }
                        }
                    } else {
                        if (termSet.id === this.state.activeItem.id && prevState.activeItem.name !== termSet.name) {
                            this.setState({ activeItem: termSet });
                            break;
                        }
                    }
                }
            }
        }
    }

    closeFlyOut = () => {
        this.setState({ ...this.state, activeFlyOut: { id: "", type: "" } });
    };

    setFlyOut = (node, typeOfNode, termSetItem, e) => {
        e.stopPropagation();
        this.setState({
            ...this.state,
            activeFlyOut: { id: node.id, type: typeOfNode },
            activeItem: termSetItem,
            activeItemType: typeOfNode,
            mode: "update",
            cannotEdit: termSetItem.isClassification
        });
    };

    handleActiveNode = async (node, typeOfNode, typeOfClick, e) => {
        e.stopPropagation();
        let cannotEdit = node.hasOwnProperty("termSet") ? !node.termSet.isEditable : !node.isEditable;
        const isTermGroup = !node.hasOwnProperty("termGroupName") && !node.hasOwnProperty("termSetName");

        if (isTermGroup) {
            cannotEdit = typeOfClick !== "add";
        }

        await this.setState({
            activeItem: node,
            activeItemType: typeOfNode,
            mode: typeOfClick,
            cannotEdit
        });
        this.props.clearNotification();
        this.closeFlyOut();
    };

    setActiveTab = (selectedTabId, tabName) => {
        switch (selectedTabId) {
            case 0:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: (
                        <section className="tree-component-wrapper">
                            <TreeView
                                termGroupsWithChildren={this.props.termGroupsWithChildren}
                                handleActiveNode={this.handleActiveNode}
                                handleCloseFlyOut={this.closeFlyOut}
                                handleSetFlyOut={this.setFlyOut}
                                activeFlyOut={this.state.activeFlyOut}
                                currentUser={this.props.currentUser}
                                activeItem={this.state.activeItem}
                            />
                            <TreeDetailsWindow activeItem={this.state} />
                        </section>
                    )
                });
                break;

            case 1:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: <section>Test types</section>
                });
                break;
            default:
                this.setState({
                    activeTab: 0,
                    bodyComponent: <section className="tree-component-wrapper"></section>
                });
        }
    };

    render() {
        return (
            <React.Fragment>
                <section className="tree-component-wrapper">
                    <TreeView
                        termGroupsWithChildren={this.props.termGroupsWithChildren}
                        handleActiveNode={this.handleActiveNode}
                        handleCloseFlyOut={this.closeFlyOut}
                        handleSetFlyOut={this.setFlyOut}
                        activeFlyOut={this.state.activeFlyOut}
                        currentUser={this.props.currentUser}
                        activeItem={this.state.activeItem}
                    />
                    <TreeDetailsWindow activeItem={this.state} />
                </section>
            </React.Fragment>
        );
    }
}

TreeComponent.propTypes = {
    termGroupsWithChildren: PropTypes.array.isRequired
};

const mapStateToProps = state => {
    return {
        notification: state.metaDataSlice.notification
    };
};

export default connect(
    mapStateToProps,
    { clearNotification }
)(TreeComponent);
