import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { setArchive, fetchTeamDetails } from "../../../../../../store/teams/actions";

class PanelArchive extends React.Component {
    archiveFunction = () => {
        this.props.setArchive(this.props.teamId);
        // this.props.fetchTeamDetails(this.props.teamId);
        this.props.onDismiss();
    };

    render() {
        const { intl } = this.props;
        let actionButtons;

        actionButtons = (
            <React.Fragment>
                <div
                    className="team-request-wrapper__action team-request-wrapper__action--green"
                    onClick={() => this.archiveFunction()}
                >
                    <Icon iconName="Accept" />
                    <FormattedMessage id="teamModalBody.archivePanelAchiveYesLabel" defaultMessage="Archiveren" />
                </div>
                <div
                    className="team-request-wrapper__action team-request-wrapper__action--red"
                    onClick={() => this.props.onDismiss()}
                >
                    <Icon iconName="ChromeClose" />
                    <FormattedMessage id="teamModalBody.archivePanelAchiveNoLabel" defaultMessage="Annuleren" />
                </div>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <section className="account-panel__panel-header">
                    <div className="account-panel__panel-header--flex">
                        <h1>
                            <FormattedMessage
                                id="teamModalBody.archivePanelHeaderLabel"
                                defaultMessage="Team archiveren"
                            />
                        </h1>

                        <div className="account-panel__panel-close" onClick={() => this.props.onDismiss()}>
                            <Icon iconName="ChromeClose" />
                        </div>
                    </div>
                </section>
                <section className="metadata-content" style={{ lineHeight: "1.8rem" }}>
                    <FormattedMessage
                        id="teamModalBody.archivePanelAreYouSureMessage"
                        defaultMessage="Weet u zeker dat u dit team wilt archiveren?"
                    />
                    <div className="team-request-wrapper wrapper-spacer">{actionButtons}</div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(
    null,
    { setArchive, fetchTeamDetails }
)(PanelArchive);
