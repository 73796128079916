import axios from 'axios';
import { acquireToken, getToken, adalOneApiFetch } from '../adalConfig';
import { objectToArray } from '../assets/general_functions/objectsAndArrays';

export const postProvisioningRequest = async teamObj => {
	// we initiate a new token, to be sure that it didn't expire.
	let apiToken = await acquireToken();

	try {
		let response = await axios({
			url: `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/provisioningrequests`,
			method: 'POST',
			data: teamObj,
			headers: { Authorization: 'Bearer ' + apiToken }
		});

		if (
			response.status != 200 &&
			response.status != 204 &&
			response.status != 201
		) {
			return 'error';
		}

		return await response.data.value;
	} catch (error) {
		return 'error';
	}
};

export const GetGroupMembers = async groupId => {
	// we initiate a new token, to be sure that it didn't expire.
	let token = acquireToken();

	try {
		let response = await axios({
			url: `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${groupId}/members`,
			method: 'GET',
			headers: { Authorization: 'Bearer ' + token }
		});

		if (response.status != 200 && response.status != 204) {
			return 'error';
		}

		const membersToSend = [];

		response.data.forEach(member => {
			membersToSend.push(member);
		});
		return membersToSend;
	} catch (error) {
		return 'error';
	}
};

export const getImage = async url => {
	// we initiate a new token, to be sure that it didn't expire.
	let apiToken = acquireToken();

	try {
		let response = await axios({
			url: url,
			method: 'get',
			responseType: 'blob',
			headers: { Authorization: 'Bearer ' + apiToken }
		});

		if (response.status != 200 && response.status != 204) {
			return 'error';
		}

		var urlCreator = window.URL || window.webkitURL;
		var imageUrl = urlCreator.createObjectURL(response.data);

		return await imageUrl;
	} catch (error) {
		return 'error';
	}
};

export const doesTenantHasAccess = async () => {
	// we initiate a new token, to be sure that it didn't expire.
	await getToken();
	let apiToken = acquireToken();

	await adalOneApiFetch(
		fetch,
		`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/checkSubscription`
	).then(response => {
		response.json().then(result => {
			return result;
		});
	});
};

export const updateUserSettings = async settings => {
	// we initiate a new token, to be sure that it didn't expire.
	let apiToken = acquireToken();

	try {
		let response = await axios({
			url: `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/userSettings`,
			method: 'POST',
			data: settings,
			headers: { Authorization: 'Bearer ' + apiToken }
		});

		if (response.status != 200 && response.status != 204) {
			return 'error';
		}

		return 'settings updated';
	} catch (error) {
		return 'error';
	}
};

export const createTenant = async tenantObj => {
	// we initiate a new token, to be sure that it didn't expire.
	let apiToken = acquireToken();
	let url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/setup`;
	const result = await adalOneApiFetch(fetch, url, {
		method: 'POST',
		body: JSON.stringify(tenantObj),
		headers: {
			'Content-Type': 'application/json'
		}
	});

	const apiResult = await result.json();

	if (result.ok === false) {
		return 'error';
	} else {
		return apiResult;
	}
};


export const createSubscription = async tenantObj => {
	// we initiate a new token, to be sure that it didn't expire.
	let apiToken = acquireToken();
	let url = `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/subscribe`;
	const result = await adalOneApiFetch(fetch, url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		}
	});

	const apiResult = await result.json();

	if (result.ok === false) {
		return 'error';
	} else {
		return apiResult;
	}
};