import React from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import { createTerm, updateTerm } from "../../../../../store/metaData/actions";

class TermFormComponent extends React.Component {
    disableFieldWhenLastActiveTerm = () => {
        const { mode, activeItem, termGroups } = this.props;
        let isDisabled = "";

        if (this.props.cannotEdit) {
            isDisabled = "disabled";
        } else if (mode === "update" && activeItem.isActive) {
            const termGroupId = activeItem.termSet.termGroupId;

            const currentTermGroup = [...termGroups].filter(termGroup => termGroup.id === termGroupId)[0];
            const currentTermSetWithChildren = currentTermGroup.termSets.filter(
                termSet => termSet.id === activeItem.termSetId
            )[0];

            let hasOtherActiveTerm = false;
            if (currentTermSetWithChildren.isRequired) {
                for (let term of currentTermSetWithChildren.terms) {
                    if (term.id !== activeItem.id && term.isActive) {
                        hasOtherActiveTerm = true;
                    }
                }

                if (!hasOtherActiveTerm) {
                    isDisabled = "disabled";
                }
            }
        }

        return isDisabled;
    };

    render() {
        const { intl } = this.props;

        let termSetId = this.props.activeItem.id;
        let name = "";
        let isActive = true;
        let isDisabled = "disabled";
        let isDisabledBecauseLastActiveTerm = this.disableFieldWhenLastActiveTerm();
        let submitButton = "";
        let submitButtonText = <FormattedMessage id="general.formCreateText" defaultMessage="Aanmaken" />;

        if (this.props.mode === "update") {
            termSetId = this.props.activeItem.termSetId;
            name = this.props.activeItem.name;
            isActive = this.props.activeItem.isActive;
            submitButtonText = <FormattedMessage id="general.formEditText" defaultMessage="Aanpassen" />;
        }

        if (!this.props.cannotEdit) {
            isDisabled = "";
            submitButton = (
                <button type="submit" className="btn btn-primary teams-submit-button">
                    {submitButtonText}
                </button>
            );
        }

        return (
            <div className="details-form-wrapper">
                <Formik
                    enableReinitialize
                    initialValues={{
                        termSetId: termSetId,
                        name: name,
                        isActive: isActive
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            if (this.props.mode === "update") {
                                const objectToSend = values;
                                objectToSend.id = this.props.activeItem.id;
                                this.props.updateTerm(values);
                            } else {
                                this.props.createTerm(values);
                            }
                            setSubmitting(false);
                        }, 500);
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .min(
                                2,
                                intl.formatMessage({
                                    id: "metadata.form.validation.termNameMinTwoCharacters",
                                    defaultMessage: "U moet minstens 2 tekens gebruiken."
                                })
                            )
                            .required(
                                intl.formatMessage({
                                    id: "metadata.form.validation.termNameRequired",
                                    defaultMessage: "U moet een naam voor de term opgeven"
                                })
                            )
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    className="form-input-group"
                                    type="hidden"
                                    name="termSetId"
                                    value={termSetId}
                                ></Field>

                                <label htmlFor="name" style={{ display: "block" }}>
                                    <FormattedMessage id="metadata.form.termNameInputLabel" defaultMessage="Naam" />
                                </label>
                                {errors.name && touched.name && <div className="input-feedback">{errors.name}</div>}

                                <div className="form-input-wrapper">
                                    <div className="form-input-description">
                                        <FormattedMessage
                                            id="metadata.form.termNameInputDescription"
                                            defaultMessage="Voer een naam voor deze term in"
                                        />
                                    </div>
                                    <div className="form-input-field">
                                        <input
                                            id="name"
                                            placeholder={intl.formatMessage({
                                                id: "metadata.form.termNameInputDescription",
                                                defaultMessage: "Voer een naam voor deze term in"
                                            })}
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="checkbox-spacer">
                                    <label htmlFor="isActive" style={{ display: "block" }}>
                                        <FormattedMessage
                                            id="metadata.form.termIsActiveInputLabel"
                                            defaultMessage="Is actief"
                                        />
                                    </label>

                                    <div className="form-input-wrapper">
                                        <div className="form-input-description">
                                            <FormattedMessage
                                                id="metadata.form.termIsActiveInputDescription"
                                                defaultMessage="Deze term mag aan teams gekoppeld worden"
                                            />
                                        </div>

                                        <div className="form-input-field">
                                            <Field
                                                type="checkbox"
                                                name="isActive"
                                                checked={values.isActive}
                                                onChange={() => {
                                                    setFieldValue("isActive", !values.isActive);
                                                }}
                                                disabled={isDisabledBecauseLastActiveTerm}
                                                title={
                                                    isDisabledBecauseLastActiveTerm
                                                        ? intl.formatMessage({
                                                              id: "metadata.form.termIsRequiredDisabledInfoText",
                                                              defaultMessage:
                                                                  "Deze term kan niet inactief worden omdat de bovenliggende term set verplicht is en dit de laatste actieve term daarvan is."
                                                          })
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {submitButton}
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        termGroups: state.metaDataSlice.termGroups
    };
};

export default connect(
    mapStateToProps,
    { createTerm, updateTerm }
)(injectIntl(TermFormComponent));
