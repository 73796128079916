import React from "react";
import { CommandBar as Bar } from "office-ui-fabric-react/lib/CommandBar";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ReactAI from "react-appinsights";

import FilterArea from "./filterArea/filterArea";
import * as filterActions from "../../../store/filters/actions";
import * as settingsActions from "../../../store/settings/actions";
import ProvisioningRequestPanel from "./provisioningRequestPanel/provisioningRequestPanel";
import {
	logUserOpenRequestNewTeamPanel,
	logUserClickedFilters,
	logUserRemovedAllFilters,
	logUserCloseRequestNewTeamPanel,
} from "../../../logging/applicationInsights/logFunctions";

class CommandBar extends React.Component {
	state = {
		filterMenuIsOpen: false,
		provisioningRequestScreenIsOpen: false,
	};

	toggleFilterMenu = () => {
		logUserClickedFilters(this.props.currentUser.userPrincipalName, this.props.currentUser.tenant.name);
		this.setState({ filterMenuIsOpen: !this.state.filterMenuIsOpen });
	};

	openProvisioningRequestPanel = () => {
		logUserOpenRequestNewTeamPanel(this.props.currentUser.userPrincipalName, this.props.currentUser.tenant.name);
		this.setState({ provisioningRequestScreenIsOpen: true });
	};

	closeProvisioningRequestPanel = () => {
		logUserCloseRequestNewTeamPanel(this.props.currentUser.userPrincipalName, this.props.currentUser.tenant.name);
		this.setState({ provisioningRequestScreenIsOpen: false });
	};

	removeAllFilters = () => {
		this.props.removeFilters();
		logUserRemovedAllFilters(this.props.currentUser.userPrincipalName, this.props.currentUser.tenant.name);
	};

	getItems = (hideProvisioningButton) => {
		const { intl } = this.props;
		const filterText = intl.formatMessage({
			id: "commandbar.filter",
			defaultMessage: "Filter",
		});
		const removeFilterText = intl.formatMessage({
			id: "commandbar.removeFilter",
			defaultMessage: "Filters verwijderen",
		});
		const addTeam = intl.formatMessage({
			id: "commandbar.addTeam",
			defaultMessage: "Team Toevoegen",
		});

		if (hideProvisioningButton === true) {
			return [
				{
					key: "filter",
					name: filterText,
					cacheKey: "myCacheKey", // changing this key will invalidate this items cache
					iconProps: {
						iconName: "Filter",
					},
					ariaLabel: "Filter teams",
					onClick: () => this.toggleFilterMenu(),
				},
				{
					key: "filtersRemove",
					name: removeFilterText,
					iconProps: {
						iconName: "EraseTool",
					},
					ariaLabel: "Remove filters",
					onClick: () => this.removeAllFilters(),
				},
				{
					key: "addTeam",
					name: addTeam,
					iconProps: {
						iconName: "Add",
					},
					ariaLabel: "Add item",
					onClick: () => this.openProvisioningRequestPanel(),
				},
			];
		} else {
			return [
				{
					key: "filter",
					name: filterText,
					cacheKey: "myCacheKey", // changing this key will invalidate this items cache
					iconProps: {
						iconName: "Filter",
					},
					ariaLabel: "Filter teams",
					onClick: () => this.toggleFilterMenu(),
				},
				{
					key: "filtersRemove",
					name: removeFilterText,
					iconProps: {
						iconName: "EraseTool",
					},
					ariaLabel: "Remove filters",
					onClick: () => this.removeAllFilters(),
				},
			];
		}
	};

	render() {
		let showProvisioningFormButton = false;

		if (Object.entries(this.props.settings).length !== 0 && this.props.settings.constructor === Object) {
			// loop over the settings
			this.props.settings.applicationSettings.filter((setting) => {
				if (setting.key === "ShowProvisioningForm" && setting.value.toLowerCase() === "true") {
					showProvisioningFormButton = true;
				}
			});

			// if the settings doesn't show the button, check if the user is admin.
			if (showProvisioningFormButton === false && this.props.currentUser && this.props.currentUser.roles) {
				this.props.currentUser.roles.filter((role) => {
					if (role.code === "admin") {
						showProvisioningFormButton = true;
					}
				});
			}
		}

		return (
			<React.Fragment>
				<Bar items={this.getItems(showProvisioningFormButton)} />

				<Collapse isOpen={this.state.filterMenuIsOpen} style={{ marginTop: "-.1rem" }}>
					<FilterArea
						toggleFilterMenu={this.toggleFilterMenu}
						hideStatusFilters={this.props.hideStatusFilters}
					/>
				</Collapse>

				<Panel
					className="account-panel"
					isOpen={this.state.provisioningRequestScreenIsOpen}
					type={PanelType.smallFixedFar}
					onDismiss={this.closeProvisioningRequestPanel}
					closeButtonAriaLabel="Close"
					isLightDismiss={true}
					onLightDismissClick={this.closeProvisioningRequestPanel}
				>
					<ProvisioningRequestPanel
						closeProvisioningRequestPanel={() => this.closeProvisioningRequestPanel()}
					/>
				</Panel>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUserSlice.currentUser,
		settings: state.settingsSlice.settings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeFilters: () => dispatch(filterActions.removeAllFilters()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(injectIntl(CommandBar));
