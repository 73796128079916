import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import teamsReducer from "./teams/reducer";
import userReducer from "./users/reducer";
import tokenReducer from "./tokens/reducer";
import filterReducer from "./filters/reducer";
import allTeamsReducer from "./allTeams/reducer";
import settingsReducer from "./settings/reducer";
import metaDataReducer from "./metaData/reducer";
import teamRequestsReducer from "./teamRequests/reducer";
import teamTypesReducer from "./teamTypes/reducer";

import { routerMiddleware } from "connected-react-router";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

if (window.location.pathname === "/") {
    history.push("/teams");
}

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        tokensSplice: tokenReducer,
        teamsSlice: teamsReducer,
        currentUserSlice: userReducer,
        filtersSlice: filterReducer,
        allTeamsSlice: allTeamsReducer,
        settingsSlice: settingsReducer,
        metaDataSlice: metaDataReducer,
        teamRequestsSlice: teamRequestsReducer,
        teamTypesSlice: teamTypesReducer
    });

let appToExport = "";

if (process.env.NODE_ENV === "production") {
    appToExport = function configureStore(preloadedState) {
        const store = createStore(
            rootReducer(history),
            preloadedState,
            applyMiddleware(thunk, routerMiddleware(history))
        );

        return store;
    };
} else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    appToExport = function configureStore(preloadedState) {
        const store = createStore(
            rootReducer(history),
            preloadedState,
            composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
        );

        return store;
    };
}

export default appToExport;
