import React from "react";

class Tabs extends React.Component {
    render() {
        const tabs = this.props.tabData.map(tab => {
            let active = false;
            let classes = "";
            let needsExclamationMark = "";

            if (this.props.activeTab === tab.id) {
                active = true;
                classes += "active";
            }

            if (tab.needsExclamationMark === false) {
                needsExclamationMark = <span className="data-is-missing"> ! </span>;
            }

            return (
                <li
                    key={tab.tabName}
                    title={tab.tabTitle}
                    onClick={() => this.props.setActiveTab(tab.id, tab.tabName)}
                    className={classes}
                >
                    {tab.tabName} {needsExclamationMark}
                </li>
            );
        });
        return (
            <section>
                <ul className="tabs-ul-wrapper ">{tabs}</ul>
            </section>
        );
    }
}

export default Tabs;
