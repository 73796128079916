import React from "react";
import { Icon, Dropdown, PrimaryButton } from "office-ui-fabric-react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import Loader from "../../../../../../common/utils/withLoading";

import {
	fetchGroupSettingsById,
	createGroupSettingsById,
	updateGroupSettingsById,
	closeGroupSettingsPanel,
} from "../../../../../../store/settings/actions";

class PanelGroupSettings extends React.Component {
	state = {
		allowToAddGuests: "-1",
		settingId: undefined,
		settingsHasValue: false,
	};

	componentDidMount() {
		this.props.fetchGroupSettingsById(this.props.teamId);
	}

	submit = () => {
		if (this.state.allowToAddGuests === "-1") {
			return;
		}
    
        let allowToAddGuestsString = this.state.allowToAddGuests;
        if (typeof this.state.allowToAddGuests === "boolean") {
            // Ensure that a string is sent in stead of a boolean value.
            allowToAddGuestsString = this.state.allowToAddGuests === true ? "true" : "false";
        }

		if (this.state.settingsHasValue) {
			// Update
			this.props.updateGroupSettingsById(this.props.teamId, {
				id: this.state.settingId,
				displayName: "GroupSettings",
				templateId: "08d542b9-071f-4e16-94b0-74abb372e3d9",
				values: [
					{
						name: "AllowToAddGuests",
						value: allowToAddGuestsString,
					},
				],
			});
		} else {
			// Create
			this.props.createGroupSettingsById(this.props.teamId, {
				id: this.state.settingId,
				displayName: "GroupSettings",
				templateId: "08d542b9-071f-4e16-94b0-74abb372e3d9",
				values: [
					{
						name: "AllowToAddGuests",
						value: allowToAddGuestsString,
					},
				],
			});
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props !== prevProps) {
			if (this.props.currentGroupSettings !== null) {
				this.props.currentGroupSettings.map((groupSettings) => {
					for (let i = 0; i < groupSettings.values.length; i++) {
						const setting = groupSettings.values[i];
						if (setting.name === "AllowToAddGuests") {
							this.setState({
								...this.state,
								allowToAddGuests: setting.value === undefined ? false : setting.value.toLowerCase() === "true",
								settingsHasValue: true,
								settingId: groupSettings.id,
							});
							break;
						}
					}
				});
			}
		}
	}

	render() {
		const { intl, currentGroupSettings } = this.props;

		if (this.props.shouldCloseGroupSettingsPanel) {
			this.props.closeGroupSettingsPanel();
			this.props.onDismiss();
		}

		if (this.state.allowToAddGuests == "-1" && currentGroupSettings !== null) {
			currentGroupSettings.map((groupSettings) => {
				for (let i = 0; i < groupSettings.values.length; i++) {
					const setting = groupSettings.values[i];
					if (setting.name === "AllowToAddGuests") {
						this.setState({
							...this.state,
							allowToAddGuests: setting.value === undefined ? false : setting.value.toLowerCase() === "true",
							settingsHasValue: true,
							settingId: groupSettings.id,
						});
						break;
					}
				}
			});
		}

		return (
			<React.Fragment>
				<section className="account-panel__panel-header">
					<div className="account-panel__panel-header--flex">
						<h1>
							<FormattedMessage
								id="teamModalBody.groupSettings.label"
								defaultMessage="Groepsinstellingen"
							/>
						</h1>

						<div className="account-panel__panel-close" onClick={() => this.props.onDismiss()}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				<section className="metadata-content" style={{ lineHeight: "1.8rem" }}>
					{this.props.currentGroupSettingsLoading || this.props.currentGroupSettingsSaving ? (
						<Loader isLoading={true} />
					) : (
						<div>
							<Dropdown
								label={intl.formatMessage({
									id: "teamModalBody.groupSettings.allowToAddGuets.label",
									defaultMessage: "Externen toelaten",
								})}
								selectedKey={this.state.allowToAddGuests.toString()}
								onChange={(e, option) => this.setState({ ...this.state, allowToAddGuests: option.key })}
								options={[
									{
										key: "-1",
										text: intl.formatMessage({
											id: "teamModalBody.groupSettings.allowToAddGuets.chooseValue",
											defaultMessage: "Kies een waarde",
										}),
										disabled: true,
									},
									{
										key: "true",
										text: intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										}),
									},
									{
										key: "false",
										text: intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										}),
									},
								]}
							/>

							<PrimaryButton
								disabled={this.state.allowToAddGuests === "-1"}
								styles={{
									root: {
										marginTop: "30px",
									},
									flexContainer: {
										marginTop: "4px",
									},
								}}
								onClick={() => this.submit()}
							>
								<FormattedMessage id="general.save" defaultMessage="Opslaan" />
							</PrimaryButton>
						</div>
					)}
				</section>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentGroupSettings: state.settingsSlice.currentGroupSettings,
		currentGroupSettingsLoading: state.settingsSlice.currentGroupSettingsLoading,
		shouldCloseGroupSettingsPanel: state.settingsSlice.shouldCloseGroupSettingsPanel,
		currentGroupSettingsSaving: state.settingsSlice.currentGroupSettingsSaving,
	};
};

export default connect(
	mapStateToProps,
	{ fetchGroupSettingsById, createGroupSettingsById, updateGroupSettingsById, closeGroupSettingsPanel }
)(injectIntl(PanelGroupSettings));
