import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Fade from "react-reveal/Fade";
import { FormattedMessage } from "react-intl";

class TreeViewTeamTypes extends React.Component {
  handleFlyoutMenu = (node, typeOfNode, termSetItem, e) => {
    e.stopPropagation();

    // if the current id in the state is equal to what the user clicked on, reset the state to 0.
    // this created the effect of closing the flyout menu
    if (
      this.props.activeFlyOut.id === node.id &&
      this.props.activeFlyOut.type === typeOfNode
    ) {
      this.props.handleCloseFlyOut("", "");
    } else {
      this.props.handleSetFlyOut(node, typeOfNode, termSetItem, e);
    }
  };

  parseTeamTypes(teamTypes) {
    let defaultCursor = { cursor: "default" };

    let flyout = "";
    let isCurrentItem =
      this.props.activeFlyOut.type === "termSet" &&
      this.props.activeFlyOut.id === teamTypes.id;

    const teamTypesToRender = this.props.teamTypes.map(type => {
      return this.parseTermItem(type);
    });

    if (isCurrentItem) {
      flyout = (
        <Fade appear={true}>
          <section
            className="term-box__action-flyout"
            onClick={e =>
              this.props.handleActiveNode(teamTypes, "termSet", "add", e)
            }
          >
            <ul>
              <li>
                <FormattedMessage
                  id="metadata.newTeamTypeText"
                  defaultMessage="Nieuwe teamtype aanmaken"
                />
              </li>
            </ul>
          </section>
        </Fade>
      );
    }

    return (
      <div>
        <div className="term-box">
          <div className="term-box__text-wrapper" style={defaultCursor}>
            <FormattedMessage
              id="metadata.form.teamTypeLabel"
              defaultMessage="Teamtype"
            />
          </div>

          <div
            className="term-box__actions-wrapper"
            style={defaultCursor}
            onClick={e =>
              this.handleFlyoutMenu(
                { id: teamTypes.id },
                "termSet",
                teamTypes,
                e
              )
            }
          >
            <Icon iconName="ChevronRightSmall" />
          </div>
          {flyout}
        </div>

        <div>
          <ul>{teamTypesToRender}</ul>
        </div>
      </div>
    );
  }

  parseTermItem(teamType) {
    const isActiveItem =
      this.props.activeItem.id === teamType.id &&
      this.props.activeItem.hasOwnProperty("domain");
    const styles = isActiveItem
      ? "term-box__text-wrapper term-box--full-length active--term-boxes"
      : "term-box__text-wrapper term-box--full-length";

    return (
      <li className="term-box " key={teamType.id}>
        <div
          className={styles}
          onClick={e =>
            this.props.handleActiveNode(teamType, "term", "update", e)
          }
        >
          {teamType.name}
        </div>
      </li>
    );
  }

  render() {
    const items = this.parseTeamTypes(this.props.teamTypes);
    return <div className="treeview-component-wrapper">{items}</div>;
  }
}

export default TreeViewTeamTypes;
