import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { SubscriptionStatus } from "@one/core";
import { acquireGraphToken, acquireToken } from "../../adalConfig";
import { getToken } from "../../adalConfig";
import { createTenant, createSubscription } from "../../HttpRepositories/oneApiRepository";
import { adalOneApiFetch } from "../../adalConfig";
import { Redirect } from "react-router-dom";

class TrialRequestForm extends React.Component {
	state = {
		errorCode: 0,
		errorMessage: "",
		createTenant: false,
		createSubscription: false,
		tenantInfo: null,
	};

	checkWhetherTenantExists = async () => {
		const response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant`);
		const result = await response.json();

		if (!result.hasOwnProperty("errorCode")) {
			this.setState({ createSubscription: true, createTenant: false, tenantInfo: result });
		} else {
			this.setState({ createSubscription: false, createTenant: true });
		}
	};

	checkWhetherAccessIsAllowed = async () => {
		const response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/checkSubscription`);

		const result = await response.json();

		if (result.hasOwnProperty("errorCode")) {
			if (result.errorCode === 3000) {
				this.setState({ errorCode: result.errorCode, errorMessage: result.errorMessage });
			}
		}
	};
	async componentDidMount() {
		// Sets an interval that refreshes to get the token every 15 minutes
		// We use this because our custom API's do not automatically issue a
		// refresh token.
		setInterval(this.appTokenRefresher, 1000);
		const token = await getToken();
		await this.checkWhetherAccessIsAllowed();
		await this.checkWhetherTenantExists();
	}

	appTokenRefresher = () => {
		acquireToken();
		acquireGraphToken();
	};

	createTenantHandler = async (values) => {
		const tenantResult = await createTenant(values);
		window.location.reload();
	};

	requestSubscription = async () => {
		const tenantResult = await createSubscription();
		window.location.reload();
	};

	render() {
		if (this.state.errorCode === 3000) {
			return (
				<Redirect
					to={{
						pathname: "/expired",
						state: this.state.errorMessage,
					}}
				/>
			);
		}

		let content = "";
		if (this.state.createTenant) {
			content = (
				<React.Fragment>
					<section className="teaser full-height-page">
						<div className="teaser__container">
							<h1>
								One for Teams<sup>&reg;</sup> de applicatie voor alle Microsoft Teams gebruikers.
							</h1>
							<p>Vul het onderstaande formulier in om een proeflicentie aan te vragen.</p>
						</div>

						<div className="sign-up-form">
							<Formik
								initialValues={{
									companyName: "",

									contactPersonName: "",
									contactPersonPhone: "",
									contactPersonEmailAddress: "",
								}}
								onSubmit={(values, { setSubmitting }) => {
									setTimeout(() => {
										this.createTenantHandler(values);

										setSubmitting(false);
									}, 500);
								}}
								validationSchema={Yup.object().shape({
									companyName: Yup.string()
										.min(3, "u moet minimaal 3 tekens gebruiken.")
										.required("U moet een bedrijf opgeven."),
									contactPersonName: Yup.string()
										.required("U moet een contactpersoon opgeven.")
										.min(8, "U moet minimaal 8 tekens gebruiken"),
									contactPersonPhone: Yup.string()
										.required("U moet het telefoonnummer van de contactpersoon opgeven.")
										.min(6, "U moet minimaal 6 tekens gebruiken"),
									contactPersonEmailAddress: Yup.string()
										.required("U moet een emailadres van de contactpersoon opgeven.")
										.email("U moet een geldig emailadres opgeven"),
								})}
							>
								{(props) => {
									const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<label htmlFor="companyName" style={{ display: "block" }}>
												Bedrijfsnaam
											</label>
											{errors.companyName && touched.companyName && (
												<div className="input-feedback">{errors.companyName}</div>
											)}
											<input
												id="companyName"
												placeholder="Vul uw bedrijfsnaam in."
												type="text"
												value={values.companyName}
												onChange={handleChange}
												onBlur={handleBlur}
												className={errors.companyName && touched.companyName ? "text-input error" : "text-input"}
											/>

											<div className="form-seperator">
												<label htmlFor="contactPersonName" style={{ display: "block" }}>
													Naam contactpersoon
												</label>
												{errors.contactPersonName && touched.contactPersonName && (
													<div className="input-feedback">{errors.contactPersonName}</div>
												)}
												<input
													id="contactPersonName"
													placeholder="Vul de naam van de contactpersoon in.."
													type="text"
													value={values.contactPersonName}
													onChange={handleChange}
													onBlur={handleBlur}
													className={
														errors.contactPersonName && touched.contactPersonName
															? "text-input error"
															: "text-input"
													}
												/>

												<label htmlFor="contactPersonPhone" style={{ display: "block" }}>
													Contactpersoon telefoonnummer
												</label>
												{errors.contactPersonPhone && touched.contactPersonPhone && (
													<div className="input-feedback">{errors.contactPersonPhone}</div>
												)}
												<input
													id="contactPersonPhone"
													placeholder="Vul het telefoon nummer van de contactpersoon in.."
													type="text"
													value={values.contactPersonPhone}
													onChange={handleChange}
													onBlur={handleBlur}
													className={
														errors.contactPersonPhone && touched.contactPersonPhone
															? "text-input error"
															: "text-input"
													}
												/>

												<label htmlFor="contactPersonEmailAddress" style={{ display: "block" }}>
													Contactpersoon email
												</label>
												{errors.contactPersonEmailAddress && touched.contactPersonEmailAddress && (
													<div className="input-feedback">{errors.contactPersonEmailAddress}</div>
												)}
												<input
													id="contactPersonEmailAddress"
													placeholder="Vul een emailadres van de contactpersoon in.."
													type="text"
													value={values.contactPersonEmailAddress}
													onChange={handleChange}
													onBlur={handleBlur}
													className={
														errors.contactPersonEmailAddress && touched.contactPersonEmailAddress
															? "text-input error"
															: "text-input"
													}
												/>
											</div>

											<button
												type="submit"
												//	disabled={isSubmitting}
												className="btn btn-primary teams-submit-button"
											>
												Proeflicentie aanvragen
											</button>
										</form>
									);
								}}
							</Formik>
						</div>
					</section>
				</React.Fragment>
			);
		}

		if (this.state.createSubscription) {
			content = (
				<section className="teaser full-height-page">
					<div className="teaser__container">
						<h1>
							One for Teams<sup>&reg;</sup> de applicatie voor alle Microsoft Teams gebruikers.
						</h1>
						<p>U kunt hieronder een proefabonnement aanvragen.</p>

						<div className="sign-up-form">
							<div className="sign-up-form__subscription-item">
								<b>Bedrijfsnaam:</b>
								<div>{this.state.tenantInfo.name}</div>
							</div>
							<div className="sign-up-form__subscription-item">
								<b>Naam contactpersoon:</b>
								<div>{this.state.tenantInfo.contactPersonName}</div>
							</div>
							<div className="sign-up-form__subscription-item">
								<b>Contactpersoon telefoonnummer:</b>
								<div>{this.state.tenantInfo.contactPersonPhone}</div>
							</div>
							<div className="sign-up-form__subscription-item">
								<b>Contactpersoon email:</b>
								<div>{this.state.tenantInfo.contactPersonEmailAddress}</div>
							</div>

							<button type="submit" onClick={this.requestSubscription} className="btn btn-primary teams-submit-button">
								Proeflicentie aanvragen
							</button>
						</div>
					</div>
				</section>
			);
		}

		return <React.Fragment>{content}</React.Fragment>;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUserSlice.currentUser,
	};
};

export default connect(mapStateToProps)(TrialRequestForm);
