import React from "react";
import * as filterActions from "../../../store/filters/actions";
import { connect } from "react-redux";
import ReactAI from "react-appinsights";

class Checkbox extends React.Component {
    onChangeHandler = (event, termId) => {
        this.props.handleOnChange(
            this.props.checkboxTermId,
            this.props.checkboxTermSetId,
            this.props.checkboxTermName,
            this.props.checkboxTermSetName,
            event
        );
    };

    render() {
        return (
            <React.Fragment>
                <label className="checkbox-item-wrapper" key={this.props.checkboxTermId}>
                    <input
                        autoFocus={this.props.autoFocus}
                        tabIndex={this.props.tabIndex}
                        type="checkbox"
                        checked={this.props.checked}
                        name={this.props.checkboxTermSetName}
                        key={this.props.checkboxTermName}
                        value={this.props.checkboxValue}
                        onChange={event => this.onChangeHandler(event, this.props.checkboxTermId)}
                    />

                    <div className="checkbox-item-wrapper__label-text"> {this.props.checkboxValue}</div>

                    <br />
                </label>
            </React.Fragment>
        );
    }
}

export default Checkbox;
