import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class SubscriptionRouter extends React.Component {
	state = {
		hasrun: false,
	};

	render() {
		if (!this.state.hasrun) {
			this.setState({ hasrun: true });

			if (this.props.subscriptionType === 3000) {
				return (
					<Redirect
						to={{
							pathname: "/expired",
							state: this.props.subscriptionErrorMessage,
						}}
					/>
				);
			} else if (this.props.subscriptionType === 3001 || this.props.subscriptionType === 1000) {
				return (
					<Redirect
						to={{
							pathname: "/info",
						}}
					/>
				);
			}
		}

		return <React.Fragment></React.Fragment>;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUserSlice.currentUser,
		router: state.router,
	};
};

export default connect(mapStateToProps)(withRouter(SubscriptionRouter));
