import {
    FETCH_TEAM_REQUESTS,
    FETCH_TEAM_REQUESTS_FAILURE,
    FETCH_TEAM_REQUESTS_SUCCESS,
    REMOVE_TEAM_REQUEST_FAILURE,
    REMOVE_TEAM_REQUEST_SUCCESS,
    RESET_TEAM_REQUEST_STATUS,
    APPROVE_TEAM_REQUEST_SUCCESS,
    APPROVE_TEAM_REQUEST_FAILURE,
    REJECT_TEAM_REQUEST_SUCCESS,
    REJECT_TEAM_REQUEST_FAILURE,
    CREATE_TEAM_REQUEST_SUCCESS,
    CREATE_TEAM_REQUEST_FAILURE,
    CHECK_IF_TEAM_NAME_SUCCESS,
    CHECK_IF_TEAM_NAME_FAILURE,
    CLEAR_CREATE_TEAM_REQUEST_STATUS
} from "./actions";

const initialState = {
    provisioningRequests: [],
    provisioningRequestsNotificationHub: [],
    provisioningRequestsLoading: false,
    provisioningRequestsLoadingNotificationHub: false,
    provisioningRequestsError: "",

    createRequestStatus: 0, // 0: None, 1: SuccessNeedsApproval, 2: SuccessNoApprovalNeeded, 3: Failed
    provisioningTeamAlreadyExists: false,
    provisioningNeedsApproval: false,

    removeRequestStatus: "",
    approveRequestStatus: "",
    rejectRequestStatus: ""
};

const teamRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEAM_REQUESTS: {
            switch (action.payload.source) {
                case "RequestedTeamOverviewComponent":
                    return {
                        ...state,
                        provisioningRequestsLoadingNotificationHub: true,
                        provisioningRequestsError: ""
                    };
                default:
                    // Default, source is AllTeamRequestsContainer or MyTeamRequestContainer
                    return {
                        ...state,
                        provisioningRequestsLoading: true,
                        provisioningRequestsError: ""
                    };
            }
        }

        case FETCH_TEAM_REQUESTS_SUCCESS: {
            switch (action.payload.source) {
                case "RequestedTeamOverviewComponent":
                    return {
                        ...state,
                        provisioningRequestsLoading: false,
                        provisioningRequestsLoadingNotificationHub: false,
                        provisioningRequestsNotificationHub: action.payload.request
                    };
                default:
                    // Default, source is AllTeamRequestsContainer or MyTeamRequestContainer
                    return {
                        ...state,
                        provisioningRequestsLoading: false,
                        provisioningRequestsLoadingNotificationHub: false,
                        provisioningRequests: action.payload.request
                    };
            }
        }

        case FETCH_TEAM_REQUESTS_FAILURE: {
            return {
                ...state,
                provisioningRequests: false,
                provisioningRequestsLoading: false,
                provisioningRequestsLoadingNotificationHub: false,
                provisioningRequests: [],
                provisioningRequestsError: action.payload.error.message
            };
        }

        case CREATE_TEAM_REQUEST_SUCCESS: {
            const provisioningRequests = [...state.provisioningRequests];
            provisioningRequests.push(action.payload.teamRequestObj);

            return {
                ...state,
                createRequestStatus: state.provisioningNeedsApproval ? 1 : 2,
                provisioningRequests
            };
        }

        case CREATE_TEAM_REQUEST_FAILURE: {
            return {
                ...state,
                createRequestStatus: action.payload.statusCode
            };
        }

        case REMOVE_TEAM_REQUEST_SUCCESS: {
            const newProvisioningRequests = state.provisioningRequests.map(request => {
                if (request.id === action.payload.itemId) {
                    request.status = 6;
                    request.statusReason = "Deleted";
                }
                return request;
            });

            return {
                ...state,
                provisioningRequests: newProvisioningRequests,
                removeRequestStatus: "success"
            };
        }

        case REMOVE_TEAM_REQUEST_FAILURE: {
            return {
                ...state,
                removeRequestStatus: "error"
            };
        }

        case RESET_TEAM_REQUEST_STATUS: {
            return {
                ...state,
                removeRequestStatus: "",
                approveRequestStatus: "",
                rejectRequestStatus: ""
            };
        }

        case APPROVE_TEAM_REQUEST_SUCCESS: {
            const newProvisioningRequests = state.provisioningRequests.map(request => {
                if (request.id === action.payload.itemId) {
                    request.status = 1;
                    request.statusReason = "Approved";
                }
                return request;
            });

            return {
                ...state,
                provisioningRequests: newProvisioningRequests,
                approveRequestStatus: "success"
            };
        }

        case APPROVE_TEAM_REQUEST_FAILURE: {
            return {
                ...state,
                approveRequestStatus: action.payload.error.message
            };
        }

        case REJECT_TEAM_REQUEST_SUCCESS: {
            const newProvisioningRequests = state.provisioningRequests.map(request => {
                if (request.id === action.payload.itemId) {
                    request.status = 2;
                    request.statusReason = "Rejected";
                }
                return request;
            });

            return {
                ...state,
                provisioningRequests: newProvisioningRequests,
                rejectRequestStatus: "success"
            };
        }

        case REJECT_TEAM_REQUEST_FAILURE: {
            return {
                ...state,
                rejectRequestStatus: action.payload.error.message
            };
        }

        case CHECK_IF_TEAM_NAME_FAILURE: {
            const error = action.payload.error;
            return {
                ...state,
                error
            };
        }

        case CHECK_IF_TEAM_NAME_SUCCESS: {
            const teamExists = action.payload.team.value.length > 0 ? true : false;
            const approvalRequired = action.payload.approvalRequired;

            return {
                ...state,
                provisioningTeamAlreadyExists: teamExists,
                provisioningNeedsApproval: approvalRequired
            };
        }

        case "@@router/LOCATION_CHANGE":
        case CLEAR_CREATE_TEAM_REQUEST_STATUS: {
            return {
                ...state,
                createRequestStatus: 0
            };
        }

        default: {
            return state;
        }
    }
};

export default teamRequestReducer;
