import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import TeamCardLogo from "./teamCardLogo/teamCardLogo";

import { Modal } from "office-ui-fabric-react/lib/Modal";
import TeamModal from "./teamModal/teamModal";
import { connect } from "react-redux";
import * as teamsActions from "../../store/teams/actions";
import { injectIntl } from "react-intl";
import TeamCardPersonaMembers from "./teamCardPersonaMembers/teamCardPersonaMembers";
import UserImage from "../../assets/images/anonymous-user-gravatar.png";

import { logUserOpenedTeamModal } from "../../logging/applicationInsights/logFunctions";

import { getToken } from "../../adalConfig";
import axios from "axios";

class TeamCard extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }
  componentLoadedOnce = false;

  state = {
    members: "",
    modelIsOpen: false,
    needToLoad: false
  };

  toggleFavourite = teamId => {
    this.props.toggleFavorite(teamId);
  };

  async componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source();

    if (this.props.pageYPosition < 200) {
      this.setState({ needToLoad: true });
    }
  }

  _openModal = id => {
    this.setState({ modelIsOpen: true });
    logUserOpenedTeamModal(
      this.props.currentUser.userPrincipalName,
      this.props.currentUser.tenant.name,
      id
    );
  };
  _getMembers = async () => {
    if (this.state.members === "" && this.componentLoadedOnce == false) {
      this.componentLoadedOnce = true;
      const membersToSend = [];
      let apiToken = await getToken();

      await axios
        .get(
          `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teams/${this.props.id}/members`,
          {
            cancelToken: this.axiosCancelSource.token,
            headers: { Authorization: "Bearer " + apiToken }
          }
        )

        .then(response => {
          response.data.forEach(member => {
            membersToSend.push(member);
          });

          this.setState({ members: membersToSend });
        })
        .catch(err => console.log(err));
    } else {
      return null;
    }
  };

  _closeModal = () => {
    this.props.removeCurrentTeamData();
    this.setState({ modelIsOpen: false });
  };

  render() {
    const { intl } = this.props;
    let members = "";
    let memberComponent = null;

    if (
      (document.getElementById(this.props.id) != "" &&
        document.getElementById(this.props.id) != null) ||
      this.state.needToLoad === true
    ) {
      const teamCardStartHeight = document.getElementById(this.props.id)
        .offsetTop;
      const pageCurrentHeight = this.props.pageYPosition;
      if (pageCurrentHeight + 1000 >= teamCardStartHeight) {
        let members = "";
        if (this.state.members === "") {
          members = this._getMembers();
        }

        if (this.state.members.length > 0) {
          memberComponent = (
            <TeamCardPersonaMembers
              members={this.state.members}
              teamId={this.props.id}
              pageYPositionY={this.props.pageYPosition}
            />
          );
        }
      }
    }

    let favouriteIcon = "";
    let visibilityIcon = "";
    let archivedIcon = "";

    const markAsNonFavorite = intl.formatMessage({
      id: "teamcard.marksAsNonFavorite",
      defaultMessage: "Stel in als niet favoriet."
    });

    const markAsFavorite = intl.formatMessage({
      id: "teamcard.marksAsFavorite",
      defaultMessage: "Stel in als favoriet."
    });

    const statusLabelPrivate = intl.formatMessage({
      id: "teamcard.statusLabelPrivate",
      defaultMessage: "Prive"
    });

    const statusLabelPublic = intl.formatMessage({
      id: "teamcard.statusLabelPublic",
      defaultMessage: "Publiek."
    });

    this.props.isFavorite === true
      ? (favouriteIcon = (
        <Icon iconName="FavoriteStarFill" title={markAsNonFavorite} />
      ))
      : (favouriteIcon = (
        <Icon iconName="FavoriteStar" title={markAsFavorite} />
      ));

    this.props.isPrivate === true
      ? (visibilityIcon = (
        <Icon iconName="LockSolid" title={statusLabelPrivate} />
      ))
      : (visibilityIcon = <Icon iconName="Unlock" title={statusLabelPublic} />);

    if (memberComponent === null) {
      memberComponent = (
        <div className="team-card-body__personas-wrapper">
          <img src={UserImage} className="team-card-persona-wrapper" />
        </div>
      );
    }

    if (this.props.teamStatus === 2) {
      archivedIcon = (
        <Icon
          iconName="Archive"
          title={intl.formatMessage({
            id: "teamModalBody.archivedStatusInactive",
            defaultMessage: "gearchiveerd"
          })}
        />
      );
    }

    return (
      <React.Fragment>
        {/* <Fade bottom delay={this.props.delay} appear={true}> */}
        <article className="team-card-wrapper" id={this.props.id}>
          <header className="team-card-wrapper__header">
            <div onClick={() => this._openModal(this.props.id)}>
              <TeamCardLogo
                injectClass="team-card-wrapper__header-photo"
                teamId={this.props.id}
              />
            </div>

            <div className="team-card-wrapper__header-options-wrapper">
              <div className="header-options__icon-group">
                <div className="header-options__group-type">{archivedIcon}</div>
              </div>
              <div className="header-options__icon-group">
                <div className="header-options__group-type">
                  {visibilityIcon}
                </div>
              </div>

              <div className="header-options__icon-group">
                <div
                  className="header-options__favourite"
                  onClick={teamId => this.toggleFavourite(this.props.id)}
                >
                  {favouriteIcon}
                </div>
              </div>
            </div>
          </header>

          <section
            className="team-card-body"
            onClick={() => this._openModal(this.props.id)}
          >
            <h1 title={this.props.title}>{this.props.title}</h1>

            <div className="team-card-body__personas-wrapper">
              {memberComponent}
            </div>

            <p className="description" title={this.props.description}>
              {this.props.description}
            </p>
          </section>
        </article>
        {/* </Fade> */}
        <Modal
          titleAriaId={this._titleId}
          subtitleAriaId={this._subtitleId}
          isOpen={this.state.modelIsOpen}
          onDismiss={this._closeModal}
          isBlocking={false}
          containerClassName="team-modal-wrapper"
          scrollableContentClassName="team-model-body"
        >
          <TeamModal
            teamId={this.props.id}
            title={this.props.title}
            description={this.props.description}
            favorite={this.props.isFavorite}
            members={this.state.members}
            closeModal={this._closeModal}
            openedFrom="myTeams"
          />
        </Modal>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel();
  }
}

const mapStateToProps = state => {
  return {
    toggleData: state.teamsSlice.toggleData,
    currentUser: state.currentUserSlice.currentUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeCurrentTeamData: () => dispatch(teamsActions.removeCurrentTeam()),
    toggleFavorite: teamId => dispatch(teamsActions.toggleFavorite(teamId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TeamCard));
