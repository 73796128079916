import React from "react";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { authorizeUserIsAdminFunction } from "../../assets/general_functions/authorizeFunctions";

import BreadCrumb from "./breadCrumb/breadCrumb";
import SettingsTile from "./settingsTile/settingsTile";

class ApplicationSettingsContainer extends React.Component {
	render() {
		const { intl } = this.props;
		const pathToPage = [
			{
				text: intl.formatMessage({
					id: "settings.panelTitle",
					defaultMessage: "Instellingen",
				}),
				key: "settings",
			},
		];

		const settingsItems = [];

		const hasAccessToAll = authorizeUserIsAdminFunction(this.props.currentUser);
		if (hasAccessToAll) {
			settingsItems.push(
				{
					title: intl.formatMessage({
						id: "settings.appSettingsTitle",
						defaultMessage: "Applicatie-Instellingen",
					}),
					description: intl.formatMessage({
						id: "settings.appSettingsDescription",
						defaultMessage: "Hier kunt u de applicatie-instellingen wijzigen.",
					}),
					routeTo: "settings/appsettings",
				},
				{
					title: intl.formatMessage({
						id: "settings.metaDataSettingTitle",
						defaultMessage: "Categorieën beheren",
					}),
					description: intl.formatMessage({
						id: "settings.metaDataSettingDescription",
						defaultMessage: "Beheer de categorieën voor de applicatie.",
					}),
					routeTo: "settings/managemetadata",
				}
			);
		}

		settingsItems.push({
			title: intl.formatMessage({
				id: "settings.allTeamRequestsSettingTitle",
				defaultMessage: "Alle team aanvragen",
			}),
			description: intl.formatMessage({
				id: "settings.allTeamRequestsSettingDescription",
				defaultMessage: "Hier kunt u alle team aanvragen bekijken en beheren.",
			}),
			routeTo: "settings/allTeamRequests",
		});

		const settingsUi = settingsItems.map((setting) => (
			<SettingsTile
				key={setting.routeTo}
				title={setting.title}
				description={setting.description}
				routeTo={setting.routeTo}
			/>
		));

		return (
			<div className="settings-page">
				<BreadCrumb path={pathToPage} />

				<div className="settings-wrapper">
					<div className="settings-header">
						<h2>
							<FormattedMessage id="settings.panelTitle" defaultMessage="Instellingen" />
						</h2>
					</div>

					<div className="setting-tile-wrapper">{settingsUi}</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUserSlice.currentUser,
	};
};

export default connect(mapStateToProps)(injectIntl(ApplicationSettingsContainer));
