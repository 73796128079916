import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

import { getGroupPlans } from "../../../../../HttpRepositories/graphRepository";
import * as teamActions from "../../../../../store/teams/actions";
import WithLoading from "../../../../utils/withLoading";

import { logUserOpenedPlan } from "../../../../../logging/applicationInsights/logFunctions";

class PlansComponent extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
	}

	state = {
		plans: []
	};

	componentDidMount() {
		this.props.fetchTeamPlans(this.props.teamId);
	}

	parsePlanUi(plan, currentUser, link) {
		return (
			<div className="plan-wrapper" key={plan.id}>
				<div className="plan-image-wrapper">
					<div className="plan-image-wrapper__image">
						<a href={link} target="_blank">
							<Icon iconName="PlannerLogo" />
						</a>
					</div>
				</div>
				<div className="plan-text-wrapper">
					<a
						href={link}
						target="_blank"
						onClick={() =>
							logUserOpenedPlan(
								currentUser.userPrincipalName,
								currentUser.tenant.name,
								plan.title
							)
						}
					>
						{plan.title}
					</a>
				</div>
			</div>
		);
	}

	render() {
		let plansArray = [];
		const domain = this.props.currentUser.tenant.domain;
		const language = this.props.currentUser.languageCode;

		if (this.props.plansLoading) {
			plansArray = <WithLoading isLoading={this.props.plansLoading} />;
		} else {
			if (Object.keys(this.props.currentTeam).length > 1) {
				if (
					this.props.currentTeam.plans != "" &&
					this.props.currentTeam.plans != "undefined"
				) {
					if (this.props.currentTeam.plans.length > 0) {
						plansArray = this.props.currentTeam.plans.map(plan => {
							const link = `https://tasks.office.com/${domain}/${language}/Home/Planner#/plantaskboard?groupId=${
								this.props.teamId
								}&planId=${plan.id}`;

							this.parsePlanUi(plan, this.props.currentUser, link);
						});
					}

				}
				else {
					if (this.props.plansLoading === false) {
						plansArray = (
							<FormattedMessage
								id="teamModalBody.planNoneAvailable"
								defaultMessage="Er zijn geen plannen."
							/>
						);
					}
				}
			}
		}

		return <div>{plansArray}</div>;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
}

const mapStateToProps = state => {
	return {
		currentUser: state.currentUserSlice.currentUser,
		currentTeam: state.teamsSlice.currentTeam,
		plansLoading: state.teamsSlice.plansLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchTeamPlans: teamId => dispatch(teamActions.fetchTeamPlans(teamId))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PlansComponent);
