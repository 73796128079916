import React from 'react';

import responsiveImage from '../../assets/images/oneResponsiveShowcase.png';
import teamOverviewImage from '../../assets/images/teamOverview.jpg';
import teamsFilterImage from '../../assets/images/teamsFilter.jpg';
import requestTeamImage from '../../assets/images/requestTeam.jpg';
import { Route, Switch } from 'react-router-dom';
import TrialRequestForm from './trialRequestForm';

class setSubscriptionPage extends React.Component {
	routeToTrialPage = () => {
		this.props.history.push('/teams');
	};
	render() {
		return (
			<React.Fragment>
				<section className='teaser'>
					<div className='teaser__container'>
						<h1>
							One for Teams<sup>&reg;</sup> de applicatie voor alle Microsoft
							Teams gebruikers.
						</h1>
						<p>
							Creëer binnen enkele minuten een duidelijk overzicht binnen teams
							d.m.v. metadata. Maak teams aan binnen een handomdraai en zie alle
							benodigde bestanden, personen en kanalen in één overzicht!
						</p>
						<img src={responsiveImage} />
					</div>
				</section>

				<section className='what-is-teams-section'>
					<div className='what-is-teams-section__container'>
						<div className='what-is-teams-section__left'>
							<h2>Wat is One Teams?</h2>
							<p>
								One teams voegt extra functionaliteit toe aan Microsoft Teams.
								Hierdoor wordt het makkelijker om uw teams en office groepen te
								managen.
							</p>
							<ul>
								<li>
									One for Teams brengt overzicht en structuur aan in alle Teams.
								</li>
								<li>Gebruik metadata om uw Teams te classificeren.</li>
								<li>
									Maak gebruik van de snelle filter- en zoekfuncties om uw Teams
									te kunnen vinden.
								</li>
								<li>
									Geef alle gebruikers de mogelijkheid om op een
									geautomatiseerde en gecontroleerde wijze nieuwe Teams aan te
									maken.
								</li>
								<li>
									One for Teams geeft in één oogopslag inzicht in de beschikbare
									informatie binnen een Team.
								</li>
							</ul>
						</div>
						<div className='what-is-teams-section__right'>
							<img src={requestTeamImage} />
						</div>
					</div>
				</section>

				<section className='why-choose-teams-section'>
					<div className='why-choose-teams-section__container'>
						<div className='why-choose-teams-section__left'>
							<img src={teamOverviewImage} />
						</div>

						<div className='why-choose-teams-section__right'>
							<h2>Waarom One Teams?</h2>
							<p>
								One for Teams steelt u en uw bedrijf in staat om snel overzicht
								te krijgen en te behouden van uw Microsoft Office 365 Teams. One
								for Teams wordt continu doorontwikkeld, zodat u optimaal gebruik
								kunt maken van de mogelijkheden van Microsoft Teams.
							</p>
						</div>
					</div>
				</section>

				<section className='free-trial-section'>
					<div className='free-trial-section__container'>
						<h1>
							Binnen enkele minuten grip op uw Office 365 Teams en groepen
						</h1>

						<div>
							<button
								onClick={() => this.routeToTrialPage()}
								className='btn btn-primary teams-submit-button teams-button'
							>
								Gratis proefversie
							</button>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default setSubscriptionPage;
