import React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

import TeamCardPersona from "../../../teamCardPersona/teamCardPersona";
import * as teamActions from "../../../../../store/teams/actions";
import WithLoading from "../../../../utils/withLoading";
import { logUserNavigatedToDelveToViewTeamMember } from "../../../../../logging/applicationInsights/logFunctions";

class MembersComponent extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
	}

	state = {
		members: [],
		owners: []
	};

	componentDidMount() {
		this.props.fetchTeamUsers(this.props.teamId);
	}

	createPersonaCard = person => {
		const { intl } = this.props;
		const link = `https://eur.delve.office.com/?u=${person.id}&v=work`;
		const currentUserName = this.props.currentUser != "" ? this.props.currentUser.name : "";

		const currentTenantName = this.props.currentUser != "" ? this.props.currentUser.tenant.name : "";
		let externalFlag = "";
		if (person.isExternal) {
			externalFlag = (
				<Icon
					iconName="Globe"
					className="external-user-warning"
					title={intl.formatMessage({
						id: "teamModalBody.membersExternalUserTitle",
						defaultMessage: "Externe gebruiker"
					})}
				/>
			);
		}

		return (
			<div className="member-wrapper" key={person.id}>
				<div className="member-image-wrapper">
					<div className="member-image-wrapper__image">
						<TeamCardPersona member={person} big={true} />
					</div>
				</div>
				<div className="member-text-wrapper">
					<a
						href={link}
						target="_blank"
						onClick={logUserNavigatedToDelveToViewTeamMember(currentUserName, currentTenantName, person.id)}
					>
						{person.displayName}
						<span>&nbsp; </span>
						{externalFlag}
					</a>
					<span className="member-text-job-title">{person.jobTitle}</span>
				</div>
			</div>
		);
	};

	render() {
		let owners = [];
		let members = [];
		let ownersContent = "";
		let membersContent = "";
		let content = "";

		if (this.props.usersLoading) {
			content = <WithLoading isLoading={this.props.usersLoading} />;
		} else {
			if (typeof this.props.currentTeam != "undefined" && Object.keys(this.props.currentTeam).length > 1) {
				let allUsers;

				if (Object.keys(this.props.currentTeam).length > 1) {
					if (this.props.currentTeam.users != "" && this.props.currentTeam.user != "undefined") {
						this.props.currentTeam.users.map(user => {
							if (user.memberType === 1) {
								owners.push(this.createPersonaCard(user));
							} else if (user.memberType === 0) {
								members.push(this.createPersonaCard(user));
							}
						});
					}
				}
			}

			if (owners.length > 0) {
				ownersContent = (
					<React.Fragment>
						<h3>
							{" "}
							<FormattedMessage
								id="teamModalBody.membersOwnersHeader"
								defaultMessage="Externe gebruiker"
							/>
						</h3>
						<div>{owners}</div>
					</React.Fragment>
				);
			}

			if (members.length > 0) {
				membersContent = (
					<React.Fragment>
						<h3>
							<FormattedMessage
								id="teamModalBody.membersMembersHeader"
								defaultMessage="Externe gebruiker"
							/>
						</h3>
						<div>{members}</div>
					</React.Fragment>
				);
			}

			content = (
				<section className="members-wrapper">
					{ownersContent}
					{membersContent}
				</section>
			);
		}

		return <React.Fragment>{content}</React.Fragment>;
	}
}

const mapStateToProps = state => {
	return {
		currentTeam: state.teamsSlice.currentTeam,
		usersLoading: state.teamsSlice.usersLoading,
		currentUser: state.currentUserSlice.currentUser
	};
};
const mapDispatchToProps = dispatch => {
	return {
		fetchTeamUsers: teamId => dispatch(teamActions.fetchTeamUsers(teamId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MembersComponent));
