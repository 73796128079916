import { adalOneApiFetch } from "../../adalConfig";

export const FETCH_TERMSETS_STARTED = "FETCH_TERMSETS_STARTED";
export const FETCH_TERMSETS_SUCCESS = "FETCH_TERMSETS_SUCCESS";
export const FETCH_TERMSETS_FAILURE = "FETCH_TERMSETS_FAILURE";

export const FETCH_TERMGROUPS_STARTED = "FETCH_TERMGROUPS_STARTED";
export const FETCH_TERMGROUPS_SUCCESS = "FETCH_TERMGROUPS_SUCCESS";
export const FETCH_TERMGROUPS_FAILURE = "FETCH_TERMGROUPS_FAILURE";

export const CREATE_TERMSET_STARTED = "CREATE_TERMSET_STARTED";
export const CREATE_TERMSETS_SUCCESS = " CREATE_TERMSET_SUCCESS";
export const CREATE_TERMSET_FAILURE = " CREATE_TERMSET_FAILURE";

export const UPDATE_TERMSET_STARTED = "UPDATE_TERMSET_STARTED";
export const UPDATE_TERMSET_SUCCESS = "UPDATE_TERMSET_SUCCESS";
export const UPDATE_TERMSET_FAILURE = "UPDATE_TERMSET_FAILURE";

export const CREATE_TERM_STARTED = "CREATE_TERM_STARTED";
export const CREATE_TERM_FAILURE = "CREATE_TERM_FAILURE";
export const CREATE_TERM_SUCCESS = "CREATE_TERM_SUCCESS";

export const UPDATE_TERM_STARTED = "UPDATE_TERM_STARTED";
export const UPDATE_TERM_FAILURE = "UPDATE_TERM_FAILURE";
export const UPDATE_TERM_SUCCESS = "UPDATE_TERM_SUCCESS";

export const CLEAR_METADATA_NOTIFICATION = "CLEAR_METADATA_NOTIFICATION";
export const UPDATE_METADATA_NAV_STATE = "UPDATE_METADATA_NAV_STATE";

const fetchTermSetsRequest = () => {
  return dispatch => {
    dispatch(fetchTermsetsStarted());

    adalOneApiFetch(
      fetch,
      `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termsets`,
      {}
    )
      .then(response => {
        // If the reponse code is not positive, throw an error.
        if (response.status != 200 && response.status != 204) {
          dispatch(fetchTermsetsFailure("fout"));
          return;
        }

        response.json().then(result => {
          dispatch(fetchTermsetsSuccess(result));
        });
      })
      .catch(error => {
        dispatch(fetchTermsetsFailure(error));
      });
  };
};

const fetchTermsetsStarted = () => ({
  type: FETCH_TERMSETS_STARTED
});

const fetchTermsetsFailure = error => {
  return {
    type: FETCH_TERMSETS_FAILURE,
    payload: {
      error
    }
  };
};

const fetchTermsetsSuccess = termSets => ({
  type: FETCH_TERMSETS_SUCCESS,
  payload: {
    ...termSets
  }
});

export const fetchTermSets = () => {
  return dispatch => {
    dispatch(fetchTermSetsRequest());
  };
};

export const fetchTermGroups = () => {
  return dispatch => {
    dispatch(fetchTermGroupsRequest());
  };
};

const fetchTermGroupsRequest = () => {
  return dispatch => {
    dispatch(fetchTermGroupsStarted());

    adalOneApiFetch(
      fetch,
      `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termgroups`,
      {}
    )
      .then(response => {
        // If the reponse code is not positive, throw an error.
        if (response.status != 200 && response.status != 204) {
          dispatch(fetchTermGroupsFailure("fout"));
          return;
        }

        response.json().then(result => {
          dispatch(fetchTermGroupsSuccess(result));
        });
      })
      .catch(error => {
        dispatch(fetchTermsetsFailure(error));
      });
  };
};

const fetchTermGroupsStarted = () => ({
  type: FETCH_TERMGROUPS_STARTED
});

const fetchTermGroupsFailure = error => {
  return {
    type: FETCH_TERMGROUPS_FAILURE,
    payload: {
      error
    }
  };
};

const fetchTermGroupsSuccess = termSets => ({
  type: FETCH_TERMGROUPS_SUCCESS,
  payload: {
    ...termSets
  }
});

export const createTermSet = termSet => {
  return dispatch => {
    dispatch(createTermSetRequest(termSet));
  };
};

const createTermSetRequest = termSet => {
  return dispatch => {
    dispatch(createTermSetStarted());

    adalOneApiFetch(
      fetch,
      `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termset`,
      {
        method: "PUT",
        body: JSON.stringify(termSet),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        // If the reponse code is not positive, throw an error.
        if (
          response.status != 200 &&
          response.status != 204 &&
          response.status != 201
        ) {
          dispatch(createTermSetFailure("fout"));
          return;
        }

        response.json().then(result => {
          dispatch(createTermSetSuccess(result));
        });
      })
      .catch(error => {
        dispatch(createTermSetFailure(error));
      });
  };
};

const createTermSetStarted = () => ({
  type: CREATE_TERMSET_STARTED
});

const createTermSetFailure = error => {
  return {
    type: CREATE_TERMSET_FAILURE,
    payload: {
      error
    }
  };
};

const createTermSetSuccess = termSet => ({
  type: CREATE_TERMSETS_SUCCESS,
  payload: {
    ...termSet
  }
});

export const updateTermSet = termSet => {
  return dispatch => {
    dispatch(updateTermSetRequest(termSet));
  };
};

const updateTermSetRequest = termSet => {
  return dispatch => {
    dispatch(updateTermSetStarted());

    adalOneApiFetch(
      fetch,
      `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termset`,
      {
        method: "PATCH",
        body: JSON.stringify(termSet),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        // If the reponse code is not positive, throw an error.
        if (
          response.status != 200 &&
          response.status != 204 &&
          response.status != 201
        ) {
          dispatch(updateTermSetFailure("fout"));
          return;
        }

        response.json().then(result => {
          dispatch(updateTermSetSuccess(result));
        });
      })
      .catch(error => {
        dispatch(updateTermSetFailure(error));
      });
  };
};

const updateTermSetStarted = () => ({
  type: UPDATE_TERMSET_STARTED
});

const updateTermSetFailure = error => {
  return {
    type: UPDATE_TERMSET_FAILURE,
    payload: {
      error
    }
  };
};

const updateTermSetSuccess = termSet => ({
  type: UPDATE_TERMSET_SUCCESS,
  payload: {
    ...termSet
  }
});

export const createTerm = term => {
  return dispatch => {
    dispatch(createTermRequest(term));
  };
};

const createTermRequest = term => {
  return dispatch => {
    dispatch(createTermStarted());

    adalOneApiFetch(
      fetch,
      `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/term`,
      {
        method: "PUT",
        body: JSON.stringify(term),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        // If the reponse code is not positive, throw an error.
        if (
          response.status != 200 &&
          response.status != 204 &&
          response.status != 201
        ) {
          dispatch(createTermFailure("fout"));
          return;
        }

        response.json().then(result => {
          dispatch(createTermSuccess(result));
        });
      })
      .catch(error => {
        dispatch(createTermFailure(error));
      });
  };
};

const createTermStarted = () => ({
  type: CREATE_TERM_STARTED
});

const createTermFailure = error => {
  return {
    type: CREATE_TERM_FAILURE,
    payload: {
      error
    }
  };
};

const createTermSuccess = term => ({
  type: CREATE_TERM_SUCCESS,
  payload: {
    ...term
  }
});

export const updateTerm = term => {
  return dispatch => {
    dispatch(updateTermRequest(term));
  };
};

const updateTermRequest = term => {
  return dispatch => {
    dispatch(updateTermStarted());

    adalOneApiFetch(
      fetch,
      `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/term`,
      {
        method: "PATCH",
        body: JSON.stringify(term),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        // If the reponse code is not positive, throw an error.
        if (
          response.status != 200 &&
          response.status != 204 &&
          response.status != 201
        ) {
          dispatch(updateTermFailure("fout"));
          return;
        }

        response.json().then(result => {
          dispatch(updateTermSuccess(result));
        });
      })
      .catch(error => {
        dispatch(updateTermFailure(error));
      });
  };
};

const updateTermStarted = () => ({
  type: UPDATE_TERM_STARTED
});

const updateTermFailure = error => {
  return {
    type: UPDATE_TERM_FAILURE,
    payload: {
      error
    }
  };
};

const updateTermSuccess = term => ({
  type: UPDATE_TERM_SUCCESS,
  payload: {
    ...term
  }
});

export const clearNotification = () => ({
  type: CLEAR_METADATA_NOTIFICATION
});
export const updateMetaDataNav = navData => ({
  type: UPDATE_METADATA_NAV_STATE,
  payload: {
    activeTab: navData
  }
});
