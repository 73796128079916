import ReactAI from 'react-appinsights';

import { md5 } from './../../assets/general_functions/objectsAndArrays';

/* ------------------------------------------------- 
--------------  COMMAND BAR  -----------------------
---------------------------------------------------- */

export const logUserClickedFilters = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft het filter menu geopend.', {
		user: md5(userName),
		action: 'commandbar',
		tenant: tenantName,
		application: 'Teams'
	});
};

export const userHasLoggedIn = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker is inglogd.', {
		user: md5(userName),
		action: 'app',
		tenant: tenantName,
		application: 'Teams'
	});
};

export const logUserOpenRequestNewTeamPanel = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent(
		"De gebruiker heeft het het scherm 'Nieuw team aanvragen' geopened.",
		{
			user: md5(userName),
			action: 'commandbar',
			tenant: tenantName,
			application: 'Teams'
		}
	);
};
export const logUserRequestedNewTeam = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft een team aangevraagd.', {
		user: md5(userName),
		action: 'commandbar',
		tenant: tenantName,
		application: 'Teams'
	});
};

export const logUserCloseRequestNewTeamPanel = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent(
		"De gebruiker heeft het het scherm 'Nieuw team aanvragen' afgesloten.",
		{
			user: md5(userName),
			action: 'commandbar',
			tenant: tenantName,
			application: 'Teams'
		}
	);
};

export const logUserRemovedAllFilters = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft de filters verwijderd.', {
		user: md5(userName),
		action: 'commandbar',
		tenant: tenantName,
		application: 'Teams'
	});
};

/* ------------------------------------------------- 
--------------  ACCOUNT ----------------------------
---------------------------------------------------- */

export const logUserLogout = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker is uitgelogd.', {
		user: md5(userName),
		action: 'account',
		tenant: tenantName,
		application: 'Teams'
	});
};

export const logUserNavigatedToDelve = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent(
		'De gebruiker is is naar zijn/haar profiel in Delve genavigeerd.',
		{
			user: md5(userName),
			action: 'account',
			tenant: tenantName,
			application: 'Teams'
		}
	);
};

export const logUserNavigatedToOffice = (userName, tenantName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker is is naar Office.com genavigeerd.', {
		user: md5(userName),
		action: 'account',
		tenant: tenantName,
		application: 'Teams'
	});
};

/* ------------------------------------------------- 
--------------  SEARCH -----------------------------
---------------------------------------------------- */

export const logUserSearch = (userName, tenantName, searchTerm) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft de zoek functionaliteit gebruikt.', {
		user: md5(userName),
		action: 'search',
		tenant: tenantName,
		searchTerm: searchTerm,
		application: 'Teams'
	});
};

/* ------------------------------------------------- 
--------------  TEAMS ------------------------------
---------------------------------------------------- */

export const logUserOpenedTeamModal = (userName, tenantName, teamId) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft een team modal geopened.', {
		user: md5(userName),
		action: 'Teams',
		tenant: tenantName,
		teamId,
		application: 'Teams'
	});
};

export const logUserStartedApplicationFromTeam = (
	userName,
	tenantName,
	application
) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent(
		'De gebruiker heeft via een team modal een applicatie gestart.',
		{
			user: md5(userName),
			action: 'Teams',
			tenant: tenantName,
			application: 'Teams'
		}
	);
};

export const logUserOpenedFile = (userName, tenantName, fileName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft via een team modal een bestand geopend.', {
		user: md5(userName),
		action: 'Teams',
		tenant: tenantName,
		fileName,
		application: 'Teams'
	});
};

export const logUserOpenedChannel = (userName, tenantName, channel) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft via een team modal een kanaal geopend.', {
		user: md5(userName),
		action: 'Teams',
		tenant: tenantName,
		channel,
		application: 'Teams'
	});
};

export const logUserOpenedPlan = (userName, tenantName, planName) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft via een team modal een plan geopend.', {
		user: md5(userName),
		action: 'Teams',
		tenant: tenantName,
		planName,
		application: 'Teams'
	});
};

export const logUserNavigatedToDelveToViewTeamMember = (
	userName,
	tenantName,
	memberId
) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent(
		'De gebruiker is via een team modal naar het delve profiel van een member genavigeerd.',
		{
			user: md5(userName),
			action: 'Teams',
			tenant: tenantName,
			memberId,
			application: 'Teams'
		}
	);
};

export const logUserOpenedEnrichMetaData = (userName, tenantName, teamId) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('Teams', {
		user: md5(userName),
		action:
			"De gebruiker heeft in het team modal het panel 'aanvullen van metadata' geopened.",
		tenant: tenantName,
		teamId,
		application: 'Teams'
	});
};

export const logUserEnrichedMetaData = (userName, tenantName, teamId) => {
	const Ai = ReactAI.ai();
	Ai.trackEvent('De gebruiker heeft de metadata van een team bijgewerkt.', {
		user: md5(userName),
		action: 'Teams',
		tenant: tenantName,
		teamId,
		application: 'Teams'
	});
};
