import React from "react";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";

import { NavLink } from "react-router-dom";

import { Settings } from "../../../assets/general_functions/settings";
import { updateApplicationSettings, closeApplicationSettingsAlert } from "../../../store/settings/actions";
import { UncontrolledAlert } from "reactstrap";

class Settingsbutton extends React.Component {
	state = {
		isAdmin: false,
		language: "nl",
		showProvisionForm: false,
		applicationSettings: [],
		tenantSettings: [],
	};

	componentDidMount() {
		if (this.props.currentUser && this.props.currentUser.roles) {
			let isAdmin = false;
			this.props.currentUser.roles.filter((role) => {
				if (role.isAdmin === true || role.code === "teammanager") {
					isAdmin = true;
				}
			});

			if (isAdmin === true) {
				this.setState({
					isAdmin: true,
					language: this.props.currentUser.languageCode,
				});
			} else {
				this.setState({
					language: this.props.currentUser.languageCode,
				});
			}
		}
	}

	render() {
		let activeLinkClass = "settings-wrapper__button";
		if (this.props.activePath.indexOf("settings") !== -1) {
			activeLinkClass = "settings-wrapper__button top-nav__active-link";
		}
		const settings = new Settings(this.props.settings);
		let panelIsOpen = false;

		if (this.props.isOpen && this.props.panelType === "settings") {
			panelIsOpen = true;
		}

		let applicationLanguage = settings.getSettingByKey("applicationSettings", "Language");
		let showApplicationProvisioningForm = settings.getSettingByKey("applicationSettings", "ShowProvisioningForm");

		if (showApplicationProvisioningForm == "") showApplicationProvisioningForm = { value: "false" };

		if (applicationLanguage == "") applicationLanguage = { value: "nl" };

		let updateAppSettings;

		if (this.props.settingsMeta != "") {
			if (this.props.settingsMeta.hasApplicationSettingsUpdated) {
				if (this.props.settingsMeta.updateApplicationSettingsError != "") {
					// if settings are updated correctly, show a message
					updateAppSettings = (
						<UncontrolledAlert
							color="danger"
							className="custom-alert-settings"
							toggle={() => this.props.closeApplicationSettingsAlert()}
						>
							<FormattedMessage
								id="account.accountSettingsUpdateFailed"
								defaultMessage="Er is iets fout gegaan"
							/>
						</UncontrolledAlert>
					);
				} else {
					// if settings could not be updated show an error
					updateAppSettings = (
						<UncontrolledAlert
							color="success"
							className="custom-alert-settings"
							toggle={() => this.props.closeApplicationSettingsAlert()}
						>
							<FormattedMessage
								id="account.accountSettingsUpdateSucces"
								defaultMessage="De instellingen zijn opgeslagen."
							/>
						</UncontrolledAlert>
					);
				}
			}
		}

		let content = "";
		const yes = (
			<FormattedMessage id="general.yes">{(txt) => <option value="True">{txt}</option>}</FormattedMessage>
		);
		const no = <FormattedMessage id="general.no">{(txt) => <option value="False">{txt}</option>}</FormattedMessage>;

		if (this.state.isAdmin) {
			// get the tenant settings.

			content = (
				<div className="settings-wrapper">
					<NavLink to="/settings">
						<button className={activeLinkClass}>
							<Icon iconName={this.props.iconName} className="navbar__office-logo-icon" />
						</button>
					</NavLink>
				</div>
			);
		}

		return <React.Fragment>{content}</React.Fragment>;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUserSlice.currentUser,
		settings: state.settingsSlice.settings,
		settingsMeta: state.settingsSlice,
		activePath: state.router.location.pathname,
	};
};

export default connect(
	mapStateToProps,
	{ updateApplicationSettings, closeApplicationSettingsAlert }
)(Settingsbutton);
