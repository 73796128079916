import React, { useEffect } from "react";
import TeamCardLogo from "../../common/teamCard/teamCardLogo/teamCardLogo";
import { Button } from 'reactstrap';
import CardComponent from './cardComponent/CardComponent';

export const convertTeamToUiComponent = (teams, fetchTeamDetails) => {

    const teamsToReturn = teams.map(team => {
        return (
            <section className="allteam-wrapper" key={team.id}>

                <CardComponent team={team} fetchTeamDetails={fetchTeamDetails} />

            </section >
        )
    })

    return teamsToReturn;
}