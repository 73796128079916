import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { FormattedMessage, injectIntl } from 'react-intl';
import {  Field, Formik } from 'formik';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';

import {
	updateUserSettings,
	closeUserSettingsAlert
} from '../../../store/settings/actions';

import { Settings } from '../../../assets/general_functions/settings';
import { getGraphToken, authContext } from '../../../adalConfig';
import userImage from '../../../assets/images/anonymous-user-gravatar.png';
import {
	logUserNavigatedToDelve,
	logUserLogout
} from '../../../logging/applicationInsights/logFunctions';

class LogoutButton extends React.Component {
	state = {
		currentUserImage: ''
	};

	componentDidMount() {
		let graphToken = getGraphToken();

		axios({
			url: 'https://graph.microsoft.com/v1.0/me/photos/64x64/$value',
			method: 'GET',
			responseType: 'blob', // important
			headers: { Authorization: 'Bearer ' + graphToken }
		})
			.then(response => {
				if (this.unmounted) return;

				if (response.status != 200 && response.status != 204) {
					console.log('error');
				}

				var urlCreator = window.URL || window.webkitURL;
				var imageUrl = urlCreator.createObjectURL(response.data);

				this.setState({ currentUserImage: imageUrl });
			})
			.catch(error => {
				console.log('error');
			});
	}

	handleLogout = () => {
		logUserLogout(
			this.props.currentUser.userPrincipalName,
			this.props.currentUser.tenant.name
		);
		authContext.logOut();
	};

	render() {
		const { intl } = this.props;
		const settings = new Settings(this.props.settings);
		const userLanguage = settings.getSettingByKey('userSettings', 'Language');
		let updateUserSettingsAlert;
		let panelIsOpen = false;

        if (this.props.isOpen && this.props.panelType === "logout") {
            panelIsOpen = true;
        }

		if (this.props.settingsMeta != '') {
			if (this.props.settingsMeta.hasUserSettingsUpdated) {
				if (this.props.settingsMeta.updateUserSettingsError != '') {
					// if settings are updated correctly, show a message
					updateUserSettingsAlert = (
						<UncontrolledAlert
							color='danger'
							className='custom-alert-settings  remove-margin'
							toggle={() => this.props.closeUserSettingsAlert()}
						>
							<FormattedMessage
								id='account.accountSettingsUpdateFailed'
								defaultMessage='Er is iets fout gegaan'
							/>
						</UncontrolledAlert>
					);
				} else {
					// if settings could not be updated show an error
					updateUserSettingsAlert = (
						<UncontrolledAlert
							color='success'
							className='custom-alert-settings remove-margin'
							toggle={() => this.props.closeUserSettingsAlert()}
						>
							<FormattedMessage
								id='account.accountSettingsUpdateSucces'
								defaultMessage='De instellingen zijn opgeslagen.'
							/>
						</UncontrolledAlert>
					);
				}
			}
		}

		const currentUserName =
			this.props.currentUser != '' ? this.props.currentUser.name : '';

		const currentTenantName =
			this.props.currentUser != '' ? this.props.currentUser.tenant.name : '';

		const currentUseremail =
			this.props.currentUser != '' ? this.props.currentUser.emailAddress : '';
		const image =
			this.state.currentUserImage != '' ? (
				<img
					className='logout-wrapper__avatar'
					src={this.state.currentUserImage}
					alt='logout button'
				/>
			) : (
				<img
					className='logout-wrapper__avatar'
					src={userImage}
					alt='logout button'
				/>
			);
		const avatarBig =
			this.state.currentUserImage != '' ? (
				<img
					className='avatarBig'
					src={this.state.currentUserImage}
					alt='logout button'
				/>
			) : (
				<img className='avatarBig' src={userImage} alt='logout button' />
			);
		const linkToProfile =
			this.props.currentUser != ''
				? `https://${this.props.currentUser.tenant.name}-my.sharepoint.com/person.aspx`
				: '';

		return (
			<section className='logout-wrapper'>
				<button
					className='logout-wrapper__button'
					onClick={() => this.props.togglePanel("logout")}
				>
					{image}
				</button>

				<Panel
					className='account-panel'
					isOpen={panelIsOpen}
					type={PanelType.smallFixedFar}
					isBlocking='false'
					closeButtonAriaLabel='Close'


					isLightDismiss={true}
					onLightDismissClick={() => this.props.togglePanel("logout")}
				>
					<section className='account-panel__panel-header'>
						<div className='account-panel__panel-header--flex'>
							<h1>
								<FormattedMessage
									id='account.settings'
									defaultMessage='Mijn instellingen'
								/>
							</h1>

							<div
								className='account-panel__panel-close'
								onClick={() => this.props.togglePanel("logout")}
							>
								<Icon iconName='ChromeClose' />
							</div>
						</div>
					</section>
					{updateUserSettingsAlert}

					<section className='personal'>
						<div className='personal-info-wrapper'>
							<div className='personal-info-wrapper__image-wrapper'>
								{avatarBig}
							</div>

							<div className='personal-info-wrapper__details'>
								<div className='details__wrapper'>
									<p className='details__wrapper--name' title={currentUserName}>
										{currentUserName}
									</p>
									<p
										className='details__wrapper--email'
										title={currentUseremail}
									>
										{currentUseremail}
									</p>
								</div>
							</div>
						</div>

						<div className='account-actions-wrapper'>
							<ul>
								<li>
									<a
										href={linkToProfile}
										target='_blank'
										onClick={() =>
											logUserNavigatedToDelve(
												currentUserName,
												currentTenantName
											)
										}
									>
										<FormattedMessage
											id='account.myProfile'
											defaultMessage='Mijn profiel'
										/>
									</a>
								</li>
								<li>
									<span
										onClick={() => this.handleLogout()}
										className='needs-underline-on-hover'
									>
										<FormattedMessage
											id='account.logout'
											defaultMessage='Uitloggen'
										/>
									</span>
								</li>
							</ul>
						</div>
					</section>

					<section className='personal-settings'>
						<div className='general-settings-wrapper'>
							<h3>
								<FormattedMessage
									id='account.userSettingsHeader'
									defaultMessage='Gebruikers instellingen'
								/>
							</h3>

							<label>
								<Formik
									initialValues={{
										Language: userLanguage.value
									}}
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											setSubmitting(false);

											const valuesToSend = settings.convertSettingsToApiSaveFormat(
												values
											);

											const result = this.props.updateUserSettings(
												valuesToSend
											);
										}, 500);
									}}
									validationSchema={Yup.object().shape({
										Language: Yup.string().required(
											intl.formatMessage({ id:"account.languageRequiredText", defaultMessage:"U moet een taal selecteren",})
										)
									})}
								>
									{props => {
										const {
											values,
											touched,
											errors,
											isSubmitting,
											handleChange,
											handleBlur,
											handleSubmit
										} = props;
										return (
											<form onSubmit={handleSubmit}>
												<label htmlFor='Language' style={{ display: 'block' }}>
													<span>
														<FormattedMessage
															id='account.languageDropdownText'
															defaultMessage='Taal'
														/>
													</span>
												</label>
												{errors.Language && touched.Language && (
													<div className='input-feedback'>
														{errors.Language}
													</div>
												)}
												<Field
													className='form-input-group'
													component='select'
													name='Language'
												>
													<option value=''>{intl.formatMessage({ id:"general.makeAChoice", defaultMessage:"Maak uw keuze"})}</option>
													<option value='nl'>Nederlands</option>
													<option value='en'>English</option>
												</Field>

												<button
													type='submit'
													disabled={isSubmitting}
													className='btn btn-primary teams-submit-button margin-bottom-2'
													disabled={
														Object.keys(errors).length !== 0 &&
														errors.constructor === Object
													}
												>
													<FormattedMessage
														id='account.saveSttingsText'
														defaultMessage='Instellingen opslaan'
														className='remove-margin'
													/>
												</button>
											</form>
										);
									}}
								</Formik>
							</label>
						</div>
					</section>
				</Panel>
			</section>
		);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}
}

const mapStateToProps = state => {
	return {
		currentUserError: state.currentUserSlice.currentUserError,
		currentUserLoading: state.currentUserSlice.currentUserLoading,
		currentUser: state.currentUserSlice.currentUser,
		settings: state.settingsSlice.settings,
		settingsMeta: state.settingsSlice
	};
};

export default connect(
	mapStateToProps,
	{ updateUserSettings, closeUserSettingsAlert }
)(injectIntl(LogoutButton));
