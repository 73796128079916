export const createMetaDataOptions = rawMetaData => {
    // we create an array to safe all the options  for all termsets.
    const optionsArray = [];
    let termSetWithOptions = [];

    if (typeof rawMetaData == "undefined") {
        return [];
    }

    // we add the termsets to the array.
    rawMetaData.map(termSet => {
        if (termSet.isActive) {
            const termSetArray = {
                name: termSet.name,
                isRequired: termSet.isRequired,
                isEditable: termSet.isEditable
            };

            // we loop over the terms of an termSet and then write it back as options to the array.
            const terms = termSet.terms.map(term => {
                return { value: `${termSet.id}-${term.id}`, label: term.name };
            });

            // in the termset we add all options as objects.
            termSetArray.items = terms;
            termSetWithOptions.push(termSetArray);
        }
    });
    return termSetWithOptions;
};

export const createMetaDataFilledInOptions = terms => {
    if (terms.length === 0) {
        return [];
    }

    // form all the entries to return an array with combined termSetid and termIds
    const allTermsForTeam = terms.map(term => {
        return { value: `${term.termSetId}-${term.id}`, label: term.name };
    });

    return allTermsForTeam;
};
