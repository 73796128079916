import React from "react";

import TreeComponent from "./treeComponent/TreeComponent";
import TermTypesTreeComponent from "./treeComponent/termTypesTreeComponent";
import { connect } from "react-redux";

import { clearNotification } from "../../store/metaData/actions";
import { clearTeamTypesNotifications } from "../../store/teamTypes/actions";
import Tabs from "../../common/utils/tabs/tabs";
import { injectIntl } from "react-intl";

class MetaDataTreeComponent extends React.Component {
  state = {
    activeTab: 1,
    bodyComponent: ""
  };

  componentDidMount() {
    this.setActiveTab(1);
  }

  setActiveTab = (selectedTabId, tabName) => {
    this.props.clearNotification();
    this.props.clearTeamTypesNotifications();
    switch (selectedTabId) {
      case 0:
        this.setState({
          activeTab: selectedTabId,
          bodyComponent: (
            <TreeComponent
              termGroupsWithChildren={this.props.termGroupsWithChildren}
              currentUser={this.props.currentUser}
            />
          )
        });
        break;

      case 1:
        this.setState({
          activeTab: selectedTabId,
          bodyComponent: (
            <TermTypesTreeComponent
              termGroupsWithChildren={this.props.termGroupsWithChildren}
              currentUser={this.props.currentUser}
            />
          )
        });
        break;
    }
  };

  render() {
    const { intl } = this.props;
    let tabData = [
      {
        id: 1,
        tabName: intl.formatMessage({
          id: "metadata.teamTypesManage",
          defaultMessage: "Teamtypes beheren"
        }),
        tabTitle: intl.formatMessage({
          id: "metadata.teamTypesManage",
          defaultMessage: "Teamtypes beheren"
        })
      },
      {
        id: 0,
        tabName: intl.formatMessage({
          id: "metadata.categoriesManage",
          defaultMessage: "Categorieën beheren"
        }),
        tabTitle: intl.formatMessage({
          id: "metadata.categoriesManage",
          defaultMessage: "Categorieën beheren"
        })
      }
    ];
    return (
      <div className="metadata-tabs-wrapper">
        <Tabs
          activeTab={this.state.activeTab}
          tabData={tabData}
          setActiveTab={(id, tabName) => this.setActiveTab(id, tabName)}
        />

        {this.state.bodyComponent}
      </div>
    );
  }
}

export default connect(null, {
  clearNotification,
  clearTeamTypesNotifications
})(injectIntl(MetaDataTreeComponent));
