import React from 'react';
import {
	BrowserRouter,
	Route,
	Switch,
	withRouter,
	Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import StylesheetLoader from '../../common/utils/stylesheetLoader/stylesheetLoader';
import Navbar from '../navbar/navbar';
import Infopage from './setSubscriptionPage';
import TrialRequestForm from './trialRequestForm';
import SubscriptionRouter from "./subscriptionRouter"
import ExpiredPage from "./expiredPage"



class SubscriptionActions extends React.Component {

	render() {
		const theme = {
			tenant: {
				theme: {
					fileNameSass: 'default.scss',
					name: 'default'
				}
			}
		};

		return (
			<React.Fragment>
				<BrowserRouter>
					<React.Fragment>
						<StylesheetLoader user={theme} />
						<Navbar type='anonymous' />

						<Switch>
							<Route
								exact
								path='/teams'
								component={withRouter(TrialRequestForm)}
							/>
							<Route
								exact
								path='/expired'
								component={withRouter(ExpiredPage)}
							/>
							<Route exact path='/info' component={withRouter(Infopage)} />
						</Switch>

						<SubscriptionRouter subscriptionType={this.props.subscriptionType} subscriptionErrorMessage={this.props.subscriptionErrorMessage} />
					</React.Fragment>
				</BrowserRouter>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentUser: state.currentUserSlice.currentUser,
		router: state.router
	};
};

export default connect(mapStateToProps)(withRouter(SubscriptionActions));