import React from 'react';
import { Breadcrumb } from 'office-ui-fabric-react/lib/Breadcrumb';
import { Redirect, withRouter } from 'react-router-dom';

class BreadCrumb extends React.Component {
	state = {
		pathItems: []
	};

	componentDidMount() {
		this.setState({ pathItems: this._mapPropsToItems() });
	}

	_navigateToPage = settingsPageToOpen => {
		this.props.history.push(`/${settingsPageToOpen}`);
	};

	_mapPropsToItems() {
		// append isCurrentItem if it's the last item in the array. otherwise make sure the link works.
		return this.props.path.map((item, i) => {
			return this.props.path.length - 1 === i
				? { ...item, isCurrentItem: true }
				: { ...item, onClick: () => this._navigateToPage(item.key) };
		});
	}

	render() {
		// TODO: create the path via for the breadcrumb via Redux router
		const breadCrumbComponent = (
			<Breadcrumb
				items={this.state.pathItems}
				ariaLabel='Breadcrumb with no maxDisplayedItems'
			/>
		);

		return <div className='breadcrumb'>{breadCrumbComponent}</div>;
	}
}

export default withRouter(BreadCrumb);
