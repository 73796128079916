import { adalOneApiFetch } from '../../adalConfig';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_STARTED = 'GET_CURRENT_USER_STARTED';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
export const SEND_USER_LOGIN_LOG = 'SEND_USER_LOGIN_LOG';

export const fetchRequest = () => {
	return (dispatch, getState) => {
		const currentState = getState();

		dispatch(fetchCurrentUserStarted());

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/me`, {})
			.then(response => {
				// If the reponse code is not positive, throw an error.
				if (response.status != 200 && response.status != 204) {
					dispatch(fetchCurrentUserFailure('fout'));
					return;
				}

				response.json().then(result => {
					dispatch(fetchCurrentUserSuccess(result));
				});
			})
			.catch(error => {
				dispatch(fetchCurrentUserFailure(error));
			});
	};
};

export const fetchCurrentUserSuccess = users => {
	return {
		type: FETCH_CURRENT_USER_SUCCESS,
		payload: {
			...users
		}
	};
};

export const sendUserLoginLog = () => {
	return {
		type: SEND_USER_LOGIN_LOG,
		payload: {
			loggedIn: true
		}
	};
};

export const fetchCurrentUserStarted = () => ({
	type: FETCH_CURRENT_USER_STARTED
});

export const fetchCurrentUserFailure = error => {
	return {
		type: FETCH_CURRENT_USER_FAILURE,
		payload: {
			error
		}
	};
};

export const fetchCurrentUser = () => {
	return dispatch => {
		dispatch(fetchRequest());
	};
};
