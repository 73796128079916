import React from "react";
import { connect } from "react-redux";
import { UncontrolledAlert } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import userImage from "../../../assets/images/anonymous-user-gravatar.png";
import { getImage } from "../../../HttpRepositories/graphRepository";
import { objectToArray } from "../../../assets/general_functions/objectsAndArrays";
import { clearCreateTeamRequestStatus } from "../../../store/teamRequests/actions";

class SummarySection extends React.Component {
    _isMounted = true;

    state = {
        currentUserImage: ""
    };

    parseNotificationAlertUi = () => {
        switch (this.props.teamRequests.createRequestStatus) {
            // 0: None, 1: SuccessNeedsApproval, 2: SuccessNoApprovalNeeded, 3: Failed
            case 1:
                return (
                    <UncontrolledAlert
                        color="success"
                        className="custom-alert"
                        toggle={this.props.clearCreateTeamRequestStatus}
                    >
                        <FormattedMessage
                            id="commandbar.teamCreatedNeedsApproval"
                            defaultMessage="Het team is aangevraagd maar wacht op goedkeuring..."
                        />
                    </UncontrolledAlert>
                );
            case 2:
                return (
                    <UncontrolledAlert
                        color="success"
                        className="custom-alert"
                        toggle={this.props.clearCreateTeamRequestStatus}
                    >
                        <FormattedMessage
                            id="commandbar.teamCreated"
                            defaultMessage="Het team wordt aangemaakt, dit kan een ogenblik duren..."
                        />
                    </UncontrolledAlert>
                );
            case 3:
                return (
                    <UncontrolledAlert
                        color="danger"
                        className="custom-alert"
                        toggle={this.props.clearCreateTeamRequestStatus}
                    >
                        <FormattedMessage
                            id="commandbar.teamCreatedFailed"
                            defaultMessage="Er is iets mis gegaan met het aanmaken van het team."
                        />
                    </UncontrolledAlert>
                );

            default:
                return "";
        }
    };

    async componentDidMount() {
        this._isMounted = true;
        let userPhoto = await getImage("https://graph.microsoft.com/beta/me/photos/48x48/$value");

        if (userPhoto !== "error") {
            if (this._isMounted) {
                this.setState({ currentUserImage: userPhoto });
            }
        }
    }

    render() {
        let alertMessage = this.parseNotificationAlertUi();
        let numberOfTeamsIJoined = null;

        if (this.props.allMyTeams) {
            if (objectToArray(this.props.allMyTeams).length > 0) {
                numberOfTeamsIJoined = objectToArray(this.props.allMyTeams).length + " Teams";
            }
        }

        let currentUserName = "";
        let currentUserJob = "";
        const image =
            this.state.currentUserImage != "" ? (
                <img src={this.state.currentUserImage} alt="Avatar" />
            ) : (
                <img src={userImage} alt="Avatar" />
            );

        if (this.props.currentUser != "") {
            currentUserName = this.props.currentUser.name;
            currentUserJob = this.props.currentUser.jobTitle;
        }

        return (
            <React.Fragment>
                <section className="summary">
                    <div className="summary-wrapper">
                        {/* shows the avatar image, job title and username */}
                        <div className="user-wrapper">
                            <div className="user-wrapper__photo">
                                <div className="user-wrapper__photo-avatar">{image}</div>
                            </div>

                            <div className="user-wrapper__userInfo">
                                <div className="user-wrapper__userInfo-text">
                                    <div className="user-wrapper__userInfo-header">
                                        <span className="blue-wrapper" title={currentUserName}>
                                            {currentUserName}
                                        </span>
                                    </div>
                                    <div className="user-wrapper__userInfo-sub" title={currentUserJob}>
                                        {currentUserJob}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="joined-teams-wrapper">
                            <div className="joined-teams-wrapper__teams-info">
                                <div className="joined-teams-wrapper__teams-info-header">
                                    <span className="blue-wrapper">
                                        <FormattedMessage id="summary.memberOf" defaultMessage="Lid van" />:
                                    </span>
                                </div>
                                <div className="joined-teams-wrapper__teams-info-sub">{numberOfTeamsIJoined}</div>
                            </div>
                        </div>
                    </div>
                </section>
                {alertMessage}
            </React.Fragment>
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUserSlice.currentUser,
        allMyTeams: state.teamsSlice.allMyTeams,
        teamRequests: state.teamRequestsSlice
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearCreateTeamRequestStatus: () => dispatch(clearCreateTeamRequestStatus())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SummarySection));
