import * as actions from "./actions";
import { sortArray } from "../../assets/general_functions/objectsAndArrays";

const initialState = {
    teamTypesError: "",
    teamTypesLoading: false,
    teamTypes: [],
    notificationMessage: "",
    notification: {
        isOpen: false,
        isSuccess: true,
        code: null // notification codes: 0: Create teamType success, 1: create teamType failed, 2: update teamType success, 3: update teamType failed,
    }
};

const teamTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_TEAM_TYPES: {
            return {
                ...state
            };
        }

        case actions.FETCH_TEAM_TYPES_FAILURE: {
            const error = action.payload.error;

            return {
                ...state,
                teamsError: error
            };
        }

        case actions.FETCH_TEAM_TYPES_SUCCESS: {
            return {
                ...state,
                teamTypes: action.payload.teamsTypes
            };
        }

        case actions.UPDATE_TEAM_TYPE: {
            return {
                ...state,
                teamTypesLoading: true,
                notification: initialState.notification
            };
        }

        case actions.UPDATE_TEAM_TYPE_SUCCESS: {
            let newState = { ...state };
            newState.teamTypes = [...newState.teamTypes];

            const newTeamTypes = newState.teamTypes.map(teamType => {
                if (teamType.id === action.payload.teamType.id) {
                    return (teamType = action.payload.teamType);
                }
                return teamType;
            });

            newTeamTypes.sort(sortArray("name")).reverse();

            return {
                ...state,
                teamTypesLoading: false,
                teamTypesError: "",
                teamTypes: newTeamTypes,
                notification: {
                    ...newState.notification,
                    isOpen: true,
                    isSuccess: true,
                    code: 2
                }
            };
        }

        case actions.UPDATE_TEAM_TYPE_FAILED: {
            return {
                ...state,
                teamTypesLoading: false,
                teamTypesError: action.payload.error,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: false,
                    code: 3
                }
            };
        }

        case actions.ADD_TEAM_TYPE: {
            return {
                ...state,
                teamTypesLoading: true
            };
        }

        case actions.ADD_TEAM_TYPE_SUCCESS: {
            let newState = { ...state };
            newState.teamTypes = [...newState.teamTypes];
            newState.teamTypes.push(action.payload.teamType);

            const newTeamTypes = newState.teamTypes.sort(sortArray("name")).reverse();

            return {
                ...state,
                teamTypesLoading: false,
                teamTypesError: "",
                teamTypes: newTeamTypes,
                notification: {
                    ...newState.notification,
                    isOpen: true,
                    isSuccess: true,
                    code: 0
                }
            };
        }

        case actions.ADD_TEAM_TYPE_FAILED: {
            return {
                ...state,
                teamTypesLoading: false,
                teamTypesError: action.payload.error,
                notification: {
                    ...state.notification,
                    isOpen: true,
                    isSuccess: false,
                    code: 1
                }
            };
        }

        case actions.UPDATE_NOTIFICATION_MESSAGE: {
            return {
                ...state,
                notificationMessage: action.payload.messageType
            };
        }

        case actions.TEAM_TYPE_CLEAR_NOTIFICATION: {
            return {
                ...state,
                notification: initialState.notification
            };
        }

        default: {
            return state;
        }
    }
};

export default teamTypesReducer;
