import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
    fetchAllTeamsRequest,
    fetchAllTeamsFirstBatchRequest,
    fetchTeamDetails,
    updateFirstBatchSection
} from "../../store/allTeams/actions";
import Loader from "../../common/utils/withLoading";
import {
    parseApplicableFilters,
    parseApplicableTerms,
    parseTeamTerms,
    compareFilters,
    filterOnStatus,
    filterOnType
} from "../../assets/general_functions/filterFunctions";
import { convertTeamToUiComponent } from "./AllTeamsHelpers";
import { FormattedMessage } from "react-intl";

class AllTeamsContainer extends React.Component {
    state = {
        scrolling: false
    };

    loadMore = () => {
        this.setState({
            scrolling: true
        });
    };

    handleScroll = () => {
        const { scrolling } = this.state;
        if (scrolling) return;
        if (typeof this.props.allTeams !== "undefined" || this.props.allTeams > 0) {
            const lastTeam = document.querySelector(".allteam-wrapper:last-of-type");

            // get the height of the current team, and get the height of the current position on screen.
            if (lastTeam) {
                const lastTeamOffset = lastTeam.offsetTop + lastTeam.clientHeight;
                const pageOffset = window.pageYOffset + window.innerHeight;
                const bottomOffset = 30;

                if (pageOffset > lastTeamOffset - bottomOffset) {
                    if (this.props.firstBatchOfTeams < this.props.allTeams) {
                        const teamsToAdd = this.props.allTeams.slice(
                            this.props.firstBatchOfTeams.length,
                            this.props.firstBatchOfTeams.length + 100
                        );

                        let allTeams = this.props.firstBatchOfTeams;
                        allTeams = allTeams.concat(teamsToAdd);

                        this.props.updateFirstBatchSection(allTeams);

                        this.loadMore();
                        setTimeout(() => {
                            this.setState({ scrolling: false });
                        }, 1500);
                    } else window.removeEventListener("scroll", this.handleScroll);
                }
            }
        }
    };

    componentDidMount() {
        // if the allTeams if already available, disable the call to get the first 100 teams.
        if (typeof this.props.allTeams != "undefined" && this.props.allTeams.length > 0) {
            const firstBatchOfTeams = this.props.allTeams.slice(0, 100);
            this.props.updateFirstBatchSection(firstBatchOfTeams);
        } else {
            this.props.fetchAllTeamsFirstBatchRequest();
        }
    }

    applyFilters = teams => {
        let applicableTermSets = [];
        let filteredTeams = [];

        // create a list with applicableFilters
        applicableTermSets = parseApplicableFilters(applicableTermSets, this.props.activeFilters);

        // now that we have the applicableFilterList we sort all terms inside the termsets.
        parseApplicableTerms(applicableTermSets, this.props.activeFilters);

        // loop over the teams and create an object with the filters in it as an array.
        teams.filter(team => {
            team = parseTeamTerms(team, team.terms);
        });

        // see if the length of the team filters is at least as long as the applicableFilters
        teams.filter(team => {
            const filteredTeam = compareFilters(team, applicableTermSets);

            if (filteredTeam != "" && typeof filteredTeam != "undefined") {
                filteredTeams.push(filteredTeam);
            }
        });

        return filteredTeams;
    };

    render() {
        let {
            activeFilters,
            currentTeam,
            allTeams,
            teamsAreLoading,
            fetchAllTeamsFirstBatchRequest,
            firstBatchOfTeams,
            firstBatchOfTeamsLoading,
            fetchTeamDetails
        } = this.props;
        this.scrollListener = window.addEventListener("scroll", this.handleScroll);
        let content = "";
        let filterSummary = "";
        let showMoreTeamsLoading = <Loader isLoading={true} />;

        if (typeof this.props.allTeams != "undefined" && this.props.allTeams.length > 0) {
            showMoreTeamsLoading = <Loader isLoading={false} />;
        }

        if (firstBatchOfTeamsLoading === true) {
            content = <Loader isLoading={firstBatchOfTeamsLoading} />;
        } else {
            let teams = "";
            let activeStatusFilters = [];
            let activeTypeFilters = [];

            if (this.props.allTeams != "undefined" && this.props.allTeams.length > 0) {
                let filteredTeams = this.props.allTeams;

                // if there is an active filter
                if (typeof activeFilters !== "undefined" && activeFilters.length > 0) {
                    firstBatchOfTeams = this.applyFilters(filteredTeams);
                    filteredTeams = firstBatchOfTeams;
                }

                // If a status filter has been selected, filter on those.
                if (this.props.activeStatusFilters !== undefined && this.props.activeStatusFilters.length > 0) {
                    firstBatchOfTeams = filterOnStatus(filteredTeams, this.props.activeStatusFilters);
                    filteredTeams = firstBatchOfTeams;

                    // Define the active status filters UI.
                    activeStatusFilters = this.props.activeStatusFilters.map(filter => {
                        return (
                            <span key={filter.statusCode}>
                                <div className="filter-cancel-block__text">
                                    <FormattedMessage id={filter.intlId} defaultMessage={filter.defaultMessage} />
                                </div>
                            </span>
                        );
                    });
                }

                // If a type filter has been selected, filter on those.
                if (this.props.activeTypeFilters !== undefined && this.props.activeTypeFilters.length > 0) {
                    firstBatchOfTeams = filterOnType(filteredTeams, this.props.activeTypeFilters);
                    filteredTeams = firstBatchOfTeams;

                    // Define the active type filters UI.
                    activeTypeFilters = this.props.activeTypeFilters.map(filter => {
                        return (
                            <span key={filter.id}>
                                <div className="filter-cancel-block__text">{filter.name}</div>
                            </span>
                        );
                    });
                }

                let cancelableFilters = this.props.activeFilters.map(filter => {
                    const titleText = `${filter.termSetName} - ${filter.termName} `;
                    return (
                        <span key={filter.termId}>
                            <div className="filter-cancel-block__text" title={titleText}>
                                {filter.termName}
                            </div>
                        </span>
                    );
                });

                if (cancelableFilters.length > 0 || activeStatusFilters.length > 0 || activeTypeFilters.length > 0) {
                    filterSummary = (
                        <div className="filter-summary filter-summary__all-teams">
                            <FormattedMessage id="teams.activeFilters" defaultMessage="Actieve filters." />:{" "}
                            {activeStatusFilters} {activeTypeFilters} {cancelableFilters}
                        </div>
                    );
                } else {
                    filterSummary = <div className="no-filters-spacer no-filters-spacer__all-teams" />;
                }

                // always use all teams to search upon, unless there is an active filter.
                let searchSource = this.props.allTeams;
                if (typeof activeFilters !== "undefined" && activeFilters.length > 0) {
                    searchSource = firstBatchOfTeams;
                }

                // when a search term is defined, apply it on the teams.
                if (this.props.searchTerm != "" && typeof this.props.searchTerm != "undefined") {
                    firstBatchOfTeams = searchSource.filter(team => {
                        if (team.title && team.description) {
                            if (
                                team.title.toLowerCase().indexOf(this.props.searchTerm) != -1 ||
                                team.description.toLowerCase().indexOf(this.props.searchTerm) != -1
                            ) {
                                return team;
                            }
                        } else if (team.title) {
                            if (team.title.toLowerCase().indexOf(this.props.searchTerm) != -1) {
                                return team;
                            }
                        }
                    });
                }

                teams = convertTeamToUiComponent(firstBatchOfTeams, fetchTeamDetails);
            }
            content = (
                <React.Fragment>
                    <div className="team-all-card-wrapper">
                        {filterSummary}
                        <div></div>
                        {teams}
                    </div>
                    {showMoreTeamsLoading}
                </React.Fragment>
            );
        }

        return <div>{content}</div>;
    }
}

AllTeamsContainer.propTypes = {
    allTeams: PropTypes.array.isRequired,
    teamsAreLoading: PropTypes.bool.isRequired,
    fetchAllTeamsFirstBatchRequest: PropTypes.func.isRequired,
    fetchAllTeamsRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    allTeams: state.teamsSlice.allTeams,
    teamsAreLoading: state.teamsSlice.loading,
    firstBatchOfTeams: state.allTeamsSlice.firstBatchOfTeams,
    firstBatchOfTeamsLoading: state.allTeamsSlice.firstBatchLoading,
    currentTeam: state.allTeamsSlice.currentTeam,
    activeFilters: state.filtersSlice.activeFilters,
    activeStatusFilters: state.filtersSlice.activeStatusFilters,
    activeTypeFilters: state.filtersSlice.activeTypeFilters,
    searchTerm: state.teamsSlice.searchTerm
});

export default connect(
    mapStateToProps,
    { fetchAllTeamsRequest, fetchAllTeamsFirstBatchRequest, fetchTeamDetails, updateFirstBatchSection }
)(AllTeamsContainer);
