import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import Tabs from "../../../utils/tabs/tabs";
import FilesComponent from "./filesComponent/filesComponent";
import ChannelsComponent from "./channelsComponent/channelsComponent";
import MembersComponent from "./membersComponent/membersComponent";
import PlansComponent from "./plansComponent/plansComponent";
import InfoComponent from "./InfoComponent/infoComponent";
import WithLoading from "../../../utils/withLoading";

class TeamModalBody extends React.Component {
    state = {
        activeTab: 0,
        bodyComponent: <InfoComponent openedFrom={this.props.openedFrom} />
    };

    setActiveTab = (selectedTabId, tabName) => {
        switch (selectedTabId) {
            case 0:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: <InfoComponent openedFrom={this.props.openedFrom} />
                });
                break;

            case 1:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: <MembersComponent teamId={this.props.teamId} members={this.props.members} />
                });
                break;
            case 2:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: <ChannelsComponent teamId={this.props.teamId} />
                });
                break;

            case 3:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: <FilesComponent teamId={this.props.teamId} />
                });
                break;

            case 4:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: <PlansComponent teamId={this.props.teamId} />
                });
                break;

            default:
                this.setState({
                    activeTab: selectedTabId,
                    bodyComponent: <InfoComponent openedFrom={this.props.openedFrom} />
                });
        }
    };

    render() {
        const { intl } = this.props;
        let content = "";
        let hasTermsetsMissing = 2;
        let privateShowAllLinks = false;

        // if user is admin we want to show an exclamation mark when termsets are missing on the i nfo tab.
        // otherwise don't show it.
        let currentUserIsAdmin = false;

        if (
            this.props.currentTeam != undefined &&
            this.props.currentTeam.users != undefined &&
            this.props.currentTeam.users.length > 0
        ) {
            this.props.currentTeam.users.map(user => {
                // we user the user.objectId as the id from office 365. because user.id points to the one db userId
                if (user.memberType === 1 && this.props.currentUser.objectId === user.id) {
                    currentUserIsAdmin = true;
                }
            });
        }
        if (hasTermsetsMissing && currentUserIsAdmin) {
            hasTermsetsMissing = this.props.currentTeam.isMetadataComplete;
        }

        if (this.props.loading) {
            content = <WithLoading isLoading={this.props.loading} />;
        } else {
            content = this.state.bodyComponent;
        }

        const filesTabName = intl.formatMessage({
            id: "teamModalBody.filesTabName",
            defaultMessage: "Bestanden"
        });

        const filesTabTitle = intl.formatMessage({
            id: "teamModalBody.filesTabTitle",
            defaultMessage: "Alle bestanden"
        });

        const plansTabName = intl.formatMessage({
            id: "teamModalBody.plansTabName",
            defaultMessage: "Plannen"
        });

        const plansTabTitle = intl.formatMessage({
            id: "teamModalBody.plansTabTitle",
            defaultMessage: "Alle plannen"
        });

        const membersTabName = intl.formatMessage({
            id: "teamModalBody.membersTabName",
            defaultMessage: "Leden"
        });

        const membersTabTitle = intl.formatMessage({
            id: "teamModalBody.membersTabTitle",
            defaultMessage: "Alle leden"
        });

        const channelsTabTitle = intl.formatMessage({
            id: "teamModalBody.channelsTabTitle",
            defaultMessage: "kanalen"
        });

        const infoTabName = intl.formatMessage({
            id: "teamModalBody.infoTabName",
            defaultMessage: "Info"
        });

        const infoTabTitle = intl.formatMessage({
            id: "teamModalBody.infoTabTitle",
            defaultMessage: "Team informatie"
        });

        let tabData = [
            {
                id: 0,
                tabName: infoTabName,
                tabTitle: infoTabTitle,
                needsExclamationMark: hasTermsetsMissing
            },
            {
                id: 1,
                tabName: membersTabName,
                tabTitle: membersTabTitle,
                needsExclamationMark: 2
            }
        ];

        // if the call comes from all teams, the group is public and the user is a member of it
        if (this.props.openedFrom === "allTeams") {
            if (this.props.allTeamsIAmMemberOf.length > 0) {
                for (var i = 0; i < this.props.allTeamsIAmMemberOf.length; i++) {
                    if (this.props.allTeamsIAmMemberOf[i].id === this.props.teamId) {
                        privateShowAllLinks = true;
                        break;
                    }
                }
            }
        }

        // if the call comes from my teams or
        // if the call comes from all teams and the group is public or
        // if the call comes from all teams, the group is private and the user is a member of the group.
        if (
            this.props.openedFrom === "myTeams" ||
            (this.props.currentTeam != "" &&
                this.props.openedFrom === "allTeams" &&
                this.props.currentTeam.isPrivate === false) ||
            privateShowAllLinks === true
        ) {
            tabData = [
                {
                    id: 0,
                    tabName: infoTabName,
                    tabTitle: infoTabTitle,
                    needsExclamationMark: hasTermsetsMissing
                },
                {
                    id: 1,
                    tabName: membersTabName,
                    tabTitle: membersTabTitle,
                    needsExclamationMark: 2
                },
                {
                    id: 2,
                    tabName: channelsTabTitle,
                    tabTitle: channelsTabTitle,
                    needsExclamationMark: 2
                },
                {
                    id: 3,
                    tabName: filesTabName,
                    tabTitle: filesTabTitle,
                    needsExclamationMark: 2
                },
                {
                    id: 4,
                    tabName: plansTabName,
                    tabTitle: plansTabTitle,
                    needsExclamationMark: 2
                }
            ];
        }

        return (
            <section className="team-modal-body-wrapper">
                <Tabs
                    activeTab={this.state.activeTab}
                    tabData={tabData}
                    setActiveTab={(id, tabName) => this.setActiveTab(id, tabName)}
                />

                <div className="team-model-body__content">{content}</div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.teamsSlice.teamsError,
        loading: state.teamsSlice.detailsLoading,
        currentTeam: state.teamsSlice.currentTeam,
        currentUser: state.currentUserSlice.currentUser
    };
};

export default connect(mapStateToProps)(injectIntl(TeamModalBody));
