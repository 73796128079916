import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { UncontrolledAlert } from "reactstrap";

import { clearNotification } from "../../../../store/metaData/actions";
import { fetchFilters } from "../../../../store/filters/actions";
import { clearTeamTypesNotifications } from "../../../../store/teamTypes/actions";

import TermForm from "./termForm/TermForm";
import TermSetForm from "./termSetForm/TermSetForm";
import TermGroupForm from "./termGroupForm/TermGroupForm";
import TeamTypesForm from "./teamTypeForm/teamTypeForm";

class TreeDetailsWindowComponent extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.notification.isOpen && !prevProps.notification.isOpen) {
      this.props.fetchFilters();
    }
  }

  renderTeamTypeNotificationAlert = () => {
    const { isSuccess, code } = this.props.teamTypesNotification;
    let teamTypeMessageText = "";

    // notification codes: 0: Create teamType success, 1: create teamType failed, 2: update teamType success, 3: update teamType failed,
    switch (code) {
      case 0:
        teamTypeMessageText = (
          <FormattedMessage
            id="metadata.addTeamTypeSuccessAlert"
            defaultMessage="Teamtype is succesvol aangemaakt"
          />
        );
        break;
      case 1:
        teamTypeMessageText = (
          <FormattedMessage
            id="metadata.addTeamTypeFailedAlert"
            defaultMessage="Er ging iets mis bij het aanpassen van de teamtype"
          />
        );
        break;

      case 2:
        teamTypeMessageText = (
          <FormattedMessage
            id="metadata.updateTeamTypeSuccessAlert"
            defaultMessage="Teamtype is succesvol aangepast"
          />
        );
        break;

      case 3:
        teamTypeMessageText = (
          <FormattedMessage
            id="metadata.updateTeamTypeFailedAlert"
            defaultMessage="Er ging iets mis bij het aanpassen van de teamtype"
          />
        );
        break;
    }

    return (
      <UncontrolledAlert
        color={isSuccess ? "success" : "danger"}
        style={{ marginBottom: "1rem" }}
        toggle={() => this.props.clearTeamTypesNotifications()}
      >
        {teamTypeMessageText}
      </UncontrolledAlert>
    );
  };

  renderNotificationAlert = () => {
    const { isSuccess, code } = this.props.notification;
    let messageText = "";

    switch (code) {
      case 0:
        messageText = (
          <FormattedMessage
            id="metadata.createTermSuccessAlert"
            defaultMessage="Term aangemaakt"
          />
        );
        break;
      case 1:
        messageText = (
          <FormattedMessage
            id="metadata.createTermFailedAlert"
            defaultMessage="Er ging iets mis bij het aanmaken van de term"
          />
        );
        break;
      case 2:
        messageText = (
          <FormattedMessage
            id="metadata.updateTermSuccessAlert"
            defaultMessage="Term succesvol aangepast"
          />
        );
        break;
      case 3:
        messageText = (
          <FormattedMessage
            id="metadata.updateTermFailedAlert"
            defaultMessage="Er ging iets mis bij het aanpassen van de term"
          />
        );
        break;
      case 4:
        messageText = (
          <FormattedMessage
            id="metadata.createTermSetSuccessAlert"
            defaultMessage="TermSet aangemaakt"
          />
        );
        break;
      case 5:
        messageText = (
          <FormattedMessage
            id="metadata.createTermSetFailedAlert"
            defaultMessage="Er ging iets mis bij het aanmaken van de termSet"
          />
        );
        break;
      case 6:
        messageText = (
          <FormattedMessage
            id="metadata.updateTermSetSuccessAlert"
            defaultMessage="TermSet succesvol aangepast"
          />
        );
        break;
      case 7:
        messageText = (
          <FormattedMessage
            id="metadata.updateTermSetFailedAlert"
            defaultMessage="Er ging iets mis bij het aanpassen van de termSet"
          />
        );
        break;
    }

    return (
      <React.Fragment>
        <UncontrolledAlert
          color={isSuccess ? "success" : "danger"}
          style={{ marginBottom: "1rem" }}
          toggle={() => this.props.clearNotification()}
        >
          {messageText}
        </UncontrolledAlert>
      </React.Fragment>
    );
  };

  render() {
    let formToRender = (
      <FormattedMessage
        id="metadata.detailsContentText"
        defaultMessage="Selecteer een categorie aan de linkerkant."
      />
    );
    let headerText = "";
    let notification = "";
    let teamTypesNotification = "";

    if (
      this.props.activeItem.mode === "add" &&
      this.props.activeItem.activeItemType === "termSet"
    ) {
      headerText = (
        <FormattedMessage
          id="metadata.newTermText"
          defaultMessage="Nieuwe termset aanmaken"
        />
      );
    } else if (
      this.props.activeItem.mode === "add" &&
      this.props.activeItem.activeItemType === "termGroup"
    ) {
      headerText = (
        <FormattedMessage
          id="metadata.newTermSetText"
          defaultMessage="Nieuwe term aanmaken"
        />
      );
    } else if (
      this.props.activeItem.mode === "update" &&
      this.props.activeItem.activeItemType === "termGroup"
    ) {
      headerText = this.props.activeItem.activeItem.name;
    } else {
      // we check whether an active type has been set. if not show a default text.
      if (this.props.activeItem.activeItemType == "") {
        headerText = (
          <FormattedMessage
            id="metadata.detailsHeaderText"
            defaultMessage="Categorieën beheren"
          />
        );
      } else {
        headerText = this.props.activeItem.activeItem.name;
      }
    }

    if (
      this.props.activeItem.mode === "add" &&
      this.props.activeItem.activeItemType === "termSet" &&
      this.props.actionFromTeamTypes
    ) {
      headerText = (
        <FormattedMessage
          id="metadata.newTeamTypeText"
          defaultMessage="Nieuwe teamtype aanmaken"
        />
      );
    }

    if (this.props.notification.isOpen) {
      notification = this.renderNotificationAlert();
    }

    if (this.props.teamTypesNotification.isOpen) {
      teamTypesNotification = this.renderTeamTypeNotificationAlert();
    }

    switch (this.props.activeItem.activeItemType) {
      case "termGroup": {
        // if the mode is update we show the term group form, else we show the term set form
        if (
          this.props.activeItem.mode === "update" &&
          !this.props.actionFromTeamTypes
        ) {
          formToRender = <TermGroupForm activeItem={this.props.activeItem} />;
        } else {
          formToRender = (
            <TermSetForm
              mode={this.props.activeItem.mode}
              activeItem={this.props.activeItem.activeItem}
              cannotEdit={this.props.activeItem.cannotEdit}
            />
          );
        }

        break;
      }

      case "termSet": {
        if (
          this.props.activeItem.mode === "update" &&
          !this.props.actionFromTeamTypes
        ) {
          formToRender = (
            <TermSetForm
              mode={this.props.activeItem.mode}
              activeItem={this.props.activeItem.activeItem}
              cannotEdit={this.props.activeItem.cannotEdit}
            />
          );
        } else {
          if (
            this.props.actionFromTeamTypes &&
            this.props.activeItem.activeFlyOut.type != "termSet"
          ) {
            formToRender = (
              <TeamTypesForm
                mode={this.props.activeItem.mode}
                activeItem={this.props.activeItem.activeItem}
              />
            );
          } else if (this.props.activeItem.activeFlyOut.type != "termSet") {
            formToRender = (
              <TermForm
                mode={this.props.activeItem.mode}
                activeItem={this.props.activeItem.activeItem}
              />
            );
          }
        }

        break;
      }

      case "term": {
        if (this.props.actionFromTeamTypes) {
          formToRender = (
            <TeamTypesForm
              mode={this.props.activeItem.mode}
              activeItem={this.props.activeItem.activeItem}
              cannotEdit={this.props.activeItem.cannotEdit}
            />
          );
        } else {
          formToRender = (
            <TermForm
              mode={this.props.activeItem.mode}
              activeItem={this.props.activeItem.activeItem}
              cannotEdit={this.props.activeItem.cannotEdit}
            />
          );
        }

        break;
      }
    }

    return (
      <section className="treeview-details-wrapper">
        {teamTypesNotification}
        {notification}
        <section className="treeview-details-content">
          <h1>{headerText}</h1>
          {formToRender}
        </section>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    notification: state.metaDataSlice.notification,
    teamTypesNotification: state.teamTypesSlice.notification
  };
};

TreeDetailsWindowComponent.propTypes = {
  activeItem: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  clearNotification,
  fetchFilters,
  clearTeamTypesNotifications
})(TreeDetailsWindowComponent);
