import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import {
  addTeamType,
  updateTeamType,
  updateNotificationMessage
} from "../../../../../store/teamTypes/actions";

class TeemTypeFormComponent extends React.Component {
  render() {
    const { intl } = this.props;

    let id = this.props.activeItem.id;
    let name = "";
    let isActive = true;
    let isDisabled = "disabled";
    let approvalRequired = false;
    let submitButton = "";
    let submitButtonText = (
      <FormattedMessage id="general.formCreateText" defaultMessage="Aanmaken" />
    );

    if (this.props.mode === "update") {
      id = this.props.activeItem.id;
      name = this.props.activeItem.name;
      isActive = this.props.activeItem.isActive;
      approvalRequired = this.props.activeItem.approvalRequired;
      submitButtonText = (
        <FormattedMessage
          id="general.formEditText"
          defaultMessage="Aanpassen"
        />
      );
    }

    if (!this.props.cannotEdit) {
      isDisabled = "";
      submitButton = (
        <button type="submit" className="btn btn-primary teams-submit-button">
          {submitButtonText}
        </button>
      );
    }

    return (
      <div className="details-form-wrapper">
        <Formik
          enableReinitialize
          initialValues={{
            id: id,
            name: name,
            isActive: isActive,
            approvalRequired: approvalRequired
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              // we do this check because for a termset the active item gets set.
              // but in this case we don't want this to happen on the teamType.
              if (this.props.activeItem.isTeamType) {
                values.isActive = false;
              }

              if (this.props.mode === "add") {
                this.props.addTeamType(values);
              } else {
                this.props.updateTeamType(values);
              }

              setSubmitting(false);
            }, 500);
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(
                2,
                intl.formatMessage({
                  id: "metadata.form.validation.termNameMinTwoCharacters",
                  defaultMessage: "U moet minstens 2 tekens gebruiken."
                })
              )
              .required(
                intl.formatMessage({
                  id: "metadata.form.validation.termNameRequired",
                  defaultMessage: "U moet een naam voor de term opgeven"
                })
              )
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  className="form-input-group"
                  type="hidden"
                  name="id"
                  value={id}
                ></Field>

                <label htmlFor="name">
                  <FormattedMessage
                    id="metadata.form.teamType.nameLabel"
                    defaultMessage="Teamtype"
                  />
                </label>
                {errors.name && touched.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}

                <div className="form-input-wrapper">
                  <div className="form-input-description">
                    <FormattedMessage
                      id="metadata.form.teamType.nameDescription"
                      defaultMessage="De naam voor het teamtype"
                    />
                  </div>
                  <div className="form-input-field">
                    <input
                      id="name"
                      placeholder={intl.formatMessage({
                        id: "metadata.form.termNameInputDescription",
                        defaultMessage: "Voer een naam voor deze term in"
                      })}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="checkbox-spacer">
                  <label htmlFor="isActive" style={{ display: "block" }}>
                    <FormattedMessage
                      id="metadata.form.termIsActiveInputLabel"
                      defaultMessage="Is actief"
                    />
                  </label>

                  <div className="form-input-wrapper">
                    <div className="form-input-description">
                      <FormattedMessage
                        id="metadata.form.termIsActiveInputDescription"
                        defaultMessage="Deze term mag aan teams gekoppeld worden"
                      />
                    </div>

                    <div className="form-input-field">
                      <Field
                        type="checkbox"
                        name="isActive"
                        checked={values.isActive}
                        onChange={() => {
                          setFieldValue("isActive", !values.isActive);
                        }}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>

                <div className="checkbox-spacer">
                  <label
                    htmlFor="approvalRequired"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage
                      id="metadata.form.teamType.approvalRequiredLabel"
                      defaultMessage="Goedkeuring vereist"
                    />
                  </label>

                  <div className="form-input-wrapper">
                    <div className="form-input-description">
                      <FormattedMessage
                        id="metadata.form.teamType.approvalRequiredDescription"
                        defaultMessage="Bepaald of een team aanvraag goegkeuring vereist"
                      />
                    </div>

                    <div className="form-input-field">
                      <Field
                        type="checkbox"
                        name="approvalRequired"
                        checked={values.approvalRequired}
                        onChange={() => {
                          setFieldValue(
                            "approvalRequired",
                            !values.approvalRequired
                          );
                        }}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>

                {submitButton}
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default connect(null, {
  addTeamType,
  updateTeamType,
  updateNotificationMessage
})(injectIntl(TeemTypeFormComponent));
