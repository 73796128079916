import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form, Field, Formik } from 'formik';
import { UncontrolledAlert } from 'reactstrap';
import * as Yup from 'yup';

import BreadCrumb from '../../breadCrumb/breadCrumb';
import { Settings } from '../../../../assets/general_functions/settings';
import {
	updateApplicationSettings,
	closeApplicationSettingsAlert
} from '../../../../store/settings/actions';

class AppSettingsPageComponent extends React.Component {
	state = {
		isAdmin: false,
		language: 'nl',
		showProvisionForm: false,
		applicationSettings: [],
		tenantSettings: []
	};

	componentDidMount() {
		if (this.props.currentUser) {
			let isAdmin = false;

			this.props.currentUser.roles.filter(role => {
				if (role.isAdmin === true) {
					isAdmin = true;
				}
			});

			if (isAdmin === true) {
				this.setState({
					isAdmin: true,
					language: this.props.currentUser.languageCode
				});
			} else {
				this.setState({ language: this.props.currentUser.languageCode });
			}
		}
	}

	render() {
		const settings = new Settings(this.props.settings);
		let updateAppSettingsBar;
		let content = '';

		let applicationLanguage = settings.getSettingByKey(
			'applicationSettings',
			'Language'
		);
		let showApplicationProvisioningForm = settings.getSettingByKey(
			'applicationSettings',
			'ShowProvisioningForm'
		);

		if (showApplicationProvisioningForm == '')
			showApplicationProvisioningForm = { value: 'false' };

		if (applicationLanguage == '') applicationLanguage = { value: 'nl' };

		const yes = (
			<FormattedMessage id='general.yes'>
				{txt => <option value='true'>{txt}</option>}
			</FormattedMessage>
		);
		const no = (
			<FormattedMessage id='general.no'>
				{txt => <option value='false'>{txt}</option>}
			</FormattedMessage>
		);

		const pathToPage = [
			{
				text: <FormattedMessage id="settings.panelTitle" defaultMessage="Instellingen" />,
				key: 'settings'
			},
			{
				text: <FormattedMessage id="account.appSettingsPanelTitle" defaultMessage="Instellingen" />,
				key: 'settings/appsettings'
			}
		];

		if (this.props.settingsMeta != '') {
			if (this.props.settingsMeta.hasApplicationSettingsUpdated) {
				if (this.props.settingsMeta.updateApplicationSettingsError != '') {
					// if settings are updated correctly, show a message
					updateAppSettingsBar = (
						<UncontrolledAlert
							color='danger'
							className='custom-alert-settings'
							toggle={() => this.props.closeApplicationSettingsAlert()}
						>
							<FormattedMessage
								id='account.accountSettingsUpdateFailed'
								defaultMessage='Er is iets fout gegaan'
							/>
						</UncontrolledAlert>
					);
				} else {
					// if settings could not be updated show an error
					updateAppSettingsBar = (
						<UncontrolledAlert
							color='success'
							className='custom-alert-settings'
							toggle={() => this.props.closeApplicationSettingsAlert()}
						>
							<FormattedMessage
								id='account.accountSettingsUpdateSucces'
								defaultMessage='De instellingen zijn opgeslagen.'
							/>
						</UncontrolledAlert>
					);
				}
			}
		}

		if (this.state.isAdmin) {
			content = (
				<div>
					<label>
						<Formik
							initialValues={{
								ShowProvisioningForm: showApplicationProvisioningForm.value,
								Language: applicationLanguage.value
							}}
							onSubmit={(values, { setSubmitting }) => {
								setTimeout(() => {
									setSubmitting(false);

									const valuesToSend = settings.convertSettingsToApiSaveFormat(
										values
									);

									const result = this.props.updateApplicationSettings(
										valuesToSend
									);
								}, 500);
							}}
							validationSchema={Yup.object().shape({})}
						>
							{props => {
								const {
									values,
									touched,
									errors,
									isSubmitting,
									handleChange,
									handleBlur,
									handleSubmit
								} = props;
								return (
									<form onSubmit={handleSubmit}>
										<label
											htmlFor='ShowProvisioningForm'
											style={{ display: 'block' }}
										>
											<span>
												<FormattedMessage
													id='account.showProvisioningDropdownText'
													defaultMessage='Toon aanvraagformulier voor nieuwe teams aan gebruikers'
												/>
											</span>
										</label>
										{errors.ShowProvisioningForm &&
											touched.ShowProvisioningForm && (
												<div className='input-feedback'>
													{errors.ShowProvisioningForm}
												</div>
											)}
										<Field
											className='form-input-group'
											component='select'
											name='ShowProvisioningForm'
											autoFocus={true}
										>
											{yes}
											{no}
										</Field>

										<label htmlFor='Language' style={{ display: 'block' }}>
											<span>
												<FormattedMessage
													id='account.languageDropdownText'
													defaultMessage='Taal'
												/>
											</span>
										</label>
										{errors.Language && touched.Language && (
											<div className='input-feedback'>{errors.Language}</div>
										)}
										<Field
											className='form-input-group'
											component='select'
											name='Language'
										>
											<option value='nl'>Nederlands</option>
											<option value='en'>English</option>
										</Field>

										<button
											type='submit'
											disabled={isSubmitting}
											className='btn btn-primary teams-submit-button margin-bottom-2'
										>
											<FormattedMessage
												id='account.saveSttingsText'
												defaultMessage='Instellingen opslaan'
												className='remove-margin'
											/>
										</button>
									</form>
								);
							}}
						</Formik>
					</label>
				</div>
			);
		}

		return (
			<div className='settings-page'>
				<BreadCrumb path={pathToPage} />
				{updateAppSettingsBar}

				<div className='settings-wrapper'>
					<div className='settings-header'>
						<h2><FormattedMessage id="account.appSettings" defaultMessage="Applicatie-Instellingen" /></h2>
					</div>

					<section className='setting-pages-wrapper'>
						<div
							className='settings-wrapper-half-page'
							test-name='settings-tile'
						>
							<div className='settings-tile-body'>{content}</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentUser: state.currentUserSlice.currentUser,
		settings: state.settingsSlice.settings,
		settingsMeta: state.settingsSlice
	};
};

export default connect(
	mapStateToProps,
	{ updateApplicationSettings, closeApplicationSettingsAlert }
)(AppSettingsPageComponent);
