import React from "react";

class StylesheetLoader extends React.Component {
	render() {
		// check if the the user object is set
		if (
			typeof this.props.user !== "undefined" &&
			Object.keys(this.props.user).length > 0
		) {
			// grab the fileName and stylesheet.
			const cssToLoad = this.props.user.tenant.theme.fileNameSass;
			const cssFileName = this.props.user.tenant.theme.name;

			// load the stylesheet, if the value was not set or any other error occurs it will load the default.

			try {
				require(`../../../styles/themes/${cssToLoad}`);
			} catch {
				require("../../../styles/themes/default.scss");
			}
		}

		return null;
	}
}

export default StylesheetLoader;
