import React from "react";
import { Redirect, withRouter } from "react-router-dom";

class SettingsTile extends React.Component {
    render() {
        const openSettingsPage = settingsPageToOpen => {
            this.props.history.push(`/${settingsPageToOpen}`);
        };

        return (
            <div
                className="settings-tile"
                onClick={() => openSettingsPage(this.props.routeTo)}
                test-name="settings-tile"
            >
                <div className="settings-tile-header">{this.props.title}</div>
                <div className="settings-tile-body">{this.props.description}</div>
            </div>
        );
    }
}

export default withRouter(SettingsTile);
