import React from "react";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import { UncontrolledAlert } from "reactstrap";
import { connect } from "react-redux";

import RequestedTeamOverviewComponent from "./requestedTeamOverviewComponent/requestedTeamOverviewComponent";
import { closeNotificationAlert } from "../.././../store/teamRequests/actions";

class NotificationHubButtton extends React.Component {
    renderSubHeaderUi = header => {
        return (
            <h3>
                <span>{header}</span>
            </h3>
        );
    };

    render() {
        let content = "";
        let panelIsOpen = false;
        let notificationBar = "";

        const notificationHeaderText = (
            <FormattedMessage id="notificationHub.notificationHeaderText" defaultMessage="Notificaties" />
        );

        const requestedTeamsHeaderText = (
            <FormattedMessage id="notificationHub.requestedHeaderText" defaultMessage="Recente team aanvragen" />
        );

        let requestedTeamsHeader = this.renderSubHeaderUi(requestedTeamsHeaderText);

        if (this.props.isOpen && this.props.panelType === "notificationHub") {
            panelIsOpen = true;
        }

        if (this.props.teamRequests.removeRequestStatus !== "") {
            if (this.props.teamRequests.removeRequestStatus === "success") {
                notificationBar = (
                    <UncontrolledAlert
                        color="success"
                        className="custom-alert-settings"
                        toggle={() => this.props.closeNotificationAlert()}
                        style={{ marginBottom: "0" }}
                    >
                        <FormattedMessage id="notificationHub.deleteSuccess" defaultMessage="Aanvraag verwijderd" />
                    </UncontrolledAlert>
                );
            } else if (this.props.teamRequests.removeRequestStatus === "error") {
                notificationBar = (
                    <UncontrolledAlert
                        color="danger"
                        className="custom-alert-settings"
                        toggle={() => this.props.closeNotificationAlert()}
                        style={{ marginBottom: "0" }}
                    >
                        <FormattedMessage
                            id="notificationHub.deleteFailure"
                            defaultMessage="Er ging iets mis met het verwijderen van de aanvraag"
                        />
                    </UncontrolledAlert>
                );
            }
        }

        content = (
            <React.Fragment>
                <div className="notification-hub-wrapper">
                    <button
                        className="notification-hub-wrapper__button"
                        onClick={() => this.props.togglePanel("notificationHub")}
                    >
                        <FontAwesomeIcon icon={faBell} className="team-card-persona-wrapper__photo-icon" />
                    </button>
                </div>

                <Panel
                    isOpen={panelIsOpen}
                    type={PanelType.smallFixedFar}
                    closeButtonAriaLabel="Close"
                    className="account-panel settings-panel"
                    isLightDismiss={true}
                    onLightDismissClick={() => this.props.togglePanel("notificationHub")}
                >
                    <section className="account-panel__panel-header ">
                        <div className="account-panel__panel-header--flex">
                            <h1>{<FormattedMessage id="notificationHub.panelHeader" defaultMessage="Berichten" />}</h1>

                            <div
                                className="account-panel__panel-close"
                                onClick={() => this.props.togglePanel("notificationHub")}
                            >
                                <Icon iconName="ChromeClose" />
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="notification-wrapper">{notificationBar}</div>
                        <div className="general-notification-hub-wrapper">
                            <div className="requested-teams-wrapper">
                                {requestedTeamsHeader}
                                <RequestedTeamOverviewComponent togglePanel={this.props.togglePanel} />
                            </div>
                        </div>
                    </section>
                </Panel>
            </React.Fragment>
        );
        return <React.Fragment>{content}</React.Fragment>;
    }
}

const mapStateToProps = state => {
    return {
        teamRequests: state.teamRequestsSlice
    };
};

export default connect(
    mapStateToProps,
    { closeNotificationAlert }
)(NotificationHubButtton);
