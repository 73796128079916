import { adalOneApiFetch } from "../../adalConfig";

export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_FILTERS_STARTED = "FETCH_FILTERS_STARTED";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_FAILURE = "FETCH_FILTERS_FAILURE";
export const SAVE_FILTERS = "SAVE_FILTERS";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const REMOVE_ALL_FILTERS = "REMOVE_ALL_FILTERS";
export const UNSET_NEEDS_TO_DELETE = "UNSET_NEEDS_TO_DELETE";

const fetchFiltersRequest = () => {
    return dispatch => {
        dispatch(getFiltersStarted());

        adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termsets`, {})
            .then(response => {
                // If the reponse code is not positive, throw an error.
                if (response.status != 200 && response.status != 204) {
                    dispatch(fetchFiltersFailure("fout"));
                    return;
                }

                response.json().then(result => {
                    dispatch(fetchTFiltersSucces(result));
                });
            })
            .catch(error => {
                dispatch(fetchFiltersFailure(error));
            });
    };
};

const getFiltersStarted = () => ({
    type: FETCH_FILTERS_STARTED
});

const fetchFiltersFailure = error => {
    return {
        type: FETCH_FILTERS_FAILURE,
        payload: {
            error
        }
    };
};

const fetchTFiltersSucces = filters => ({
    type: FETCH_FILTERS_SUCCESS,
    payload: {
        ...filters
    }
});

export const fetchFilters = () => {
    return dispatch => {
        dispatch(fetchFiltersRequest());
    };
};

export const saveFilters = (filters, teamsToFilter, searchType, statusFilters, typeFilters) => ({
    type: SAVE_FILTERS,
    payload: {
        filters,
        teamsToFilter,
        searchType,
        statusFilters,
        typeFilters
    }
});

export const removeFilter = filterToRemove => ({
    type: REMOVE_FILTER,
    payload: {
        filterToRemove
    }
});

export const unsetNeedsToDelete = () => ({
    type: UNSET_NEEDS_TO_DELETE
});

export const removeAllFilters = () => ({
    type: REMOVE_ALL_FILTERS
});
