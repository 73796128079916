import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/app/App";
import CheckTenantHasAccess from "./containers/checkTenantHasAccess/checkTenantHasAccess";
import "react-app-polyfill/ie11";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import nl from "react-intl/locale-data/nl";
import en from "react-intl/locale-data/en";
import { addLocaleData } from "react-intl";

import "bootstrap/dist/css/bootstrap.min.css";

import configureStore, { history } from "./store/configureStore";
import { polyfillObjectarrays } from "./assets/general_functions/objectsAndArrays";

polyfillObjectarrays();
addLocaleData([...en, ...nl]);

const store = configureStore(/* provide initial state if any */);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={CheckTenantHasAccess} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
