import React from "react";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Alert } from "reactstrap";
import { objectToArray } from "../../../assets/general_functions/objectsAndArrays";

import { connect } from "react-redux";

import * as teamsActions from "../../../store/teams/actions";
import { logUserSearch } from "../../../logging/applicationInsights/logFunctions";

class searchBar extends React.Component {
	_timeout = 0;
	_searchTerm = "";

	state = {
		searchPristine: true
	};

	_onChange = (e, searchTerm, filteredTeamSet) => {
		let searchType = "";
		let filteredTeams = [];
		this._searchTerm = searchTerm;

		if (this._timeout) clearTimeout(this._timeout);
		this._timeout = setTimeout(() => {
			this.props.onSetTeamSearchTerm(
				this._searchTerm,
				'private',
				filteredTeams
			);

			logUserSearch(
				this.props.currentUser.userPrincipalName,
				this.props.currentUser.tenant.name,
				this._searchTerm
			);
		}, 500);
	};


	componentDidUpdate(prevState) {
		let searchType = "";

		if (prevState.searchTerm != this.props.searchTerm) {
			if (
				this.props.teamsLoading === false &&
				this.props.teamsSearchTerm != ""
			) {
				this.props.onSetTeamSearchTerm(this._searchTerm, searchType);
			}
		}
	}

	render() {
		const { intl } = this.props;
		let searchType = '';
		let disableSearch = true;

		const searchPlaceholder = intl.formatMessage({
			id: "search.placeholder",
			defaultMessage: "Zoeken..."
		});


		// if we are on the myteams page load "all my teams", otherwise load all teams.
		if (
			this.props.location.pathname === "/teams" ||
			this.props.location.pathname === "/"
		) {
			searchType = "private";
			if (typeof this.props.myTeams != 'undefined' && this.props.myTeams.length > 0) {
				disableSearch = false;
			}

		} else {
			searchType = "public";
			if (typeof this.props.allTeams != 'undefined' && this.props.allTeams.length > 0) {
				disableSearch = false;
			}

		}



		return (
			<div className="search-bar">
				<TextField
					iconProps={{ iconName: "Search" }}
					placeholder={searchPlaceholder}
					onChange={this._onChange}
					value={this.props.teamsSearchTerm}
					onFocus={this._onFocus}
					disabled={disableSearch}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		teamsSearchTerm: state.teamsSlice.searchTerm,
		myTeams: state.teamsSlice.allMyTeams,
		allTeams: state.teamsSlice.allTeams,
		currentUser: state.currentUserSlice.currentUser,
		route: state.router.location.pathname
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSetTeamSearchTerm: (searchTerm, searchType, filteredTeamSet) =>
			dispatch(
				teamsActions.setTeamsSearchTerm(
					searchTerm,
					searchType,
					objectToArray(filteredTeamSet)
				)
			),
		fetchAllTeams: type => dispatch(teamsActions.fetchAllTeams(type))
	};
};

export default withRouter(
	injectIntl(
		connect(
			mapStateToProps,
			mapDispatchToProps,
			null,
			{ withRed: true }
		)(searchBar)
	)
);
