import { getGraphToken, getToken } from '../adalConfig';
import axios from 'axios';

export const GetGroupMembers = async groupId => {
	// we initiate a new token, to be sure that it didn't expire.
	let graphToken = getGraphToken();

	try {
		let response = await axios({
			url: `https://graph.microsoft.com/v1.0/groups/${groupId}/members?$select=id,displayName`,
			method: 'GET',
			headers: { Authorization: 'Bearer ' + graphToken }
		});

		if (response.status != 200 && response.status != 204) {
			return 'error';
		}

		return await response.data.value;
	} catch (error) {
		return 'error';
	}
};
export const getGroupPlans = async groupId => {
	// we initiate a new token, to be sure that it didn't expire.
	let graphToken = await getGraphToken();

	try {
		let response = await axios({
			url: `https://graph.microsoft.com/v1.0/groups/${groupId}/planner/plans`,
			method: 'GET',
			headers: { Authorization: 'Bearer ' + graphToken }
		});

		if (response.status != 200 && response.status != 204) {
			return 'error';
		}

		return await response.data.value;
	} catch (error) {
		return 'error';
	}
};
export const getImage = async url => {
	// we initiate a new token, to be sure that it didn't expire.
	let graphToken = getGraphToken();

	try {
		let response = await axios({
			url: url,
			method: 'get',
			responseType: 'blob',
			headers: { Authorization: 'Bearer ' + graphToken }
		});

		if (response.status != 200 && response.status != 204) {
			return 'error';
		}

		var urlCreator = window.URL || window.webkitURL;
		var imageUrl = urlCreator.createObjectURL(response.data);

		return await imageUrl;
	} catch (error) {
		return 'error';
	}
};

export const getPersonaImage = async url => {
	// we initiate a new token, to be sure that it didn't expire.
	let graphToken = getToken();

	try {
		let response = await axios({
			url: url,
			method: 'get',
			responseType: 'blob',
			headers: { Authorization: 'Bearer ' + graphToken }
		});

		if (response.status != 200 && response.status != 204) {
			return 'error';
		}

		var urlCreator = window.URL || window.webkitURL;
		var imageUrl = urlCreator.createObjectURL(response.data);
		const image = await { image: imageUrl, type: response.data.type };

		return image;
	} catch (error) {
		return 'error';
	}
};
