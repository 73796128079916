import React from "react";
import { FormattedMessage } from "react-intl";

export const defineTeamRequestStatusUi = teamRequestStatus => {
    const teamRequestStatusUi = {
        statusText: "",
        fontColor: "black"
    };

    switch (teamRequestStatus) {
        case 0:
            teamRequestStatusUi.statusText = (
                <FormattedMessage id="teamRequest.statusTextRequested" defaultMessage="Aangevraagd" />
            );
            break;
        case 2:
            teamRequestStatusUi.statusText = (
                <FormattedMessage id="teamRequest.statusTextRejected" defaultMessage="Afgewezen" />
            );
            teamRequestStatusUi.fontColor = "darkorange";
            break;
        // Functionally, the status 'Approved' and 'In Progress' are the same. Because of this the text of 'In Progress' in shown to prevent confusion.
        case 1:
        case 3:
            teamRequestStatusUi.statusText = (
                <FormattedMessage id="teamRequest.statusTextInProgress" defaultMessage="In behandeling" />
            );
            break;
        case 4:
            teamRequestStatusUi.statusText = (
                <FormattedMessage id="teamRequest.statusTextReady" defaultMessage="Aangemaakt" />
            );
            teamRequestStatusUi.fontColor = "green";
            break;
        case 5:
            teamRequestStatusUi.statusText = (
                <FormattedMessage id="teamRequest.statusTextFailed" defaultMessage="Mislukt" />
            );
            teamRequestStatusUi.fontColor = "red";
            break;
        case 6:
            teamRequestStatusUi.statusText = (
                <FormattedMessage id="teamRequest.statusTextDeleted" defaultMessage="Verwijderd" />
            );
            teamRequestStatusUi.fontColor = "darkorange";
            break;
        default:
            break;
    }
    return teamRequestStatusUi;
};
