import React from "react";
import BreadCrumb from "../../breadCrumb/breadCrumb";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { fetchTermGroups } from "../../../../store/metaData/actions";
import Loader from "../../../../common/utils/withLoading";
import MetaDataTreeComponent from "../../../../common/metaDataTreeComponent/metaDataTreeComponent";

class ManageMetaDataPageComponent extends React.Component {
    async componentDidMount() {
        await this.props.fetchTermGroups();
    }

    render() {
        // set up the breadcrumb path.
        let content = null;
        const pathToPage = [
            {
                text: <FormattedMessage id="settings.panelTitle" defaultMessage="Instellingen" />,
                key: "settings"
            },
            {
                text: <FormattedMessage id="metadata.panelTitle" defaultMessage="filterdata beheren" />,
                key: "settings/managemetadata"
            }
        ];

        // if metaData is still loading show the spinner.
        if (this.props.termSetsLoading) {
            content = <Loader isLoading={true} />;
        }

        if (Array.isArray(this.props.termGroups) && this.props.termGroups.length) {
            content = (
                <section className="settings-page">
                    <section className="settings-wrapper">
                        <div className="settings-header">
                            <FormattedMessage id="metadata.panelTitle" defaultMessage="filterdata beheren" />
                        </div>
                        <section className="settings-pages-wrapper">
                            <div className="settings-wrapper-full-page">
                                <div className="settings-tile-body">
                                    <MetaDataTreeComponent
                                        termGroupsWithChildren={this.props.termGroups}
                                        currentUser={this.props.currentUser}
                                    />
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
            );
        }

        return (
            <div>
                <BreadCrumb path={pathToPage} />
                {content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        termGroups: state.metaDataSlice.termGroups,
        termGroupsError: state.metaDataSlice.termGroupsError,
        termGroupsLoading: state.metaDataSlice.termGroupsLoading,
        currentUser: state.currentUserSlice.currentUser
    };
};

export default connect(
    mapStateToProps,
    { fetchTermGroups }
)(ManageMetaDataPageComponent);
