import React from 'react';
import SearchBar from '../../common/utils/searchBar/searchBar';
import { NavLink } from 'react-router-dom';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

class LeftNavigation extends React.Component {
	render() {
		return (
			<nav className='left-navigation-menu'>
				<SearchBar />

				<h2>
					<FormattedMessage id='nav.teams' defaultMessage='Teams' />
				</h2>

				<ul>
					<li>
						<NavLink to='/teams' activeClassName='left-nav__active-link'>
							<div className='left-navigation-menu__menu-item-wrapper'>
								<div className='left-navigation-menu__icon'>
									<Icon iconName='Group' />
								</div>
								<div className='left-navigation-menu__text'>
									<FormattedMessage
										id='nav.myTeams'
										defaultMessage='Mijn Teams'
									/>
								</div>
							</div>
						</NavLink>
					</li>
					<li>
						<NavLink to='/allTeams' activeClassName='left-nav__active-link'>
							<div className='left-navigation-menu__menu-item-wrapper'>
								<div className='left-navigation-menu__icon'>
									<Icon iconName='Search' />
								</div>
								<div className='left-navigation-menu__text'>
									<FormattedMessage
										id='nav.discoverTeams'
										defaultMessage='Alle teams'
									/>
								</div>
							</div>
						</NavLink>
					</li>
				</ul>
			</nav>
		);
	}
}

export default LeftNavigation;
